import React, { useState } from 'react';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import {Row,Form,Button} from 'react-bootstrap';
import PricingList from './pricingList';
import { Checkbox } from 'antd';


const options = [
    { label: 'Accommodation Provider', value: 'Accommodation Provider' },
    { label: 'Tour Guide', value: 'Tour Guide' },
    { label: 'Cab Vendor', value: 'Cab Vendor' },
    { label: 'Bus Vendor', value: 'Bus Vendor' },
    { label: 'Rental Vehicle Vendor', value: 'Rental Vehicle Vendor' },
    { label: 'Charter Plane Vendor', value: 'Charter Plane Vendor' },
    { label: 'Activities Vendor', value: 'Activities Vendor' },
  ];

const Pricing = (props)=>{
    const [category,updateCategory] = useState([]);
    const [percentage,updatePercentage] = useState('');
    function onChange(checkedValues) {
        updateCategory(checkedValues);
    }
    const handleSubmit = async()=>{
         
    }
 
    return(
        <div className={classes.Box}>
            <Form.Group controlId="formBasicAddress">
                <Form.Label>Pricing For:</Form.Label>
                <Checkbox.Group 
                    style={{marginRight:"30px"}} 
                    options={options} 
                    onChange={onChange}
                    required/>
            </Form.Group>
            <Form.Group controlId="formBasicNumber">
                <Form.Label>Pricing Percentage:</Form.Label>
                <Form.Control
                    type="number" 
                    placeholder="Enter Pricing Percentage" 
                    name="percentage"
                    onChange={(e)=>updatePercentage(e.target.value)}
                    value={percentage}
                    required/>
            </Form.Group>
            
            <center>
                <Button onClick={handleSubmit} style={{margin:'2%'}}>Save Pricing</Button>
            </center>  
            <hr/>
            <PricingList/>                                                                                                             
        </div>
    )
}

export default Pricing;