import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import {createStore,applyMiddleware, combineReducers} from 'redux';
import thunk from 'redux-thunk';
import {Provider} from 'react-redux';
import App from './App';
import accountReducer from './entities/reducer/reducer';
import adminReducer from './entities/account/reducer/reducer';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'semantic-ui-css/semantic.min.css';
import 'antd/dist/antd.css';

const Combined = combineReducers({
  account:accountReducer,
  adminAccount:adminReducer
})
 
const store = createStore(Combined,applyMiddleware(thunk))
const app = ( 
  <Provider store={store}>
    <BrowserRouter>
       <App/>
    </BrowserRouter>
  </Provider>
)
 
ReactDOM.render(app,
  document.getElementById('root')
);

serviceWorker.unregister();
