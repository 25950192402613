import {
    AUTH_SUCCESS,
    AUTH_FAIL,
    LOGOUT_START_INIT,
    LOGOUT_SUCCESS,
    LOGIN_VERIFIED,
    GET_DEPARTMENT,
    GET_DESIGNATION,
    AUTH_REFRESH
} from '../../actionType';

const INIT_STATE = {
    isLoggedIn:false,
    isLoading:false,
    apiResponse:null,
    departments:[],
    designations:[]
}

const reducer = (state=INIT_STATE,action)=>{
    switch(action.type){
        case LOGIN_VERIFIED:
            return{...state,isLoggedIn:true}
        case AUTH_SUCCESS:
            return{...state,isLoading:false,apiResponse:action.payload}
        case LOGOUT_START_INIT:
            return{...state,isLoading:true}
        case AUTH_REFRESH:
            return{...state,isLoading:true}
        case LOGOUT_SUCCESS:
            return{...state,isLoading:false,isLoggedIn:false,apiResponse:null}
        case AUTH_FAIL:
            return{...state,isLoading:false,isLoggedIn:false}
        case GET_DEPARTMENT:
            return{...state,departments:action.payload}
        case GET_DESIGNATION:
            return{...state,designations:action.payload}    
        default:
            return state            
    }
}
export default reducer;