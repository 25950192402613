import React, { useState,useEffect } from 'react';
import {Col,Button,Form, Container, Row,Table} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DraftEditor from '../draftEditor';
import { Checkbox } from 'antd';
import classesX from '../WebsiteManagement/websiteManagement.module.css'
// import {useSelector} from 'react-redux';
import { WalletOffer,WalletOfferDelete } from '../../entities/action/action';

const WalletOffers = (props)=>{
    const options = [
        { label: 'Vendors', value: 'Vendors' },
        { label: 'Customers', value: 'Customers' }
    ];
    // const userData = useSelector(state=>state.adminAccount.apiResponse);
    const [dataSet, updateDataSet] = useState([]);
    const [benifits, setBenifits] = useState([]);
    const [message,updateMessage] = useState('');
    const onChange = (checkedValues)=> {
        setBenifits(checkedValues);
    }
    useEffect(()=>{
        (async()=>{
            const newData = {
                "emailId": "",
                "sessionKey": "",
              }
            const myresponse = await fetch("https://api.zupptravel.com/getWalletOffers", {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(newData),
                });
                const myjson = await myresponse.json();
                console.log(myjson);
                if(myjson.successful){
                    updateDataSet(myjson.moneyManagementWalletOffersList);
                }
        })()
    },[])

    const handleDelete = async(id)=>{
        const data = {
            "id": id,
            "loginid": "",
            "sessionKey": "userData.sessionId"
        }
        const res = await WalletOfferDelete(data);
        if(res.successful){
            alert("Successfully Deleted");
            window.location.reload();
        }else{
            alert("Something went wrong!!");
        }
    }
     
    return(
        <> 
        <Container fluid> 
        <Formik
            initialValues={{rechargeAmount:0,getAmountpercentage:0}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    const newData ={
                        "amountPercentage": values.getAmountpercentage,
                        "benefitsFor": benifits.toString(),
                        "emailId":  "",
                        "getAmount":  values.rechargeAmount+ ((values.getAmountpercentage*values.rechargeAmount)/100),
                        "rechargeAmount": values.rechargeAmount,
                        "sessionKey": "",
                        "termsAndConditions": message
                    }
                    console.log(newData);
                    const response = await WalletOffer(newData); 
                    if(response){
                        alert("Successfully added wallet offer");
                        resetForm();
                        window.location.reload();
                    }else{
                        alert("Something went wrong!!!!");
                    }
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="formBasicAddress" as={Row}>
                                            <Form.Label column sm="2">Offers For:</Form.Label>
                                            <Checkbox.Group 
                                                style={{marginRight:"30px"}} 
                                                options={options} 
                                                value={benifits}
                                                onChange={onChange}                   
                                            />
                                    </Form.Group>
                                        
                                        <Form.Group controlId="formBasicPromo" as={Row} style={{marginTop:'2%'}}>
                                            <Form.Label column sm="2">Benifit Type:</Form.Label>
                                            <Col sm="10"> 
                                                <Row>
                                                          
                                                    <Col xs={2}>Recharge Amount</Col>
                                                    <Col xs={4}>
                                                    <Form.Control
                                                        type="number" 
                                                        min={0}
                                                        placeholder="Recharge Amount" 
                                                        name="rechargeAmount"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.rechargeAmount}
                                                        />
                                                    </Col>
                                                    <Col xs={2}>Get Amount Percentage</Col>
                                                    <Col xs={4}>
                                                    <Form.Control
                                                        type="number" 
                                                        min={0}
                                                        placeholder="Get Amount Percentage" 
                                                        name="getAmountpercentage"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.getAmountpercentage}
                                                        />
                                                    </Col>
                                                    <Col xs={2}>Get Amount</Col>
                                                    <Col xs={4}>
                                                    <Form.Control
                                                        type="number" 
                                                        name="getAmount"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.rechargeAmount+ ((values.getAmountpercentage*values.rechargeAmount)/100)}
                                                        />
                                                    </Col>
                                                    <Col xs={2}>Get Cashback</Col>
                                                    <Col xs={4}>
                                                    <Form.Control
                                                        type="number" 
                                                        name="getCashback"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.getCashback}
                                                        />
                                                    </Col>
                                                   
                                                </Row>
                                            </Col>
                                        </Form.Group>
                                        <Row style={{margin:'2% 0'}}>
                                            <Col sm={2} style={{margin:'2% 0'}} className={classes.Col}>
                                                Terms & Conditions:
                                            </Col>
                                            <Col sm={10} style={{border:'1px solid black'}}>
                                                <DraftEditor change={(text)=>updateMessage(text)} text={message}/>
                                            </Col>
                                        </Row>
                                        <center>
                                            <Button type="submit" variant="success">Submit</Button>
                                        </center>
                                        <br/>
                                    </Form>
                            )}
                        </Formik>
                        <div className="container">
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>S No.</th>
                                    <th>Date</th>
                                    <th>Offer for</th>
                                    <th>Recharge Amount</th>
                                    <th>Offer Amount</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dataSet.map((data,key) =>
                                        <tr key={key}>
                                            <td>{key+1}</td>
                                            <td>{data.dateCreated}</td>
                                            <td>{data.benefitsFor}</td>
                                            <td>{data.rechargeAmount}</td>
                                            <td>{data.getAmount}</td>
                                            <td className={classesX.CenterRow}>
                                            <Button style={{ marginLeft: '-50px' }} onClick={() => handleDelete(data.id)}>Delete</Button>
                                        </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </Table>
                    </div>   
                </Container>
                     
            </>         
    )
}
export default WalletOffers;