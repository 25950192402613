import React, { useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import MultipleSelect from "../../multiSelect";

import { DatePicker } from "antd";

import moment from "moment";
const { RangePicker } = DatePicker;

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
}

const BlockAndUnblock = (props) => {
  const [selected, updateSelected] = useState("");
  const [date, updateDate] = useState([]);
  const [select, updateSelect] = useState([]);
  const categoryList = [
    { type: "CATEGORY" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
  ];
  return (
    <div>
      <Formik
        initialValues={{
          unitSericeVariablemsp: "",
          taxSlabVariablemsp: "",
          unitSericeVariableocc: "",
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {}}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicActionType" as={Row}>
              <Form.Label column sm="2">
                Action Type:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  placeholder="Action Type"
                  name="actionType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.actionType}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Block">Block</option>
                  <option value="Unblock">Unblock</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicBusName" as={Row}>
              <Form.Label column sm="2">
                Cab Registration:
              </Form.Label>
              <Col sm="10">
                <SingleSelect change={(select) => updateSelected(select)} />
              </Col>
            </Form.Group>
            {/* <Form.Group controlId="formBasicSeatType" as={Row}>
                                            <Form.Label column sm="2">Seat Type:</Form.Label>
                                            <Col sm="10">
                                                <MultipleSelect type="1" selectedValue={(selected)=>updateSelect(selected)}/>
                                            </Col>
                                        </Form.Group> */}

            <Form.Group controlId="formBasictimePerSlot" as={Row}>
              <Form.Label column sm="2">
                Date:
              </Form.Label>
              <Col>
                <RangePicker
                  onChange={(val) => updateDate(val)}
                  value={date}
                  disabledDate={disabledDate}
                />
              </Col>
            </Form.Group>
            {/* <Form.Group controlId="formBasicdateFrom" as={Row}>
                                            <Form.Label column sm="2">Date From:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="date" 
                                                name="dateFrom"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.dateFrom}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicdateTo" as={Row}>
                                            <Form.Label column sm="2">Date To:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="date" 
                                                name="dateTo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.dateTo}
                                                required/>
                                            </Col>
                                        </Form.Group> */}
            {/* <Form.Group controlId="formBasicMetaNoOfSeats" as={Row}>
                                            <Form.Label column sm="2">No. of Seats:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter No. of Seats" 
                                                name="noOfSeats"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.noOfSeats}
                                                required/>
                                            </Col>
                                        </Form.Group> */}
            <center>
              <Button type="submit" variant="success">
                Block/Unblock Room
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default BlockAndUnblock;
