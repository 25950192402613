import React, { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { AddDepartment, AddDesignation } from "../../../entities/action/action";
import AddFeaturesAndFacilitiesList from "./addFeaturesAndFacilitiesList";

const AddFeaturesAndFacilitiesForm = (props) => {
  const [input, SetInput] = useState("");
  const [icon, SetIcon] = useState("");
  // const handleSubmit = async()=>{
  //     let newData;
  //     let response;
  //     if(props.categoryType=="Features"){
  //         newData = {
  //             "department": input
  //         }
  //         response = await AddDepartment(newData);
  //     }else{
  //         newData = {
  //             "designation": input
  //         }
  //         response = await AddDesignation(newData);
  //     }
  //     if(response==200){
  //         alert("Successfully Added");
  //         SetInput('');
  //         window.location.reload();
  //     }
  // }
  const handleSubmit = async () => {
    if (props.categoryType == "Features") {
      const newdata = {
        features: input,
        vehicleFeaturesId: 0,
        emailId: "string",
        icon: icon,
        sessionKey: "string",
      };
      console.log(newdata);
      const res = await fetch("https://api.zupptravel.com/addVehicleFeatures", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      console.log(json);
      if (json.successful) {
        alert("Added Features");
        window.location.reload();
      }
    } else {
      const newdata = {
        facilities: input,
        vehicleFacilitiesId: 0,
        emailId: "string",
        icon: icon,
        sessionKey: "string",
      };
      console.log(newdata);
      const res = await fetch(
        "https://api.zupptravel.com/addVehicleFacilities",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newdata),
        }
      );
      const json = await res.json();
      console.log(json);
      if (json.successful) {
        alert("Added Facility");
        window.location.reload();
      }
    }
  };
  return (
    <div>
      <Form.Group controlId="formBasicPromo" as={Row}>
        <Form.Label column sm="3">
          {props.categoryType}:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            placeholder={`Enter ${props.categoryType}`}
            name="category"
            onChange={(e) => SetInput(e.target.value)}
            value={input}
            required
          />
        </Col>
      </Form.Group>
      <Form.Group controlId="formBasicIcon" as={Row}>
        <Form.Label column sm="3">
          Icon:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            placeholder="Enter Icon"
            name="icon"
            onChange={(e) => SetIcon(e.target.value)}
            value={icon}
            required
          />
        </Col>
      </Form.Group>
      <center>
        <Button onClick={handleSubmit} style={{ margin: "2%" }}>
          Save {`${props.categoryType}`}
        </Button>
      </center>
      <AddFeaturesAndFacilitiesList
        type={props.categoryType}
        data={props.data}
      />
    </div>
  );
};
export default AddFeaturesAndFacilitiesForm;
