const Image = async(file)=>{
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "zupptravel");
    const response = await fetch(`https://api.cloudinary.com/v1_1/dhbed8btg/auto/upload`, {
        method: "POST",
        body: formData
    });
    const data = await response.json();
    return data["secure_url"];
}
export default Image;