import React, { useState } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import FlightDiscount from './flightDiscount';

const AddDiscount = (props)=>{
    const [active,updateActive] = useState("1");
 
    return(
        <Container fluid className={classes.Box}>
            <Row style={{margin:'0 0 2% 0',textAlign:'center',cursor:'pointer'}}>
                <Col className={classes.Col} 
                style={{backgroundColor:`${active==='1'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("1")}>
                    Flight
                </Col>
            </Row>
            {
                active==="1"?<FlightDiscount/>:null
            }
        </Container>        
    )
}        
export default AddDiscount;