import React, { useState } from 'react';
import classes from '../Dashboard/dashboard.module.css';
import CustomerView from './customerView'
import CustomerList from './customerList'
import AdminNavbar from '../navbar/adminNavbar';


const CustomerManagement = (props) => {
    const [active, updateActive] = useState('1');
    const [data, updateData] = useState({});
    const handleClick = (select) => {
        console.log(select)
        updateData(select);
        updateActive("2");
    }
    
    return (
        <>
            <div className={classes.Head}>
                <AdminNavbar/>
                <h1 className={classes.Heading}>Customer Management</h1>
           
                {
                    active === '1' ?<CustomerList click={(select)=>handleClick(select)}/>:
                    active === "2"?<CustomerView data={data} clicked={()=>updateActive("1")}/> :null
                }
                </div>
            </>
            );
}


export default CustomerManagement;