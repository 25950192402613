import React, { Fragment } from 'react';
import {Col,Container,Row } from 'react-bootstrap';
import classes from './dashboard.module.css';
import AdminNavbar from '../navbar/adminNavbar';

const Dashboard = (props)=>{
    //Get The UserName and key from Redux Store After Login
    // const userName = useSelector(state=>state.apiResponse.userName); 
    // const key = useSelector(state=>state.apiResponse.key); 

    const elements = [
        {id:"0"},
        {id:'1',type:'Total Flight Bookings',icon:"fas fa-plane"},
        {id:'2',type:'Total Cab Bookings',icon:"fas fa-car"},
        {id:'3',type:"Total Accommodation Bookings",icon:"fas fa-hotel"},
        {id:'4',type:"Today Bus Bookings",icon:"fas fa-bus"},
        {id:'5',type:"Total Customers",icon:"fas fa-users"},
        {id:"6"},
        {id:"7"},
        {id:'8',type:"Total Holiday Bookings",icon:"fas fa-luggage-cart"},
        {id:'9',type:"Today's Bookings",icon:"fas fa-user-plus"},
        {id:'10',type:"Total Revenue",icon:"fas fa-money-bill-alt"},
        {id:'11',type:"API Wallet Balance",icon:"fas fa-user-plus"},
        {id:'12',type:"API Credit Balance",icon:"fas fa-money-bill-alt"},
        {id:"13"}
    ]
    return(
        <>
            <div className={classes.Head}>
                <AdminNavbar/>
                <h1 className={classes.Heading}>Dashboard</h1> 
                <Container fluid> 
                <Row> 
                    {
                        elements.map(element=>
                            <Fragment key={element.id}> 
                                {
                                    (element.id==="0"||element.id==="6"||element.id==="7"||element.id==="13")?
                                    <Col md={1} xs={0}></Col>:
                                    <Col md={2} sm={6} xs={12} key={element.id} className={classes.Col}>
                                    <div className={classes.Box} >
                                        <div><i className={element.icon} style={{padding:'5%',display:'flex',justifyContent:'center',alignSelf:'center'}}></i></div>
                                        <center>
                                            <p style={{fontSize:'1.2rem'}}>{element.type}:</p>
                                            <p className={classes.P} style={{fontSize:'1.2rem'}}><strong>INR 1000</strong></p>
                                        </center>
                                    </div>
                                </Col>
                                }
                            </Fragment> 
                        )
                    }
                </Row>
                </Container>
            </div>
        </>
    )
}
export default Dashboard;