import React, { Component } from 'react';
import { EditorState,convertToRaw,ContentState  } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


class DraftEditor extends Component {
  state = {
      editorState: EditorState.createEmpty(),
      message:this.props.text
    };
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
      message:draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
    this.props.change(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };
  // shouldComponentUpdate(nextProps, nextState){
  //   if(this.props.text!=""&&nextProps!=this.props){
  //     const contentBlock = htmlToDraft(this.props.text);
  //     if (contentBlock) {
  //       const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  //       const editorState = EditorState.createWithContent(contentState);
  //       this.setState({editorState})
  //     }
  //   }else{
  //     return false;
  //   }
  // }  

  render() {
    const { editorState } = this.state;
    return (
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={this.onEditorStateChange}
      />
    )
  }
}
export default DraftEditor;