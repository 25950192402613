import React, { Fragment,useState } from 'react';
import { Modal, Button, Form, Col } from 'react-bootstrap';
import { Formik } from 'formik';
import {useSelector} from 'react-redux';
import {AddAddressCustomer} from '../../entities/action/action';

const AddAddress = (props) => {
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    const [success,updateSuccess] = useState(false);
    return (
        <Fragment>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                {
                    success?
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>
                        :
                    <Formik
                        initialValues={{ addressline1: '', addressline2: '', city: '' }}
                        onSubmit={
                            async(values, { setSubmitting, resetForm }) => {
                                setSubmitting(true);
                                const newData = {
                                    "addressLine1": values.addressline1,
                                    "addressLine2": values.addressline2,
                                    "city": values.city,
                                    "customerEmailId": props.data.emailId,
                                    "customerId": props.data.id,
                                    "id": props.data.id,
                                    "loginEmailId": userData.data.key,
                                    "phoneNumber": props.data.phoneNumber,
                                    "pinCode": values.pin,
                                    "sessionKey": userData.sessionId,
                                    "state": values.state,
                                    "village": values.village
                                }
                                const res = await AddAddressCustomer(newData);
                                console.log(res);
                                if(res.successful){
                                    updateSuccess(true);
                                    setTimeout(()=>{
                                        updateSuccess(false)
                                    },3000)
                                }
                                resetForm();
                                setSubmitting(false);
                            }
                        }>
                        {
                            ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
                                <Form onSubmit={handleSubmit}>
                                     <Modal.Header>
                                        <Modal.Title id="contained-modal-title-vcenter">
                                            Add Address
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                    <Form.Group controlId="formBasicAddress">
                                        <Form.Label>Address Line 1:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Address"
                                            name="addressline1"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.addressline1}
                                            required />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicAddress">
                                        <Form.Label>Address Line 2:</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Address"
                                            name="addressline2"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.addressline2}
                                            required />
                                    </Form.Group>

                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formBasicVillage">
                                            <Form.Label>Village:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Village"
                                                name="village"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.village}
                                                />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formBasicCity">
                                            <Form.Label>City:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter City"
                                                name="city"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.city}
                                                required />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="formBasicState">
                                            <Form.Label>State:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter State"
                                                name="state"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.state}
                                                required />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formBasicPin">
                                            <Form.Label>Pin Code:</Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Enter Pin Code"
                                                name="pin"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.pin}
                                                required />
                                        </Form.Group>
                                    </Form.Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={props.onHide}>
                                            Close
                                        </Button>
                                        <Button variant="primary" type="submit">
                                            Submit
                                        </Button>
                                    </Modal.Footer>
                                </Form>
                            )
                        }
                    </Formik>
                }
            </Modal>
        </Fragment>
    )
}
export default AddAddress;