import React, { useState } from "react";
import { Formik } from "formik";
import { Form, Button, Row, Col } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
// import {DropzoneArea} from 'material-ui-dropzone';

const AddCabGeneralView = (props) => {
  const [message, updateMessage] = useState("");
  // const [files,updateFiles] = useState([]);
  const [edit, updateEdit] = useState(false);
  // const handleImages = (inpFile)=>{
  //     updateFiles(inpFile)
  // }

  const clickHandler = (id) => {
    const win = window.open(id, "_blank");
    win.focus();
  };

  console.log(props.data);

  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          driverAddress: props.data.addressLine1,
          driverAddress1: props.data.addressLine2,
          driverage: props.data.age,
          city: props.data.city,
          village: props.data.village,
          country: props.data.country,
          pinCode: props.data.pinCode,
          state: props.data.state,
          // status: true,
          block: props.data.tehsil_block,
          driverName: props.data.driverName,
          drivingType: props.data.driverType,
          drivingExperience: props.data.experience,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);

          const newdata = {
            addressLine1: values.driverAddress,
            addressLine2: values.driverAddress1,
            age: values.driverage,
            cabDriverId: props.data.id,
            city: props.data.city,
            country: props.data.country,
            driverName: props.data.driverName,
            driverType: props.data.driverType,
            emailId: "string",
            experience: props.data.experience,
            licenceCopy: props.data.licenceCopy,
            photo: props.data.photo,
            pinCode: props.data.pinCode,
            proof: props.data.proof,
            sessionKey: "string",
            state: props.data.state,
            tehsil_block: props.data.tehsil_block,
            village: props.data.village,
          };
          console.log(newdata);
          const res = await fetch(
            "https://api.zupptravel.com/updateCabDetails",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newdata),
            }
          );
          const json = await res.json();
          console.log(json);
          if (json.successful) {
            alert(" Cab Driver Updated!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "2%",
              }}
            >
              {!edit ? (
                <Button variant="success" onClick={() => updateEdit(true)}>
                  Edit
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={() => {
                    updateEdit(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="light"
                style={{ marginLeft: "15px" }}
                onClick={() => props.click()}
              >
                {`<--`} Back
              </Button>
            </div>
            <Form.Group controlId="formBasicInfo" as={Row}>
              <Form.Label column sm="2">
                Driver Name:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  // disabled={!edit}
                  readOnly
                  placeholder="Enter Driver Name"
                  name="driverName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.driverName}
                />
              </Col>
              <Form.Label column sm="2">
                Driver Age:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="string"
                  placeholder="Enter Driver Age"
                  name="driverage"
                  // readOnly
                  disabled={!edit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.driverage}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTourGuideType" as={Row}>
              <Form.Label column sm="2">
                Driving Type:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  // as="select"
                  type="text"
                  readOnly
                  // disabled={!edit}
                  placeholder="Enter Driving Type"
                  name="drivingType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.drivingType}
                />
                {/* <option value="">--select--</option> */}
                {/* </Form.Control> */}
              </Col>
              <Form.Label column sm="2">
                Driving Licence Copy:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="file"
                  name="file"
                  readOnly
                  disabled
                  //   onChange={(e) => updateFile1(e.target.files[0])}
                  required
                />
              </Col>
              <Col>
                <Button onClick={() => clickHandler(props.data.licenceCopy)}>
                  Open
                </Button>
              </Col>
            </Form.Group>

            <Form.Group controlId={`DrivingExperienceandImage`} as={Row}>
              <Form.Label column sm="2">
                Driving Experience :
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder={`Enter Driving Experience `}
                  name={`drivingExperience`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  readOnly
                  // disabled={!edit}
                  value={values.drivingExperience}
                  required
                />
              </Col>

              <Form.Label column sm="2">
                Upload ID Proof:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="file"
                  name="file"
                  readOnly
                  disabled
                  //   onChange={(e) => updateFile(e.target.files[0])}
                  required
                />
              </Col>
              <Col>
                <Button onClick={() => clickHandler(props.data.proof)}>
                  Open
                </Button>
              </Col>
            </Form.Group>
            <Form.Group controlId={"drivinguploadphoto"} as={Row}>
              <Form.Label column sm="2">
                Upload Photo:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="file"
                  readOnly
                  // disabled={!edit}
                  disabled
                  name="file"
                  //   onChange={(e) => updateFile2(e.target.files[0])}
                  required
                />
              </Col>
              <Col>
                <Button onClick={() => clickHandler(props.data.photo)}>
                  Open
                </Button>
              </Col>
            </Form.Group>
            <Form.Group controlId={"DrivingLicenceCopy"} as={Row}>
              <Form.Label column sm="2">
                Address Line 1:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder={`Enter Address`}
                  name={`driverAddress`}
                  onChange={handleChange}
                  // readOnly
                  disabled={!edit}
                  onBlur={handleBlur}
                  // value={values[`language`]}
                  value={values.driverAddress}
                />
              </Col>
              <Form.Label column sm="2">
                Address Line 2:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder={`Enter Address`}
                  name={`driverAddress1`}
                  // readOnly
                  disabled={!edit}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // value={values[`language`]}
                  value={values.driverAddress1}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicAddress2" as={Row}>
              <Form.Label column sm="2"></Form.Label>
              <Col sm="9">
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formBasicPin" as={Row}>
                      <Form.Label column sm="2">
                        Pin Code:
                      </Form.Label>
                      <Col sm="9">
                        {/* <Form.Control
                          type="text"
                          placeholder="Enter Pin Code"
                          name="pincode"
                          onChange={handleChange}
                          value={values.pincode}
                          readOnly
                          disabled={!edit}
                          onBlur={myfunc}
                          change={(select) => handlePincode(select)}
                          className={touched.pincode ? "has-error" : null}
                        /> */}
                        <Form.Control
                          type="text"
                          readOnly
                          placeholder="Enter Country"
                          name="pinCode"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={props.data.pinCode}
                        />
                        {/* <SingleSelect
                          type="5"
                          list={location}
                            change={(select) => handlePincode(select)}
                        /> */}
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formBasicCountry" as={Row}>
                      <Form.Label column sm="2">
                        Country:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          readOnly
                          placeholder="Enter Country"
                          name="country"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={props.data.country}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="formBasicCity" as={Row}>
                      <Form.Label column sm="2">
                        City:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          readOnly
                          placeholder="Enter City"
                          name="city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={props.data.city}
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicState" as={Row}>
                      <Form.Label column sm="2">
                        State:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          readOnly
                          type="text"
                          placeholder="Enter State"
                          name="state"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={props.data.state}
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicVillage" as={Row}>
                      <Form.Label column sm="2">
                        Village:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          placeholder="Enter Village"
                          readOnly
                          name="village"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={props.data.village}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicBlock" as={Row}>
                      <Form.Label column sm="2">
                        Tehsil/Block :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          placeholder="Enter Tehsil/Block"
                          name="block"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={props.data.tehsil_block}
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <center>
              <Button disabled={!edit} type="submit" variant="success">
                Update Cab
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddCabGeneralView;
