import React, { useState,useEffect } from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DraftEditor from '../../draftEditor';
import { AddBlog, GetBLOGCategory } from '../../../entities/action/action';
import Image from '../../../utilities/imageTestApi/image';

const CreateBlogCard = (props)=>{

    const [message,updateMessage] = useState('');
    const [file,updateFile] = useState({});
    const [footerFile1,updateFooterFile1] = useState({});
    const [footerFile2,updateFooterFile2] = useState({});
    const [footerFile3,updateFooterFile3] = useState({});
    const [category,updateCategoryList] = useState([]);

    useEffect(()=>{
        
        (async()=>{
            let arr = await GetBLOGCategory();
            if(arr?.success){
                updateCategoryList(arr?.blogCategories);
            }
        })()
    },[])
    return(
        <div className={classes.Box}> 
        <Formik
            initialValues={{title:'',category:category?.length>0?category?.[0].categoryName:""}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    setSubmitting(true);
                    const response1 = await Image(file);
                    const response2 = await Image(footerFile1);
                    const response3 = await Image(footerFile2);
                    const response4 = await Image(footerFile3);
                    const newData = {
                        "blogCategory": values?.category,
                        "blogDescription": message,
                        "imageUrl": response1,
                        "footerImageUrl1": response2,
                        "footerImageUrl2": response3,
                        "footerImageUrl3": response4,
                        "language": values.language,
                        "metaDescription": values.metaDescription,
                        "metaTitle": values.metaTitle,
                        "title": values.title
                      }
                    

                    const res = await AddBlog(newData);
                    if(res.successful){
                        alert("Successfully Added Blog");
                        resetForm();
                        props.click();
                    }
                    setSubmitting(false);
                }
            }
            >
                {
                            ({values,handleChange,handleBlur,handleSubmit})=>{
                                console.log(values);
                                return (
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasic" as={Row}>
                                            <Form.Label column sm="2">Title:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Title for Blog" 
                                                name="title"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.title}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicMetaTitle" as={Row}>
                                            <Form.Label column sm="2">Meta Title:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Meta Title" 
                                                name="metaTitle"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.metaTitle}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasic" as={Row}>
                                            <Form.Label column sm="2">Meta Description:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Meta Description" 
                                                name="metaDescription"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.metaDescription}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicLanguage" as={Row}>
                                            <Form.Label column sm="2">Language:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Language" 
                                                name="language"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.language}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicAddress" as={Row}>
                                            <Form.Label column sm="2">Blog Category:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="CATEGORY" 
                                                    name="category"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values?.category}
                                                    required>
                                                        <option value={''}>Select Category</option> 
                                                    {
                                                        category?.map((categories,key)=>
                                                        <option key={key} value={categories?.categoryName}>{categories.categoryName}</option>    
                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>   
                                        <Form.Group controlId="formBasicPromo" as={Row}>
                                            <Form.Label column sm="2">Images:</Form.Label>
                                            <Col sm="5"> 
                                                <Form.Control
                                                type="file" 
                                                name="file"
                                                onChange={(e)=>updateFile(e.target.files[0])}
                                                required/>
                                            </Col>
                                            <Col sm="5"> 
                                                <Form.Control
                                                type="file" 
                                                name="footerFile1"
                                                onChange={(e)=>updateFooterFile1(e.target.files[0])}
                                                required/>
                                            </Col>
                                            <Form.Label column sm="2"></Form.Label>
                                            <Col sm="5"> 
                                                <Form.Control
                                                type="file" 
                                                name="footerFile2"
                                                onChange={(e)=>updateFooterFile2(e.target.files[0])}
                                                required/>
                                            </Col>
                                            <Col sm="5"> 
                                                <Form.Control
                                                type="file" 
                                                name="footerFile3"
                                                onChange={(e)=>updateFooterFile3(e.target.files[0])}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Row style={{margin:'2% 0'}}>
                                            <Col sm={2} style={{margin:'2% 0'}} className={classes.Col}>
                                                Blog Description:
                                            </Col>
                                            <Col sm={10} style={{border:'1px solid black'}}>
                                                <DraftEditor change={(text)=>updateMessage(text)} text={message} />
                                            </Col>
                                        </Row>
                                        <center>
                                            <Button type="submit" variant="success">Save Blog</Button>
                                        </center>
                                    </Form>
                            )}}
                        </Formik>
                    </div>            
    )
}
export default CreateBlogCard;