import React, { useEffect, useState } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import {GetActiveCoupon,DeleteCoupon} from '../../entities/action/action';
import Page from '../pagination/pagination';

const ActiveCoupon = (props)=>{
    const [dataSet,updateDataSet] = useState([]);

    const [count,updateCount] = useState(0)
    const postPerPage = 20;
    const [currentPage,updateCurrentPage] = useState(1);
    const indexOfLast = currentPage*postPerPage;
    const indexOfFirst = indexOfLast - postPerPage;
    const currentPosts = dataSet.slice(indexOfFirst,indexOfLast);
    const handleChangePage = (event,value)=>{
        updateCurrentPage(value)
    }
    useEffect(()=>{
        updateCount(Math.ceil(dataSet.length/postPerPage))
    },[dataSet])
    useEffect(()=>{
        (async()=>{
            let arr = await GetActiveCoupon();
            console.log("arr = ",arr)
            if(arr!=null){
                updateDataSet(arr);
            }
        })()
    },[])
    console.log(dataSet)
    const handleDelete = async(id)=>{
        const newData = {
            "id": id,
        }
        const res = await DeleteCoupon(newData);
        if(res.success){
            alert("Successfully Deleted");
            let arr = await GetActiveCoupon();
            if(arr!=null){
                updateDataSet(arr);
            }
        }
    }
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Image</th>
                        <th>PROMOCOE</th>
                        <th>PROMONAME</th>
                        <th>From</th>
                        <th>To</th>
                        <th>Discount Type</th>
                        
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentPosts.length>0?currentPosts.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td><img src={data.imageUrl} style={{width:'50px'}} alt="active coupon"/></td>
                                <td>{data.promocode}</td>
                                <td>{data.promoname}</td>
                                <td>{data.startValidity.split('T')[0]}</td>
                                <td>{data.endValidity.split('T')[0]}</td>
                                <td>{data.discountType}</td>
                               
                                <td className={classes.CenterRow}>
                                    <Button style={{margin:'auto 1%'}} variant="danger" onClick={()=>handleDelete(data.id)}>Delete</Button>
                                    <Button onClick={()=>props.click(data)}>View</Button>
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
            <div style={{display:'flex',justifyContent:'center',margin:'15px 0'}}>
                <Page count={count} change={handleChangePage} page={currentPage}/> 
            </div>
        </Container>
    )
}
export default ActiveCoupon;