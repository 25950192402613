import React, { useState, Fragment, useEffect } from 'react';
import { Col, Button, Form, Container, Row, Table } from 'react-bootstrap';
import { Formik } from 'formik';
import classesA from '../WebsiteManagement/websiteManagement.module.css'
import classesX from '../Dashboard/dashboard.module.css';
import CustomerProfile from './customerProfile'
import CustomerReward from './customerReward'
import CustomerOrder from './customerOrder'
import CustomerSecurity from './customerSecurity'

const CustomerView = (props) => {
    const [active,updateActive] = useState('1');
    const [btnActive,updateBtnActive]=useState(true);
    const [btnActiveNo,updateBtnActiveNo]=useState("0");
    const [status,setStatus]=useState("active");
    
    function handleClick(e){
        updateBtnActive(!btnActive);
        updateBtnActiveNo(e);
        
    }
    useEffect(()=>{
        if(active==="5"){
            props.clicked()
        }
    },[active])
    const items = [
        { id: "1", type: "Profile" },
        { id: "2", type: "Reward Earning" },
        { id: "3", type: "Orders & Bookings" },
        // { id: "4", type: "Security" }
        { id: "5", type: "Back" },
    ]
    
    return (
        <Container fluid>
            <Row>
                <Col lg={3} md={12} className={classesA.Response2}>
                    <div style={{ height: '100%' }}>
                        <ul className={classesA.Ul}>
                            {
                                items.map(item => <li
                                    key={item.id}
                                    onClick={() =>updateActive(item.id)}
                                    className={classesA.Li}
                                    style={{ backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`, textAlign: 'center' }}>
                                    {item.type}
                                </li>
                                )
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={9} md={12} className={classesA.Response} style={{ padding: '0' }}>
                    {active === "1" ? <CustomerProfile data={props.data} /> :
                    active === "2" ? <CustomerReward data={props.data} /> :
                    active === "3" ? <CustomerOrder data={props.data} /> :null
                    // active === "4" ? <CustomerSecurity data={props.data}/> : null
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default CustomerView;




