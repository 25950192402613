import React, { Fragment, useState, useEffect } from 'react';
import {Col,Table, DropdownButton,Container, Button} from 'react-bootstrap';
// import classes from '../WebsiteManagement/websiteManagement.module.css';
import classes from '../../WebsiteManagement/websiteManagement.module.css';

import {useSelector} from 'react-redux';
import AccommodationDetails from './AccommodationDetails';

const AccommodationData = (props)=>{
    const [dataSet,updateDataSet] = useState([]);

    const [active, updateActive] = useState('1');
    const [data, updateData] = useState({});
const userData = useSelector((state) => state.adminAccount.apiResponse);
console.log(userData);
const [accomodationdata, setAccomodationdata] = useState({});
useEffect(() => {
    (async () => {
      const data = {
        emailId: userData.data.key,
        password: "string",
        phoneno: userData.data.phone,
        sessionKey: userData.sessionId,
      };
   
      const res = await fetch(
        "https://api.zupptravel.com/getAccommodationBooking",
       

        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await res.json();
      console.log("accomodation  data ", json);
      if (json.successful) {
        setAccomodationdata(json.pincodes);
      }
    })();
  }, []);
  console.log("accomodation in state",accomodationdata);

        const [showDetails, setShowDetails] = useState(false);

        const handleViewClick = () => {
          setShowDetails(true);
        };
      
        const handleCloseDetails = () => {
          setShowDetails(false);
        };
    return(
        <Container fluid style={{marginTop:"5vh"}}>
  <Col lg={12} md={12} className={classes.Response2}>
             <Table striped bordered hover>
<thead>
    <tr>
        <th>S.No</th>
        <th>Booking Date and Time</th>
        <th>Booking ID</th>
        <th>Phone Number</th>
        <th>Booking Status</th>
        <th>Action</th>
    </tr>
</thead>
<tbody>
{
    // dataSet.length>0?dataSet.map((data,key)=>
    // <tr key={key}>
    <tr>
        {/* <td>{key+1}</td> */}
        <td>1</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>
            {/* <DropdownButton id="dropdown-variants-Success" title="Actions"> */}
                <div className={classes.Center}>
                    <Button variant="danger" onClick={() => props.click(data)}>View</Button>
                </div>
            {/* </DropdownButton> */}
        </td>
    </tr>       
    // ):null
}
</tbody>
</Table>
</Col>
</Container>

)
}
export default AccommodationData;
