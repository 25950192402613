import React, {useState, Fragment } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import CDMPromotionalOffers from './cdmpromotionalOffers';
import WalletOffers from './walletOffers';


const Offerings = (props)=>{
    const [active,updateActive] = useState('1');

    return(
        <Fragment> 
    
        <Container fluid className={classes.Box}>
        <Row style={{margin:'0 0 2% 0',textAlign:'center',cursor:'pointer'}}>
                <Col xs={6} className={classes.Col} 
                style={{backgroundColor:`${active==='1'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("1")}>
                    Wallet Offers 
                </Col>
                <Col xs={6} className={classes.Col}
                style={{backgroundColor:`${active==='2'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("2")}>
                  Vendor  Promotional Offers
                </Col>
            </Row>
            <Row>
            
            <Col md={12} className={classes.Response}>
                    <div style={{height:'100%'}}> 
                        {
                            active==="1"?<WalletOffers/>:
                            active==="2"?<CDMPromotionalOffers/>:null
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default Offerings;