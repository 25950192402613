import React, { useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import moment from "moment";
import { DatePicker } from "antd";
import { useEffect } from "react";
import { GetVendor } from "../../../entities/action/action";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
const { RangePicker } = DatePicker;
function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
}

const SetRoomPrice = (props) => {
  const [date, updateDate] = useState([]);
  const [roomNames, updateRoomNames] = useState([]);
  const [roomNames1, updateRoomNames1] = useState([]);
  const [roomName, updateRoomName] = useState("");
  const [roomSetNames, updateRoomSetNames] = useState([]);
  const [roomSetName, updateRoomSetName] = useState([]);
  const [unitPrice, setUnitPrice] = useState("");
  const [cxUnitPrice, setCxUnitPrice] = useState("");
  const [ztUnitPrice, setZtUnitPrice] = useState("");
  const [unitGstRate, setUnitGstRate] = useState([]);
  const [roomNameId, updateRoomNameId] = useState("");
  const [dataSet, updateDataSet] = useState([]);
  const [amenities, updateAmenities] = useState([]);
  const [vendorList, updateVendorList] = useState([]);
  const [accList, updateAccList] = useState([]);
  const [id, updateId] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await GetVendor();
      if (res != undefined && res.length > 0) {
        const arr = res.filter(
          (item, key) =>
            item.companyInformation.vendortype === "Accommodation Provider"
        );
        console.log(res);
        updateVendorList(arr);
      }
    })();
  }, []);
  const [accomodationId, updateaccomodationId] = useState("");

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        `https://api.zupptravel.com/getAccommodation`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await myresponse.json();
      console.log("jsssoooooonnn", json);

      if (json.successful) {
        updateDataSet(json.accommodations);
      }
    })();
  }, []);
  const [vendorId, updateVendorId] = useState("");

  const arr = [];
  useEffect(() => {
    dataSet.map((data, key) => {
      if (data.vendorId == vendorId) {
        arr.push(data.accId);
      }
    });

    updateAccList(arr);
  }, [vendorId]);

  const id1 = [];
  useEffect(() => {
    dataSet.map((data, key) => {
      if (data.accId == accomodationId) {
        id1.push(data.id);
      }
    });
    updateId(id1);
  }, [accomodationId]);

  const handlePrice = (x, c, y, z, a) => {
    updateRoomName(x);
    setUnitGstRate(c);
    setUnitPrice(y);
    setCxUnitPrice(z);
    setZtUnitPrice(a);
  };

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getRoomCategory", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const json = await res.json();
      console.log("firstss ", json);
      if (json.successful) {
        updateRoomNames(json.roomCategorys);
      }
    })();
  }, []);

  // console.log(roomNames);

  useEffect(() => {
    let r = [];
    roomNames.map((data, id) => {
      if (data.accId == accomodationId) {
        r.push(data);
      }
    });
    updateRoomNames1(r);
  }, [accomodationId]);

  useEffect(() => {
    let d;
    roomNames.map((data, id) => {
      if (data.name == roomName) {
        d = data.id;
      }
    });
    updateRoomNameId(d);
  }, [roomName]);

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getRoomSet", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const json = await res.json();
      console.log("firstss ", json);
      if (json.successful) {
        updateRoomSetNames(json.roomSets);
      }
    })();
  }, []);
  // console.log(props.accid);

  console.log(roomSetNames);
  console.log(roomName);
  useEffect(() => {
    let arr = [];
    roomSetNames.map((data, id) => {
      if (data.roomName == roomName) {
        arr.push(data.roomSetName);
      }
    });

    updateRoomSetName(arr);
  }, [roomName]);

  console.log(roomSetName);

  return (
    <div>
      <Formik
        initialValues={{
          unitSericeVariablemsp: "",
          taxSlabVariablemsp: "",
          unitSericeVariableocc: "",
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const newdata = {
            accommodationId: id[0],
            emailId: "string",
            dateTo: date[1]?._d.toISOString().slice(0, 10),
            roomAmount: unitPrice,
            roomCXAmount: cxUnitPrice,
            roomCXGstCost: Math.round(cxUnitPrice * 0.01 * unitGstRate),
            roomCXGstRate: unitGstRate,
            roomGstCost: Math.round(unitPrice * 0.01 * unitGstRate),
            roomGstRate: unitGstRate,
            roomPriceId: 0,
            roomSet: values.roomSet,
            roomCategoryId: roomNameId,
            roomCategory: values.roomName,

            roomZTAmount: ztUnitPrice,
            roomZTGstCost: Math.round(ztUnitPrice * 0.01 * unitGstRate),
            roomZTGstRate: unitGstRate,
            mrp: Math.round(unitPrice + unitPrice * 0.01 * unitGstRate),
            purchasePrice: Math.round(
              ztUnitPrice * 0.01 * unitGstRate + ztUnitPrice
            ),
            sellingPrice: Math.round(
              cxUnitPrice + cxUnitPrice * 0.01 * unitGstRate
            ),
            sessionKey: "string",
            dateFrom: date[0]?._d.toISOString().slice(0, 10),
          };

          const res = await fetch("https://api.zupptravel.com/addRoomPrice", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newdata),
          });

          console.log("rrrrrrrrrrrr", newdata);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("Added Room Price!");
            window.location.reload();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            {/* <Form.Group controlId="formBasicAccommodationName" as={Row}>
                                            <Form.Label column sm="2">Accommodation Name:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Accommodation Name" 
                                                    name="accommodationName"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.accommodationName}
                                                    required>
                                                    {
                                                        categoryList.map((categories,key)=>
                                                        <option key={key} value={categories.type}>{categories.type}</option>    
                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>    */}
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="2">
                Vendor ID:
              </Form.Label>
              <Col sm="10">
                <SingleSelect
                  type="3"
                  list={vendorList}
                  change={(select) => updateVendorId(select)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="2">
                Accommodation ID:
              </Form.Label>
              <Col sm="10">
                <SingleSelect
                  type="4"
                  list={accList}
                  change={(select) => updateaccomodationId(select)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicRoomName" as={Row}>
              <Form.Label column sm="2">
                Room Name:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  placeholder="Room Name"
                  name="roomName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.roomName}
                  required
                >
                  <option value="">--Select--</option>
                  {roomNames1.map((item, key) => {
                    return (
                      <>
                        <option value={item.name}>{item.name}</option>
                      </>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicRoomSet" as={Row}>
              <Form.Label column sm="2">
                Room Set:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  placeholder="Room Set"
                  name="roomSet"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.roomSet}
                  required
                >
                  <option value="">--Select--</option>
                  {roomSetName.map((item, key) => {
                    return (
                      <>
                        <option value={item}>{item}</option>
                      </>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasictimePerSlot" as={Row}>
              <Form.Label column sm="2">
                Date:
              </Form.Label>
              <Col>
                <RangePicker
                  onChange={(val) => updateDate(val)}
                  value={date}
                  disabledDate={disabledDate}
                />
              </Col>
            </Form.Group>
            <Form.Label style={{ fontWeight: "bold" }}>Room Cost:</Form.Label>
            <div style={{ border: "1px solid grey" }} className="container">
              <Form.Group
                controlId="formBasicMrp"
                as={Row}
                style={{ margin: "1% 0" }}
              >
                <Form.Label>Unit Price:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step={0.01}
                    placeholder="Enter Unit Price"
                    name="unitSericeVariablemsp"
                    min={0}
                    onChange={handleChange}
                    value={values.unitSericeVariablemsp}
                    required
                  />
                </Col>
                <Form.Label>Unit GST Rate:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step={0.01}
                    placeholder="G.S.T Rate"
                    name="taxSlabVariablemsp"
                    min={1}
                    max={100}
                    isInvalid={
                      values.taxSlabVariablemsp < 0 ||
                      values.taxSlabVariablemsp > 100
                    }
                    onChange={handleChange}
                    value={values.taxSlabVariablemsp}
                    required
                  />
                </Col>
                <Form.Label>GST Cost:</Form.Label>
                <Col>
                  <Form.Control
                    readOnly
                    name="taxRateVariablemsp"
                    onChange={handleChange}
                    value={Math.round(
                      values.unitSericeVariablemsp *
                        0.01 *
                        values.taxSlabVariablemsp
                    )}
                    required
                  />
                </Col>
                <Form.Label>M.R.P:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step={0.01}
                    readOnly
                    min={0}
                    placeholder="M.R.P"
                    name="variableCostmsp"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={Math.round(
                      values.unitSericeVariablemsp +
                        values.unitSericeVariablemsp *
                          0.01 *
                          values.taxSlabVariablemsp
                    )}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group
                controlId="formBasicAppliesTo"
                as={Row}
                style={{ margin: "1% 0" }}
              ></Form.Group>
            </div>
            <Form.Label style={{ fontWeight: "bold" }}>
              Room Cost to CX:
            </Form.Label>
            <div style={{ border: "1px solid grey" }} className="container">
              <Form.Group
                controlId="formBasicMrp"
                as={Row}
                style={{ margin: "1% 0" }}
              >
                <Form.Label>Unit Price:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitSericeVariableocc"
                    min={0}
                    isInvalid={
                      values.unitSericeVariableocc >
                      values.unitSericeVariablemsp
                    }
                    onChange={handleChange}
                    value={values.unitSericeVariableocc}
                    required
                  />
                </Col>
                <Form.Label>Unit GST Rate:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder="G.S.T Rate"
                    name="taxSlabVariableocc"
                    min={1}
                    readOnly
                    max={100}
                    isInvalid={
                      values.taxSlabVariablemsp < 0 ||
                      values.taxSlabVariablemsp > 100
                    }
                    onChange={handleChange}
                    value={values.taxSlabVariablemsp}
                    required
                  />
                </Col>
                <Form.Label>GST Cost:</Form.Label>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="taxRateVariableocc"
                    onChange={handleChange}
                    value={Math.round(
                      values.unitSericeVariableocc *
                        0.01 *
                        values.taxSlabVariablemsp
                    )}
                    required
                  />
                </Col>
                <Form.Label>Selling Price:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    readOnly
                    min={0}
                    placeholder="Selling Price"
                    name="variableCostocc"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={Math.round(
                      values.unitSericeVariableocc +
                        values.unitSericeVariableocc *
                          0.01 *
                          values.taxSlabVariablemsp
                    )}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group
                controlId="formBasicAppliesTo"
                as={Row}
                style={{ margin: "1% 0" }}
              ></Form.Group>
            </div>
            <Form.Label style={{ fontWeight: "bold" }}>
              Room Cost to ZT:
            </Form.Label>
            <div style={{ border: "1px solid grey" }} className="container">
              <Form.Group
                controlId="formBasicMrp"
                as={Row}
                style={{ margin: "1% 0" }}
              >
                <Form.Label>Unit Price</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitSericeVariableww"
                    min={0}
                    readOnly
                    value={Math.round(values.unitSericeVariableocc * 0.8675)}
                    required
                  />
                </Col>
                <Form.Label>Unit GST Rate:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder="G.S.T Rate"
                    name="taxSlabVariableww"
                    min={1}
                    readOnly
                    max={100}
                    isInvalid={
                      values.taxSlabVariablemsp < 0 ||
                      values.taxSlabVariablemsp > 100
                    }
                    value={values.taxSlabVariablemsp}
                    required
                  />
                </Col>
                <Form.Label>GST Cost:</Form.Label>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="taxRateVariableww"
                    value={Math.round(
                      values.unitSericeVariableocc *
                        0.8675 *
                        0.01 *
                        values.taxSlabVariablemsp
                    )}
                    required
                  />
                </Col>
                <Form.Label>Purchase Price:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    readOnly
                    min={0}
                    placeholder="Purchase Price"
                    name="variableCostww"
                    value={Math.round(
                      values.unitSericeVariableocc *
                        0.8675 *
                        0.01 *
                        values.taxSlabVariablemsp +
                        values.unitSericeVariableocc * 0.8675
                    )}
                    required
                  />
                </Col>
              </Form.Group>
            </div>
            <center>
              <Button
                style={{ marginTop: "15px" }}
                type="submit"
                variant="success"
              >
                Save Room Price
              </Button>
            </center>

            {handlePrice(
              values.roomName,
              values.taxSlabVariablemsp,
              values.unitSericeVariablemsp,
              values.unitSericeVariableocc,
              values.unitSericeVariableocc * 0.8675
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default SetRoomPrice;
