
export const LOGIN_INIT = 'login/init';
export const LOGIN = 'login/save';

export const LOGOUT_INIT = 'logout/init';
export const LOGOUT = 'logout/save';

export const PINCODE_INIT = 'pincode/init';
export const PINCODE = 'pincode/save';

export const RATE_INIT = 'rate/init';
export const RATE = 'rate/save';

export const FINALAMOUNT_INIT = 'finalamount/init';
export const FINALAMOUNT = 'finalamount/save';

export const REFRESH = 'refresh';

export const WALLET_INIT = 'wallet/init';
export const WALLET = 'wallet/save';

export const ORDER_INIT = 'order/init';
export const ORDER = 'order/save';

export const REGISTER_INIT = 'register/init';
export const REGISTER = 'register/save';

export const TRACKING_INIT = 'tracking/init';
export const TRACKING = 'tracking/save';

export const PRINTSHIPMENT_INIT = 'shipment/init';
export const PRINTSHIPMENT = 'shipment/save';

export const ALLORDERS_INIT = 'allorders/init'; //all orders of a user
export const ALLORDERS = 'allorders/save';

export const DETAILS_INIT = 'details/init';  //details of a particular order
export const DETAILS = 'details/save';

export const CANCEL_INIT = 'cancel/init';
export const CANCEL = 'cancel/save';

export const WAREHOUSELIST_INIT = 'cancel/init';
export const WAREHOUSELIST = 'cancel/save';

export const SUPERLOGIN_INIT = 'superlogin/init';
export const SUPERLOGIN = 'superlogin/save';

export const TOTALORDERS_INIT = 'totalorders/init';  //total orders in our database
export const TOTALORDERS = 'totalorders/save';

export const ADDPRICING_INIT = 'addPricing/init';
export const ADDPRICING = 'addPricing/save';

export const CREATECOUPON_INIT = 'createCoupon/init';
export const CREATECOUPON = 'createCoupon/save';

export const OTP_INIT = 'otp/init';
export const OTP = 'otp/save';

export const OTPVERIFY_INIT = 'otpVerify/init';
export const OTPVERIFY = 'otpVerify/save';

export const GETAPIDATA = 'get/api/data';
export const PAYMENTMANAGEMENT="get/paymentManagement";

export const LOGIN_VERIFIED = 'login/verified';
export const AUTH_SUCCESS = 'auth/success';
export const AUTH_FAIL = 'auth/fail';
export const LOGOUT_START_INIT = 'logout/start/init';
export const LOGOUT_SUCCESS = 'logout/success';

export const GET_DEPARTMENT = 'get/department';
export const GET_DESIGNATION = 'get/designation';

export const AUTH_REFRESH = 'auth/refresh'
