import React, { useState, useEffect } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import MultipleSelect from "../../multiSelect";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import { useSelector } from "react-redux";
import Image from "../../../utilities/imageTestApi/image";
import { AddGift, GetGiftCategory } from "../../../entities/action/action";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";

const CreateGiftCard = (props) => {
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const [select, updateSelect] = useState([]);
  const [messageCondition, updateMessageCondition] = useState("");
  const [message, updateMessage] = useState("");
  const [file, updateFile] = useState({});
  const [categoryList, updatecategorylist] = useState([]);
  const [categoryList1, updatecategorylist1] = useState([]);

  const [dataSet, updateDataSet] = useState([]);
  const userData = useSelector((state) => state.adminAccount.apiResponse);
  useEffect(() => {
    (async () => {
      const newData = {
        phoneno: "",
        password: "",
        emailId: userData.data.key,
        sessionKey: userData.sessionId,
      };
      let arr = await GetGiftCategory(newData);
      console.log(arr);
      if (arr.successful) {
        // updatecategorylist(arr.categoryList);
        const arr1 = [];
        arr.categoryList.map((data, key) => {
          arr1.push(data.categoryName);
        });
        updatecategorylist(arr1);
      }
    })();
  }, []);

  //   useEffect(() => {
  //     const arr = [];
  //     categoryList.map((data, key) => arr.push(data.categoryName));
  //     updatecategorylist2(arr);
  //   }, []);

  //   console.log(categoryList2);
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{ title: "" }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          const response = await Image(file);
          const newData = {
            emailId: userData.data.key,
            giftCardCategory: categoryList1,
            giftCardFeature: messageCondition,
            giftCardId: 0,
            imageUrl: response,
            minimumAmount: values.minAmount,
            sessionKey: userData.sessionId,
            termsAndCondition: message,
            title: values.title,
          };
          console.log(response);
          console.log(newData);
          const res = await AddGift(newData);
          if (res.successful) {
            alert("Successfully Added Gift Card");
            // resetForm();
            props.click();
          }
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasic" as={Row}>
              <Form.Label column sm="2">
                Title:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  placeholder="Enter Title for Gift Card"
                  name="title"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicAddress" as={Row}>
              <Form.Label column sm="2">
                Gift Card Category:
              </Form.Label>
              <Col sm="10">
                <SingleSelect
                  type="12"
                  list={categoryList}
                  change={(select) => updatecategorylist1(select)}
                />
                {/* <Form.Control
                  as="select"
                  placeholder="CATEGORY"
                  name="category"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.category}
                  required
                >
                  {categoryList.map((categories, key) => (
                    <option key={key} value={categories.categoryName}>
                      {categories.categoryName}
                    </option>
                  ))}
                </Form.Control> */}
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicAmount" as={Row}>
              <Form.Label column sm="2">
                Minimum Amount:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="number"
                  placeholder="Minimum Amount"
                  name="minAmount"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.minAmount}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPromo" as={Row}>
              <Form.Label column sm="2">
                Image:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="file"
                  name="file"
                  onChange={(e) => updateFile(e.target.files[0])}
                  required
                />
              </Col>
            </Form.Group>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={2} style={{ margin: "2% 0" }} className={classes.Col}>
                Gift Card Feature:
              </Col>
              <Col sm={10} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessageCondition(text)}
                  text={messageCondition}
                />
              </Col>
              <Col sm={2} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={10} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                />
              </Col>
            </Row>
            <center>
              <Button type="submit" variant="success">
                Save Gift Card
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default CreateGiftCard;
