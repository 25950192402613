import React, { useEffect } from 'react';
import { DatePicker} from 'antd';
import {Form,Col,Row} from 'react-bootstrap';
import moment from 'moment';
import { useState } from 'react';

const { RangePicker } = DatePicker;

function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
}

const Schedular = (props)=>{
    const[date,updateDate] = useState([]);
    const[slot,updateSlot] = useState(1);
    const[time,updateTime] = useState([]);
    const[timePerSlot,updateTimePerSlot] = useState('');
    const[quantity,updateQuantity] = useState([]);
    const[arr,updateArr] = useState([]);
    const handleChange = (key,e,type)=>{
        if(type==="time"){
            let tempArr = [...time];
            tempArr[key] = e.target.value;
            updateTime(tempArr);
        }else{
            let tempArr2 = [...quantity];
            tempArr2[key] = e.target.value;
            updateQuantity(tempArr2);
        }
    }
    useEffect(()=>{
        let newArr = [];
        for(let i=0;i<slot;i++){
            newArr.push(i);
        }
        updateArr(newArr);
    },[slot])
    useEffect(()=>{
        const data={
            date,
            slot,
            time,
            timePerSlot,
            quantity
        }
        props.change(data);
    },[slot,time,date,timePerSlot,quantity])
    return(
        <div style={{backgroundColor:'whitesmoke',padding:'0 15px'}}>
            <hr/>
            <Form.Group controlId="formBasictimePerSlot" as={Row}>
                <Form.Label column sm="2">Date:</Form.Label>
                <Col> 
                    <RangePicker
                        onChange={val=>updateDate(val)} 
                        value={date}
                        disabledDate={disabledDate} />
                </Col>
            </Form.Group>
            <Form.Group controlId="formBasicslot" as={Row}>
                <Form.Label column sm="2">Slot:</Form.Label>
                <Col sm="10"> 
                    <Form.Control
                        type="text" 
                        placeholder="Enter slot" 
                        name="slot"
                        onChange={(e)=>updateSlot(e.target.value)}
                        value={slot}
                        required/>
                </Col>
            </Form.Group>
            <Form.Group controlId="formBasictimePerSlot" as={Row}>
                <Form.Label column sm="2">Time per slot:</Form.Label>
                <Col sm="10"> 
                    <Form.Control
                        type="text" 
                        placeholder="Enter Time Per Slot" 
                        name="timePerSlot"
                        onChange={(e)=>updateTimePerSlot(e.target.value)}
                        value={timePerSlot}
                        required/>
                </Col>
            </Form.Group>
            {
                arr.length>0?
                arr.map((_,i)=>
                <Form.Row key={i} style={{width:'100%'}}>
                    <Form.Group as={Col} controlId="formBasictimePerSlot">
                        <Form.Label>Time {i+1}:</Form.Label>
                            <Form.Control
                                type="time" 
                                placeholder={`Enter Time ${i+1}`} 
                                name={`time${i+1}`}
                                onChange={(e)=>handleChange(i,e,"time")}
                                value={time[i]}
                                required/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="formBasicQuantity">
                    <Form.Label>Quantity {i+1}:</Form.Label>
                        <Form.Control
                            type="text" 
                            placeholder={`Enter Quantity ${i+1}`} 
                            name={`quantity${i+1}`}
                            onChange={(e)=>handleChange(i,e,"quantity")}
                            value={quantity[i]}
                            required/>
                    </Form.Group>
                </Form.Row>
                ):null
            }
            <hr/>
        </div>
    )
}

export default Schedular;