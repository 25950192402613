import React, { useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import { useEffect } from "react";
import { GetVendor } from "../../../entities/action/action";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import Image from "../../../utilities/imageTestApi/image";
import { useSelector } from "react-redux";
// import {DropzoneArea} from 'material-ui-dropzone';

const AddCabType = (props) => {
  const [message, updateMessage] = useState("");
  // const [files,updateFiles] = useState([]);

  // const handleImages = (inpFile)=>{
  //     updateFiles(inpFile)
  // }
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [file, updateFile] = useState({});
  const [arrPoints, updateArrPoints] = useState([1]);
  const [dataSet, updateDataSet] = useState([]);
  const [currentPoints1, updateCurrentPoints1] = useState(1);
  const [arrPoints1, updateArrPoints1] = useState([1]);
  const [vendorList, updateVendorList] = useState([]);
  const [vendorId, updateVendorId] = useState("");
  const [amenities, updateAmenities] = useState("");
  const [amenitiesId, updateAmenitiesId] = useState("");
  const data = useSelector((state) => state.adminAccount.apiResponse);
  useEffect(() => {
    (async () => {
      const res = await GetVendor();
      if (res != undefined && res.length > 0) {
        const arr = res.filter(
          (item, key) => item.companyInformation.vendortype === "Cab Operator"
        );
        console.log(res);
        console.log(arr);
        updateVendorList(arr);
      }
    })();
  }, []);
  // console.log(vendorList);
  console.log(file);

  useEffect(() => {
    (async () => {
      const newdata = {
        emailId: data.data.key,
        password: "string",
        phoneno: "string",
        sessionKey: data.sessionId,
      };
      console.log(newdata);
      const res = await fetch("https://api.zupptravel.com/getCabAmenities", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      console.log(json);
      if (json.successful) {
        updateDataSet(json.cabAmenitiess);
      }
    })();
  }, []);

  console.log("dataaa", dataSet);

  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    } else {
      const newCurrentPoint1 = currentPoints1 + 1;
      updateCurrentPoints1(newCurrentPoint1);
      updateArrPoints1([...arrPoints1, newCurrentPoint1]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    } else if (type === 2 && currentPoints1 > 1) {
      const newCurrentPoint1 = currentPoints1 - 1;
      updateCurrentPoints1(newCurrentPoint1);
      let arr = [...arrPoints1];
      arr.pop();
      updateArrPoints1(arr);
    }
  };

  console.log(amenities);

  useEffect(() => {
    dataSet.map((data, key) => {
      if (data.amenities == amenities) {
        updateAmenitiesId(data.id);
      }
    });
  }, [amenities]);

  console.log(amenitiesId);

  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
          const img = await Image(file);
          console.log(img);

          const charges = [];
          const cancellation = [];

          Object.entries(values).map(([key, value]) => {
            console.log(key, value);
            if (key.includes("charges") && typeof value === "string") {
              charges.push(value);
            } else if (
              key.includes("cancellation") &&
              typeof value === "string"
            ) {
              cancellation.push(value);
            }
          });

          const cancellationCharges = [];

          for (let i = 0; i < charges.length; i++) {
            cancellationCharges.push({
              cancellation1: cancellation[i],
              cancellationCharges1: charges[i],
            });
          }

          console.log(charges, cancellation, "checkkkkk");

          const newdata = {
            cabCancellation: cancellationCharges,
            cabDetailsId: 0,
            cabAmenities: amenitiesId.toString(),
            cabManufacturerName: values.CabManufacturerName,
            cabName: values.CabName,
            cabRegistrationNumber: values.CabRegistrationNumber,
            cabType: values.CabType,
            // emailId: "string",
            fuelType: values.fuelType,
            liveTracking: values.liveTracking,
            partialCancellation: values.partialCancellation,
            rcImage: img,
            seatCount: values.seatCount1,
            // seatType: values.seatType,
            sessionKey: "string",
            termsAndCondition: message,
            vendorId: vendorId,
            waitingPeriod: values.waitingPeriod,
          };

          const res = await fetch("https://api.zupptravel.com/addCabDetails", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newdata),
          });

          console.log("rrrrrrrrrrrr", newdata);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("Added Cab Detail!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <SingleSelect
                  type="3"
                  list={vendorList}
                  change={(select) => updateVendorId(select)}
                />
                {/* <Form.Control
                  as="select"
                  placeholder="Vendor ID"
                  name="vendorId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vendorId}
                  required
                >
                  <option value="">--select--</option>
                </Form.Control> */}
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicInfo" as={Row}>
              <Form.Label column sm="3">
                Cab Registration Number:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Cab Registration Number"
                  name="CabRegistrationNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.CabRegistrationNumber}
                  required
                />
              </Col>
              <Form.Label column sm="2">
                Cab Manufacturer Name:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Cab Manufacturer Name"
                  name="CabManufacturerName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.CabManufacturerName}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCabType" as={Row}>
              <Form.Label column sm="3">
                Cab Type:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="select"
                  type="text"
                  placeholder="Enter Cab Type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="CabType"
                  value={values.CabType}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Sedan">Sedan</option>
                  <option value="Suv">Suv</option>
                  <option value="Hatchback">Hatchback</option>
                  <option value="Muv">Muv</option>
                  <option value="Coupe">Coupe</option>
                  <option value="Convertibles">Convertibles</option>
                  <option value="MiniVan">MiniVan</option>
                  <option value="Van">Van</option>
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                Cab Name(Model):
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter Cab Name"
                  name="CabName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.CabName}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicBusName" as={Row}>
              <Form.Label column sm="3">
                Cab Amenities:
              </Form.Label>

              <Col sm="9">
                <SingleSelect
                  type="10"
                  list={dataSet}
                  change={(select) => updateAmenities(select)}
                />
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicCabName" as={Row}>
              <Form.Label column sm="3">
                Fuel Type:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="select"
                  type="text"
                  placeholder="Enter Fuel Type"
                  name="fuelType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fuelType}
                  required
                >
                  <option value="">--select--</option>
                  <option value="CNG">CNG</option>
                  <option value="Petrol">Petrol</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Hybrid">Hybrid</option>
                  <option value="Electric">Electric</option>
                </Form.Control>
              </Col>

              <Form.Label column sm="2">
                Waiting Period:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter Waiting Period"
                  name="waitingPeriod"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.waitingPeriod}
                  required
                />
              </Col>
            </Form.Group>
            {/* {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group controlId={`formSeatType${d}`} key={key} as={Row}>
                    <Form.Label column sm="3">
                      Seat Type {d}:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Enter Seat Type ( option ${d} )`}
                        name={`seatType${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`seatType${d}`]}
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Enter Seat Count"
                        name={`seatCount${1}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`seatCount${1}`]}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null} */}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>
              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div> */}
            <Form.Group controlId="formBasicSeatType1" as={Row}>
              <Form.Label column sm="2">
                Seat Count:
              </Form.Label>
              {/* <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter Seat Type ( option 1 )"
                  name="seatType1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatType1}
                  required
                />
              </Col> */}
              <Form.Label column sm="1">
                {/* Seat Count: */}
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter Seat Count"
                  name="seatCount1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatCount1}
                  required
                />
              </Col>

              <Form.Label column sm="2">
                Rc Image:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="file"
                  name="file"
                  onChange={(e) => updateFile(e.target.files[0])}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicLiveTracking" as={Row}>
              <Form.Label column sm="3">
                Partial Cancellation:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="select"
                  placeholder="Partial Cancellation"
                  name="partialCancellation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.partialCancellation}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                Live Tracking:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  as="select"
                  placeholder="Live Tracking"
                  name="liveTracking"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.liveTracking}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
            </Form.Group>
            {arrPoints1.length > 0
              ? arrPoints1.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Cancellation {d}:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Cancellation ${d}`}
                        name={`cancellation${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`cancellation${d}`]}
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Charges ${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`charges${d}`}
                        value={values[`charges${d}`]}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints1 <= 1 ? true : false}
                onClick={() => handleRemove(2)}
              >
                Remove
              </Button>
              <Button variant="success" onClick={() => handleAdd(2)}>
                Add
              </Button>
            </div>
            {/* <Form.Group controlId="formBasicChild" as={Row}>
                                            <Form.Label column sm="3">Images:</Form.Label>
                                            <Col sm="9"> 
                                                <DropzoneArea onChange={ handleImages }filesLimit={5}/>
                                            </Col>
                                        </Form.Group> */}
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                />
              </Col>
            </Row>
            <center>
              <Button type="submit" variant="success">
                Save Cab
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddCabType;
