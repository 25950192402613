import React from "react";
import {NavDropdown, Spinner} from 'react-bootstrap';
import {useSelector,useDispatch} from 'react-redux';
import { Logout } from "../../entities/account/action/action";

const AdminNavbar=()=>{
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    const dispatch = useDispatch();
    return(
        <>
            <div style={{float:'right'}}>
                        <NavDropdown title="Puneet Gupta" id="dropdown-menu-align-right" >
                            <NavDropdown.Item>Key Number - {userData!=null?userData.data.key:<Spinner/>}</NavDropdown.Item>
                            <NavDropdown.Item>Account Type - Admin</NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item  onClick={()=>dispatch(Logout())}>Logout</NavDropdown.Item>
                        </NavDropdown>
                </div>
        </>
    )
}
export default AdminNavbar;