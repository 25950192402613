// import React, { Fragment, useState, useEffect } from 'react';
// import {Col,Table, DropdownButton,Container, Button} from 'react-bootstrap';
// import classes from '../WebsiteManagement/websiteManagement.module.css';

// import {useSelector} from 'react-redux';
// import AccommodationDetails from './AccommodationDetails';
// import AccommodationData from './AccommodationData';
// const Accommodation = (props)=>{
 

//     const [active, updateActive] = useState('1');
//     const [data, updateData] = useState({});
   
//     const handleClick = (select) => {
//         console.log(select)
//         updateData(select);
//         updateActive("2");
//     }

//     return(
//         <Container fluid>
          
//       {
//                     active === '1' ?<AccommodationData click={(select)=>handleClick(select)}/>:
//                     active === "2"?<AccommodationDetails data={data} clicked={()=>updateActive("1")}/> :null
//                 }    

           
//         </Container>
//     )
// }
// export default Accommodation;


import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import AccommodationData from './AccommodationData';
import AccommodationDetails from './AccommodationDetails';
import {Col,Row} from 'react-bootstrap';
// import classes from '../WebsiteManagement/websiteManagement.module.css';
import classes from '../../WebsiteManagement/websiteManagement.module.css'

const Accommodation = (props) => {
  const [active, updateActive] = useState('1');
  const [data, updateData] = useState({});

  const handleClick = (select) => {
    console.log(select);
    updateData(select);
    updateActive('2');
  };

  const handleDetailsClose = () => {
    updateActive('1');
  };

  return (
    <Container fluid>
          <Col lg={12} md={12} className={classes.Response2}>
      {active === '1' ? (
        <AccommodationData click={(select) => handleClick(select)} />
      ) : null}

      {active === '2' ? (
        <AccommodationDetails data={data} clicked={handleDetailsClose} />
      ) : null}

</Col>
    </Container>
  );
};

export default Accommodation;
