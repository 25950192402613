import React,{useState,Fragment} from 'react';
import {Modal,Button,Form} from 'react-bootstrap';
import {DropzoneArea} from 'material-ui-dropzone'
import { Formik } from 'formik';
import {useSelector} from 'react-redux';
import { AddSliderImageCustomer } from '../../../entities/action/action';
import Image from '../../../utilities/imageTestApi/image';

const AddSlider = (props)=>{
    const [show, setShow] = useState(false);
    const [success,updateSuccess] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [files,updateFiles]=useState([]);
    const  handleChanged=(inpFile)=>{
        updateFiles(inpFile);
    }
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    return(
        <Fragment> 
            <Button size="lg" variant="success" onClick={handleShow}>Add</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Image Slider</Modal.Title>
                </Modal.Header>
                {
                    success?
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>:
                <Formik
                initialValues={{}}
                onSubmit ={
                    async(values,{setSubmitting,resetForm})=>{
                        setSubmitting(true);
                        const img = await Image(files[0]); 
                        const newData = {
                            // "dealType": values.dealType,
                            // "discountType": values.discountType,
                            "days": "string",
                           
                            "hrs": "string",
                            "imageUrl": img,
                            "minutes": "string",
                            "emailId": userData.data.key,
                            "link": values.link,
                            // "productTitle": values.productTitle,
                            "sessionKey": userData.sessionId,
                            // "status": true,
                        
                        }
                        const response = await AddSliderImageCustomer(newData);
                        console.log(response)
                        if(response.successful){
                            updateSuccess(true);
                            setTimeout(()=>{
                                updateSuccess(false)
                            },3000)
                        }
                        resetForm();
                        setSubmitting(false);
                    }
                }
                > 
                    {({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body>
                            <DropzoneArea 
                            onChange={ handleChanged }
                            filesLimit={1}
                            /> 
                            {/* <Form.Group>
                                <Form.Label>Deal Type</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Deal Type"
                                name="dealType" 
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.dealType}
                                required
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Product Title</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Product Title"
                                name="productTitle" 
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.productTitle}
                                required
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Discount Type</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Discount Type"
                                name="discountType" 
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.discountType}
                                required
                                />
                            </Form.Group> */}
                            <Form.Group>
                                <Form.Label>Timer date</Form.Label>
                                <Form.Control 
                                type="date" 
                                placeholder="Timer Date"
                                name="timerDate" 
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.timerDate}
                                required
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Link</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Enter Link"
                                name="link" 
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.link}
                                required
                                />
                            </Form.Group>
                            {/* <fieldset>
                                <Form.Group as={Row}>
                                <Form.Label as="legend" column sm={2}>
                                    Status
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Check
                                    type="radio"
                                    onChange={handleChange}
                                    value={true}
                                    label="Active"
                                    name="status"
                                    />
                                    <Form.Check
                                    type="radio"
                                    label="Inactive"
                                    value={false}
                                    onChange={handleChange}
                                    name="status"
                                    />
                                </Col>
                                </Form.Group>
                            </fieldset> */}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button size="lg" variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button size="lg" type="submit">
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
                }
            </Modal>
        </Fragment>                     
    )
}
export default AddSlider;