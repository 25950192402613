import React, {useState, Fragment } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import CreateCoupon from './createCoupon';
import ActiveCoupon from './activeCoupon';
import UpdateCollectionCenter from './updateViewCoupon';

const Coupons = (props)=>{
    const [active,updateActive] = useState('1');
    const [couponData,updateCouponData] = useState({});
    const items = [
        {id:"1",type:"Create Coupons "},
        {id:"2",type:"Active Coupons"}
    ]
    const handleCouponView = (data)=>{
        updateActive("3");
        updateCouponData(data);
    }
    return(
        <Fragment> 
            {/* <div style={{float:'right'}} className={classesX.Logout}>Logout</div>
            <h1 className={classesX.Heading}>Money Management</h1>   */}
        <Container fluid className={classes.Box}>
        <Row style={{margin:'0 0 2% 0',textAlign:'center',cursor:'pointer'}}>
                <Col xs={6} className={classes.Col} 
                style={{backgroundColor:`${active==='1'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("1")}>
                    Create Coupon 
                </Col>
                <Col xs={6} className={classes.Col}
                style={{backgroundColor:`${active==='2'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("2")}>
                    Active Coupon
                </Col>
            </Row>
            <Container fluid> 
                <Row>   
                    <Col md={12} className={classes.Response} style={{paddingRight:'10px'}}>
                        <div style={{height:'100%'}}> 
                            {
                                active==="1"?<CreateCoupon click={()=>updateActive("2")}/>:
                                active==="2"?<ActiveCoupon click={(data)=>handleCouponView(data)}/>:
                                active==="3"?<UpdateCollectionCenter data={couponData}/>:null
                            }   
                        </div>
                    </Col>
                </Row>
            </Container>
        </Container>
        </Fragment>
    )
}

export default Coupons;