import React, { useState, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import classesX from "../Dashboard/dashboard.module.css";
import AdminNavbar from "../navbar/adminNavbar";
import classes from "../WebsiteManagement/websiteManagement.module.css";

import AddLocation from "./addLocation";
import AddPincode from "./AddPincode";
import LocationList from "./LocationList";
import PincodeList from "./PincodeList";

const LocationManagement = (props) => {
  const [active, updateActive] = useState("1");
  const items = [
    { id: "1", type: "Add Location" },
    { id: "2", type: "Location List" },
    { id: "3", type: "Add Pincode" },
    { id: "4", type: "Pincode List" },
  ];

  return (
    <Fragment>
      <AdminNavbar />
      <h1 className={classesX.Heading}>Location Managements</h1>
      <Container fluid style={{ paddingLeft: "10px" }}>
        <Row>
          <Col lg={12} md={12} className={classes.Response2}>
            <div style={{ height: "100%" }}>
              <ul className={classes.Ul} style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 ,height:"10vh",}}>
                {items.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => updateActive(item.id)}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                      textAlign: 'center',
                      margin: '0 1px', // Adjust the margin as needed
                      padding:" 2% 0",
                      color: 'white',
                      cursor: 'pointer',
                      width:"25%",
                      height:"100%"
                  }}
                  >
                    {item.type}
                  </li>
                ))}
                {/* {active === "3" ? (
                  <li
                    onClick={() => updateActive("2")}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${
                        active === "4" ? "steelblue" : "#585858"
                      }`,
                      textAlign: "center",
                    }}
                  >
                    Back
                  </li>
                ) : null} */}
              </ul>
            </div>
          </Col>
          <Col lg={12} md={12} className={classes.Response}>
            <div style={{ height: "100%",margin:"2vh 7vw"}}>
              {
                active === "1" ? (
                  <AddLocation click={() => updateActive("2")} />
                ) : active === "2" ? (
                  <LocationList />
                ) : active == "3" ? (
                  <AddPincode />
                ) : active == "4" ? (
                  <PincodeList/>
                ): null
                // active==="3"?<CouponView data={couponData}/>:null
              }
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
    // <Fragment>
    //   <AdminNavbar />
    //   <h1 className={classesX.Heading}>Location Management</h1>
    //   <Container fluid>{active === "1" ? <AddLocation /> : null}</Container>
    // </Fragment>
  );
};

export default LocationManagement;
