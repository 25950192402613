import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Form, Button, Row, Col } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import { Pincode } from "../../../entities/action/action";
import { useSelector, useDispatch } from "react-redux";
import { DropzoneArea } from "material-ui-dropzone";
import Image from "../../../utilities/imageTestApi/image";

const AddAccommodationGeneralView = (props) => {
  const [state, setState] = useState("");
  const [mycity, setCity] = useState("");
  const [arrPoints, updateArrPoints] = useState([
    ...new Array(props.data.cancellationCharges.length)
      .fill(0)
      .map((_, i) => i),
  ]);
  const [files, updateFiles] = useState([]);
  const [location, updateLocations] = useState([]);
  const [country, setCountry] = useState("");
  const [file, updateFile] = useState({});
  const [pincode, updatePincode] = useState("");
  const [edit, updateEdit] = useState(false);
  const handleImages = (inpFile) => {
    updateFiles(inpFile);
  };
  const dispatch = useDispatch();
  const cityState = useSelector((state) => state.account.cityState);
  const myfunc = (e) => {
    if (e.target.value.length === 6) {
      dispatch(Pincode(e.target.value));
    } else {
      setState("");
      setCity("");
    }
  };
  const clickHandler = (id) => {
    const win = window.open(id, "_blank");
    win.focus();
  };

  const handlePincode = (e) => {
    if (e !== "") {
      updatePincode(e);
      const x = location.filter((a, i) => a.pincode === e);
      setCity(x[0].city);
      setState(x[0].state);
      setCountry(x[0].country);
      // setBlock(x[0].blockTehsilTaluka);
    }
  };

  console.log(props.data);
  console.log(props.data.state);
  console.log(props.data.city);
  console.log(arrPoints);

  useEffect(() => {
    if (!cityState) {
        setState("");
        setCity("");
    } else {
        setState(cityState?.state);
        setCity(cityState?.city);
    }
  }, [cityState]);

  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          // accommodationId: 0,
          accommodationName: props.data.accommodationName,
          accommodationType: props.data.accommodationType,

          ...Object.assign(
            {},
            ...props.data.cancellationCharges.map((i, index) => ({
              [`cancellation${index}`]: i.cancellation,
              [`charges${index}`]: i.charges,
            }))
          ),

          city: props.data.city,
          country: props.data.country,
          emailId: "string",
          // images: props.data.img2,
          pincode: props.data.pincode,
          // proof: props.data.img,
          propertyEmail1: props.data.propertyEmail1,
          propertyEmail2: props.data.propertyEmail2,
          propertyInformation: props.data.propertyInformation,
          propertyPhone: props.data.propertyPhone,
          propertyPhone1: props.data.propertyPhone1,
          propertyPhone2: props.data.propertyPhone2,
          propertyPhone3: props.data.propertyPhone3,
          propertyPhone4: props.data.propertyPhone4,
          propertyStar: props.data.propertyStar,
          addressline1: props.data.regAddress1,
          addressline2: props.data.regAddress2,
          sessionKey: "string",
          state: props.data.state,
          // status: true,
          tehsil_block: props.data.tehsil_block,
          vendorId: props.data.vendorId,
          village: props.data.village,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);

          let img = props.data.proof;
          let img2 = props.data.images;

          if (file.type != undefined) {
            img = await Image(file);
          }
          if (files.type != undefined) {
            img = await Image(files);
          }

          const charges = [];
          const cancellation = [];

          Object.entries(values).map(([key, value]) => {
            console.log(key, value);
            if (key.includes("charges") && typeof value === "string") {
              charges.push(value);
            } else if (
              key.includes("cancellation") &&
              typeof value === "string"
            ) {
              cancellation.push(value);
            }
          });

          const cancellationCharges = [];
          for (let i = 0; i < charges.length; i++) {
            cancellationCharges.push({
              cancellation: cancellation[i],
              charges: charges[i],
            });
          }

          console.log(charges, cancellation, "checkkkkk");
          const newdata = {
            accommodationId: props.data.id,
            accommodationName: values.accommodationName,
            accommodationType: props.data.accommodationType,

            cancellationCharges: [...cancellationCharges],

            city: props.data.city,
            country: props.data.country,
            emailId: "string",
            images: img2,
            pincode: props.data.pincode,
            proof: img,
            propertyEmail1: values.propertyEmail1,
            propertyEmail2: values.propertyEmail2,
            propertyInformation: values.propertyInformation,
            propertyPhone: values.propertyPhone,
            propertyPhone1: values.propertyPhone1,
            propertyPhone2: values.propertyPhone2,
            propertyPhone3: values.propertyPhone3,
            propertyPhone4: values.propertyPhone4,
            propertyStar: values.propertyStar,
            regAddress1: values.addressline1,
            regAddress2: values.addressline2,
            tehsil_block: values.tehsil_block,
            sessionKey: "string",
            state: state,
            status: true,
            vendorId: props.data.vendorId,
            village: values.village,
          };

          const res = await fetch(
            "https://api.zupptravel.com/updateAccommodation",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newdata),
            }
          );

          console.log("rrrrrrrrrrrr", newdata);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("updated Accommodation!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "2%",
              }}
            >
              {!edit ? (
                <Button variant="success" onClick={() => updateEdit(true)}>
                  Edit
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={() => {
                    updateEdit(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="light"
                style={{ marginLeft: "15px" }}
                onClick={() => props.click()}
              >
                {`<--`} Back
              </Button>
            </div>
            <Form.Group controlId="formBasicAccommodationName" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  readOnly
                  type="text"
                  placeholder="Enter Accommodation Type"
                  name="vendorId"
                  value={props.data.vendorId}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicAccommodationName" as={Row}>
              <Form.Label column sm="3">
                Accommodation Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  readOnly
                  type="text"
                  placeholder="Enter Accommodation Type"
                  name="accommodationName"
                  value={props.data.accommodationType}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicAccommodationName" as={Row}>
              <Form.Label column sm="3">
                Accommodation Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Accommodation Name"
                  name="accommodationName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.accommodationName}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyStar" as={Row}>
              <Form.Label column sm="3">
                Property Star:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Property Star"
                  name="propertyStar"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.propertyStar}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyEmail" as={Row}>
              <Form.Label column sm="3">
                Property Email 1:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Property Email"
                  name="propertyEmail1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.propertyEmail1}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyEmail" as={Row}>
              <Form.Label column sm="3">
                Property Email 2:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Property Email"
                  name="propertyEmail2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.propertyEmail2}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyPhone" as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="9">
                <Row>
                  <Form.Label column sm="3">
                    Primary Phone:
                  </Form.Label>
                  <Col sm="3">
                    <Form.Control
                      type="number"
                      placeholder="Enter Property Phone"
                      name="propertyPhone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.propertyPhone}
                      disabled={!edit}
                      required
                    />
                  </Col>

                  <Form.Label column sm="3">
                    Phone 1:
                  </Form.Label>
                  <Col sm="3">
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone 1"
                      name="propertyPhone1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.propertyPhone1}
                      disabled={!edit}
                      required
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyPhone" as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="9">
                <Row>
                  <Form.Label column sm="3">
                    Phone 2:
                  </Form.Label>
                  <Col sm="3">
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone 2"
                      name="propertyPhone2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.propertyPhone2}
                      disabled={!edit}
                    />
                  </Col>

                  <Form.Label column sm="3">
                    Phone 3:
                  </Form.Label>
                  <Col sm="3">
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone 3"
                      name="propertyPhone3"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.propertyPhone3}
                      disabled={!edit}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyPhone" as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="9">
                <Row>
                  <Form.Label column sm="3">
                    Phone 4:
                  </Form.Label>
                  <Col sm="3">
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone 4"
                      name="propertyPhone4"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!edit}
                      value={values.propertyPhone4}
                    />
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicAddress1" as={Row}>
              <Form.Label column sm="3">
                Registered Address Line 1:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  name="addressline1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.addressline1}
                  readOnly
                  // disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicAddress2" as={Row}>
              <Form.Label column sm="3">
                Registered Address Line 2:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  name="addressline2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.addressline1}
                  readOnly
                  // disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicAddress2" as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="9">
                <Row>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicPin" as={Row}>
                      <Form.Label column sm="3">
                        Pin Code:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          placeholder="Enter Pin Code"
                          name="pincode"
                          onChange={handleChange}
                          value={values.pincode}
                          readOnly
                          // disabled={!edit}
                          onBlur={myfunc}
                          change={(select) => handlePincode(select)}
                          className={touched.pincode ? "has-error" : null}
                        />
                      </Col>
                      {errors.pincode && touched.pincode && errors.pincode}
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicCity" as={Row}>
                      <Form.Label column sm="3">
                        City:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          placeholder="Enter City"
                          name="city"
                          value={values.city}
                          readOnly
                          // disabled={!edit}
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicState" as={Row}>
                      <Form.Label column sm="3">
                        State:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          placeholder="Enter State"
                          name="state"
                          value={props.data.state}
                          readOnly
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicVillage" as={Row}>
                      <Form.Label column sm="3">
                        Village:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          readOnly
                          type="text"
                          placeholder="Enter Village"
                          name="village"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={props.data.village}
                          // disabled={!edit}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicBlock" as={Row}>
                      <Form.Label column sm="3">
                        Tehsil/Block :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          readOnly
                          type="text"
                          placeholder="Enter Tehsil/Block"
                          name="tehsil_block"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={props.data.tehsil_block}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyInformation" as={Row}>
              <Form.Label column sm="3">
                Property Information:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Property Information"
                  name="propertyInformation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.propertyInformation}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicUploadProof" as={Row}>
              <Form.Label column sm="3">
                Upload Proof:
              </Form.Label>
              <Col>
                <Form.Control
                  type="file"
                  name="file"
                  disabled={!edit}
                  onChange={(e) => updateFile(e.target.files[0])}
                />
              </Col>

              <Col>
                <Button onClick={() => clickHandler(props.data.proof)}>
                  Open
                </Button>
              </Col>

              <Col></Col>
            </Form.Group>
            {arrPoints.map((d, key) => (
              <Form.Group controlId={`formCancellation${d}`} key={key} as={Row}>
                <Form.Label column sm="3">
                  Cancellation :
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder={`Cancellation `}
                    name={`cancellation${d}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={!edit}
                    value={values[`cancellation${d}`]}
                    required
                  />
                </Col>
                <Form.Label column sm="3">
                  Charge:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    placeholder={`Charges `}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name={`charges${d}`}
                    disabled={!edit}
                    value={values[`charges${d}`]}
                    required
                  />
                </Col>
              </Form.Group>
            ))}
            <Form.Group controlId="formBasicChild" as={Row}>
              <Form.Label column sm="3">
                Images:
              </Form.Label>
              <Col>
                <Form.Control
                  type="file"
                  name="file"
                  disabled={!edit}
                  onChange={handleImages}
                />
              </Col>
              <Col>
                <Button onClick={() => clickHandler(props.data.images)}>
                  Open
                </Button>
              </Col>
            </Form.Group>

            <center>
              <Button disabled={!edit} type="submit" variant="success">
                Save Accommodation
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddAccommodationGeneralView;
