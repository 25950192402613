import React from "react";
import { Formik } from "formik";
import { Col, Button, Form, Row } from "react-bootstrap";
import { useState } from "react";
import classes from "../../WebsiteManagement/websiteManagement.module.css";

function SeatManagement() {
  const [arrPoints, updateArrPoints] = useState([1]);
  const [currentPoints, updateCurrentPoints] = useState(1);

  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
      // } else if (type === 2 && currentPoints1 > 1) {
      //   const newCurrentPoint1 = currentPoints1 - 1;
      //   updateCurrentPoints1(newCurrentPoint1);
      //   let arr = [...arrPoints1];
      //   arr.pop();
      //   updateArrPoints1(arr);
    }
  };
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          unitSericeVariablemsp: "",
          taxSlabVariablemsp: "",
          unitSericeVariableocc: "",
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {}}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group controlId={`formSeatType${d}`} key={key} as={Row}>
                    <Form.Label column sm="3">
                      Seat Type {d}:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Enter Seat Type ( option ${d} )`}
                        name={`seatType${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // value={values.seatType[d]?.seatType}
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="number"
                        placeholder="Enter Seat Count"
                        name={`seatCount${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        //    value={values.seatType[d]?.seatCount}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>
              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default SeatManagement;
