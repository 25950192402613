import React, { useState,useEffect } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { GetGiftCategory,DeleteGiftCategory } from '../../../entities/action/action';
import classes from '../../WebsiteManagement/websiteManagement.module.css';

const GiftCategorySubcategoryList = (props)=>{
    const [dataSet,updateDataSet] = useState([]);
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    useEffect(()=>{
        
        (async()=>{
            const newData = {
                "phoneno": "",
                "password": "",
                "emailId": userData.data.key,
                "sessionKey": userData.sessionId
              }
            let arr = await GetGiftCategory(newData);
            console.log(arr)
            if(arr.successful){
                updateDataSet(arr.categoryList);
            }
        })()
    },[])
    const handleDelete = async(id,val)=>{
       
        const newData = {
            "emailId": userData.data.key,
            "sessionKey": userData.sessionId,
            "giftCategoryId": id,
            
        }
        console.log(newData)
        const res = await DeleteGiftCategory(newData);
        console.log(res)
        if(res.successful){
            alert("Successfully Deleted");
            window.location.reload();
        }
    }
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Gift {`${props.list}`}</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataSet.length>0?dataSet.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.categoryName}</td>
                                <td className={classes.CenterRow}>
                                    <Button onClick={()=>handleDelete(data.id,data.categoryName)} style={{margin:'auto 1%'}} variant="danger">Delete</Button>
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
        </Container>
    )
}
export default GiftCategorySubcategoryList;