import React from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import { useSelector } from "react-redux";
import { Addairline } from "../../entities/action/action";

function AddAirline() {
  const data = useSelector((state) => state.adminAccount.apiResponse);
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
          setSubmitting(true);
          const newData = {
            airlineCode: values.airlineCode,
            airlineHelplineEmail: values.airlineHelplineEmail,
            airlineHelplineNo: values.airlineHelplineNo,
            Id: 0,
            active: true,
            airlineName: values.airlineName,
            emailId: data.data.key,
            sessionKey: data.sessionId,
            providerType: values.providerType,
          };
          console.log(newData);
          const res = await Addairline(newData);
          console.log(res);
          if (res.successful) {
            alert("Successfully Added Airline");
            resetForm();
          } else {
            alert("Not added! Please try again.");
            resetForm();
          }
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasic" as={Row}>
              <Form.Label column sm="3">
                Airline Code:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Airline Code"
                  name="airlineCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.airlineCode}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicIATACode" as={Row}>
              <Form.Label column sm="3">
                Airline Helpline Email:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="email"
                  placeholder="Enter Airline Email"
                  name="airlineHelplineEmail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.airlineHelplineEmail}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicIATACode" as={Row}>
              <Form.Label column sm="3">
                Airline Helpline Number:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Airline Number"
                  name="airlineHelplineNo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.airlineHelplineNo}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCName" as={Row}>
              <Form.Label column sm="3">
                Airline Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Airline Name"
                  name="airlineName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.airlineName}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCountryCode" as={Row}>
              <Form.Label column sm="3">
                Provider Type
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Provider Type"
                  name="providerType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.providerType}
                  required
                />
              </Col>
            </Form.Group>
            <center>
              <Button type="submit" variant="success">
                Save Airline
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default AddAirline;
