import React, { useEffect, useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Field, FieldArray, Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import MultipleSelect from "../../multiSelect";
import { DropzoneArea } from "material-ui-dropzone";
import { GetVendor } from "../../../entities/action/action";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import { useSelector } from "react-redux";
import Image from "../../../utilities/imageTestApi/image";

const AddTourGuide = (props) => {
  const [message, updateMessage] = useState("");
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const [city, updateCity] = useState([]);
  const [dataSet, updateDataSet] = useState([]);
  const [id, setId] = useState("");
  const [message2, updateMessage2] = useState("");
  const [select, updateSelect] = useState("");
  const [files, updateFiles] = useState([]);
  const [files2, updateFiles2] = useState([]);
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([1]);
  const [currentPoints1, updateCurrentPoints1] = useState(1);
  const [arrPoints1, updateArrPoints1] = useState([1]);
  const [currentPoints2, updateCurrentPoints2] = useState(1);
  const [arrPoints2, updateArrPoints2] = useState([1]);
  const [vendorList, updateVendorList] = useState([]);
  const [vendorId, updateVendorId] = useState("");

  useEffect(() => {
    (async () => {
      const myresponse = await fetch(
        `https://api.zupptravel.com/getLocationManagement`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            emailId: data.data.key,
            password: "string",
            phoneno: "string",
            sessionKey: data.sessionId,
          }),
        }
      );
      const json = await myresponse.json();
      // console.log(json);
      if (json.successful) {
        updateDataSet(json.locationManagements);
      }
    })();
  }, []);
  console.log(dataSet);
  useEffect(() => {
    const arr1 = dataSet.map((data, key) => data.city);
    console.log(arr1);
    updateCity(arr1);
  }, [dataSet]);

  // console.log(city);

  useEffect(() => {
    (async () => {
      const res = await GetVendor();

      if (res != undefined && res.length > 0) {
        const arr = res.filter(
          (item, key) => item.companyInformation.vendortype === "Tour Guide"
        );

        updateVendorList(arr);
      }
    })();
  }, []);

  console.log(vendorList);

  const handleImages = (inpFile) => {
    updateFiles(inpFile);
  };
  const updateCertificateImage = (e, key) => {
    let arr = [...files2];
    console.log(arr);
    arr[key] = e.target.files[0];
    updateFiles2(arr);
  };

  console.log(files2);

  const images = [];
  const images1 = async () => {
    for (let i = 0; i < files2.length; i++) {
      const img = await Image(files2[i]);
      console.log(img);
      images.push(img);
    }
    console.log(images);
  };
  images1();

  console.log(vendorId);

  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    } else if (type === 2) {
      const newCurrentPoint1 = currentPoints1 + 1;
      updateCurrentPoints1(newCurrentPoint1);
      updateArrPoints1([...arrPoints1, newCurrentPoint1]);
    } else {
      const newCurrentPoint2 = currentPoints2 + 1;
      updateCurrentPoints2(newCurrentPoint2);
      updateArrPoints2([...arrPoints2, newCurrentPoint2]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    } else if (type === 2 && currentPoints1 > 1) {
      const newCurrentPoint1 = currentPoints1 - 1;
      updateCurrentPoints1(newCurrentPoint1);
      let arr = [...arrPoints1];
      arr.pop();
      updateArrPoints1(arr);
    } else if (type === 3 && currentPoints2 > 1) {
      const newCurrentPoint2 = currentPoints2 - 1;
      updateCurrentPoints2(newCurrentPoint2);
      let arr = [...arrPoints2];
      arr.pop();
      updateArrPoints2(arr);
    }
  };
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          cancellation: [
            {
              cancellation: "",
              charges: "",
            },
          ],
          certification: [
            {
              certificationType: "",
              imageUrl: "",
            },
          ],
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log("values", values);

          const charges = [];
          const cancellation = [];
          const certificationType = [];

          Object.entries(values).map(([key, value]) => {
            console.log(key, value);
            if (key.includes("charges") && typeof value === "string") {
              charges.push(value);
            } else if (
              key.includes("cancellation") &&
              typeof value === "string"
            ) {
              cancellation.push(value);
            } else if (
              key.includes("certificationType") &&
              typeof value === "string"
            ) {
              certificationType.push(value);
            }
          });

          const cancellationCharges = [];
          const certificationType1 = [];

          for (let i = 0; i < charges.length; i++) {
            cancellationCharges.push({
              cancellation: cancellation[i],
              charges: charges[i],
            });
          }

          for (let i = 0; i < certificationType.length; i++) {
            certificationType1.push({
              certificationType: certificationType[i],
              imageUrl: images[i],
            });
          }
          console.log(charges, cancellation, "checkkkkk");
          console.log(certificationType, images, "kkkkkkkkkkkkkk");

          const newdata = {
            about: message2,
            active: true,
            cancellation: [...cancellationCharges],
            citiesServed: select,
            certification: [...certificationType1],
            emailId: "string",
            guideName: values.tourGuideName,
            guideType: values.tourGuideType,
            language: values.language,
            sessionKey: "string",
            termsAndCondition: message,
            tourGuideId: 0,
            vendorId: 0,
          };
          console.log(newdata);
          const res = await fetch("https://api.zupptravel.com/addTourGuide", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newdata),
          });
          const json = await res.json();
          console.log(json);
          if (json.successful) {
            alert("Added tour guide!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <SingleSelect
                  type="3"
                  list={vendorList}
                  change={(select) => updateVendorId(select)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTourGuide" as={Row}>
              <Form.Label column sm="3">
                Tour Guide Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Tour Guide Name"
                  name="tourGuideName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tourGuideName}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTourGuide" as={Row}>
              <Form.Label column sm="3">
                Tour Guide Email:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Tour Guide Email"
                  name="tourGuideEmail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tourGuideEmail}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTourGuideType" as={Row}>
              <Form.Label column sm="3">
                Tour Guide Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  placeholder="Enter Tour Guide Type"
                  name="tourGuideType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tourGuideType}
                >
                  <option value="">--select--</option>
                  <option value="HistoricalGuide">Historical Guide</option>
                  <option value="Bi-lingualGuide">Bi-lingual Guide</option>
                  <option value="TreakGuide">Treak Guide</option>
                  <option value="MuseumGuide">Museum Guide</option>
                  <option value="NatureGuide">Nature Guide</option>
                  <option value="CityGuide">City Guide</option>
                  <option value="Parks/gardensGuide">
                    Parks/gardens Guide
                  </option>
                  <option value="FreelanceGuide">Freelance Guide</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVehicleAmenities" as={Row}>
              <Form.Label column sm="3">
                Cities served:
              </Form.Label>
              <Col sm="9">
                <SingleSelect
                  type="9"
                  list={city}
                  change={(select) => updateSelect(select)}
                />
                {/* <MultipleSelect
                  type="1"
                  selectedValue={(selected) => updateSelect(selected)}
                /> */}
              </Col>
            </Form.Group>

            <Form.Group controlId={`formBasicLanguage`} as={Row}>
              <Form.Label column sm="3">
                Language
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder={`Enter Language`}
                  name={`language`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // value={values[`language`]}
                  value={values.language}
                />
              </Col>
            </Form.Group>
            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Cancellation :
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Cancellation `}
                        name={`cancellation${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cancellation[d]?.cancellation}
                        required
                      />
                    </Col>
                    <Form.Label column sm="3">
                      Charge:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Charges `}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`charges${d}`}
                        value={values.cancellation[d]?.charges}
                        require
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>

              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div>

            <br />

            {arrPoints1.length > 0
              ? arrPoints1.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Certification :
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Certification `}
                        name={`certificationType${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.certification[d]?.certificationType}
                        required
                      />
                    </Col>

                    <Form.Label column sm="3">
                      Image
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control
                        type="file"
                        name={`imageUrl${d}`}
                        onChange={(e) => updateCertificateImage(e, key)}
                        onBlur={handleBlur}
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints1 <= 1 ? true : false}
                onClick={() => handleRemove(2)}
              >
                Remove
              </Button>

              <Button variant="success" onClick={() => handleAdd(2)}>
                Add
              </Button>
            </div>

            {/* <Field name={`cancellation.${index}.cancellation`}/> */}

            {/* <Form.Label column sm="3">
                            Image
                          </Form.Label>
                          <Col sm="3">
                            <Form.Control
                              type="file"
                              name={`certification.${index}.imageUrl`}
                              onChange={(e) => updateCertificateImage(e, index)}
                              onBlur={handleBlur}
                            />
                          </Col> */}

            {/* <Field name={`cancellation.${index}.charges`}/> */}

            {/* <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'15px'}}>
                                            <Button 
                                                variant="danger" 
                                                style={{marginRight:'15px'}} 
                                                disabled={currentPoints<=1?true:false}
                                                onClick={()=>handleRemove(1)}
                                            >Remove</Button>
                                            <Button variant="success" onClick={()=>handleAdd(1)}>Add</Button>
                                        </div> */}

            <Row style={{ margin: "2% 0", float: "none" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                About:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage2(text)}
                  text={message2}
                />
              </Col>
            </Row>
            <center>
              <Button type="submit" variant="success">
                Save Tour Guide
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddTourGuide;
