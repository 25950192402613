import React, { useEffect, useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import Image from "../../../utilities/imageTestApi/image";
import MultipleSelect from "../../multiSelect";
import RoomCategoryList from "./roomCategoryList";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import { useSelector } from "react-redux";

const BusRoute = (props) => {
  const [selected, updateSelected] = useState("");
  const [select, updateSelect] = useState([]);
  const [select1, updateSelect1] = useState([]);
  const [file1, updateFile1] = useState({});
  const [file2, updateFile2] = useState({});
  const [file3, updateFile3] = useState({});
  const [busType, updateBusType] = useState("");
  const [busName, updateBusName] = useState("");
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([1]);
  const [currentPoints1, updateCurrentPoints1] = useState(1);
  const [arrPoints1, updateArrPoints1] = useState([1]);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const [city, updateCity] = useState([]);
  const [dataSet, updateDataSet] = useState([]);

  useEffect(() => {
    (async () => {
      const myresponse = await fetch(
        `https://api.zupptravel.com/getLocationManagement`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            emailId: data.data.key,
            password: "string",
            phoneno: "string",
            sessionKey: data.sessionId,
          }),
        }
      );
      const json = await myresponse.json();
      console.log(json);
      if (json.successful) {
        updateDataSet(json.locationManagements);
      }
    })();
  }, []);
  console.log(dataSet);
  useEffect(() => {
    const arr1 = dataSet.map((data, key) => data.city);
    console.log(arr1);
    updateCity(arr1);
  }, [dataSet]);

  console.log(city);
  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    } else {
      const newCurrentPoint1 = currentPoints1 + 1;
      updateCurrentPoints1(newCurrentPoint1);
      updateArrPoints1([...arrPoints1, newCurrentPoint1]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    } else if (type === 2 && currentPoints1 > 1) {
      const newCurrentPoint1 = currentPoints1 - 1;
      updateCurrentPoints1(newCurrentPoint1);
      let arr = [...arrPoints1];
      arr.pop();
      updateArrPoints1(arr);
    }
  };
  return (
    <div>
      <Formik
        initialValues={{ title: "" }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {}}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicInfo" as={Row}>
              <Form.Label column sm="2">
                Bus Route Name:
              </Form.Label>
              <Col sm="4">
                <Col sm="10">
                  <Form.Control
                    type="text"
                    placeholder="Bus Route Name"
                    name="Busroutename"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    // value={values[`dropoffLocation${d}`]}
                    required
                  />
                </Col>
              </Col>
            </Form.Group>

            {/* <Form.Group controlId="formBasicBusInfo" as={Row}>
              <Form.Label column sm="2">
                Bus Type:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  readOnly
                  type="text"
                  placeholder="Bus Type"
                  name="busType"
                  value={busType}
                  required
                />
              </Col>
              <Form.Label column sm="2">
                Bus Name:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  readOnly
                  type="text"
                  placeholder="Bus Name"
                  name="busName"
                  value={busName}
                  required
                />
              </Col>
            </Form.Group> */}
            <Form.Group controlId="formBasicBusInfo" as={Row}>
              <Form.Label column sm="2">
                Location:
              </Form.Label>
              <Col sm="5">
                <SingleSelect
                  type="9"
                  list={city}
                  change={(select) => updateSelect(select)}
                />
              </Col>
              <Col sm="5">
                <SingleSelect
                  type="9"
                  list={city}
                  change={(select) => updateSelect1(select)}
                />
              </Col>
            </Form.Group>
            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group controlId={`formBoarding${d}`} key={key} as={Row}>
                    <Form.Label column sm="2">
                      Boarding Point {d}:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Location ${d}`}
                        name={`location${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`location${d}`]}
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="time"
                        name="boardingTime"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.boardingTime}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>
              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div>
            {arrPoints1.length > 0
              ? arrPoints1.map((d, key) => (
                  <Form.Group controlId={`formBoarding${d}`} key={key} as={Row}>
                    <Form.Label column sm="2">
                      Dropoff Point {d}:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Location ${d}`}
                        name={`dropoffLocation${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`dropoffLocation${d}`]}
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="time"
                        name="dropoffBoardingTime"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.dropoffBoardingTime}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints1 <= 1 ? true : false}
                onClick={() => handleRemove(2)}
              >
                Remove
              </Button>
              <Button variant="success" onClick={() => handleAdd(2)}>
                Add
              </Button>
            </div>
            <center>
              <Button type="submit" variant="success">
                Save Bus Route
              </Button>
            </center>
          </Form>
        )}
      </Formik>
      <hr />
      {/* <RoomCategoryList /> */}
    </div>
  );
};
export default BusRoute;
