import React, { useState } from 'react';
import {Button,Form,Col,Row} from 'react-bootstrap';
import classes from '../websiteManagement.module.css';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { GetSocialLinks } from '../../../entities/action/action';

const SocialLinks = ()=>{
    const [links,updateLinks] = useState({});
    const [active,updateActive] = useState(true);
    const [visible,updateVisible] = useState(false);
    useEffect(()=>{
        (async()=>{
            const response = await GetSocialLinks();
            if(response.success && response.websiteManagementSocialLinksResponse!=null){
                updateLinks(response?.websiteManagementSocialLinksResponse)
                updateVisible(true);
            }
        })()
    },[])

    return(
        <div className={classes.Box2}>
            {
                visible?
                <Formik
            initialValues={{facebook:links.facebook,instagram:links.instagram,youtube:links.youtube,twitter:links.twitter,linkedIn:links.linkedin}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    const newData = {
                        "facebook": values.facebook,
                        "instagram": values.instagram,
                        "twitter": values.twitter,
                        "youtube": values.youtube,
                        "linkedin":values.linkedIn
                    }
                    const token = localStorage.getItem('token');
                    const authHeader = `Bearer ${token}`
                    const myresponse = await fetch("https://api.zupptravel.com/api/v1/social/addSocialLinks", {
                        method: "post",
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': authHeader
                        },
                        body: JSON.stringify(newData),
                        });
                        const myjson = await myresponse.json();
                        if(myjson.success){
                            alert("Successfully Updated Links")
                            window.location.reload();
                        }
                }
            }
            >
                {
                    ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                        <Form onSubmit={handleSubmit}>
                            <Form.Group as={Row} controlId="formHorizontalFace">
                                <Form.Label column sm={2}>
                                Facebook
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control 
                                    type="text" 
                                    placeholder="Facebook Link" 
                                    name="facebook"
                                    value={values.facebook}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={active}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalInsta">
                                <Form.Label column sm={2}>
                                Instagram
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control 
                                    type="text" 
                                    placeholder="Instagram Link"
                                    name="instagram" 
                                    value={values.instagram}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={active}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalYou">
                                <Form.Label column sm={2}>
                                Youtube
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control 
                                    type="text" 
                                    placeholder="Youtube Link"
                                    name="youtube" 
                                    value={values.youtube}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={active}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalYou">
                                <Form.Label column sm={2}>
                                Twitter
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control 
                                    type="text" 
                                    placeholder="Twitter Link"
                                    name="twitter" 
                                    value={values.twitter}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={active}/>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} controlId="formHorizontalLinkedIn">
                                <Form.Label column sm={2}>
                                linkedIn
                                </Form.Label>
                                <Col sm={10}>
                                    <Form.Control 
                                    type="text" 
                                    placeholder="LinkedIn Link"
                                    name="linkedIn" 
                                    value={values.linkedIn}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    disabled={active}/>
                                </Col>
                            </Form.Group>
                            <center>
                                <Button variant="danger" size="lg" style={{margin:'3% 2%'}} onClick={()=>updateActive(!active)}>Edit</Button>
                                <Button variant="success" size="lg" style={{margin:'3% 2%'}} type="submit">Save</Button>
                            </center>
                        </Form>
                    )
                }
            </Formik>:null
            }
        </div>
    )
}
export default SocialLinks;