import React, { useState }  from 'react';
import {Col,Button,Form, Container, Row} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../WebsiteManagement/websiteManagement.module.css';

import DraftEditor from '../draftEditor';
import Image from '../../utilities/imageTestApi/image';
import { UpdateCoupon } from '../../entities/action/action';
import { Markup } from 'interweave';

const CouponView = (props)=>{

    const [edit,updateEdit] = useState(false);
    const [file,updateFile] = useState({});
    const [message,updateMessage] = useState(props.data.termsAndCondition);
    const categoryList = [
        {type:'Flight'},
        {type:'Hotels and Resorts'},
        {type:'Cabs'},
        {type:'Bus'},
        {type:'Homestays'},
        {type:'Hostels'},
        {type:'Camps'},
        {type:'Holidays'},
        {type:'Insurance'},
        {type:'Tour Guide'},
        {type:'Charter Plane'},
        {type:'Villas & Apartments'},
        {type:'Guest House'},
        {type:'Cottage'},
        {type:'Tree-House'},
        {type:'Motel'},
        {type:'Palace'},
        {type:'Houseboat'},
        {type:'All'}
    ]
    return(
        <Container fluid className={classes.Box}>
            {
                !edit?
                    <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'2%'}}>
                        <Button variant="success" onClick={()=>updateEdit(true)}>Edit</Button>
                    </div>: 
                    <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'2%'}}>
                        <Button variant="danger" onClick={()=>updateEdit(false)}>Cancel</Button>
                    </div>
            }
            <Formik
            initialValues={{
                promocode:props.data.promocode,
                vFrom:props.data.startValidity.split("T")[0],
                vTo:props.data.endValidity.split("T")[0], 
                vTime:props.data.endValidityTime,
                promoName:props.data.promoname,
                limit:props.data.usageLimit,
                category:props.data.appliesTo,
                priceMax:props.data.maxPrice,
                priceMin:props.data.minPrice,
                promotype:props.data.discountType,
                promoAmount:props.data.amount,
                buyProductName:props.data.buyProductName,
                buyQty:props.data.buyProductQty,
                getProductName:props.data.getProductName,
                getQty:props.data.getProductQty,
                benifitCashback:props.data.cashBack,
                benifitCredit:props.data.storeCredits,
                playMoney:props.data.playMoney,
                loginRequirment:props.data.loginRequired
            }}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    setSubmitting(true);
                    let response = props.data.imageUrl;
                    if(file.type!=undefined){
                        response = await Image(file);
                    }
                    const newData = {
                        "cashBack": values.benifitCashback,
                        "appliesTo": values.category,
                        "termsAndCondition": message,
                        "endValidity": values.vTo,
                        "endValidityTime": values.vTime,
                        "imageUrl": response,
                        "loginRequired": values.loginRequirment,
                        "maxPrice": values.priceMax,
                        "minPrice": values.priceMin,
                        "playMoney": values.playMoney,
                        "amount": values.promoAmount,
                        "promocode": values.promocode,
                        "promoname": values.promoName,
                        "discountType": values.promotype,
                        "startValidity": values.vFrom,
                        "usageLimit": values.limit,
                        "id":props.data.id,
                        "active":true,
                      }
                      console.log(newData);
                      const res = await UpdateCoupon(newData);
                      if(res.successful){
                          resetForm();
                          window.location.reload();
                      }else{
                          alert("Something Went Wrong!!");
                      }
                      setSubmitting(false);
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicPromo" as={Row}>
                                            <Form.Label column sm="2">PROMOCODE:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter PROMOCODE" 
                                                name="promocode"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.promocode}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <hr/>
                                        <Form.Group controlId="formBasicPromo" as={Row}>
                                            <Form.Label column sm="2">PROMONAME:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter PROMONAME" 
                                                name="promoName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.promoName}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicAddress" as={Row}>
                                            <Form.Label column sm="2">VALIDITY:</Form.Label>
                                            <Col sm={4}> 
                                                <Form.Control 
                                                type="date" 
                                                placeholder="From" 
                                                name="vFrom"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.vFrom}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                            <Col sm={4}> 
                                                <Form.Control 
                                                type="date" 
                                                placeholder="To" 
                                                name="vTo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.vTo}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                            <Col sm={2}>
                                                <Form.Control 
                                                type="time" 
                                                placeholder="Time" 
                                                name="vTime"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.vTime}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group>    
                                        <Form.Group controlId="formBasicLimit" as={Row}>
                                            <Form.Label column sm="2">USAGE LIMIT:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="number" 
                                                placeholder="USAGE LIMIT" 
                                                name="limit"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.limit}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicAppliesTo" as={Row}>
                                            <Form.Label column sm="2">APPLIES TO:</Form.Label>
                                            <Col sm="5"> 
                                                <Form.Control 
                                                as="select" 
                                                placeholder="CATEGORY" 
                                                name="category"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.category}
                                                disabled={!edit}
                                                required>
                                                    {
                                                        categoryList.map((categories,key)=>
                                                        <option key={key} value={categories.type}>{categories.type}</option>    
                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicAddress" as={Row}>
                                            <Form.Label column sm="2">PRICE RANGE:</Form.Label>
                                            <Col sm={5}> 
                                                <Form.Control 
                                                type="number"
                                                min={0} 
                                                placeholder="Minimum" 
                                                name="priceMin"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.priceMin}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                            <Col sm={5}> 
                                                <Form.Control 
                                                type="number" 
                                                min={0}
                                                placeholder="Maximum" 
                                                name="priceMax"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.priceMax}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Row>
                                            <Col sm={2}>
                                                Images:
                                            </Col>
                                            <Col sm={10} style={{marginBottom:'1%'}}> 
                                            <Form.Control 
                                            type="file" 
                                            name="file"
                                            onChange={(e)=>updateFile(e.target.files[0])}
                                            disabled={!edit}
                                            />
                                            </Col>
                                        </Row>
                                        <fieldset>
                                            <Form.Group as={Row}
                                            value={values.loginRequirment}
                                            onChange={handleChange}
                                            disabled={!edit}
                                        >
                                            <Form.Label as="legend" column sm={2}>
                                                Login Type:
                                            </Form.Label>
                                            <Col sm={5}>
                                                <Form.Check
                                                type="radio"
                                                label="NOT REQUIRED"
                                                name="loginRequired"
                                                value={false}
                                                />
                                            </Col> 
                                            <Col sm={5}>   
                                                <Form.Check
                                                type="radio"
                                                label="REQUIRED"
                                                name="loginRequired"
                                                value={true}
                                                />
                                            </Col>
                                            </Form.Group>
                                        </fieldset>
                                        <Form.Group style={{marginTop:"10px"}}  controlId="formBasicAppliesTo" as={Row}>
                                            <Form.Label column sm="2">Discount Type:</Form.Label>
                                            <Col sm="5"> 
                                                <Form.Control 
                                                as="select" 
                                                placeholder="Type" 
                                                name="promotype"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.promotype}
                                                disabled={!edit}
                                                required>
                                                    <option value="fixed">Fixed</option>
                                                    <option value="percentage">Percentage</option>
                                                </Form.Control>
                                            </Col>
                                            <Col sm={5}> 
                                                <Form.Control 
                                                type="number" 
                                                placeholder="Amount" 
                                                name="promoAmount"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.promoAmount}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Row style={{margin:'2% 0'}}>
                                            <Col sm={2} style={{margin:'2% 0'}} className={classes.Col}>
                                                Terms & Conditions:
                                            </Col>
                                            <Col sm={10} style={{border:'1px solid black'}}>
                                                <DraftEditor change={(text)=>updateMessage(text)} text={message}/>
                                            </Col>
                                        </Row>
                                        <Row style={{margin:'2% 0'}}>
                                            <Col sm={2} style={{margin:'2% 0'}} className={classes.Col}>
                                                Current Terms & Conditions:
                                            </Col>
                                            <Col sm={10} style={{border:'1px solid black'}}>
                                                {message!==undefined?message.length>0?<Markup content={message} />:null:null}
                                            </Col>
                                        </Row>
                                        {
                                            edit?
                                                <center>
                                                    <Button type="submit" variant="success">Update Coupon</Button>
                                                </center>:null
                                        }
                                    </Form>
                            )}
                        </Formik>
        </Container>
    )
}
export default CouponView;