import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import classes from "./login.module.css";
import { useDispatch } from "react-redux";
import { loginSuccess } from "../entities/account/action/action";

const Login = (props) => {
  console.log(props);
  const [error, updateError] = useState("");
  const dispatch = useDispatch();
  return (
    <>
      <Formik
        initialValues={{ password: "", phone: ""}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          if (
            values.password.length === parseInt(props.len) &&
            values.phone.toString().length === 10
          ) {
            updateError("");
            setSubmitting(true);
            let url = "https://api.zupptravel.com/api/v1/auth/initiateAuthenticationForAdmin";
            const response = await fetch(url, {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(values),
            });
            const Json = await response.json();
            console.log(Json);
            if (Json?.success) {
              dispatch(loginSuccess({ data: values }));
              props.click(values);
              resetForm();
            } else {
              updateError("User doesn't exists ");
              resetForm();
            }
          } else {
            updateError("Invalid Input");
          }
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicPassword">
              <Form.Label style={{ color: "white" }}>{props.label}</Form.Label>
              <Form.Control
                name="password"
                type="text"
                placeholder={props.placeholder}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                className={touched.password && errors.password ? "has-error" : null}
                required
              />
              <Form.Text className="text-muted">
                {errors.password && touched.password}
              </Form.Text>
            </Form.Group>
            <Form.Group controlId="formBasicPhone">
              <Form.Label style={{ color: "white" }}>Phone No.</Form.Label>
              <Form.Control
                name="phone"
                type="number"
                placeholder="Phone No."
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.phone}
                className={touched.phone && errors.phone ? "has-error" : null}
              />
              <Form.Text className="text-muted">
                {errors.phone && touched.phone && errors.phone}
              </Form.Text>
            </Form.Group>
            <center>
              <Button
                size="lg"
                type="submit"
                style={{
                  margin: "3% 0",
                  background: "pink",
                  border: "none",
                  color: "#585858",
                  fontWeight: "bold",
                }}
                disabled={isSubmitting}
              >
                Login
              </Button>
              <Button
                size="lg"
                variant="secondary"
                style={{ margin: "3% 2%" }}
                onClick={() => window.location.reload()}
              >
                Cancel
              </Button>
            </center>
          </Form>
        )}
      </Formik>
      {error ? <p className={classes.Error}>{error}</p> : null}
    </>
  );
};
export default Login;
