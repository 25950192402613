import React, { useState, Fragment } from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import BlockAndUnblock from "./blockAndUnblock";
import SetSeatLayout from "./CabSeatLayout";
import CabRoute from "./cabRoute";
import SetSeatPriceAndAvailability from "./SetSeatPriceAndAvailability";

const SetPrice = (props) => {
  const [active, updateActive] = useState("3");

  return (
    <Fragment>
      <Container fluid className={classes.Box}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "2%",
          }}
        >
          <Button
            variant="light"
            style={{ marginLeft: "15px" }}
            onClick={() => props.click()}
          >
            {`<--`} Back
          </Button>
        </div>
        <Row
          style={{
            margin: "0 0 2% 0",
            textAlign: "center",
            cursor: "pointer",
            fontSize: "0.8rem",
          }}
        >
          {/* <Col
            className={classes.Col}
            style={{
              backgroundColor: `${active === "1" ? "steelblue" : "#585858"}`,
              color: "white",
              padding: "1%",
            }}
            onClick={() => updateActive("1")}
          >
            Add Cab Route
          </Col> */}
          {/* <Col
            className={classes.Col}
            style={{
              backgroundColor: `${active === "2" ? "steelblue" : "#585858"}`,
              color: "white",
              padding: "1%",
            }}
            onClick={() => updateActive("2")}
          >
            Set Seat Layout
          </Col> */}
          <Col
            className={classes.Col}
            style={{
              backgroundColor: `${active === "3" ? "steelblue" : "#585858"}`,
              color: "white",
              padding: "1%",
            }}
            onClick={() => updateActive("3")}
          >
            Set Seat Price & Availability
          </Col>
          <Col
            className={classes.Col}
            style={{
              backgroundColor: `${active === "4" ? "steelblue" : "#585858"}`,
              color: "white",
              padding: "1%",
            }}
            onClick={() => updateActive("4")}
          >
            Block & Unblock Cabs
          </Col>
        </Row>
        {active === "1" ? (
          <CabRoute />
        ) : // active==="2"?<SetSeatLayout/>:
        active === "3" ? (
          <SetSeatPriceAndAvailability />
        ) : active === "4" ? (
          <BlockAndUnblock />
        ) : null}
      </Container>
    </Fragment>
  );
};

export default SetPrice;
