import React, { useEffect, useState } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import classes from './WebsiteManagement/websiteManagement.module.css';
import Page from './pagination/pagination';

const CustomerSearchManagement = (props)=>{
    const [dataSet,updateDataSet] = useState([1]);
    const [count,updateCount] = useState(0)
    const postPerPage = 20;
    const [currentPage,updateCurrentPage] = useState(0);
    const indexOfLast = currentPage*postPerPage;
    const indexOfFirst = indexOfLast - postPerPage;
    const currentPosts = dataSet.slice(indexOfFirst,indexOfLast);
    const handleChangePage = (event,value)=>{
        updateCurrentPage(value)
    }
    useEffect(()=>{
        updateCount(Math.ceil(dataSet.length/postPerPage))
    },[dataSet])
 
    return(
        <>
         <div className={classes.Head}>
             <br/>
       <center> <h1 className={classes.Heading}>Customer Search Management</h1></center>
       <br/>
       
        <Container fluid >
            <Table striped hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Product Search</th>
                        
                        <th>Date & Time</th>
                        <th>IP Address</th>
                        <th>Email</th>
                        <th>Phone Number</th>
                        <th>Product Details</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentPosts.length>0?currentPosts.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.vendorId}</td>
                                <td>{data.activityId}</td>
                                <td>{data.activityType}</td>
                                <td>{data.activityCategory}</td>
                                <td>{data.activityCategory}</td>
                                <td className={classes.CenterRow}>
                                    {/* <Button style={{marginRight:'5px'}} variant="danger">Delete</Button> */}
                                    <Button style={{marginRight:'5px'}}>View</Button>
                                    
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
            <div style={{display:'flex',justifyContent:'center',margin:'15px 0'}}>
                <Page count={count} change={handleChangePage} page={currentPage}/> 
            </div>
        </Container>
        </div>
        </>
    )
}
export default CustomerSearchManagement;