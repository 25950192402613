import React, { useState, Fragment } from 'react';
import { Col, Button, Form, Container, Row, Table } from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';


export default function CustomerOrder(props) {
    const [active, updateActive] = useState("1");

    return (
        <Fragment>
            <div class="container">
                <div className={classes.Box}>
                    <Table responsive>
                        <thead>
                            <tr>
                                <th>S No</th>
                                <th>Order ID</th>
                                <th>Order Date</th>
                                <th>Order Amount</th>
                                <th>Order Status</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            
                        </tbody>
                    </Table>
                </div>
            </div>

        </Fragment>
    );
}