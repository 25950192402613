import React,{useEffect, useState} from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Field, FieldArray, Formik } from 'formik';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DraftEditor from '../../draftEditor';
import {DropzoneArea} from 'material-ui-dropzone';
import {CityState, GetVendor} from "../../../entities/action/action"
import Image from '../../../utilities/imageTestApi/image';

const ActivityView = (props)=>{
    console.log(props.data)
    const [message,updateMessage] = useState('sadgg');
    const [tAndC,updateTAndC] = useState(props.data.termsAndCondition);
    const [req,updateReq] = useState(props.data.requirements);
    const [files,updateFiles] = useState([]);
    const [currentPoints,updateCurrentPoints] = useState(1);
    const [arrPoints,updateArrPoints] = useState([1]);
    const [currentPoints1,updateCurrentPoints1] = useState(1);
    const [arrPoints1,updateArrPoints1] = useState([1]);
    const [currentPoints2,updateCurrentPoints2] = useState(1);
    const [arrPoints2,updateArrPoints2] = useState([1]);
    const [state,setState]=useState(props.data.state);
    const [city,setCity]=useState(props.data.city);
    const myfunc= async(e)=>{
        
        if(e.target.value.length===6){
            const response = await CityState(e.target.value)
            console.log(Object.values(response.data))
            if(response.status=="success"){
                
                setState(Object.values(response.data)[0].state_name)
                setCity(Object.values(response.data)[0].city_name)
            }
            
        }else{
            setState('')
            setCity('')
        }
    }
    const handleImages = (inpFile)=>{
        updateFiles(inpFile)
    }
    const handleAdd = (type)=>{
        if(type===1){
            const newCurrentPoint = currentPoints+1;
            updateCurrentPoints(newCurrentPoint);
            updateArrPoints([...arrPoints,newCurrentPoint]);
        }else if(type===2){
            const newCurrentPoint1 = currentPoints1+1;
            updateCurrentPoints1(newCurrentPoint1);
            updateArrPoints1([...arrPoints1,newCurrentPoint1]);
        }else{
            const newCurrentPoint2 = currentPoints2+1;
            updateCurrentPoints2(newCurrentPoint2);
            updateArrPoints2([...arrPoints2,newCurrentPoint2]);
        }
    }
    const handleRemove = (type)=>{
        if(type===1&&currentPoints>1){
            const newCurrentPoint = currentPoints-1;
            updateCurrentPoints(newCurrentPoint);
            let arr = [...arrPoints];
            arr.pop();
            updateArrPoints(arr);
        }else if(type===2&&currentPoints1>1){
            const newCurrentPoint1 = currentPoints1-1;
            updateCurrentPoints1(newCurrentPoint1);
            let arr = [...arrPoints1];
            arr.pop();
            updateArrPoints1(arr);
        }else if(type===3&&currentPoints2>1){
            const newCurrentPoint2 = currentPoints2-1;
            updateCurrentPoints2(newCurrentPoint2);
            let arr = [...arrPoints2];
            arr.pop();
            updateArrPoints2(arr);
        }
    }
    const [vendorList, updateVendorList] = useState([]);
    useEffect(()=>{
        (async()=>{
            const res = await GetVendor();
            if(res!=undefined && res.length > 0){
                const arr = res.filter((item,key)=>item.companyInformation.vendortype==="Activities Provider")
                updateVendorList(arr);
            }
        })()
    },[])
    const [edit,updateEdit] = useState(false);
    return(
        <div className={classes.Box}> 
        <Formik
            initialValues={{
                activityType: props.data.activityType,
                cancellation: props.data.cancellation,
                "activityCategory": props.data.category,
              
                "emailId": props.data,
                "language": props.data.language,
                "pickUpPoint": props.data.pickUpPoint,
                "activityTitle":  props.data.title,
                "vendorActivityId": 0,
                "ticketRequired": props.data.worldLocation,
                "pincode": props.data.zipCode

            }}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    console.log(values);
                    const img = await Image(files[0]); 
                    const newdata = {
                        "activityType": values.activityType,
                        "cancellation": values.cancellation,
                        "category": values.activityCategory,
                        "city": city,
                        "description": message,
                        "emailId": "string",
                        "images": img,
                        "language": values.language,
                        "pickUpPoint": values.pickUpPoint,
                        "requirements": req,
                        "sessionKey": "string",
                        "state": state,
                        "termsAndCondition": tAndC,
                        "title": values.activityTitle,
                        "vendorActivityId": 0,
                        "worldLocation": values.ticketRequired,
                        "zipCode": values.pincode
                    }
                    const res = await fetch('https://api.zupptravel.com/updateVendorActivities',{
                        method: "post",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body:JSON.stringify(newdata)
                    })
                    const json = await res.json();
                    if(json.successful){
                        alert("Updated Activity !");
                        props.click();
                    }else{
                        alert("Error! Please try again later");
                    }
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,resetForm})=>(
                                <Form onSubmit={handleSubmit}>
                                    <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'2%'}}>
                                        {
                                            !edit?
                                                <Button variant="success" onClick={()=>updateEdit(true)}>Edit</Button>:
                                                <Button variant="danger" onClick={()=>{updateEdit(false); resetForm()}}>Cancel</Button>
                                        }
                                    </div>
                                <Form.Group controlId="formBasicVendor" as={Row}>
                                    <Form.Label column sm="3">Vendor ID:</Form.Label>
                                    <Col sm="9"> 
                                        <Form.Control 
                                        readOnly={!edit}
                                            as="select" 
                                            placeholder="Vendor ID" 
                                            name="vendorId"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.vendorId}
                                            required>
                                                <option value="">--select--</option>
                                                {
                                                    vendorList.map((vendor,key)=>{
                                                        return(
                                                            <>
                                                              <option value={vendor.supplierid}>{vendor.supplierid}</option>  
                                                            </>
                                                        )
                                                    })
                                                }
                                        </Form.Control>
                                    </Col>
                                </Form.Group> 
                                <Form.Group controlId="formBasic" as={Row}>
                                    <Form.Label column sm="3">Activity Type:</Form.Label>
                                    <Col sm="9"> 
                                        <Form.Control
                                         readOnly={!edit}
                                        type="text" 
                                        placeholder="Enter Activity Type" 
                                        name="activityType"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.activityType}
                                        required/>
                                    </Col>
                                </Form.Group>
                                <Form.Group controlId="formBasicCityState" as={Row}>
                                <Form.Label column sm="3">World Location:</Form.Label>
                                    <Col sm="4"> 
                                        <Form.Control
                                         readOnly={!edit}
                                        as="select" 
                                        name="ticketRequired"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ticketRequired}
                                        required>
                                            <option value="">--select--</option>
                                            <option value="India">India</option>
                                            <option value="Foreign">Foreign</option>
                                        </Form.Control>
                                    </Col>
                                    <Form.Label column sm="2">Zip Code:</Form.Label>
                                    <Col sm="3"> 
                                        <Form.Control
                                         readOnly={!edit}
                                        type="text" 
                                        placeholder="Enter Zip Code" 
                                        name="pincode"
                                        onChange={handleChange}
                                        onBlur={myfunc}
                                        value={values.pincode}
                                        >
                                           
                                        </Form.Control>
                                    </Col>
                                    </Form.Group>
                                <Form.Group controlId="formBasic" as={Row}>
                                <Form.Label column sm="3">State:</Form.Label>
                                    <Col sm="4"> 
                                        <Form.Control
                                        type="text" 
                                        placeholder="Enter State" 
                                        name="state"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={state}
                                        readOnly
                                        required>
                                            {/* <option value=""></option> */}
                                        </Form.Control>
                                    </Col>
                                    <Form.Label column sm="2">City:</Form.Label>
                                    <Col sm="3"> 
                                        <Form.Control
                                        type="text" 
                                        placeholder="Enter City" 
                                        name="city"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={city}
                                        readOnly
                                        required>
                                            {/* <option value=""></option> */}
                                        </Form.Control>
                                    </Col>
                                </Form.Group>
                                <Form.Group controlId="formBasicCategory" as={Row}>
                                    <Form.Label column sm="3">Activity Category:</Form.Label>
                                    <Col sm="9"> 
                                    <Form.Control 
                                     readOnly={!edit}
                                        type="text" 
                                        placeholder="Activity Category" 
                                        name="activityCategory"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.activityCategory}
                                        required/>
                                    </Col>
                                </Form.Group>   
                                <Form.Group controlId="formBasicTitle" as={Row}>
                                    <Form.Label column sm="3">Activity Title:</Form.Label>
                                    <Col sm="9"> 
                                    <Form.Control 
                                        type="text" 
                                        readOnly={!edit}
                                        placeholder="Activity Title" 
                                        name="activityTitle"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.activityTitle}
                                        required/>
                                    </Col>
                                </Form.Group> 
                                <Row style={{margin:'2% 0'}}>
                                    <Col sm={3} style={{margin:'2% 0'}} className={classes.Col}>
                                        Description:
                                    </Col>
                                    <Col sm={9} style={{border:'1px solid black'}}>
                                        <DraftEditor change={(text)=>updateMessage(text)} text={message} />
                                    </Col>
                                </Row>
                                <Row style={{margin:'2% 0'}}>
                                    <Col sm={3} style={{margin:'2% 0'}} className={classes.Col}>
                                        Terms & Conditions:
                                    </Col>
                                    <Col sm={9} style={{border:'1px solid black'}}>
                                        <DraftEditor change={(text)=>updateTAndC(text)} text={tAndC} />
                                    </Col>
                                </Row>
                                <Row style={{margin:'2% 0'}}>
                                    <Col sm={3} style={{margin:'2% 0'}} className={classes.Col}>
                                        Requirments:
                                    </Col>
                                    <Col sm={9} style={{border:'1px solid black'}}>
                                        <DraftEditor change={(text)=>updateReq(req)} text={req} />
                                    </Col>
                                </Row>
                                <FieldArray
                                            name="cancellation"
                                            render={arrayHelpers => (
                                                <div>
                                                    {values.cancellation.map((friend, index) => (
                                                        <div style={{padding:"0px"}} key={index} >
                                                        
                                                            <Row>
                                                            <Form.Label column sm="3">
                                                             Cancellation
                                                            </Form.Label>
                                                            <Col sm="3"> 
                                                            {/* <Form.Control
                                                                type="text" 
                                                                placeholder={`Enter Cancellation`} 
                                                                name={`cancellation.${index}.cancellation`}
                                                                
                                                                /> */}
                                                            <Field style={{border:"1px solid #6C757D",paddingLeft:"10px"}} placeholder={`Enter Cancellation`} name={`cancellation.${index}.cancellation`}/>
                                                            </Col>
                                                            <Form.Label column sm="3">
                                                             Charges
                                                            </Form.Label>
                                                            <Col sm="3">
                                                            {/* <Form.Control
                                                                type="number" 
                                                                placeholder={`Enter Charges`} 
                                                                name={`cancellation.${index}.charges`}
                                                               
                                                                /> */}
                                                                <Field placeholder={`Enter Charges`}  style={{border:"1px solid #6C757D",paddingLeft:"10px"}} name={`cancellation.${index}.charges`}/>
                                                            </Col>
                                                            </Row>
                                                             
                                                            
                                                            <Button 
                                                                variant="danger" 
                                                                style={{marginRight:'15px',marginTop:"10px",marginBottom:"10px"}} 
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                disabled={index==0}
                                                            >Remove</Button>
                                                          {/* </>    */}
                                                         </div>
                                                        
                                                    ))}
                                                   <Button 
                                                        variant="success" 
                                                        style={{marginRight:'15px',marginTop:"10px",marginBottom:"10px"}} 
                                                        onClick={() => arrayHelpers.push({ cancellation: '', charges: '' })}
                                                    >Add</Button>
                                                    {/* <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.push({ cancellation: '', charges: '' })}
                                                    >
                                                        Add
                                                    </button> */}
                                                </div>
                                            )}
                                        />
                                        
                                
                                    <Form.Group style={{clear:"both"}} controlId={`formBasicLanguage`} as={Row}>
                                        <Form.Label column sm="3">
                                            Language
                                        </Form.Label>
                                        <Col sm="9"> 
                                            <Form.Control
                                            type="text" 
                                            readOnly={!edit}
                                            placeholder={`Enter Language`} 
                                            name={`language`}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.language}
                                            required/>
                                        </Col>
                                    </Form.Group> 
                                    <FieldArray
                                            name="pickUpPoint"
                                            render={arrayHelpers => (
                                                <div>
                                                    {values.pickUpPoint.map((friend, index) => (
                                                        <>
                                                            <Row>
                                                            <Form.Label column sm="3">
                                                             Pick-Up Point
                                                            </Form.Label>
                                                            <Col sm="9"> 
                                                            {/* <Form.Control
                                                                type="text" 
                                                                placeholder={`Enter PickUp Point`} 
                                                                name={`pickUpPoint.${index}.pickUpPoint`}
                                                               
                                                                /> */}
                                                            <Field style={{border:"1px solid #6C757D",paddingLeft:"10px"}} placeholder={`Enter PickUp Point`}  name={`pickUpPoint.${index}.pickUpPoint`}/>
                                                            </Col>
                                                            
                                                            </Row>
                                                             
                                                            {/* <Field name={`cancellation.${index}.charges`}/> */}
                                                            <Button 
                                                                variant="danger" 
                                                                style={{marginRight:'15px',marginTop:"10px",marginBottom:"10px"}} 
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                disabled={index==0}
                                                            >Remove</Button>
                                                            
                                                        </>
                                                    ))}
                                                    <Button 
                                                        variant="success" 
                                                        style={{marginRight:'15px',marginTop:"10px",marginBottom:"10px"}} 
                                                        onClick={() => arrayHelpers.push({ pickUpPoint: ''})}
                                                    >Add</Button>
                                                    {/* <button
                                                        type="button"
                                                        onClick={() => arrayHelpers.push({ cancellation: '', charges: '' })}
                                                    >
                                                        Add
                                                    </button> */}
                                                </div>
                                            )}
                                        />
                                        
                              
                                <Form.Group style={{clear:"both"}} controlId="formBasicChild" as={Row}>
                                    <Form.Label column sm="3">Images:</Form.Label>
                                    <Col sm="9"> 
                                        <DropzoneArea onChange={ handleImages }filesLimit={5}/>
                                    </Col>
                                </Form.Group>
                                <center>
                                    <Button  disabled={!edit} type="submit" variant="success">Save Activity</Button>
                                </center>
                            </Form>
                            )}
                        </Formik>
                    </div>            
    )
}
export default ActivityView;