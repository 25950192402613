// import React,{useState} from 'react';
// import {Col,Button,Form, Row} from 'react-bootstrap';
// import { Formik } from 'formik';
// import MultipleSelect from '../../multiSelect';
// import { Fragment } from 'react';

// const FlightDiscount = (props)=>{
//     const [select,updateSelect] = useState([]);
//     return(
//         <Formik
//             initialValues={{amount:'',gst:''}}
//             onSubmit={
//                 async(values,{setSubmitting,resetForm})=>{
//                     console.log(values);
//                 }
//             }
//             >
//                 {
//                             ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
//                                 <Form onSubmit={handleSubmit}>
//                                         <Form.Group controlId="formBasicDiscountType" as={Row}>
//                                             <Form.Label column sm="2">Discount Type:</Form.Label>
//                                             <Col sm="10"> 
//                                                 <Form.Control 
//                                                     as="select" 
//                                                     placeholder="Discount Type" 
//                                                     name="discountType"
//                                                     onChange={handleChange}
//                                                     onBlur={handleBlur}
//                                                     value={values.discountType}
//                                                     required>
//                                                         <option value="">--select--</option>
//                                                         <option value="Airlinewise">Airlinewise</option>
//                                                         <option value="All">All</option>
//                                                 </Form.Control>
//                                             </Col>
//                                         </Form.Group> 
//                                         {
//                                             values.discountType=="Airlinewise"?
//                                             <Form.Group controlId="formBasicHolidayName" as={Row}>
//                                                 <Form.Label column sm="2">Airlines:</Form.Label>
//                                                 <Col sm="10"> 
//                                                     <MultipleSelect type="1" selectedValue={(selected)=>updateSelect(selected)}/>
//                                                 </Col>
//                                             </Form.Group>:null
//                                         }
//                                         <Form.Group controlId="formBasicAmountType" as={Row}>
//                                             <Form.Label column sm="2">Amount Type:</Form.Label>
//                                             <Col sm="10"> 
//                                                 <Form.Control 
//                                                     as="select" 
//                                                     placeholder="Amount Type" 
//                                                     name="amountType"
//                                                     onChange={handleChange}
//                                                     onBlur={handleBlur}
//                                                     value={values.amountType}
//                                                     required>
//                                                         <option value="">--select--</option>
//                                                         <option value="Fix">Fix</option>
//                                                         <option value="Basic & YQ">Basic & YQ</option>
//                                                 </Form.Control>
//                                             </Col>
//                                         </Form.Group> 
//                                         {
//                                             values.amountType=="Basic & YQ"?
//                                             <Fragment>
//                                                 <Form.Group controlId="formBasicBasic" as={Row}>
//                                                     <Form.Label column sm="2">Basic:</Form.Label>
//                                                     <Col sm="10"> 
//                                                         <Form.Control
//                                                         type="text" 
//                                                         placeholder="Enter Basic" 
//                                                         name="basic"
//                                                         onChange={handleChange}
//                                                         onBlur={handleBlur}
//                                                         value={values.basic}
//                                                         required/>
//                                                     </Col>
//                                                 </Form.Group>
//                                                 <Form.Group controlId="formBasicYQ" as={Row}>
//                                                     <Form.Label column sm="2">YQ:</Form.Label>
//                                                     <Col sm="10"> 
//                                                         <Form.Control
//                                                         type="text" 
//                                                         placeholder="Enter YQ" 
//                                                         name="YQ"
//                                                         onChange={handleChange}
//                                                         onBlur={handleBlur}
//                                                         value={values.YQ}
//                                                         required/>
//                                                     </Col>
//                                                 </Form.Group>
//                                             </Fragment>:
//                                             values.amountType=="Fix"?
//                                             <Form.Group controlId="formBasicFix" as={Row}>
//                                                 <Form.Label column sm="2">Fix Amount:</Form.Label>
//                                                 <Col sm="10"> 
//                                                     <Form.Control
//                                                     type="text" 
//                                                     placeholder="Enter Fix Amount" 
//                                                     name="fix"
//                                                     onChange={handleChange}
//                                                     onBlur={handleBlur}
//                                                     value={values.fix}
//                                                     required/>
//                                                 </Col>
//                                             </Form.Group>:null
//                                         }
//                                         <center>
//                                             <Button type="submit" variant="success">Save Flight Discount</Button>
//                                         </center>
//                                     </Form>
//                             )}
//                         </Formik>          
//     )
// }
// export default FlightDiscount;


import React,{useState,useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import MultipleSelect from '../../multiSelect';
import { Fragment } from 'react';
import { Formik, Form, Field } from 'formik';
import { Button, Form as BootstrapForm, Col, Row } from 'react-bootstrap'; // Replace with your actual UI library

const isNumber = (value) => /^\d+(\.\d+)?$/.test(value);

const FlightDiscount = () => {

    const [select,updateSelect] = useState([]);
    const data = useSelector((state) => state.adminAccount.apiResponse);
    console.log("data",data);
    const validateField = (fieldName, value) => {
    if (!value) {
      return 'This field is required';
    }

    if (!isNumber(value)) {
      return 'Please enter a valid number';
    }

    return undefined; // No validation error
  };
  const validate = (values) => {
    const errors = {};

    // Add field-specific validation here
    if (values.basic && !isNumber(values.basic)) {
      errors.basic = 'Please enter a valid number';
    }

    if (values.YQ && !isNumber(values.YQ)) {
      errors.YQ = 'Please enter a valid number';
    }

    if (values.fix && !isNumber(values.fix)) {
      errors.fix = 'Please enter a valid number';
    }

    return errors;
  };

  const [airlinesRecord, setAirlinesRecord] = useState([]);
  const [selectedAirlineOptions, setSelectedAirlineOptions] = useState([]);

  const handleAirlineFilterChange = (selected) => {
    // 'selected' will be an array of selected options
    if (selected && selected[0] && selected[0].value) {
      setSelectedAirlineOptions(selected[0].value);
      // console.log("filter ans ",selected[0].value)
    } else {
      setSelectedAirlineOptions("");
    }
  };

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(`https://api.zupptravel.com/getAirline`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const myjson = await myresponse.json();
      if (myjson.successful) {
        myjson.airlines.map((airline) =>
          setAirlinesRecord((airlinesRecord) => [
            ...airlinesRecord,
            {
              id: airline.airlineId,
              label: airline.airlineName,
              value: airline.airlineName,
            },
          ])
        );
      }
    })();
  }, []);


  return (
    <Formik
      initialValues={{ amount: '', gst: '', discountType: '', amountType: '', basic: '', YQ: '', fix: '' }}
      validate={validate}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        console.log(values);
        const newData = {
          airlines: selectedAirlineOptions,
          amountType: values.amountType,
          discountType: values.discountType,
          emailId: data.data.key,
          enterBasic: values.basic,
          fixAmount: values.fix,
          markupDiscountId: 0,
          sessionKey: data.sessionId,
          yq: values.YQ
        };
        console.log(newData);
    
        const myresponse = await fetch("https://api.zupptravel.com/addMarkupDiscount", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newData),
        });
    
        const json = await myresponse.json();
        console.log(json);
    
        if (json.successful) {
          alert("Flight Discount added successfully!");
          // Optionally, you can redirect to the main page here
          window.location.reload();
        } else {
          alert("Error! Please try again later!");
        }
    
        // Optionally, you can reset the form after submission
        resetForm();
      }}
    >
    
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <Form >
          <BootstrapForm.Group controlId="formBasicDiscountType" as={Row}>
            <BootstrapForm.Label column sm="2">
              Discount Type:
            </BootstrapForm.Label>
            <Col sm="10">
              <BootstrapForm.Control
                as="select"
                placeholder="Discount Type"
                name="discountType"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.discountType}
                required
              >
                <option value="">--select--</option>
                <option value="Airlinewise">Airlinewise</option>
                <option value="All">All</option>
              </BootstrapForm.Control>
            </Col>
          </BootstrapForm.Group>
          {values.discountType === 'Airlinewise' ? (

            
            <BootstrapForm.Group controlId="formBasicHolidayName" as={Row}>
                      <div class="form-group filter-col" style={{width:"18vw",marginLeft:"1vw"}}>
                <ReactMultiSelectCheckboxes
                  className="multiselect"
                  options={airlinesRecord}
                  placeholderButtonLabel="Airline"
                  getDropdownButtonLabel={(placeholderButtonLabel) => {
                    return "Airline";
                  }}
                  onChange={handleAirlineFilterChange}
                />
              </div>
            </BootstrapForm.Group>
          ) : null}
          <BootstrapForm.Group controlId="formBasicAmountType" as={Row}>
            <BootstrapForm.Label column sm="2">
              Amount Type:
            </BootstrapForm.Label>
            <Col sm="10">
              <BootstrapForm.Control
                as="select"
                placeholder="Amount Type"
                name="amountType"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.amountType}
                required
              >
                <option value="">--select--</option>
                <option value="Fix">Fix</option>
                <option value="Basic & YQ">Basic & YQ</option>
              </BootstrapForm.Control>
            </Col>
          </BootstrapForm.Group>
          {values.amountType === 'Basic & YQ' ? (
            <>
              <BootstrapForm.Group controlId="formBasicBasic" as={Row}>
                <BootstrapForm.Label column sm="2">
                  Basic:
                </BootstrapForm.Label>
                <Col sm="10">
                  <BootstrapForm.Control
                    type="text"
                    placeholder="Enter Basic"
                    name="basic"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.basic}
                    isInvalid={touched.basic && !!errors.basic}
                    required
                  />
                  <BootstrapForm.Control.Feedback type="invalid">{errors.basic}</BootstrapForm.Control.Feedback>
                </Col>
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="formBasicYQ" as={Row}>
                <BootstrapForm.Label column sm="2">
                  YQ:
                </BootstrapForm.Label>
                <Col sm="10">
                  <BootstrapForm.Control
                    type="text"
                    placeholder="Enter YQ"
                    name="YQ"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.YQ}
                    isInvalid={touched.YQ && !!errors.YQ}
                    required
                  />
                  <BootstrapForm.Control.Feedback type="invalid">{errors.YQ}</BootstrapForm.Control.Feedback>
                </Col>
              </BootstrapForm.Group>
            </>
          ) : values.amountType === 'Fix' ? (
            <BootstrapForm.Group controlId="formBasicFix" as={Row}>
              <BootstrapForm.Label column sm="2">
                Fix Amount:
              </BootstrapForm.Label>
              <Col sm="10">
                <BootstrapForm.Control
                  type="text"
                  placeholder="Enter Fix Amount"
                  name="fix"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fix}
                  isInvalid={touched.fix && !!errors.fix}
                  required
                />
                <BootstrapForm.Control.Feedback type="invalid">{errors.fix}</BootstrapForm.Control.Feedback>
              </Col>
            </BootstrapForm.Group>
          ) : null}
          <center>
            <Button type="submit" variant="success">
              Save Flight Discount
            </Button>
          </center>
        </Form>
      )}
    </Formik>
  );
};

export default FlightDiscount;
