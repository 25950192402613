import React, { useState, useEffect } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import { useSelector } from "react-redux";
import MealTypeList from "./addMealList";
import { GetVendor } from "../../../entities/action/action";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";

const AddMealType = (props) => {
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const [vendorId, updateVendorId] = useState("");
  const [vendorList, updateVendorList] = useState([]);
  const [accList, updateAccList] = useState([]);
  const [dataSet, updateDataSet] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await GetVendor();
      if (res != undefined && res.length > 0) {
        const arr = res.filter(
          (item, key) =>
            item.companyInformation.vendortype === "Accommodation Provider"
        );
        console.log(res);
        updateVendorList(arr);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        `https://api.zupptravel.com/getAccommodation`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await myresponse.json();
      //   console.log("jsssoooooonnn", json);

      if (json.successful) {
        updateDataSet(json.accommodations);
      }
    })();
  }, []);

  //   console.log("acccc", dataSet);
  const arr = [];
  useEffect(() => {
    dataSet.map((data, key) => {
      if (data.vendorId == vendorId) {
        arr.push(data.accId);
      }
    });

    updateAccList(arr);
  }, [vendorId]);

  const [accomodationId, updateaccomodationId] = useState("");
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
          //   setSubmitting(true);
          const newData = {
            emailId: "string",
            mealType: values.mealType,
            mealTypeId: 0,
            numberOfAdults: values.numberOfAduts,
            numberOfChildren: values.numberOfChildren,
            sessionKey: "string",
          };
          const res = await fetch("https://api.zupptravel.com/addMealType", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
          });

          console.log("rrrrrrrrrrrr", newData);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("Added Meal Type!");
            // props.click();
            window.location.reload();
          } else {
            alert("Error! Please try again later");
          }

          resetForm({ values: "" });

          //   setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          resetForm,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            {/* <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <SingleSelect
                  type="3"
                  list={vendorList}
                  change={(select) => updateVendorId(select)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Accommodation ID:
              </Form.Label>
              <Col sm="9">
                <SingleSelect
                  type="4"
                  list={accList}
                  change={(select) => updateaccomodationId(select)}
                />
              </Col>
            </Form.Group> */}
            <Form.Group controlId="formBasic" as={Row}>
              <Form.Label column sm="3">
                Meal Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Meal Type"
                  name="mealType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mealType}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicNumberOfAduts" as={Row}>
              <Form.Label column sm="3">
                Number of Adults:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="number"
                  placeholder="Enter Number of Adults"
                  name="numberOfAduts"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.numberOfAduts}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicNumberOfChildren" as={Row}>
              <Form.Label column sm="3">
                Number of Children:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="number"
                  placeholder="Enter Number of Children"
                  name="numberOfChildren"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.numberOfChildren}
                  required
                />
              </Col>
            </Form.Group>
            <center>
              <Button type="submit" variant="success">
                Save Meal
              </Button>
            </center>
          </Form>
        )}
      </Formik>
      <hr />
      <MealTypeList />
    </div>
  );
};
export default AddMealType;
