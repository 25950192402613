import React, { useState, Fragment } from 'react';
import { Col, Button, Container, Row, Table, Dropdown } from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import AddAmount from './addAmount'
import DeductAmount from "./deductAmount";
import TransactionDetails from "./transactionDetails"

export default function CustomerReward(props) {
    console.log(props.data)
    const [modalShow1, setModalShow1] = React.useState(false);
    const [modalShow2, setModalShow2] = React.useState(false);
    return (
        <Fragment>
            <Container>
                <div className={classes.Box}>
                    <Row>
                        <Col md={9} xl={9}>
                            
                            <Table striped bordered hover responsive>

                                <tr>
                                    <td><strong>EWallet Money: </strong></td>
                                    <td>{props.data.finalEWalletAmount}</td>
                                </tr>
                               
                                <tr>
                                    <td><strong>Play Money: </strong></td>
                                    <td>{props.data.finalPlayMoney}</td>
                                </tr>
                                <tr>
                                    <td><strong>Cashback: </strong></td>
                                    <td>{props.data.finalCashBackAmount}</td>
                                </tr>
                            </Table>
                        </Col>
                        <Col md={3} xl={3}>
                            <Dropdown>
                                <Dropdown.Toggle variant="danger" id="dropdown-basic">
                                    Action
                                </Dropdown.Toggle>

                                <Dropdown.Menu right>
                                    <Button variant="info" style={{width: "100%", marginTop: "0px", marginBottom: "5px"}} onClick={() => setModalShow1(true)}>
                                        Add Amount
                                    </Button>
                                    <AddAmount
                                        data={props.data}
                                        show={modalShow1}
                                        onHide={() => setModalShow1(false)}
                                    />
                                    <Button variant="info" style={{width: "100%", marginTop: "0px", marginBottom: "5px"}} onClick={() => setModalShow2(true)}>
                                        Deduct Amount
                                    </Button>
                                    <DeductAmount
                                        data={props.data}
                                        show={modalShow2}
                                        onHide={() => setModalShow2(false)}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <TransactionDetails data={props.data}/>
                </div>
            </Container>
        </Fragment>
    );
}