import React, { useState, Fragment, useEffect } from "react";
import { Col, Row, Container, Button, Form } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import BlockAndUnblock from "./blockAndUnblock";
import DisplayRoom from "./displayRoom";
import MealPrice from "./mealPrice";
import RoomCategory from "./roomCategory";
import SetRoomPrice from "./setRoomPrice";
import { Formik } from "formik";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import { GetVendor } from "../../../entities/action/action";

const SetPrice = (props) => {
  const [active, updateActive] = useState("4");
  const [vendorId, updateVendorId] = useState("");
  const [vendorList, updateVendorList] = useState([]);
  const [vendorList2, updateVendorList2] = useState([]);
  const [accList, updateAccList] = useState([]);
  const [dataSet, updateDataSet] = useState([]);
  const [id, updateId] = useState([]);
  const [id2, updateId2] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await GetVendor();
      if (res != undefined && res.length > 0) {
        const arr = res.filter(
          (item, key) =>
            item.companyInformation.vendortype === "Accommodation Provider"
        );
        console.log(res);
        updateVendorList(arr);
      }
    })();
  }, []);
  const [accomodationId, updateaccomodationId] = useState("");
  console.log(vendorList);

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        `https://api.zupptravel.com/getAccommodation`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await myresponse.json();
      console.log("jsssoooooonnn", json);

      if (json.successful) {
        updateDataSet(json.accommodations);
      }
    })();
  }, []);

  console.log("acccc", dataSet);
  const arr = [];

  useEffect(() => {
    dataSet.map((data, key) => {
      if (data.vendorId == vendorId) {
        arr.push(data.accId);
      }
    });

    updateAccList(arr);
  }, [vendorId]);
  // useEffect(() => {
  //   const arr2 = [];
  //   vendorList.map((data, key) => {
  //     if (data.supplierid == vendorId) {
  //       arr2.push(data.supplierid);
  //     }
  //   });

  //   updateVendorList2(arr2);
  // }, [vendorId]);

  // console.log(vendorList2);

  const id1 = [];
  useEffect(() => {
    dataSet.map((data, key) => {
      if (data.accId == accomodationId) {
        id1.push(data.id);
      }
    });
    updateId(id1);
  }, [accomodationId]);
  // console.log(id);

  const id3 = [];
  useEffect(() => {
    vendorList.map((data, key) => {
      if (data.supplierid == vendorId) {
        id3.push(data.companyInformation.id);
      }
    });
    updateId2(id3);
  }, [vendorId]);
  // console.log(vendorList);
  // console.log(vendorId);
  // console.log(id2);
  console.log(accomodationId);

  return (
    <Fragment>
      <Container fluid className={classes.Box}>
        <Formik
          initialValues={{}}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicVendor" as={Row}>
                <Form.Label column sm="3">
                  Vendor ID:
                </Form.Label>
                <Col sm="9">
                  <SingleSelect
                    type="3"
                    list={vendorList}
                    change={(select) => updateVendorId(select)}
                  />
                </Col>
              </Form.Group>
              <Form.Group controlId="formBasicVendor" as={Row}>
                <Form.Label column sm="3">
                  Accommodation ID:
                </Form.Label>
                <Col sm="9">
                  <SingleSelect
                    type="4"
                    list={accList}
                    change={(select) => updateaccomodationId(select)}
                  />
                </Col>
              </Form.Group>
            </Form>
          )}
        </Formik>
        {/* <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'2%'}}>
                <Button variant="light" style={{marginLeft:'15px'}} onClick={()=>props.click()}>{`<--`} Back</Button>
            </div> */}
        <Row
          style={{
            margin: "0 0 2% 0",
            textAlign: "center",
            cursor: "pointer",
            fontSize: "0.8rem",
          }}
        >
          {/* <Col className={classes.Col} 
                style={{backgroundColor:`${active==='1'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("1")}>
                    Add Room Category/Name
                </Col> */}
          {/* <Col
            className={classes.Col}
            style={{
              backgroundColor: `${active === "2" ? "steelblue" : "#585858"}`,
              color: "white",
              padding: "1%",
            }}
            onClick={() => updateActive("2")}
          >
            Set Meal Price
          </Col> */}
          {/* <Col
            className={classes.Col}
            style={{
              backgroundColor: `${active === "3" ? "steelblue" : "#585858"}`,
              color: "white",
              padding: "1%",
            }}
            onClick={() => updateActive("3")}
          >
            Set Room Price
          </Col> */}
          <Col
            className={classes.Col}
            style={{
              backgroundColor: `${active === "4" ? "steelblue" : "#585858"}`,
              color: "white",
              padding: "1%",
            }}
            onClick={() => updateActive("4")}
          >
            Set Room Availability
          </Col>
          <Col
            className={classes.Col}
            style={{
              backgroundColor: `${active === "5" ? "steelblue" : "#585858"}`,
              color: "white",
              padding: "1%",
            }}
            onClick={() => updateActive("5")}
          >
            Block & Unblock Room
          </Col>
        </Row>
        {
          // active==="1"?<RoomCategory/>:

          // active === "2" ? (
          //   <MealPrice id={id} accid={accomodationId} />
          // ) :

          // active === "3" ? (
          //   <SetRoomPrice id={id} accid={accomodationId} />
          // ) :
          active === "4" ? (
            <DisplayRoom id={id} accid={accomodationId} />
          ) : active === "5" ? (
            <BlockAndUnblock vid={id2} id={id} accid={accomodationId} />
          ) : null
        }
      </Container>
    </Fragment>
  );
};

export default SetPrice;
