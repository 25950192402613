import React, { useEffect, useState } from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import { keys } from '@material-ui/core/styles/createBreakpoints';
import { Collapse } from '@material-ui/core';

const AddItinerary = (props)=>{
    const [holidayName,updateHolidayName] = useState('')
    const [sightseeingDuration,updateSightseeingDuration] = useState([]);
    const [activitiesDuration,updateActivitiesDuration] = useState([]);
    const [currentPoints,updateCurrentPoints] = useState(1);
    const [arrPoints,updateArrPoints] = useState([1]);
    const [currentPoints1,updateCurrentPoints1] = useState(1);
    const [arrPoints1,updateArrPoints1] = useState([1]);
    const [arrPoints2,updateArrPoints2] = useState([1]);
    const [day,updateDay] = useState(1);

    useEffect(()=>{
        if(day>1){
            let temp = [];
            for(let i=0;i<day;i++){
                temp.push(i);
            }
            updateArrPoints2(temp);
        }
    },[day])
    const handleAdd = (type)=>{
        if(type===1){
            const newCurrentPoint = currentPoints+1;
            updateCurrentPoints(newCurrentPoint);
            updateArrPoints([...arrPoints,newCurrentPoint]);
        }else{
            const newCurrentPoint1 = currentPoints1+1;
            updateCurrentPoints1(newCurrentPoint1);
            updateArrPoints1([...arrPoints1,newCurrentPoint1]);
        }
    }
    const handleRemove = (type)=>{
        if(type===1&&currentPoints>1){
            const newCurrentPoint = currentPoints-1;
            updateCurrentPoints(newCurrentPoint);
            let arr = [...arrPoints];
            arr.pop();
            updateArrPoints(arr);
        }else if(type===2&&currentPoints1>1){
            const newCurrentPoint1 = currentPoints1-1;
            updateCurrentPoints1(newCurrentPoint1);
            let arr = [...arrPoints1];
            arr.pop();
            updateArrPoints1(arr);
        }
    }
    return(
        <div className={classes.Box}> 
        <Formik
            initialValues={{day:1,pickupFromHome:''}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    console.log(values);
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicAddress" as={Row}>
                                            <Form.Label column sm="3">Holiday ID:</Form.Label>
                                            <Col sm="3"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Holiday ID" 
                                                    name="holidayId"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.holidayId}
                                                    required>
                                                        <option value="">--select--</option>
                                                </Form.Control>
                                            </Col>
                                            <Form.Label column sm="3">Holiday Name:</Form.Label>
                                            <Col sm="3"> 
                                                <Form.Control 
                                                    readOnly
                                                    type="text" 
                                                    placeholder="Holiday Name" 
                                                    name="holidayName"
                                                    value={holidayName}
                                                    required/>
                                            </Col>
                                        </Form.Group>                                      
                                        <Form.Group controlId="formBasicDay" as={Row}>
                                            <Form.Label column sm="3">Enter Day:</Form.Label>
                                            <Col sm="3"> 
                                                <Form.Control
                                                type="text"
                                                min={1}  
                                                isInvalid={day<1}
                                                placeholder="Enter Day" 
                                                name="day"
                                                onChange={(e)=>updateDay(Number(e.target.value))}
                                                onBlur={handleBlur}
                                                value={day}
                                                required/>
                                            </Col>
                                            <Form.Label column sm="3">Night:</Form.Label>
                                            <Col sm="3"> 
                                                <Form.Control
                                                readOnly
                                                name="night"
                                                value={(day-1)<0?0:day-1}
                                                />
                                            </Col>
                                        </Form.Group>
                                        {
                                            arrPoints2.map((_,key)=>
                                            <div style={{backgroundColor:'whitesmoke',padding:'10px',margin:'5px 0'}}> 
                                            <center><h3>Day {key+1}</h3></center>
                                            <hr/>
                                            <Form.Group controlId="formBasicDayBriefing" as={Row}>
                                                <Form.Label column sm="3">Day Briefing:</Form.Label>
                                                <Col sm="9"> 
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Day Briefing"
                                                        name="dayBriefing"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.dayBriefing}
                                                        required/>
                                                </Col>
                                            </Form.Group>
                                            {
                                               key==0? 
                                               <>
                                                <Form.Group controlId="formBasicPickupFromHome" as={Row}>
                                                    <Form.Label column sm="3">Pickup Home:</Form.Label>
                                                    <Col sm="3"> 
                                                        <Form.Control 
                                                            as="select" 
                                                            name="pickupFromHome"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.pickupFromHome}
                                                            required>
                                                                <option value="">--select--</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                        </Form.Control>
                                                    </Col>
                                                    {
                                                        values.pickupFromHome==="Yes"?
                                                        <>
                                                            <Form.Label column sm="3">Dropoff Point:</Form.Label>
                                                            <Col sm="3"> 
                                                                <Form.Control 
                                                                    type="text"
                                                                    placeholder="Dropoff Point"
                                                                    name="dropoff"
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    value={values.dropoff}
                                                                    required/>
                                                            </Col>
                                                        </>:null
                                                    }
                                                </Form.Group> 
                                               </>:key===arrPoints2.length-1?
                                               <>
                                               <Form.Group controlId="formBasicPickup" as={Row}>
                                                   <Form.Label column sm="3">Pickup:</Form.Label>
                                                   <Col sm="3"> 
                                                       <Form.Control 
                                                           as="select" 
                                                           name="pickup"
                                                           onChange={handleChange}
                                                           onBlur={handleBlur}
                                                           value={values.pickup}
                                                           required>
                                                               <option value="">--select--</option>
                                                               <option value="Yes">Yes</option>
                                                               <option value="No">No</option>
                                                       </Form.Control>
                                                   </Col>
                                                   {
                                                       values.pickup==="Yes"?
                                                       <>
                                                           <Col sm="3"> 
                                                               <Form.Control 
                                                                   type="text"
                                                                   placeholder="Pickup Depot"
                                                                   name="pickupDepot"
                                                                   onChange={handleChange}
                                                                   onBlur={handleBlur}
                                                                   value={values.pickupDepot}
                                                                   required/>
                                                           </Col>
                                                           <Col sm="3"> 
                                                               <Form.Control 
                                                                   type="text"
                                                                   readOnly
                                                                   name="dropoff"
                                                                   defaultValue="Home"
                                                                />
                                                           </Col>
                                                       </>:null
                                                   }
                                               </Form.Group> 
                                              </>:null
                                            }
                                            <Form.Group controlId="formBasicTravel" as={Row}>
                                                <Form.Label column sm="3">Inter Cities Travel By:</Form.Label>
                                                <Col sm="9"> 
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Inter Cities Travel By"
                                                        name="travelBy"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.travelBy}
                                                        required/>
                                                                {/* <option value="">--select--</option>
                                                                <option value="Cab">Cab</option>
                                                                <option value="Bus">Bus</option>
                                                                <option value="Self Car">Self Car</option>
                                                                <option value="Self Drive Rental Car">Self Drive Rental Car</option>
                                                                <option value="Flight">Flight</option>
                                                        </Form.Control> */}
                                                </Col>
                                            </Form.Group>
                                            {
                                               key!==arrPoints2.length-1? 
                                                <Form.Group controlId="formBasicAccommodationType" as={Row}>
                                                    <Form.Label column sm="3">Accommodation Type:</Form.Label>
                                                    <Col sm="9"> 
                                                        <Form.Control 
                                                            as="select" 
                                                            name="accommodationType"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.accommodationType}
                                                            required>
                                                                <option value="">--select--</option>
                                                        </Form.Control>
                                                    </Col>
                                                </Form.Group>:null
                                            } 
                                            <Form.Group controlId="formBasicSightseeingVehicle" as={Row}>
                                                <Form.Label column sm="3">Sightseeing Vehicle:</Form.Label>
                                                <Col sm="9"> 
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Sightseeing Vehicle"
                                                        name="sightseeingVehicle"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.sightseeingVehicle}
                                                        required/>
                                                </Col>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicAccommodationType" as={Row}>
                                                <Form.Label column sm="3">Lunch:</Form.Label>
                                                <Col> 
                                                    <Form.Control 
                                                        as="select" 
                                                        name="lunch"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.lunch}
                                                        required>
                                                            <option value="">--select--</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                    </Form.Control>
                                                </Col> 
                                                <Col> 
                                                    <Form.Control 
                                                        type="number"
                                                        placeholder="Lunch Cost" 
                                                        disabled={values.lunch=="Yes"?false:true}
                                                        min={0}
                                                        step={0.01}
                                                        name="lunchCost"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.lunchCost}
                                                        required/>
                                                </Col> 
                                            </Form.Group>
                                            <Form.Group controlId="formBasicDinnerType" as={Row}>
                                                {
                                                key!=arrPoints2.length-1?
                                                    <>
                                                        <Form.Label column sm="3">Dinner( if Outside ):</Form.Label>
                                                        <Col> 
                                                            <Form.Control 
                                                                as="select" 
                                                                name="dinner"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.dinner}
                                                                required>
                                                                    <option value="">--select--</option>
                                                                    <option value="Yes">Yes</option>
                                                                    <option value="No">No</option>
                                                            </Form.Control>
                                                        </Col>
                                                        <Col> 
                                                            <Form.Control 
                                                                type="number"
                                                                placeholder="Dinner Cost" 
                                                                disabled={values.dinner=="Yes"?false:true}
                                                                min={0}
                                                                step={0.01}
                                                                name="dinnerCost"
                                                                onChange={handleChange}
                                                                onBlur={handleBlur}
                                                                value={values.dinnerCost}
                                                                required/>
                                                        </Col> 
                                                    </>:null 
                                                } 
                                            </Form.Group> 
                                            {
                                               arrPoints.length>0?arrPoints.map((d,key)=>
                                                <Form.Group controlId={`formselectSightseeing${d}`} key={key} as={Row}>
                                                    <Form.Label column sm="3">Sightseeing {d}:</Form.Label>
                                                    <Col sm="3"> 
                                                        <Form.Control 
                                                            as="select" 
                                                            name={`selectSightseeing${d}`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values[`selectSightseeing${d}`]}
                                                            required>
                                                                <option value="">--select--</option>
                                                        </Form.Control>
                                                    </Col>
                                                    <Form.Label column sm="3">Duration {d}:</Form.Label>
                                                    <Col sm="3"> 
                                                        <Form.Control 
                                                            readOnly
                                                            type="text"
                                                            name={`sightseeingDuration${keys}`}
                                                            value={sightseeingDuration[keys]}
                                                            required/>
                                                    </Col>
                                                </Form.Group>
                                               ):null 
                                            }
                                            <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'15px'}}>
                                                <Button 
                                                    variant="danger" 
                                                    style={{marginRight:'15px'}} 
                                                    disabled={currentPoints<=1?true:false}
                                                    onClick={()=>handleRemove(1)}
                                                >Remove</Button>
                                                <Button variant="success" onClick={()=>handleAdd(1)}>Add</Button>
                                            </div> 
                                            {
                                               arrPoints1.length>0?arrPoints1.map((d,key)=>
                                                <Form.Group controlId={`formactivities${d}`} key={key} as={Row}>
                                                    <Form.Label column sm="3">Activities {d}:</Form.Label>
                                                    <Col sm="3"> 
                                                        <Form.Control 
                                                            as="select" 
                                                            name={`activities${d}`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values[`activities${d}`]}
                                                            required>
                                                                <option value="">--select--</option>
                                                        </Form.Control>
                                                    </Col>
                                                    <Form.Label column sm="3">Duration {d}:</Form.Label>
                                                    <Col sm="3"> 
                                                        <Form.Control 
                                                            readOnly
                                                            type="text"
                                                            name={`activitiesDuration${keys}`}
                                                            value={activitiesDuration[keys]}
                                                            required/>
                                                    </Col>
                                                </Form.Group>
                                               ):null 
                                            }
                                            <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'15px'}}>
                                                <Button 
                                                    variant="danger" 
                                                    style={{marginRight:'15px'}} 
                                                    disabled={currentPoints1<=1?true:false}
                                                    onClick={()=>handleRemove(2)}
                                                >Remove</Button>
                                                <Button variant="success" onClick={()=>handleAdd(2)}>Add</Button>
                                            </div> 
                                            <hr/>
                                            </div>
                                            )
                                        }
                                        <center>
                                            <Button type="submit" variant="success">Save Itinerary</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                    </div>            
    )
}
export default AddItinerary;