import React, {useState, Fragment } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import classesX from '../Dashboard/dashboard.module.css';
import AdminNavbar from '../navbar/adminNavbar';
import ActiveBlog from './view/bloglist';
import CreateBlog from './view/addBlogCard';
import AddBlogCategory from './view/AddBlogCategory';

const BlogCardManagement = (props)=>{
    const [active,updateActive] = useState('1');
 
    const items = [
        {id:"1",type:"Add Blog"},
        {id:"2",type:"Add Blog Category"},
        {id:"3",type:"Blog List"}
    ]
    return(
        <Fragment>
           <AdminNavbar/>
            <h1 className={classesX.Heading}>Blog Management</h1>  
        <Container fluid>
            <Row> 
                <Col lg={12} md={12} className={classes.Response2}>
                    <div style={{height:'100%',width:'90%'}}> 
                        <ul className={classes.Ul}style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 ,height:"10vh",}}>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                    style={{
                                        backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                                        textAlign: 'center',
                                        margin: '0 1px', // Adjust the margin as needed
                                        padding:" 2% 0",
                                        color: 'white',
                                        cursor: 'pointer',
                                        width:"33%",
                                        height:"100%"
                                    }}>
                                    {item.type}
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={12} md={12} className={classes.Response}>
                    <div style={{height:'100%',margin:"2vh 9vw"}}> 
                        {
                            active==="1"?<CreateBlog/>:
                            active==="2"?<AddBlogCategory/>:
                            active==="3"?<ActiveBlog/>:null
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default BlogCardManagement;