import React, { useState, useEffect } from 'react';
import {Col,Table,Container, Button} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import { GetAdminBookings } from '../../../entities/action/action';



const FlightData = (props)=>{
    const [dataSet,updateDataSet] = useState([]);
    
    useEffect(()=>{
        (async()=>{
            const response = await GetAdminBookings();
            if(response.success&&response?.bookings!==null){
                updateDataSet(response?.bookings);
            }
        })()
    },[])
  
    return(
        <Container fluid style={{marginTop:"5vh"}}>
  <Col lg={12} md={12} className={classes.Response2}>
             <Table striped bordered hover>
<thead>
    <tr>
        <th>S.No</th>
        <th>Booking Date and Time</th>
        <th>Booking ID</th>
        <th>Phone Number</th>
        <th>Booking Status</th>
        <th>Action</th>
    </tr>
</thead>
<tbody>
{
    dataSet?.length>0?dataSet?.map((data,key)=>
    <tr key={key}>
        <td>{key+1}</td>
        <td>{data?.orderDate}</td>
        <td>{data?.bookingId}</td>
        <td>{data?.phone}</td>
        <td>{data?.status}</td>
        <td>
            {/* <DropdownButton id="dropdown-variants-Success" title="Actions"> */}
                <div className={classes.Center}>
                    <Button variant="danger" onClick={() => props.click(data)}>View</Button>
                </div>
            {/* </DropdownButton> */}
        </td>
    </tr>       
    ):null
}
</tbody>
</Table>
</Col>
</Container>

)
}
export default FlightData;
