import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import {
  DeleteDepartment,
  DeleteDesignation,
} from "../../../entities/action/action";
import { useSelector } from "react-redux";

const AddAmenitiesAndCabFacilitiesList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  useEffect(() => {
    (async () => {
      if (props.type == "Amenities") {
        const newdata = {
          emailId: data.data.key,
          password: "string",
          phoneno: "string",
          sessionKey: data.sessionId,
        };
        console.log(newdata);
        const res = await fetch("https://api.zupptravel.com/getCabAmenities", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newdata),
        });
        const json = await res.json();
        console.log(json);
        if (json.successful) {
          updateDataSet(json.cabAmenitiess);
        }
      }
    })();
  }, [props]);
  console.log(dataSet);

  const HandleDelete = async (id) => {
    // console.log(id);
    if (props.type == "Amenities") {
      const r = await fetch(
        `https://api.zupptravel.com/deleteCabAmenities/${id}`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const j = await r.json();
      if (j.successful) {
        alert("Successfully deleted amenity");
        // window.location.reload();
        const newdata = {
          emailId: data.data.key,
          password: "string",
          phoneno: "string",
          sessionKey: data.sessionId,
        };
        const res = await fetch("https://api.zupptravel.com/getCabAmenities", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newdata),
        });
        const json = await res.json();
        if (json.successful) {
          updateDataSet(json.cabAmenitiess);
        }
      }
    }
  };

  console.log(props);

  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>{props.type}</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {dataSet.length > 0
            ? dataSet.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data[props.type.toLowerCase()]}</td>
                  <td className={classes.CenterRow}>
                    <Button
                      style={{ margin: "auto 1%" }}
                      variant="danger"
                      onClick={() => HandleDelete(data.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
    </Container>
  );
};
export default AddAmenitiesAndCabFacilitiesList;
