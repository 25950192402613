import React, { useState, Fragment } from "react";
import { Col, Row, Container, Button } from "react-bootstrap";
import classes from "./websiteManagement.module.css";
import { DropzoneArea } from "material-ui-dropzone";
import ContactInfo from "./Views/contactInfo";
import SocialLinks from "./Views/socialLink";
import ImageSlider from "./Views/sliderImagesCustomer";
import MetaDetails from "./Views/metaDetails";
import classesX from "../Dashboard/dashboard.module.css";
// import ImageSliderSupplier from './Views/sliderImageSupplier';
import AdminNavbar from "../navbar/adminNavbar";
import Image from "../../utilities/imageTestApi/image";
import { useSelector } from "react-redux";
import { UploadLogo, UploadFavicon } from "../../entities/action/action";
import IpBlockList from "./Views/ipBlockList";

const WebsiteManagement = (props) => {
  const [active, updateActive] = useState("1");
  const [logo, updateLogo] = useState([]);
  const [favicon, updateFavicon] = useState([]);
  const userData = useSelector((state) => state.adminAccount.apiResponse);
  // console.log(userData)
  const handleChangedLogo = (inpFile) => {
    updateLogo(inpFile);
  };
  const handleChangedFavicon = (inpFile) => {
    updateFavicon(inpFile);
  };
  const logoHandler = async () => {
    const img = await Image(logo[0]);
    let newdata = {
      imageUrl: img,
    };
    const response = await UploadLogo(newdata);
    if (response) {
      alert("Successfully Uploaded");
      updateLogo([]);
      window.location.reload();
    }
  };
  const faviconHandler = async () => {
    const img1 = await Image(favicon[0]);
    const newdata = {
      imageUrl: img1,
    };
    const response = await UploadFavicon(newdata);
    if (response) {
      alert("Successfully Uploaded");
      updateLogo([]);
      window.location.reload();
    }
  };
  const items = [
    { id: "1", type: "Contact Info" },
    { id: "2", type: "Logo" },
    { id: "3", type: "Favicon" },
    { id: "4", type: "Social Link" },
    { id: "5", type: "Homepage Slider Images-Customer" },
    { id: "9", type: "Meta Details" },
    { id: "10", type: "IP Block List" },
  ];
  return (
    <Fragment>
      <AdminNavbar />
      <h1 className={classesX.Heading}>Website Management</h1>
      <Container fluid>
        <Row>
          <Col lg={12} md={12} className={classes.Response2}>
            <div style={{ height: "93%", width: '95%' }}>
              <ul className={classes.Ul}style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 ,height:"10vh",}}>
                {items.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => updateActive(item.id)}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                      textAlign: 'center',
                      margin: '0 1px', // Adjust the margin as needed
                      padding:" 2% 0",
                      color: 'white',
                      cursor: 'pointer',
                      width:"20%",
                      height:"100%"
                  }}
                  >
                    {item.type}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col lg={12} md={12} className={classes.Response}style={{marginBottom:"5vh"}}>
            {active === "1" ? (
              <ContactInfo  style={{display:"flex",marginLeft:"-1vw"}}/>
            ) : active === "2" ? (
              <div className={classes.DropZoneBox}>
                <div className={classes.DropZone}>
                  <DropzoneArea onChange={handleChangedLogo} filesLimit={1} />
                </div>
                <center>
                  <Button
                    size="lg"
                    variant="success"
                    style={{ margin: "4%" }}
                    onClick={logoHandler}
                  >
                    Upload New Logo
                  </Button>
                </center>
              </div>
            ) : active === "3" ? (
              <div className={classes.DropZoneBox}>
                <div className={classes.DropZone}>
                  <DropzoneArea
                    onChange={handleChangedFavicon}
                    filesLimit={1}
                  />
                </div>
                <center>
                  <Button
                    size="lg"
                    variant="success"
                    style={{ margin: "4%" }}
                    onClick={faviconHandler}
                  >
                    Upload New Favicon
                  </Button>
                </center>
              </div>
            ) : active === "4" ? (
              <SocialLinks />
            ) : active === "5" ? (
              <ImageSlider />
            ) : active === "9" ? (
              <MetaDetails />
            ) : active === "10" ? (
              <IpBlockList />
            ) : null}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default WebsiteManagement;
