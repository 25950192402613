import React, { useEffect, useState } from 'react';
import {Row,Col, Container} from 'react-bootstrap';
import classes from './Dashboard/dashboard.module.css';
import Dashboard from './Dashboard/dashboard';
import WebsiteManagement from './WebsiteManagement/websiteManagement';
import InformationManagement from './informationManagement';
import QueryManagement from './queryManagement';
import CouponManagement from './couponManagement/couponManagement';
import API from './apiManagement';
import StaffManagement from './staffManagement/staffManagement';
// import PaymentManagement from './paymentManagement';
import PageManagement from './pageManagement/pageManagement';
import CustomerManagement from './CustomerManagement/customerManagement';
import { useDispatch,useSelector } from 'react-redux';
import Logo from '../assets/logo.jpg';
import {GetLogo} from '../entities/action/action';
import { checkAuthState, SetDepartment, SetDesignation } from '../entities/account/action/action';
import GiftCardManagement from './giftCard/giftCardManagement';
import AirportManagement from './airport/airportManagement';
import CurrencyManagement from './currency/currencyManagement';
import BookingManagement from './bookingList/bookingManagement';
import BlogManagement from './blogManagement/blogManagement';
import PartnerManagement from './supplierManagement/supplierManagement';
import PackageManagement from './packageManagement/packageManagement';
import OnlineMarkupManagement from './onlineMarkupManagement/onlineMarkupManagement';
import TravelDeposit from './travelDeposit/travelDeposit';
import OfflineServiceManagement from './offlineServiceManagement/offlineServiceManagement';
import SightSeeingManagement from './sightseeingManagement/sightseeingManagement';
import CustomerReviewManagement from './customerReviewManagement';
import CustomerSearchManagement from './customerSearchManagement';
import LocationManagement from './locationManagement/locationManagement';

const AdminPanel = (props)=>{
    const [active,updateActive] = useState('1');
    const isLoggedIn = useSelector(state=>state.adminAccount.isLoggedIn);
    const [logo,updateLogo] = useState(Logo);
    useEffect(()=>{
        if(!isLoggedIn){
            props.history.push('/adminLogin'); 
        }
       
    },[isLoggedIn])
    useEffect(()=>{
        (async()=>{
            const res = await GetLogo();
            if(res.successful&&res.websiteManagementLogo!=null&&res.websiteManagementLogo.logoAddress.length>0){
                updateLogo(res.websiteManagementLogo.logoAddress);
            }
        })()
    },[])
    const list = [
        {category:"Dashboard",id:"1"},
        // {category:"API Management",id:"2"},
        {category:"Money Management",id:"9"},
        // {category:"Airport Management",id:"3"},
        // {category:"Currency Management",id:"4"},
        {category:"Markup Management",id:"5"},
        {category:"Location Management",id:"27"},
        {category:"Offline Services Management (Extranet)",id:"6"},
        {category:"Sightseeing Managment",id:"26"},
        {category:"Holiday Management",id:"7"},
        {category:"Travel Deposit",id:"14"},
        {category:"Customer Management",id:"13"},
        // {category:"Staff Management",id:"15"},
        {category:"Query Management",id:"16"},
        {category:"Information Management",id:"17"},
        // {category:"Payment Management",id:"18"},
        {category:"Website Management",id:"19"},
        {category:"Page Management",id:"20"},
        {category:"Gift Card Management",id:"8"},
        {category:"Blog Management",id:"22"},
        {category:"Booking List",id:"21"},
        {category:"Vendor Management",id:"25"},
        {category:"Loyalty Management",id:"10"},
        {category:"Membership Management",id:"11"},
        {category:"Subscription Management",id:"12"},
        {category:"Customer Review Management",id:"24"},
        {category:"Customer Search Management",id:"23"}
    ]
    return(
        <Container fluid style={{padding:'0 auto',height:'100vh',overflow:'hidden'}}>
            <Row>
                <Col xl={2} md={3} sm={4} xs={5} style={{padding:0}}>
                    <ul className={classes.Ul}>
                        <center style={{backgroundColor:'#F7F7F7'}}><img src={logo} style={{width:'150px'}} alt="White Wular" /></center>
                        {
                            list.map(item=><li 
                                key={item.id} 
                                className={classes.Li}
                                style={{backgroundColor:`${item.id===active?'steelblue':'#585858'}`}}
                                onClick={()=>updateActive(item.id)}
                                ><div style={{display:'flex',justifyContent:'space-between'}}>
                                    <span>{item.category}</span>
                                    <span style={{display:`${item.id===active?'none':'flex'}`}}><i className="fas fa-chevron-right"></i></span></div></li>)
                        }
                    </ul>
                </Col>
                <Col xl={10} md={9} sm={8} xs={7} style={{height:'100vh',overflowY:'auto',padding:0}}>
                    {
                        active==="1"?<Dashboard />:
                        // active==="3"?<AirportManagement/>:
                        // active==="4"?<CurrencyManagement/>:
                        active==="5"?<OnlineMarkupManagement/>:
                        active==="6"?<OfflineServiceManagement/>:
                        active==="7"?<PackageManagement/>:
                        active==="8"?<GiftCardManagement/>:
                        active==="9"?<CouponManagement/>:
                        active==="13"?<CustomerManagement/>:
                        active==="14"?<TravelDeposit/>:
                        active==="19"?<WebsiteManagement/>:
                        // active==="2"?<API />:
                        // active==="18"?<PaymentManagement/>:
                        active==="19"?<WebsiteManagement/>:
                        // active==="15"?<StaffManagement/>:
                        active==="16"?<QueryManagement/>:
                        active==="17"?<InformationManagement/>: 
                        active==="20"?<PageManagement/>:
                        active==="21"?<BookingManagement/>:
                        active==="22"?<BlogManagement/>:
                        active==="25"?<PartnerManagement/>:
                        active==="26"?<SightSeeingManagement/>:
                        active==="24"?<CustomerReviewManagement/>:
                        active==="23"?<CustomerSearchManagement/>:
                        active==="27"?<LocationManagement/>:
                        null
                    }
                </Col>
            </Row>
        </Container>
    )
}
export default AdminPanel;
