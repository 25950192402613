import React, { useEffect, useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Field, FieldArray, Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import MultipleSelect from "../../multiSelect";
import { DropzoneArea } from "material-ui-dropzone";
import { GetVendor } from "../../../entities/action/action";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";

const AddVehicleType = (props) => {
  const [message, updateMessage] = useState("");
  const [select, updateSelect] = useState([]);
  const [select2, updateSelect2] = useState([]);
  const [state, setState] = useState([]);
  const [mycity, setCity] = useState([]);
  const [block, setBlock] = useState([]);
  const [state1, setState1] = useState([]);
  const [mycity1, setCity1] = useState([]);
  const [block1, setBlock1] = useState([]);
  const [location, updateLocations] = useState([]);
  const [files, updateFiles] = useState([]);
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([1]);
  const [currentPoints1, updateCurrentPoints1] = useState(1);
  const [arrPoints1, updateArrPoints1] = useState([1]);
  const [registrationArr, updateRegistrationArr] = useState([]);
  const [noOfVehicle, updateNoOfVehicle] = useState("");
  const [vendorId, updateVendorId] = useState("");
  useEffect(() => {
    if (noOfVehicle >= 1) {
      let arr = [];
      for (let i = 0; i < noOfVehicle; i++) {
        arr.push(i);
      }
      updateRegistrationArr(arr);
    }
  }, [noOfVehicle]);
  const handleImages = (inpFile) => {
    updateFiles(inpFile);
  };

  const [facility, updatefacility] = useState();
  useEffect(() => {
    (async () => {
      const newdata = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch(
        "https://api.zupptravel.com/getVehicleFacilities",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newdata),
        }
      );
      const json = await res.json();
      console.log(json);
      if (json.successful) {
        updatefacility(json.vehicleFacilities);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const newData = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch(
        "https://api.zupptravel.com/getLocationManagement",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newData),
        }
      );
      const json = await res.json();
      if (json.successful) {
        updateLocations(json.locationManagements);
      }
    })();
  }, []);
  console.log(location);

  const [features, updatefeatures] = useState();
  useEffect(() => {
    (async () => {
      const newdata = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getVehicleFeatures", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      console.log(json);
      if (json.successful) {
        updatefeatures(json.vehicleFeatures);
      }
    })();
  }, []);

  const [country, setCountry] = useState([]);
  const [country1, setCountry1] = useState([]);
  const [pincode, updatePincode] = useState("");
  const [pincode1, updatePincode1] = useState("");
  const handlePincode = (e) => {
    if (e !== "") {
      updatePincode(e);

      const x = location.filter((a, i) => a.pincode === e);
      setCity([...mycity, x[0].state]);
      setState([...state, x[0].state]);
      setCountry([...country, x[0].country]);
      setBlock([...block, x[0].blockTehsilTaluka]);
    }
    // updatePincode("");
  };

  console.log("fffffffffff", country);
  const handlePincode2 = (e) => {
    if (e !== "") {
      updatePincode1(e);

      const x = location.filter((a, i) => a.pincode === e);
      setCity1([...mycity1, x[0].state]);
      setState1([...state1, x[0].state]);
      setCountry1([...country1, x[0].country]);
      setBlock1([...block1, x[0].blockTehsilTaluka]);
    }
    // updatePincode("");
  };
  console.log(features);
  console.log(facility);

  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    } else {
      const newCurrentPoint1 = currentPoints1 + 1;
      updateCurrentPoints1(newCurrentPoint1);
      updateArrPoints1([...arrPoints1, newCurrentPoint1]);
      // setCountry([...country, ""]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    } else if (type === 2 && currentPoints1 > 1) {
      const newCurrentPoint1 = currentPoints1 - 1;
      updateCurrentPoints1(newCurrentPoint1);
      let arr = [...arrPoints1];
      arr.pop();
      updateArrPoints1(arr);
    }
  };
  const [vendorList, updateVendorList] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await GetVendor();
      if (res != undefined && res.length > 0) {
        const arr = res.filter(
          (item, key) =>
            item.companyInformation.vendortype === "Vehical Operator"
        );
        updateVendorList(arr);
        console.log(arr);
        console.log(res);
      }
    })();
  }, []);
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          cancellation: [
            {
              cancellation: "",
              charges: "",
            },
          ],
          picupDropOffPoints: [
            {
              dropOffPoint: "",
              pickUpPoint: "",
            },
          ],
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
          const img = await Image(files[0]);
          const newdata = {
            active: true,
            cancellation: values.cancellation,
            city: "string",
            country: "string",
            doorstepDelivery: values.doorstepDelivery,
            doorstepPickup: values.doorstepPickup,
            emailId: "string",
            images: img,
            liveTracking: values.liveTracking,
            noOfVehicle: noOfVehicle,
            partialCancellation: values.partialCancellation,
            passengersSeat: values.passengersSeat,
            picupDropOffPoints: values.picupDropOffPoints,
            rentalVehicleVendorId: 0,
            sessionKey: "string",
            state: "string",
            termsAndConditions: message,
            transmissionType: values.transmissionType,
            vehicleAmenities: select,
            vehicleBrand: values.vehicleBrand,
            vehicleFeatures: "string",
            vehicleFuel: values.vehicleFuel,
            vehicleName: values.vehicleName,
            vehicleType: values.vehicleType,
            vendorId: values.vendorId,
            village: "string",
          };
          const res = await fetch(
            "https://api.zupptravel.com/addRentalVehicleVendor",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newdata),
            }
          );
          const json = await res.json();
          console.log(json);
          if (json.successful) {
            alert("Added Rental Vehicle!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <SingleSelect
                  type="3"
                  list={vendorList}
                  change={(select) => updateVendorId(select)}
                />
                {/* <Form.Control
                  as="select"
                  placeholder="Vendor ID"
                  name="vendorId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vendorId}
                  required
                >
                  <option value="">--select--</option>
                  {vendorList.map((vendor, key) => {
                    return (
                      <>
                        <option value={vendor.supplierid}>
                          {vendor.supplierid}
                        </option>
                      </>
                    );
                  })}
                </Form.Control> */}
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVehicleName" as={Row}>
              <Form.Label column sm="3">
                Vehicle Type:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  as="select"
                  placeholder="Enter Vehicle Type"
                  name="vehicleName"
                  value={values.vehicleType}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Two wheeler">Two Wheeler</option>
                  <option value="Automatic">Four Wheeler</option>
                </Form.Control>
              </Col>

              <Form.Label column sm="2">
                Vehicle Brand:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Vehicle Brand"
                  name="vehicleBrand"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vehicleBrand}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVehicleName" as={Row}>
              <Form.Label column sm="3">
                Vehicle Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Vehicle Name"
                  name="vehicleName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vehicleName}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTransmissionType" as={Row}>
              <Form.Label column sm="3">
                Transmission Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  placeholder="Transmission Type"
                  name="transmissionType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.transmissionType}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Manual">Manual</option>
                  <option value="Automatic">Automatic</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPassengersSeat" as={Row}>
              <Form.Label column sm="3">
                Passengers Seat:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="number"
                  min={1}
                  placeholder="Enter Passengers Seat"
                  name="passengersSeat"
                  value={values.passengersSeat}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicNoOfVehicle" as={Row}>
              <Form.Label column sm="3">
                No. of vehicle:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="number"
                  placeholder="No. of vehicle"
                  min={1}
                  name="noOfVehicle"
                  onChange={(e) => updateNoOfVehicle(e.target.value)}
                  onBlur={handleBlur}
                  value={noOfVehicle}
                  required
                />
              </Col>
            </Form.Group>
            {registrationArr.map((_, i) => (
              <Form.Group controlId={`formBasicInfo${i}`} as={Row} key={i}>
                <Form.Label column sm="3">
                  Vehicle Registration Number {i + 1}:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    placeholder={`Vehicle Registration Number ${i + 1}`}
                    name={`vehicleRegistrationNumber${i + 1}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[`vehicleRegistrationNumber${i + 1}`]}
                    required
                  />
                </Col>
              </Form.Group>
            ))}
            <Form.Group controlId="formBasicAbout" as={Row}>
              <Form.Label column sm="3">
                Vehicle Brand:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Vehicle Brand"
                  name="vehicleBrand"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vehicleBrand}
                  required
                />
              </Col>
              <Form.Label column sm="2">
                Vehicle Fuel:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  as="select"
                  name="vehicleFuel"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vehicleFuel}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Gas">Gas</option>
                  <option value="Petrol">Petrol</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Electric">Electric</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicLiveTracking" as={Row}>
              <Form.Label column sm="3">
                Partial Cancellation:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="select"
                  placeholder="Partial Cancellation"
                  name="partialCancellation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.partialCancellation}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                Live Tracking:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  as="select"
                  placeholder="Live Tracking"
                  name="liveTracking"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.liveTracking}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicDelivery" as={Row}>
              <Form.Label column sm="3">
                Doorstep Delivery:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="select"
                  name="doorstepDelivery"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.doorstepDelivery}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                Doorstep Pickup:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  as="select"
                  placeholder="Doorstep Pickup"
                  name="doorstepPickup"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.doorstepPickup}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVehicleAmenities" as={Row}>
              <Form.Label column sm="3">
                Vehicle Facilities:
              </Form.Label>
              <Col sm="9">
                <MultipleSelect
                  type="11"
                  facility={facility}
                  selectedValue={(selected) => updateSelect(selected)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVehicleFeatures" as={Row}>
              <Form.Label column sm="3">
                Vehicle features:
              </Form.Label>
              <Col sm="9">
                <MultipleSelect
                  type="13"
                  features={features}
                  selectedValue={(selected) => updateSelect2(selected)}
                />
              </Col>
            </Form.Group>
            {arrPoints1.length > 0
              ? arrPoints1.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Pickup Point
                    </Form.Label>
                    <Col sm="9">
                      <Row>
                        <Col md={6} xs={12}>
                          <Form.Group controlId="formBasicPin" as={Row}>
                            <Form.Label column sm="3">
                              Pin Code:
                            </Form.Label>
                            <Col sm="9">
                              <SingleSelect
                                type="5"
                                list={location}
                                change={(select) => handlePincode(select)}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                          <Form.Group controlId="formBasicCountry" as={Row}>
                            <Form.Label column sm="3">
                              Country:
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                type="text"
                                readOnly
                                placeholder="Enter Country"
                                name={`country${d}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={country[d - 1]}
                              />
                            </Col>
                          </Form.Group>
                        </Col>

                        <Col md={6} xs={12}>
                          <Form.Group controlId="formBasicCity" as={Row}>
                            <Form.Label column sm="3">
                              City:
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                type="text"
                                readOnly
                                placeholder="Enter City"
                                name={`city${d}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={mycity[d - 1]}
                                required
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                          <Form.Group controlId="formBasicState" as={Row}>
                            <Form.Label column sm="3">
                              State:
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                readOnly
                                type="text"
                                placeholder="Enter State"
                                name={`state${d}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={state[d - 1]}
                                required
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                          <Form.Group controlId="formBasicVillage" as={Row}>
                            <Form.Label column sm="3">
                              Village:
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                type="text"
                                placeholder="Enter Village"
                                name={`village${d}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values[`village${d}`]}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                          <Form.Group controlId="formBasicBlock" as={Row}>
                            <Form.Label column sm="3">
                              Tehsil/Block :
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                type="text"
                                placeholder="Enter Tehsil/Block"
                                name={`block${d}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={block[d - 1]}
                                readOnly
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    {/* <Col sm="3">
                      <Form.Control
                        type="text"
                        placeholder={`Enter Cancellation`}
                        name={`cancellation.${key}.cancellation`}
                      />
                    </Col> */}

                    <Form.Label column sm="3">
                      Drop-Off Point
                    </Form.Label>
                    {/* <Col sm="3">
                      <Form.Control
                        type="number"
                        placeholder={`Enter Charges`}
                        name={`cancellation.${key}.charges`}
                      />
                    </Col> */}

                    <Col sm="9">
                      <Row>
                        <Col md={6} xs={12}>
                          <Form.Group controlId="formBasicPin" as={Row}>
                            <Form.Label column sm="3">
                              Pin Code:
                            </Form.Label>
                            <Col sm="9">
                              <SingleSelect
                                type="5"
                                list={location}
                                change={(select) => handlePincode2(select)}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                          <Form.Group controlId="formBasicCountry" as={Row}>
                            <Form.Label column sm="3">
                              Country:
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                type="text"
                                readOnly
                                placeholder="Enter Country"
                                name={`countrys${d}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={country1[d - 1]}
                              />
                            </Col>
                          </Form.Group>
                        </Col>

                        <Col md={6} xs={12}>
                          <Form.Group controlId="formBasicCity" as={Row}>
                            <Form.Label column sm="3">
                              City:
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                type="text"
                                readOnly
                                placeholder="Enter City"
                                name={`citys${d}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={mycity1[d - 1]}
                                required
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                          <Form.Group controlId="formBasicState" as={Row}>
                            <Form.Label column sm="3">
                              State:
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                readOnly
                                type="text"
                                placeholder="Enter State"
                                name={`states${d}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={state1[d - 1]}
                                required
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                          <Form.Group controlId="formBasicVillage" as={Row}>
                            <Form.Label column sm="3">
                              Village:
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                type="text"
                                placeholder="Enter Village"
                                name={`village1${d}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values[`village1${d}`]}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col md={6} xs={12}>
                          <Form.Group controlId="formBasicBlock" as={Row}>
                            <Form.Label column sm="3">
                              Tehsil/Block :
                            </Form.Label>
                            <Col sm="9">
                              <Form.Control
                                type="text"
                                placeholder="Enter Tehsil/Block"
                                name={`blocks${d}`}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={block1[d - 1]}
                                readOnly
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints1 <= 1 ? true : false}
                onClick={() => handleRemove(2)}
              >
                Remove
              </Button>

              <Button variant="success" onClick={() => handleAdd(2)}>
                Add
              </Button>
            </div>

            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Cancellation :
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Cancellation `}
                        name={`Cancellation${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`Cancellation${d}`]}
                        required
                      />
                    </Col>
                    <Form.Label column sm="3">
                      Charge:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Charges `}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`cancellationCharges${d}`}
                        value={values[`cancellationCharges${d}`]}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>

              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div>

            <Form.Group controlId="formBasicChild" as={Row}>
              <Form.Label column sm="3">
                Images:
              </Form.Label>
              <Col sm="9">
                <DropzoneArea onChange={handleImages} filesLimit={2} />
              </Col>
            </Form.Group>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                />
              </Col>
            </Row>
            <center>
              <Button type="submit" variant="success">
                Save Vehicle
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddVehicleType;

{
  /* <Row>
                        <Form.Label column sm="3">
                          Pickup Point
                        </Form.Label>
                        <Col sm="3">
                          <Form.Control
                            type="text"
                            placeholder={`Enter Cancellation`}
                            name={`cancellation.${index}.cancellation`}
                          />
                        </Col>
                        <Form.Label column sm="3">
                          Drop-Off Point
                        </Form.Label>
                        <Col sm="3">
                          <Form.Control
                            type="number"
                            placeholder={`Enter Charges`}
                            name={`cancellation.${index}.charges`}
                          />
                        </Col>
                      </Row>
 */
}
