import React, { useState, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";

import AddAmenities from "./addAmenities";
import SetPrice from "./setPrice";
import AddCabGeneralView from "./addCabGeneralView";
import AddCabType from "./addCabType";
import CabList from "./cabList";
import AddDriver from "./AddDriver";
import CabRoute from "./cabRoute";
import DriverList from "./DriverList";
import AddDriverGeneralView from "./AddDriverGeneralView";
import RouteList from "./RouteList";
import AddRouteGeneralView from "./AddRouteGeneralView";

const CabVendor = (props) => {
  const [active, updateActive] = useState("1");
  const [data, setdata] = useState({});
  const [data1, setdata1] = useState({});
  const [data2, setdata2] = useState({});

  const items = [
    { id: "1", type: "Add Cab" },
    { id: "2", type: "Cab List" },
    { id: "5", type: "Cab Management" },
    { id: "3", type: "Add Cab Amenities" },
    { id: "6", type: "Add Driver" },
    { id: "8", type: "Driver List" },
    { id: "7", type: "Add Route" },
    { id: "10", type: "Route List" },
  ];

  return (
    <Fragment>
      <Container fluid style={{ paddingLeft: "10px" }}>
        <Row>
          <Col lg={2} md={12} className={classes.Response2}>
            <div style={{ height: "100%" }}>
              <ul className={classes.Ul}>
                {items.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => updateActive(item.id)}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${
                        active === item.id ? "steelblue" : "#585858"
                      }`,
                      textAlign: "center",
                    }}
                  >
                    {item.type}
                  </li>
                ))}
                {active === "4" ? (
                  <li
                    onClick={() => updateActive("2")}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${
                        active === "4" ? "steelblue" : "#585858"
                      }`,
                      textAlign: "center",
                    }}
                  >
                    Back
                  </li>
                ) : null}
              </ul>
            </div>
          </Col>
          <Col lg={10} md={12} className={classes.Response}>
            <div style={{ height: "100%" }}>
              {active === "1" ? (
                <AddCabType />
              ) : active === "2" ? (
                <CabList
                  click={() => updateActive("4")}
                  mydata1={(e) => setdata1(e)}
                />
              ) : active === "3" ? (
                <AddAmenities />
              ) : active === "5" ? (
                <SetPrice />
              ) : active === "4" ? (
                <AddCabGeneralView
                  click={() => updateActive("2")}
                  data={data1}
                />
              ) : active == "6" ? (
                <AddDriver />
              ) : active == "8" ? (
                <DriverList
                  click={() => updateActive("9")}
                  mydata={(e) => setdata(e)}
                />
              ) : active === "9" ? (
                <AddDriverGeneralView
                  click={() => updateActive("8")}
                  data={data}
                />
              ) : active == "7" ? (
                <CabRoute />
              ) : active == "10" ? (
                <RouteList
                  click={() => updateActive("11")}
                  mydata={(e) => setdata2(e)}
                />
              ) : active === "11" ? (
                <AddRouteGeneralView
                  click={() => updateActive("10")}
                  data={data2}
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CabVendor;
