import React, { useState, useEffect } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import Image from "../../../utilities/imageTestApi/image";
import MultipleSelect from "../../multiSelect";
import RoomCategoryList from "./roomCategoryList";
import { GetVendor } from "../../../entities/action/action";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";

const RoomName = (props) => {
  const [select, updateSelect] = useState([]);
  const [select1, updateSelect1] = useState([]);
  const [file1, updateFile1] = useState({});
  const [file2, updateFile2] = useState({});
  const [file3, updateFile3] = useState({});
  const [dataSet, updateDataSet] = useState([]);
  const [amenities, updateAmenities] = useState([]);
  const [vendorList, updateVendorList] = useState([]);
  const [accList, updateAccList] = useState([]);
  const [id, updateId] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await GetVendor();
      if (res != undefined && res.length > 0) {
        const arr = res.filter(
          (item, key) =>
            item.companyInformation.vendortype === "Accommodation Provider"
        );
        console.log(res);
        updateVendorList(arr);
      }
    })();
  }, []);
  const [accomodationId, updateaccomodationId] = useState("");

  useEffect(() => {
    (async () => {
      const newdata = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getHotelAmenities", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      if (json.successful) {
        updateAmenities(json.hotelAmenitiess);
      }
    })();
  }, []);

  console.log(amenities);

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        `https://api.zupptravel.com/getAccommodation`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await myresponse.json();
      console.log("jsssoooooonnn", json);

      if (json.successful) {
        updateDataSet(json.accommodations);
      }
    })();
  }, []);
  const [vendorId, updateVendorId] = useState("");

  console.log("acccc", dataSet);
  const arr = [];
  useEffect(() => {
    dataSet.map((data, key) => {
      if (data.vendorId == vendorId) {
        arr.push(data.accId);
      }
    });

    updateAccList(arr);
  }, [vendorId]);

  const id1 = [];
  useEffect(() => {
    dataSet.map((data, key) => {
      if (data.accId == accomodationId) {
        id1.push(data.id);
      }
    });
    updateId(id1);
  }, [accomodationId]);
  // console.log(id);
  // console.log(accomodationId);

  console.log(select);
  return (
    <div style={{ padding: "20px" }}>
      <Formik
        initialValues={{ title: "" }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          //   setSubmitting(true);
          const img1 = await Image(file1);
          const img2 = await Image(file2);
          const img3 = await Image(file3);

          const a1 = [];

          select.map((value) => {
            a1.push(value);
          });

          console.log("first ", a1);

          const amenities1 = [];

          for (let i = 0; i < a1.length; i++) {
            amenities1.push({
              id: i,
              name: a1[i],
              vendorId: vendorId,
              accId: accomodationId,
            });
          }

          // console.log("11111111111111", amenities1);

          const data1 = {
            accId: accomodationId,
            accommodationId: id[0],
            amenities: amenities1,
            checkInTime: values.checkInTime,
            checkOutTime: values.checkOutTime,
            emailId: "string",
            imageUrl1: img1,
            imageUrl2: img2,
            imageUrl3: img3,
            name: values.roomName,
            noOfRooms: values.noOfRooms,
            roomCategoryId: 0,
            sessionKey: "string",
          };

          const response = await fetch(
            "https://api.zupptravel.com/addRoomCategory",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data1),
            }
          );

          console.log("dataaaa1", data1);

          const json = await response.json();
          console.log("sonnnnnnnnn", json);
          if (json.successful) {
            alert("Added Room Category");
            setTimeout(() => {
              window.location.reload();
            }, 0);
            props.click();
          

          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="2">
                Vendor ID:
              </Form.Label>
              <Col sm="10">
                <SingleSelect
                  type="3"
                  list={vendorList}
                  change={(select) => updateVendorId(select)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="2">
                Accommodation ID:
              </Form.Label>
              <Col sm="10">
                <SingleSelect
                  type="4"
                  list={accList}
                  change={(select) => updateaccomodationId(select)}
                />
              </Col>
            </Form.Group>
            {/* <Form.Group controlId="formBasicAccommodationType" as={Row}>
                                            <Form.Label column sm="2">Accommodation Type:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Accommodation Type" 
                                                    name="accommodationType"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.accommodationType}
                                                    required>
                                                    {
                                                        categoryList.map((categories,key)=>
                                                        <option key={key} value={categories.type}>{categories.type}</option>    
                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>    */}
            <Form.Group controlId="formBasicRoomName" as={Row}>
              <Form.Label column sm="2">
                Room Name:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  placeholder="Enter Room Name"
                  name="roomName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.roomName}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicMetaNoOfRooms" as={Row}>
              <Form.Label column sm="2">
                No. of Rooms:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="number"
                  placeholder="Enter No. of Rooms"
                  name="noOfRooms"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.noOfRooms}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCheckInTime" as={Row}>
              <Form.Label column sm="2">
                Check-in Time:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="time"
                  name="checkInTime"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.checkInTime}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCheckOutTime" as={Row}>
              <Form.Label column sm="2">
                Check-out Time:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="time"
                  name="checkOutTime"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.checkOutTime}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicAmenities" as={Row}>
              <Form.Label column sm="2">
                Select Amenities:
              </Form.Label>
              <Col sm="10">
                <MultipleSelect
                  amenities={amenities}
                  type="10"
                  selectedValue={(selected) => updateSelect(selected)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPromo" as={Row}>
              <Form.Label column sm="2">
                Image:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="file"
                  name="file1"
                  onChange={(e) => updateFile1(e.target.files[0])}
                  required
                />
              </Col>
              <Col sm="3">
                <Form.Control
                  type="file"
                  name="file2"
                  onChange={(e) => updateFile2(e.target.files[0])}
                  required
                />
              </Col>
              <Col sm="3">
                <Form.Control
                  type="file"
                  name="file3"
                  onChange={(e) => updateFile3(e.target.files[0])}
                  required
                />
              </Col>
            </Form.Group>
            <center>
              <Button type="submit" variant="success">
                Save Room Category
              </Button>
            </center>
          </Form>
        )}
      </Formik>
      <hr />
      {/* <RoomCategoryList /> */}
    </div>
  );
};
export default RoomName;
