import React, { useState } from 'react';
import {Table, Container} from 'react-bootstrap';

const FinanceList = (props)=>{
    const [dataSet,updateDataSet] = useState([
        {sNo:"1",partnerId:"ABC", partnerName: "whitewular1",status:'Active'},
        {sNo:"2",partnerId:"DEF", partnerName: "whitewular2",status:'Active'},
        {sNo:"3",partnerId:"GHI", partnerName: "whitewular3",status:'Active'},
        {sNo:"4",partnerId:"ABC", partnerName: "whitewular4",status:'Active'},
        {sNo:"5",partnerId:"FDJ", partnerName: "whitewular6",status:'Active'},
        {sNo:"6",partnerId:"XJN", partnerName: "whitewular7",status:'Active'},
        {sNo:"7",partnerId:"SDK", partnerName: "whitewular8",status:'Active'}
    ]);
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Date & Time</th>
                        <th>Amount Type</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataSet.length>0?dataSet.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.Date}</td>
                                <td>{data.amountType}</td>
                                <td>{data.Debit}</td>
                                <td>{data.Credit}</td>
                                <td>{data.Credit}</td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
        </Container>
    )
}
export default FinanceList;