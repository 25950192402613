import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
// import MyCalendar from "./MyCalendar";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import Page from "../../pagination/pagination";
import RoomAvailabilityModal from "./RoomAvailabilityModal";
import Modal from 'react-modal';
import Calendar1 from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { Col, Row, Form } from "react-bootstrap";
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Picker from 'rc-picker';
import 'rc-picker/assets/index.css';
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
}
const localizer = momentLocalizer(moment)

const events = [
  {
    title: 'Event 1',
    start: new Date(2023, 0, 1),
    end: new Date(2023, 0, 3),
  },
  {
    title: 'Event 2',
    start: new Date(2023, 0, 7),
    end: new Date(2023, 0, 10),
  },
  // Add more events as needed
];

// const [dateRange, setDateRange] = useState([]);

// const handleDateRangeChange = (value) => {
//   setDateRange(value);
// };

const RoomInventoryStatus = (props) => {
  const [dataSet, updateDataSet] = useState([1]);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet?.slice(indexOfFirst, indexOfLast);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet?.length / postPerPage));
  }, [dataSet]);
    useEffect(() => {
      (async () => {
        const data = {
          emailId: "string",
          password: "string",
          phoneno: "string",
          sessionKey: "string",
        };
        const myresponse = await fetch(
          `https://api.zupptravel.com/getAccommodation`,
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
          }
        );
        const json = await myresponse.json();
        console.log(json);
        if (json.successful) {
          updateDataSet(json.accommodations);
          console.log("dasas", dataSet);
        }
      })();
    }, []);
    console.log("data from room invantory",currentPosts)

    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
  
    const handleButtonClick = () => {
      setModalOpen(true);
    };
  
    const handleCloseModal = () => {
      setModalOpen(false);
      setShowSingleDate(null);
    };
  
    const handleDateChange = (date) => {
      console.log("date object",date);
      setSelectedDate(date);
      // Add logic to fetch and display room availability for the selected date
    };
    console.log("date object",selectedDate);

    const [date, updateDate] = useState([]);

    const [showSingleDate, setShowSingleDate] = useState(null);

    const roomCategory = [
      { name: 'Standard Room', noOfRooms: 10 },
      { name: 'Deluxe Room', noOfRooms: 5 },
      { name: 'Suite', noOfRooms: 8 },
    ];
    

    const [selectedRoomIndex, setSelectedRoomIndex] = useState(null);

    const handleBoxClick = (index) => {
      setSelectedRoomIndex(index);
    };
  
    const handleBlockButtonClick = () => {
      // Add logic for handling block/unblock action here
      console.log('Block/Unblock button clicked for room:', roomCategory[selectedRoomIndex]?.name);
    };
   
    const [noOfRooms, setNoOfRooms] = useState(null);
    const [actionType, setActionType] = useState(null); 

    // const handleSubmit = async () => {
    //   try {
    //     // const date = 
    //     // const id = /* Logic to get id */;
    //     // const values = /* Logic to get values */;
  
    //     setNewData({
    //       ...newdata,
    //       dateFrom: date[0]?._d.toISOString().slice(0, 10),
    //       dateTo: date[1]?._d.toISOString().slice(0, 10),
    //       noOfBlockedRooms: values.noOfRooms,
    //       roomCategoryId: id[0],
    //       roomCategoryName: values.roomName,
    //     });
  
    //     const res = await fetch('https://api.zupptravel.com/blockRooms', {
    //       method: 'post',
    //       headers: {
    //         'Content-Type': 'application/json',
    //       },
    //       body: JSON.stringify(newdata),
    //     });
  
    //     console.log('rrrrrrrrrrrr', newdata);
    //     const json = await res.json();
        
    //     if (json.successful) {
    //       alert('Blocked Room!');
    //       window.location.reload();
    //     } else {
    //       alert('Error! Please try again later');
    //     }
    //   } catch (error) {
    //     console.error('Error:', error);
    //   }
    // };
  
  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Accomodation ID</th>
            <th>Accommodation Name</th>
            <th>Accommodation Type</th>
            {/* <th>Room Name</th>
            <th>No of rooms</th> */}
            <th>Room inventory</th>
          </tr>
        </thead>
        <tbody>

          {currentPosts?.length > 0
            ? currentPosts?.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.accId}</td>
                  <td>{data.accommodationName}</td>
                  <td>{data.accommodationType}</td>
           
               <td className={classes.CenterRow}>
                    <Button
                      style={{ margin: "auto 1%" }}
                      variant="info"
                      onClick={handleButtonClick}
                    >
                      View
                    </Button>
                    <Modal
  isOpen={isModalOpen}
  onRequestClose={handleCloseModal}
  contentLabel="Room Availability Modal"
  style={{
    content: {
      display: 'flex',
      flexDirection: 'column', // Change to column layout
      width: '90%',
      height: '85%',
      margin: 'auto',
    },
  }}
>
  {/* Heading at the top */}
  <h1 style={{ alignSelf: 'center', marginBottom: '2vh' }}>Room Availability Check</h1>

  {/* Divider Line */}
  <hr style={{ width: '100%', border: '1px solid #ccc', margin: 0 }} />

  {/* Main Content */}
  <div style={{ display: 'flex', width: '100%', height: '100%' }}>
    <div style={{ flex: '35%', paddingRight: '5vw' }}>
      {/* ... existing content for the left side ... */}


<div style={{ flex: 3, paddingRight: '5vw',marginTop:"2vh",marginBottom:"2vh" }}>
    <div>   
      <Button
        variant="primary"
        style={{ marginLeft: "1vw", marginRight: "1vw", marginBottom: "1vh" }}
        onClick={() => setShowSingleDate(true)}
      >
        Single Date Check
      </Button>
      <Button
        variant="primary"
        style={{ marginLeft: "1vw", marginRight: "1vw", marginBottom: "1vh" }}
        onClick={() => setShowSingleDate(false)}
      >
        Multiple Date Check
      </Button>
    
      {showSingleDate !== null && (
        <>
          {showSingleDate ? (
            <div className="calendar-container">
              {/* Render the Calendar component */}
              {/* <Calendar
                localizer={localizer}
                events={events}
                onChange={handleDateChange}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 500 }}
              /> */}
              <Calendar1
                onChange={handleDateChange}
                value={selectedDate}
                style={{
                  content: {
                    width: '80%',
                    height: '70%',
                  },
                }}
              />
            </div>
          ) : (
            // Render the Date Picker component when showSingleDate is false
      //       <Form.Group controlId="formBasictimePerSlot" as={Row}>
      //         <Form.Label column sm="2">
      //           Date:
      //         </Form.Label>
      //         <Col>
      //           <RangePicker
      //             onChange={(val) => updateDate(val)}
      //             value={date}
      //             disabledDate={disabledDate}
      //           />
      //              <Button
      //   variant="primary"
      //   style={{ marginLeft: "0.5vw", marginRight: "0.5vw", marginBottom: "1vh" }}
      //   onClick={() => setShowSingleDate(false)}
      // >
      //  Search
      // </Button>
      //         </Col>
      //       </Form.Group>
      <Form.Group controlId="formBasictimePerSlot" as={Row}>
  <Form.Label column sm="2">

  </Form.Label>
  <Col sm="12" className="d-flex align-items-center">
  Date:-  <RangePicker
      onChange={(val) => updateDate(val)}
      value={date}
      disabledDate={disabledDate}
      style={{ marginLeft: "0.5vw", marginRight: "0.5vw"}}
    />
    <Button
      variant="primary"
      style={{ marginLeft: "1vw", marginRight: "1vw"}}
      onClick={() => setShowSingleDate(false)}
    >
      Search
    </Button>
  </Col>
</Form.Group>

          )}
        </>
      )}
    </div>
  </div>

    </div>

    {/* Divider Line */}
    <div style={{ borderLeft: '2px solid #ccc', height: '100%' }} />

    <div style={{ flex: '65%', marginTop: '5vh' }}>
      {/* ... existing content for the right side ... */}
      <div style={{ flex: 1, marginTop: '5vh' }}>
  {showSingleDate !== null && (
    <>
     
    <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
  {roomCategory &&
    roomCategory.map((room, roomIndex) => (
      <div
        key={roomIndex}
        style={{
          border: '1px solid #ccc',
          borderRadius: '8px',
          boxShadow: selectedRoomIndex === roomIndex ? '0 0 12px rgba(0, 0, 0, 0.6)' : 'none',
          padding: '15px',
          width: '30%',
          boxSizing: 'border-box',
          backgroundColor: '#f0f0f0',
          color: 'black',
          cursor: 'pointer',
          transition: 'box-shadow 0.3s ease-in-out', // Add a smooth transition for the shadow
        }}
        onClick={() => handleBoxClick(roomIndex)}
      >
        {room && (
          <>
            <p style={{ margin: 0 }}>Room Name: {room.name}</p>
            <p style={{ margin: 0 }}>Total Rooms: {room.noOfRooms}</p>
            <p style={{ margin: 0 }}>Blocked Rooms: Data not available</p>
          </>
        )}
      </div>
    ))}
</div>

{selectedRoomIndex !== null && (
  <div style={{ textAlign: 'center', marginTop: '10px' }}>
        <Form.Group controlId="formBasicActionType" as={Row}>
              <Form.Label column sm="2">
                Action Type:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  placeholder="Action Type"
                  name="actionType"
                  value={actionType}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Block">Block</option>
                  <option value="Unblock">Unblock</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicMetaNoOfRooms" as={Row}>
              <Form.Label column sm="2">
                No. of Rooms:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="number"
                  placeholder="Enter No. of Rooms"
                  name="noOfRooms"
    
                  value={noOfRooms}
                  required
                />
              </Col>
            </Form.Group>
    <button
      style={{
        backgroundColor: '#dc3545',
        width: '10vw',
        height: '10vh',
        color: 'white',
        border: 'none',
        padding: '10px',
        borderRadius: '8px',
        cursor: 'pointer',
      }}
      onClick={handleBlockButtonClick}
    >
      Block/Unblock Room
    </button>
  </div>
)}

    </>
  )}

</div>


    </div>
  </div>

  {/* Close Button */}
  <Button variant="danger" onClick={handleCloseModal} style={{ marginTop: '1vh', width:"10vw",height:"6vh", marginLeft:"auto",marginRight:"auto"}}>
    Close
  </Button>
</Modal>


                  
                                      {/* {data.status === "Active" ? (
                      <Button style={{ margin: "auto 1%" }} variant="success">
                        Active
                      </Button>
                    ) : (
                      <Button style={{ margin: "auto 1%" }} variant="danger">
                        Inactive
                      </Button>)} */}
                    
                  </td>
              
      
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default RoomInventoryStatus;
