import React, {useState, Fragment } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import CharterPlaneList from './CharterPlaneList';
import CharterPlaneView from './CharterPlaneView';
import AddCharterPlaneType from './addCharterPlaneType';
import AddAmenities from './addAmenities';

const CharterPlaneVendor = (props)=>{
    const [active,updateActive] = useState('1');
    const items = [
        {id:"1",type:"Add Charter Plane"},
        {id:"2",type:"Charter Plane Vendor List"},
        {id:"3",type:"Add Charter Plane Amenities & Facilities"}
    ]
 
    return(
        <Fragment> 
        <Container fluid style={{paddingLeft:'10px'}}>
            <Row> 
                <Col lg={2} md={12} className={classes.Response2}>
                    <div style={{height:'100%'}}> 
                        <ul className={classes.Ul}>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                    style={{backgroundColor:`${active===item.id?'steelblue':'#585858'}`,textAlign:'center'}}>
                                    {item.type}
                                    </li>
                                )
                            }
                            {
                                active==="5"?<li 
                                onClick={()=>updateActive("2")}
                                className={classes.Li}
                                style={{backgroundColor:`${active==="5"?'steelblue':'#585858'}`,textAlign:'center'}}>
                                Back
                                </li>:null
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={10} md={12} className={classes.Response}>
                    <div style={{height:'100%'}}> 
                        {
                            active==="1"?<AddCharterPlaneType/>:
                            active==="2"?<CharterPlaneList click={()=>updateActive("4")}/>:
                            active==="3"?<AddAmenities/>:
                            active==="4"?<CharterPlaneView/>:null
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default CharterPlaneVendor;