import React, { useState } from 'react';
import { Button,Form,Row,Col } from 'react-bootstrap';
import {AddDepartment,AddDesignation} from '../../../entities/action/action';
import AddAmenitiesAndHotelFacilitiesList from './addAmenitiesAndFacilitiesList';

const AddAmenitiesAndHotelFacilitiesForm = (props)=>{

    const [input,SetInput] = useState('');
    const [icon,SetIcon] = useState('');
    const handleSubmit = async()=>{
        let newData;
        let response;
        if(props.categoryType=="Amenities"){
            newData = {
                "department": input
            }
            response = await AddDepartment(newData);
        }else{
            newData = {
                "designation": input
            }
            response = await AddDesignation(newData);
        }
        if(response==200){
            alert("Successfully Added");
            SetInput('');
            window.location.reload();
        }
    }
    return(
        <div> 
            <Form.Group controlId="formBasicPromo" as={Row}>
                <Form.Label column sm="3">{props.categoryType}:</Form.Label>
                <Col sm="9"> 
                    <Form.Control
                        type="text" 
                        placeholder={`Enter ${props.categoryType}`} 
                        name="category"
                        onChange={(e)=>SetInput(e.target.value)}
                        value={input}
                        required/>
                </Col>
            </Form.Group>
            <Form.Group controlId="formBasicIcon" as={Row}>
                <Form.Label column sm="3">Icon:</Form.Label>
                <Col sm="9"> 
                    <Form.Control
                        type="text" 
                        placeholder="Enter Icon"
                        name="icon"
                        onChange={(e)=>SetIcon(e.target.value)}
                        value={icon}
                        required/>
                </Col>
            </Form.Group>
            <center>
                <Button onClick={handleSubmit} style={{margin:'2%'}}>Save {`${props.categoryType}`}</Button>
            </center>
            <AddAmenitiesAndHotelFacilitiesList type={props.categoryType} data={props.data}/>
        </div>
    )
}
export default AddAmenitiesAndHotelFacilitiesForm;