import React, { useEffect, useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Field, FieldArray, Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import { DropzoneArea } from "material-ui-dropzone";
import { CityState, GetVendor } from "../../../entities/action/action";
import Image from "../../../utilities/imageTestApi/image";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
const AddActivity = (props) => {
  const [message, updateMessage] = useState("");
  const [tAndC, updateTAndC] = useState("");
  const [req, updateReq] = useState("");
  const [files, updateFiles] = useState([]);
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([1]);
  const [currentPoints1, updateCurrentPoints1] = useState(1);
  const [arrPoints1, updateArrPoints1] = useState([1]);
  const [currentPoints2, updateCurrentPoints2] = useState(1);
  const [arrPoints2, updateArrPoints2] = useState([1]);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [vendorId, updateVendorId] = useState("");

  const myfunc = async (e) => {
    if (e.target.value.length === 6) {
      const response = await CityState(e.target.value);
      console.log(Object.values(response.data));
      if (response.status == "success") {
        setState(Object.values(response.data)[0].state_name);
        setCity(Object.values(response.data)[0].city_name);
      }
    } else {
      setState("");
      setCity("");
    }
  };
  const handleImages = (inpFile) => {
    updateFiles(inpFile);
  };
  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    } else if (type === 2) {
      const newCurrentPoint1 = currentPoints1 + 1;
      updateCurrentPoints1(newCurrentPoint1);
      updateArrPoints1([...arrPoints1, newCurrentPoint1]);
    } else {
      const newCurrentPoint2 = currentPoints2 + 1;
      updateCurrentPoints2(newCurrentPoint2);
      updateArrPoints2([...arrPoints2, newCurrentPoint2]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    } else if (type === 2 && currentPoints1 > 1) {
      const newCurrentPoint1 = currentPoints1 - 1;
      updateCurrentPoints1(newCurrentPoint1);
      let arr = [...arrPoints1];
      arr.pop();
      updateArrPoints1(arr);
    } else if (type === 3 && currentPoints2 > 1) {
      const newCurrentPoint2 = currentPoints2 - 1;
      updateCurrentPoints2(newCurrentPoint2);
      let arr = [...arrPoints2];
      arr.pop();
      updateArrPoints2(arr);
    }
  };
  const [vendorList, updateVendorList] = useState([]);
  useEffect(() => {
    (async () => {
      const res = await GetVendor();
      if (res != undefined && res.length > 0) {
        const arr = res.filter(
          (item, key) =>
            item.companyInformation.vendortype === "Activities Provider"
        );
        updateVendorList(arr);
      }
    })();
  }, []);
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          cancellation: [
            {
              cancellation: "",
              charges: "",
            },
          ],
          pickUpPoint: [
            {
              pickUpPoint: "",
            },
          ],
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const img = await Image(files[0]);
          console.log(values);
          const newdata = {
            activityType: values.activityType,
            cancellation: values.cancellation,
            category: values.activityCategory,
            city: city,
            description: message,
            emailId: "string",
            images: img,
            language: values.language,
            pickUpPoint: values.pickUpPoint,
            requirements: req,
            sessionKey: "string",
            state: state,
            termsAndCondition: tAndC,
            title: values.activityTitle,
            vendorActivityId: 0,
            worldLocation: values.ticketRequired,
            zipCode: values.pincode,
          };
          const res = await fetch(
            "https://api.zupptravel.com/addVendorActivities",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newdata),
            }
          );
          const json = await res.json();
          console.log(json);
          if (json.successful) {
            alert("Added Activity !");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <SingleSelect
                  type="3"
                  list={vendorList}
                  change={(select) => updateVendorId(select)}
                />
                {/* <Form.Control
                  as="select"
                  placeholder="Vendor ID"
                  name="vendorId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vendorId}
                  required
                >
                  <option value="">--select--</option>
                  {vendorList.map((vendor, key) => {
                    return (
                      <>
                        <option value={vendor.supplierid}>
                          {vendor.supplierid}
                        </option>
                      </>
                    );
                  })}
                </Form.Control> */}
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasic" as={Row}>
              <Form.Label column sm="3">
                Activity Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Activity Type"
                  name="activityType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.activityType}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCityState" as={Row}>
              <Form.Label column sm="3">
                World Location:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="select"
                  name="ticketRequired"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.ticketRequired}
                  required
                >
                  <option value="">--select--</option>
                  <option value="India">India</option>
                  <option value="Foreign">Foreign</option>
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                Zip Code:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter Zip Code"
                  name="pincode"
                  onChange={handleChange}
                  onBlur={myfunc}
                  value={values.pincode}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasic" as={Row}>
              <Form.Label column sm="3">
                State:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter State"
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={state}
                  readOnly
                  required
                >
                  {/* <option value=""></option> */}
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                City:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter City"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={city}
                  readOnly
                  required
                >
                  {/* <option value=""></option> */}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCategory" as={Row}>
              <Form.Label column sm="3">
                Activity Category:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Activity Category"
                  name="activityCategory"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.activityCategory}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTitle" as={Row}>
              <Form.Label column sm="3">
                Activity Title:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Activity Title"
                  name="activityTitle"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.activityTitle}
                  required
                />
              </Col>
            </Form.Group>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Description:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateTAndC(text)}
                  text={tAndC}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Requirments:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor change={(text) => updateReq(req)} text={req} />
              </Col>
            </Row>
            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Cancellation :
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Cancellation `}
                        name={`Cancellation${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`Cancellation${d}`]}
                        required
                      />
                    </Col>
                    <Form.Label column sm="3">
                      Charge:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Charges `}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`cancellationCharges${d}`}
                        value={values[`cancellationCharges${d}`]}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>

              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div>

            <Form.Group
              style={{ clear: "both" }}
              controlId={`formBasicLanguage`}
              as={Row}
            >
              <Form.Label column sm="3">
                Language
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder={`Enter Language`}
                  name={`language`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.language}
                  required
                />
              </Col>
            </Form.Group>

            {/*  */}

            {arrPoints1.length > 0
              ? arrPoints1.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Pick-Up Point
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        placeholder={`Enter PickUp Point`}
                        name={`pickUpPoint.${key}.pickUpPoint`}
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints1 <= 1 ? true : false}
                onClick={() => handleRemove(2)}
              >
                Remove
              </Button>

              <Button variant="success" onClick={() => handleAdd(2)}>
                Add
              </Button>
            </div>

            <Form.Group
              style={{ clear: "both" }}
              controlId="formBasicChild"
              as={Row}
            >
              <Form.Label column sm="3">
                Images:
              </Form.Label>
              <Col sm="9">
                <DropzoneArea onChange={handleImages} filesLimit={5} />
              </Col>
            </Form.Group>
            <center>
              <Button type="submit" variant="success">
                Save Activity
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddActivity;

{
  /* <Row>
                        <Form.Label column sm="3">
                          Pick-Up Point
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            placeholder={`Enter PickUp Point`}
                            name={`pickUpPoint.${index}.pickUpPoint`}
                          />
                        </Col>
                      </Row> */
}
