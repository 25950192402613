import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Form, Button, Row, Col } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import { DropzoneArea } from "material-ui-dropzone";
import MultipleSelect from "../../multiSelect";

const AddVehicleGeneralView = (props) => {
  const [message, updateMessage] = useState("");
  const [files, updateFiles] = useState([]);
  const [edit, updateEdit] = useState(false);
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([1]);
  const [registrationArr, updateRegistrationArr] = useState([]);
  const [noOfVehicle, updateNoOfVehicle] = useState("");
  const [select, updateSelect] = useState([]);
  const [select2, updateSelect2] = useState([]);

  const handleImages = (inpFile) => {
    updateFiles(inpFile);
  };
  useEffect(() => {
    if (noOfVehicle >= 1) {
      let arr = [];
      for (let i = 0; i < noOfVehicle; i++) {
        arr.push(i);
      }
      updateRegistrationArr(arr);
    }
  }, [noOfVehicle]);

  const handleAdd = () => {
    const newCurrentPoint = currentPoints + 1;
    updateCurrentPoints(newCurrentPoint);
    updateArrPoints([...arrPoints, newCurrentPoint]);
  };
  const handleRemove = () => {
    if (currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    }
  };

  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "2%",
              }}
            >
              {!edit ? (
                <Button variant="success" onClick={() => updateEdit(true)}>
                  Edit
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={() => {
                    updateEdit(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="light"
                style={{ marginLeft: "15px" }}
                onClick={() => props.click()}
              >
                {`<--`} Back
              </Button>
            </div>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  readOnly
                  as="select"
                  placeholder="Vendor ID"
                  name="vendorId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vendorId}
                  disabled={!edit}
                  required
                >
                  <option value="">--select--</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVehicleName" as={Row}>
              <Form.Label column sm="3">
                Vehicle Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Vehicle Type"
                  name="vehicleName"
                  value={values.vehicleType}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVehicleName" as={Row}>
              <Form.Label column sm="3">
                Vehicle Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Vehicle Name"
                  name="vehicleName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vehicleName}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTransmissionType" as={Row}>
              <Form.Label column sm="3">
                Transmission Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  placeholder="Transmission Type"
                  name="transmissionType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.transmissionType}
                  disabled={!edit}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Manual">Manual</option>
                  <option value="Automatic">Automatic</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPassengersSeat" as={Row}>
              <Form.Label column sm="3">
                Passengers Seat:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="number"
                  min={1}
                  placeholder="Enter Passengers Seat"
                  name="passengersSeat"
                  value={values.passengersSeat}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicNoOfVehicle" as={Row}>
              <Form.Label column sm="3">
                No. of vehicle:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="number"
                  placeholder="No. of vehicle"
                  min={1}
                  name="noOfVehicle"
                  onChange={(e) => updateNoOfVehicle(e.target.value)}
                  onBlur={handleBlur}
                  value={noOfVehicle}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            {registrationArr.map((_, i) => (
              <Form.Group controlId={`formBasicInfo${i}`} as={Row} key={i}>
                <Form.Label column sm="3">
                  Vehicle Registration Number {i + 1}:
                </Form.Label>
                <Col sm="9">
                  <Form.Control
                    type="text"
                    placeholder={`Vehicle Registration Number ${i + 1}`}
                    name={`vehicleRegistrationNumber${i + 1}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values[`vehicleRegistrationNumber${i + 1}`]}
                    disabled={!edit}
                    required
                  />
                </Col>
              </Form.Group>
            ))}
            <Form.Group controlId="formBasicAbout" as={Row}>
              <Form.Label column sm="3">
                Vehicle Brand:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Vehicle Brand"
                  name="vehicleBrand"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vehicleBrand}
                  disabled={!edit}
                  required
                />
              </Col>
              <Form.Label column sm="2">
                Vehicle Fuel:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  as="select"
                  name="vehicleFuel"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vehicleFuel}
                  disabled={!edit}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Gas">Gas</option>
                  <option value="Petrol">Petrol</option>
                  <option value="Diesel">Diesel</option>
                  <option value="Electric">Electric</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicLiveTracking" as={Row}>
              <Form.Label column sm="3">
                Partial Cancellation:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="select"
                  placeholder="Partial Cancellation"
                  name="partialCancellation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.partialCancellation}
                  disabled={!edit}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                Live Tracking:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  as="select"
                  placeholder="Live Tracking"
                  name="liveTracking"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.liveTracking}
                  disabled={!edit}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicDelivery" as={Row}>
              <Form.Label column sm="3">
                Doorstep Delivery:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="select"
                  name="doorstepDelivery"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.doorstepDelivery}
                  disabled={!edit}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                Doorstep Pickup:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  as="select"
                  placeholder="Doorstep Pickup"
                  name="doorstepPickup"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.doorstepPickup}
                  disabled={!edit}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVehicleAmenities" as={Row}>
              <Form.Label column sm="3">
                Vehicle Amenities:
              </Form.Label>
              <Col sm="9">
                <MultipleSelect
                  disabled={!edit}
                  type="1"
                  selectedValue={(selected) => updateSelect(selected)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVehicleFeatures" as={Row}>
              <Form.Label column sm="3">
                Vehicle Features:
              </Form.Label>
              <Col sm="9">
                <MultipleSelect
                  disabled={!edit}
                  type="1"
                  selectedValue={(selected) => updateSelect2(selected)}
                />
              </Col>
            </Form.Group>
            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group
                    key={key}
                    controlId={`formBasicPickupAndDropOffPoint${key}`}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      {key === 0 ? "Pickup & Drop off point:" : ""}
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Enter Pickup Point ${d}`}
                        name={`pickup${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`pickup${d}`]}
                        disabled={!edit}
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Enter Drop off point ${d}`}
                        name={`drop${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`drop${d}`]}
                        disabled={!edit}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove()}
              >
                Remove
              </Button>
              <Button variant="success" onClick={() => handleAdd()}>
                Add
              </Button>
            </div>
            <Form.Group controlId="formBasicCancellationPolicy1" as={Row}>
              <Form.Label column sm="3">
                Cancellation Policy & Charges:
              </Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Cancellation Policy 1"
                  name="cancellationPolicy1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cancellationPolicy1}
                  disabled={!edit}
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Charges 1"
                  name="charges1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.charges1}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCancellationPolicy2" as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Cancellation Policy 2"
                  name="cancellationPolicy2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cancellationPolicy2}
                  disabled={!edit}
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Charges 2"
                  name="charges2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.charges2}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCancellationPolicy3" as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Cancellation Policy 3"
                  name="cancellationPolicy3"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cancellationPolicy3}
                  disabled={!edit}
                  required
                />
              </Col>
              <Col>
                <Form.Control
                  type="text"
                  placeholder="Enter Charges 3"
                  name="charges3"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.charges3}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicChild" as={Row}>
              <Form.Label column sm="3">
                Images:
              </Form.Label>
              <Col sm="9">
                <DropzoneArea onChange={handleImages} filesLimit={2} />
              </Col>
            </Form.Group>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                />
              </Col>
            </Row>
            <center>
              <Button disabled={!edit} type="submit" variant="success">
                Update Bus
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddVehicleGeneralView;
