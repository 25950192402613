import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import AccommodationViewPropertyList from "./accommodationViewPropertyList";
import AddAccommodationGeneralView from "./addAccommodationGeneralView";
import SetPrice from "./setPrice";
import RoomInventory from "./roomInventory";
import MealPriceInternalList from "./mealPriceInternalList";

const AccommodationView = (props) => {
  const [active, updateactive] = useState("1");
  const [data, setdata] = useState({});
  console.log(data);
  return (
    <>
      {
        <Tabs defaultActiveKey="propertyDetails" id="uncontrolled-tab-example">
          <Tab
            eventKey="propertyDetails"
            title={active === "3" ? "Room Details" : "Property Details"}
          >
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey="propertyDetails"
            >
              {active === "1" ? (
                <AccommodationViewPropertyList
                  mydata={(e) => setdata(e)}
                  click={() => updateactive("2")}
                  setPrice={() => updateactive("3")}
                />
              ) : active === "2" ? (
                <AddAccommodationGeneralView
                  data={data}
                  click={() => updateactive("1")}
                />
              ) : active === "3" ? (
                <SetPrice click={() => updateactive("1")} />
              ) : null}
            </Tab.Container>
          </Tab>
          {active === "3" ? (
            <Tab
              eventKey="roomInventoryList"
              title="Room Inventory List"
              className={classes.Tabs}
            >
              <RoomInventory click={() => updateactive("1")} />
            </Tab>
          ) : null}
          {active === "3" ? (
            <Tab
              eventKey="mealPriceList"
              title="Meal Price List"
              className={classes.Tabs}
            >
              <MealPriceInternalList click={() => updateactive("1")} />
            </Tab>
          ) : null}
        </Tabs>
      }
    </>
  );
};
export default AccommodationView;
