import React, { useEffect, useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import Image from "../../../utilities/imageTestApi/image";
import MultipleSelect from "../../multiSelect";
import RoomCategoryList from "./roomCategoryList";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import { useSelector } from "react-redux";

const BusSeatLayout = (props) => {
  const [selected, updateSelected] = useState("");
  const [select, updateSelect] = useState([]);
  const [select1, updateSelect1] = useState([]);
  const [file1, updateFile1] = useState({});
  const [file2, updateFile2] = useState({});
  const [file3, updateFile3] = useState({});
  const [busType, updateBusType] = useState("");
  const [busName, updateBusName] = useState("");
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([1]);
  const [currentPoints1, updateCurrentPoints1] = useState(1);
  const [arrPoints1, updateArrPoints1] = useState([1]);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const [city, updateCity] = useState([]);
  const [dataSet, updateDataSet] = useState([]);
  useEffect(() => {
    (async () => {
      const myresponse = await fetch(
        `https://api.zupptravel.com/getLocationManagement`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            emailId: data.data.key,
            password: "string",
            phoneno: "string",
            sessionKey: data.sessionId,
          }),
        }
      );
      const json = await myresponse.json();
      console.log(json);
      if (json.successful) {
        updateDataSet(json.locationManagements);
      }
    })();
  }, []);
  console.log(dataSet);
  useEffect(() => {
    const arr1 = dataSet.map((data, key) => data.city);
    console.log(arr1);
    updateCity(arr1);
  }, [dataSet]);

  console.log(city);
  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    } else {
      const newCurrentPoint1 = currentPoints1 + 1;
      updateCurrentPoints1(newCurrentPoint1);
      updateArrPoints1([...arrPoints1, newCurrentPoint1]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    } else if (type === 2 && currentPoints1 > 1) {
      const newCurrentPoint1 = currentPoints1 - 1;
      updateCurrentPoints1(newCurrentPoint1);
      let arr = [...arrPoints1];
      arr.pop();
      updateArrPoints1(arr);
    }
  };

  return (
    <div>
      <Formik
        initialValues={{ title: "" }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {}}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicInfo" as={Row}>
              {/* <Form.Label column sm="2">
                Bus Registration Number:
              </Form.Label>
              <Col sm="4">
                <Col sm="10">
                  <SingleSelect change={(select) => updateSelected(select)} />
                </Col>
              </Col> */}
              <Form.Label column sm="2">
                Bus Manufacturer Name:
              </Form.Label>
              <Col sm="4">
                <Col sm="10">
                  <SingleSelect change={(select) => updateSelected(select)} />
                </Col>
              </Col>
            </Form.Group>
            {/* <Form.Group controlId="formBasicBusName" as={Row}>
              <Form.Label column sm="2">
                Bus Route:
              </Form.Label>
              <Col sm="10">
                <SingleSelect change={(select) => updateSelected(select)} />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicBusName" as={Row}>
              <Form.Label column sm="2">
                Bus Registration Name:
              </Form.Label>
              <Col sm="10">
                <SingleSelect change={(select) => updateSelected(select)} />
              </Col>
            </Form.Group> */}
            {/* <Form.Group controlId="formBasicBusInfo" as={Row}>
              <Form.Label column sm="2">
                Bus Type:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  readOnly
                  type="text"
                  placeholder="Bus Type"
                  name="busType"
                  value={busType}
                  required
                />
              </Col>
              <Form.Label column sm="2">
                Bus Name:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  readOnly
                  type="text"
                  placeholder="Bus Name"
                  name="busName"
                  value={busName}
                  required
                />
              </Col>
            </Form.Group> */}

            <center>
              <Button type="submit" variant="success">
                Save Bus Route
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default BusSeatLayout;
