import React, { useState, useEffect } from 'react';
import {Col,Button,Form, Container, Row,Tab,Tabs, Spinner} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import SupplierFinance from './supplierFinance';
import {GetSupplierById,Pincode,UpdateSupplierData} from "../../entities/action/action";
import {useSelector,useDispatch} from "react-redux";

const PartnerView = (props)=>{
    const [data,updateData] = useState({});
    const [active,updateActive] = useState("1");
    const [edit,updateEdit] = useState(false);
    const [idProodImg,updateIdProofImg] = useState({});
    const [idProodImgIndividual,updateIdProofImgIndividual] = useState({});
    const [cancelCheque,updateCancelCheque] = useState({});
    const [panUpload,updatePanUpload] = useState({});
    const [state,setState]=useState('');
    const [mycity,setCity]=useState('');
    const clickHandler = (id)=>{
        const win = window.open(id, '_blank');
        win.focus();
    }
    useEffect(()=>{
        (async()=>{
            const response = await GetSupplierById(props.data.supplierid);
            updateData(response);
            setCity(response.CompanyInformation.city);
            setState(response.CompanyInformation.state);
        })()
    },[])
    const dispatch = useDispatch();
    const cityState = useSelector(state=>state.account.cityState);
    const myfunc=(e)=>{
        if(e.target.value.length===6){
            
            dispatch(Pincode(e.target.value))
            
        }else{
            setState('')
            setCity('')
        }
    }
 
    useEffect(()=>{
        if(cityState===null){
            setState('')
            setCity('')
        }else{
            setState(cityState.state)
            setCity(cityState.city)
        }
    },[cityState])

    
  const passingToChild ={
 normaldata : props.data,
  detaildedData :data
}
    return(
        <>
        {
            Object.keys(data).length>0?
            <Tabs defaultActiveKey="generalDetails" id="uncontrolled-tab-example"   >
                <Tab eventKey="generalDetails" title="General Details" >
                    <Tab.Container id="left-tabs-example" defaultActiveKey="generalDetails" >             
                <Container fluid className={classes.Box}>
                    <Row style={{margin:'0 0 2% 0',textAlign:'center',cursor:'pointer'}}>
                        <Col md={3} xs={12} className={classes.Col} 
                        style={{backgroundColor:`${active==='1'?'steelblue':'#585858'}`,color:'white',padding:'0.5%'}}
                        onClick={()=>updateActive("1")}>
                            {data.category} Information
                        </Col>
                        <Col md={2} xs={12} className={classes.Col}
                        style={{backgroundColor:`${active==='2'?'steelblue':'#585858'}`,color:'white',padding:'0.5%'}}
                        onClick={()=>updateActive("2")}>
                            Billing Information
                        </Col>
                        <Col md={2} xs={12} className={classes.Col}
                        style={{backgroundColor:`${active==='3'?'steelblue':'#585858'}`,color:'white',padding:'0.5%'}}
                        onClick={()=>updateActive("3")}>
                            Banking Information
                        </Col>
                        <Col md={2} xs={12} className={classes.Col}
                        style={{backgroundColor:`${active==='4'?'steelblue':'#585858'}`,color:'white',padding:'0.5%'}}
                        onClick={()=>updateActive("4")}>
                        Authorized Person's Information
                        </Col>
                        <Col md={3} xs={12} className={classes.Col}
                        style={{backgroundColor:`${active==='6'?'steelblue':'#585858'}`,color:'white',padding:'0.5%'}}
                        onClick={()=>updateActive("5")}>
                        Digital Identity
                        </Col>
                    </Row>
                    {/* {console.log("initialValues vendor view",initialValues)} */}
                    <Formik
                    initialValues={{
                            cType:data.CompanyInformation.companytype,
                            cName:data.CompanyInformation.companyname,
                            addressline1:data.CompanyInformation.regaddrline1,
                            addressline2:data.CompanyInformation.regaddrline2,
                            village:data.CompanyInformation.vilage,
                            email:data.CompanyInformation.emailid,
                            pincode:data.CompanyInformation.pincode,
                            phone:data.CompanyInformation.phoneno,
                            certificationType:data.CompanyInformation.certtype,
                            password:data.CompanyInformation.password,
                            billingName:data.BillingInformation.billingname,
                            billingAddress:data.BillingInformation.billingaddr,
                            gstInfo:data.BillingInformation.gst,
                            gstNumber:data.BillingInformation.gstno,
                            gstAddress:data.BillingInformation.gstaddr,
                            pin:data.BillingInformation.pincode,
                            accName:data.BankingInformation.accountholdername,
                            ifs:data.BankingInformation.ifsccode,
                            accNum:data.BankingInformation.accountnumber,
                            acctype:data.BankingInformation.accounttype,
                            authEmail:data.AuthorizedPersonInformation.emailid,
                            authName:data.AuthorizedPersonInformation.name,
                            authPhone:data.AuthorizedPersonInformation.phonenumber,
                            insta:data.DigitalIdentityInformation.instagram,
                            facebook:data.DigitalIdentityInformation.facebook,
                            youtube:data.DigitalIdentityInformation.youtube,
                            whatsapp:data.DigitalIdentityInformation.whatsapp,
                            twitter:data.DigitalIdentityInformation.twitter,
                            pType:data.CompanyInformation.pType,
                            channelsType:data.CompanyInformation.channel

                    }}
                   
                    onSubmit={
                        async(values,{setSubmitting,resetForm})=>{
                            setSubmitting(true);
                            let img1 = data.AuthorizedPersonInformation.idproofimg,
                            img2 = data.CompanyInformation.panimg,
                            img3 = data.CompanyInformation.certimg,
                            img4 = data.BankingInformation.cancelledchequeimg;
                            if(idProodImg.type!=undefined){
                                img1 = await Image(idProodImg);
                            }
                            if(panUpload.type!=undefined){
                                img2 = await Image(panUpload);
                            }
                            if(idProodImgIndividual.type!=undefined){
                                img3 = await Image(idProodImgIndividual);
                            }
                            if(cancelCheque.type!=undefined){
                                img4 = await Image(cancelCheque);
                            }
                            const newData = {
                                "authorizedPersonInformation": {
                                  "emailid": values.authEmail,
                                  "idproofimg": img1,
                                  "name": values.authName,
                                  "otp": data.AuthorizedPersonInformation.otp,
                                  "phonenumber": values.authPhone
                                },
                                "bankingInformation": {
                                  "accountholdername": values.accName,
                                  "accountnumber": values.accNum,
                                  "accounttype": values.acctype,
                                  "cancelledchequeimg": img2,
                                  "ifsccode": values.ifs
                                },
                                "billingInformation": {
                                  "billingaddr": values.billingAddress,
                                  "billingname": values.billingName,
                                  "gst": values.gstInfo,
                                  "gstaddr": values.gstAddress,
                                  "gstno": values.gstNumber,
                                  "pincode": values.pin
                                },
                                "companyInformation": {
                                  "certimg": img3,
                                  "certtype": values.idProofType,
                                  "city": mycity,
                                  "companyname": values.cName,
                                  "companytype": data.category==="Company"?values.cType:"",
                                  "emailid": values.email,
                                  "panimg": img2,
                                  "password": values.password,
                                  "phoneno": values.phone,
                                  "pincode": values.pincode,
                                  "regaddrline1": values.addressline1,
                                  "regaddrline2": values.addressline2,
                                  "state": state,
                                  "vilage": values.village,
                                  "fixedphno":values.phonefixed,
                                  "producttype":values.pType,
                                  "channel":values.channelsType
                                },
                                "digitalIdentityInformation": {
                                  "facebook": values.facebook,
                                  "instagram": values.insta,
                                  "twitter": values.twitter,
                                  "whatsapp": values.whatsapp,
                                  "youtube": values.youtube
                                },
                                "category":  data.category
                            }
                            console.log(newData)
                            const res = await UpdateSupplierData(newData,data.id);
                            if(res.status===200){
                                alert("Successfully Updated Supplier");
                                window.location.reload();
                            }
                            setSubmitting(false);
                        }
                    }
                    >
                        {
                                    ({values,errors,touched,handleChange,handleBlur,handleSubmit,resetForm,isSubmitting})=>(
                                        <Form onSubmit={handleSubmit}>
                                            {
                                                !edit?
                                                <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'2%'}}>
                                                    <Button variant="success" onClick={()=>updateEdit(true)}>Edit</Button>
                                                </div>: 
                                                <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'2%'}}>
                                                    <Button variant="danger" onClick={()=>{updateEdit(false); resetForm()}}>Cancel</Button>
                                                </div>
                                            }
                                            <div style={{display:active==="1"?"block":"none"}}> 
                                            {
                                                data.category=="Company"?
                                                <Form.Group controlId="exampleForm.ControlSelect1">
                                                    <Form.Label>Company Type:</Form.Label>
                                                    <Form.Control as="select"  
                                                    name="cType"
                                                    onChange={handleChange}
                                                    disabled={!edit}
                                                    value={values.cType}
                                                    required
                                                    >
                                                    <option value="Sole Proprietorship">Sole Proprietorship</option>
                                                    <option value="Private Limited">Private Limited</option>
                                                    <option value="Limited Liability Partnership">Limited Liability Partnership</option>
                                                    <option value="Registered Partnership">Registered Partnership</option>
                                                    <option value="Public Limited Company">Public Limited Company</option>
                                                    <option value="Join Hindu Family Company">Join Hindu Family Company</option>
                                                    <option value="Section 8 Company">Section 8 Company</option>
                                                    <option value="One Person Company">One Person Company</option>
                                                    </Form.Control>
                                                </Form.Group>:null
                                            }
                                            <Form.Row>
                                                <Form.Group controlId="exampleForm.ControlPType" as={Col}>
                                                    <Form.Label>Product Type:</Form.Label>
                                                    <Form.Control as="select"  
                                                    name="pType"
                                                    onChange={handleChange}
                                                    value={values.pType}
                                                    disabled={!edit}
                                                    required
                                                    >
                                                    <option value="">--select--</option>
                                                    <option value="Goods">Goods</option>
                                                    <option value="Service">Service</option>
                                                    <option value="Both">Both</option>
                                                    </Form.Control>
                                                </Form.Group>
                                                <Form.Group controlId="exampleForm.ControlchannelType" as={Col}>
                                                    <Form.Label>Channels Type:</Form.Label>
                                                    <Form.Control as="select"  
                                                    name="channelsType"
                                                    onChange={handleChange}
                                                    value={values.channelsType}
                                                    disabled={!edit}
                                                    required
                                                    >
                                                    <option value="">--select--</option>
                                                    <option value="Online">Online</option>
                                                    <option value="Offline">Offline</option>
                                                    <option value="Bothline">Bothline</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            </Form.Row>
                                                <Form.Group controlId="formBasicName">
                                                    <Form.Label>{data.category} Name:</Form.Label>
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="Enter Name" 
                                                    name="cName"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.cName}
                                                    disabled={!edit}
                                                    required/>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicAddress">
                                                    <Form.Label>Registered Address Line 1:</Form.Label>
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="Enter Address" 
                                                    name="addressline1"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.addressline1}
                                                    disabled={!edit}
                                                    required/>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicAddress">
                                                    <Form.Label>Registered Address Line 2:</Form.Label>
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="Enter Address" 
                                                    name="addressline2"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.addressline2}
                                                    disabled={!edit}
                                                    required/>
                                                </Form.Group>
                                                <Row> 
                                                <Col md={3} xs={12}>
                                                <Form.Group controlId="exampleForm.Control">
                                                    <Form.Label>Village:</Form.Label>
                                                    <Form.Control type="text"   
                                                    name="village"
                                                    placeholder="Village"
                                                    onChange={handleChange}
                                                    value={values.village}
                                                    disabled={!edit}
                                                    />
                                                </Form.Group>
                                                </Col>
                                                <Col md={3} xs={12}>
                                                <Form.Group controlId="exampleForm.Control">
                                                    <Form.Label>City:</Form.Label>
                                                    <Form.Control 
                                                    type="text"  
                                                    name="mycity"
                                                    placeholder="City"
                                                    onChange={handleChange}
                                                    value={mycity}
                                                    disabled={!edit}
                                                    required
                                                    />
                                                </Form.Group>
                                                </Col>
                                                <Col md={3} xs={12}>
                                                <Form.Group controlId="exampleForm.Control">
                                                    <Form.Label>State:</Form.Label>
                                                    <Form.Control type="text"   
                                                    name="state"
                                                    placeholder="State"
                                                    onChange={handleChange}
                                                    value={state}
                                                    disabled={!edit}
                                                    required
                                                    />
                                                </Form.Group>
                                                </Col>
                                                <Col md={3} xs={12}>
                                                <Form.Group controlId="formBasicPin">
                                                    <Form.Label>Pin Code:</Form.Label>
                                                    <Form.Control 
                                                        type="text" 
                                                        placeholder="Enter Pin Code" 
                                                        name="pincode"
                                                        onChange={handleChange}
                                                        // onBlur={handleBlur}
                                                        value={values.pincode}
                                                        onBlur={myfunc}
                                                        disabled={!edit}
                                                        className={touched.pincode ? "has-error":null}
                                                    />
                                                        {errors.pincode && touched.pincode && errors.pincode}
                                                </Form.Group>
                                                </Col>
                                                </Row>
                                                {
                                                    data.category=="Company"?
                                                    <div>
                                                        <Row> 
                                                        <Col md={4} xs={12}> 
                                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                                <Form.Label>Type of Certification:</Form.Label>
                                                                <Form.Control as="select"  
                                                                name="certificationType"
                                                                onChange={handleChange}
                                                                value={values.certificationType}
                                                                disabled={!edit}
                                                                required
                                                                >
                                                                <option value="Registration Certification">Registration Certification</option>
                                                                <option value="MSME Registgration Certificate">MSME Registgration Certificate</option>
                                                                <option value="Shop Establishment Certificate">Shop Establishment Certificate</option>
                                                                </Form.Control>
                                                            </Form.Group>      
                                                        </Col>
                                                        <Col md={4} xs={12}>
                                                        <Form.Group controlId="formBasicDoc">
                                                            <Form.Label>Upload Certificate:</Form.Label>
                                                            <Form.Control 
                                                            type="file" 
                                                            name="idProofImgIndividual"
                                                            onChange={(e)=>updateIdProofImgIndividual(e.target.files[0])}
                                                            disabled={!edit}
                                                            />
                                                        </Form.Group>
                                                        </Col>  
                                                        <Col md={4} xs={12}> 
                                                        <Form.Group controlId="formBasicAccType">
                                                            <Form.Label>Upload PAN Card:</Form.Label>
                                                            <Form.Control 
                                                            type="file" 
                                                            name="panUpload"
                                                            onChange={(e)=>updatePanUpload(e.target.files[0])}
                                                            disabled={!edit}
                                                            />
                                                        </Form.Group>
                                                        </Col>
                                                        </Row>
                                                        <Form.Group controlId="formBasicPhone">
                                                            <Row>
                                                                <Col>
                                                                    <Form.Label>Current Certificate:</Form.Label>
                                                                    <br/>
                                                                    <Button onClick={()=>clickHandler(data.CompanyInformation.certimg)}>Open</Button>
                                                                </Col>
                                                                <Col>
                                                                    <Form.Label>Current PAN Card:</Form.Label>
                                                                    <br/>
                                                                    <Button onClick={()=>clickHandler(data.CompanyInformation.panimg)}>Open</Button>
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                    </div>:
                                                    <div>
                                                        <Row> 
                                                        <Col md={4} xs={12}>
                                                        <Form.Group controlId="exampleForm.ControlSelect1">
                                                            <Form.Label>Photo ID Proof:</Form.Label>
                                                                <Form.Control as="select"  
                                                                name="idProofType"
                                                                onChange={handleChange}
                                                                value={values.idProofType}
                                                                disabled={!edit}
                                                                required
                                                                >
                                                                <option value="Aadhar Card">Aadhar Card</option>
                                                                <option value="Voter ID">Voter ID</option>
                                                                <option value="Driving Licence">Driving Licence</option>
                                                                </Form.Control>
                                                        </Form.Group>   
                                                        </Col>
                                                        <Col md={4} xs={12}> 
                                                        <Form.Group controlId="formBasicAccType">
                                                            <Form.Label>Upload ID Proof:</Form.Label>
                                                            <Form.Control 
                                                            type="file" 
                                                            name="idProofImgIndividual"
                                                            onChange={(e)=>updateIdProofImgIndividual(e.target.files[0])}
                                                            disabled={!edit}
                                                            />
                                                        </Form.Group>
                                                        </Col>
                                                        <Col md={4} xs={12}>
                                                            <Form.Label>Current ID Proof:</Form.Label>
                                                            <br/>
                                                            <Button onClick={()=>clickHandler(data.CompanyInformation.certimg)}>Open</Button>
                                                        </Col>
                                                        </Row>
                                                    </div>
                                                }
                                                <Row>
                                                <Col xs={12}> 
                                                <Form.Group controlId="formBasicPhone">
                                                    <Form.Label>Phone Number:</Form.Label>
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="Enter Phone Number" 
                                                    name="phone"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.phone}
                                                    disabled={!edit}
                                                    required/>
                                                </Form.Group>
                                                </Col>
                                                </Row>
                                                <Form.Row>
                                                <Form.Group controlId="formBasicEmail" as={Col}>
                                                    <Form.Label>Email ID:</Form.Label>
                                                    <Form.Control 
                                                    type="email" 
                                                    placeholder="Enter Email" 
                                                    name="email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                    disabled={!edit}
                                                    required/>
                                                </Form.Group> 
                                                <Form.Group controlId="formBasicPassword" as={Col}>
                                                    <Form.Label>Password:</Form.Label>
                                                    <Form.Control 
                                                    type="password" 
                                                    placeholder="Enter Password" 
                                                    name="password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                    disabled={!edit}
                                                    required/>
                                                </Form.Group> 
                                                </Form.Row> 
                                                <center><Button style={{margin:'2%'}} onClick={()=>updateActive("2")}>Next</Button></center> 
                                            </div>
                                            <div style={{display:active==="2"?"block":"none"}}> 
                                            
                                                <Form.Group controlId="formBasicName">
                                                        <Form.Label>Billing Name:</Form.Label>
                                                        <Form.Control 
                                                        type="text" 
                                                        placeholder="Enter Billing Name" 
                                                        name="billingName"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.billingName}
                                                        disabled={!edit}
                                                        required/>
                                                    </Form.Group>
                                                <Row>
                                                <Col md={6} xs={12} >
                                                <Form.Group controlId="formBasicAddress">
                                                    <Form.Label>Billing Address:</Form.Label>
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="Billing Address" 
                                                    name="billingAddress"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.billingAddress}
                                                    disabled={!edit}
                                                    required/>
                                                </Form.Group>
                                                </Col>
                                                <Col md={6} xs={12} > 
                                                <Form.Group controlId="formBasicPincode">
                                                    <Form.Label>Pincode:</Form.Label>
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="Pincode" 
                                                    name="pin"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.pin}
                                                    disabled={!edit}
                                                    required/>
                                                </Form.Group>
                                                </Col>
                                                </Row>

                                                
                                                <Form.Group controlId="exampleForm.ControlSelect20">
                                                    <Form.Label>GST:</Form.Label>
                                                    <Form.Control as="select"  
                                                    name="gstInfo"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.gstInfo}
                                                    disabled={!edit}
                                                    required
                                                    >
                                                    <option value="">--select--</option>
                                                    <option value="Yes">Yes</option>
                                                    <option value="No">No</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            

                                                <Form.Group controlId="formBasicGstNumber" id="1">
                                                    <Form.Label>GST Number:</Form.Label>
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="GST Number" 
                                                    name="gstNumber"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.gstNumber}
                                                    disabled={!edit}
                                                    required/>
                                                </Form.Group>
                                                <Form.Group controlId="formBasicGstAddress" id="2">
                                                    <Form.Label>GST Address:</Form.Label>
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="GST Address" 
                                                    name="gstAddress"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.gstAddress}
                                                    disabled={!edit}
                                                    />
                                                </Form.Group>
                                
                                            <center>
                                                <Button onClick={()=>updateActive("1")} variant="secondary" style={{margin:'2%'}}>Back</Button>
                                                <Button onClick={()=>updateActive("3")} style={{margin:'2%'}}>Next</Button>
                                            </center>
                                        </div>
                                        <div style={{display:active==="3"?"block":"none"}}>
                                        
                                            <Form.Group controlId="exampleForm.ControlSelect1">
                                                    <Form.Label>Account Type:</Form.Label>
                                                    <Form.Control as="select"  
                                                    name="acctype"
                                                    onChange={handleChange}
                                                    value={values.acctype}
                                                    disabled={!edit}
                                                    required
                                                    >
                                                    <option value="Current">Current</option>
                                                    </Form.Control>
                                                </Form.Group>
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label>Account Holder's Name:</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Account Holder's Name" 
                                                name="accName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                disabled={!edit}
                                                value={values.accName}
                                                required/>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicAccNumber">
                                                <Form.Label>Account Number:</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Enter Account Number" 
                                                name="accNum"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.accNum}
                                                disabled={!edit}
                                                required/>
                                            </Form.Group>
                                            <Row> 
                                            <Col md={6} xs={12} > 
                                                <Form.Group controlId="formBasicPin">
                                                    <Form.Label>IFS Code:</Form.Label>
                                                    <Form.Control 
                                                    type="text" 
                                                    placeholder="Enter IFS Code" 
                                                    name="ifs"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.ifs}
                                                    disabled={!edit}
                                                    required/>
                                                </Form.Group>
                                            </Col>
                                            <Col md={6} xs={12} > 
                                            <Form.Group controlId="formBasicDoc">
                                                    <Form.Label>Upload Cancelled Cheque:</Form.Label>
                                                    <Form.Control 
                                                    type="file" 
                                                    name="cancelCheque"
                                                    onChange={(e)=>updateCancelCheque(e.target.files[0])}
                                                    disabled={!edit}
                                                    />
                                                </Form.Group>
                                            </Col>    
                                            </Row>
                                            <center>
                                            <Button onClick={()=>updateActive("2")} variant="secondary" style={{margin:'2%'}}>Back</Button>
                                            <Button onClick={()=>updateActive("4")} style={{margin:'2%'}}>Next</Button>
                                            </center>
                                        </div> 
                                        <div style={{display:active==="4"?"block":"none"}}>       
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label>Name:</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Enter Name" 
                                                name="authName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.authName}
                                                disabled={!edit}
                                                required/>
                                            </Form.Group>
                                            <Row> 
                                            <Col md={6} xs={12} > 
                                            <Form.Group controlId="formBasicpNumber">
                                                <Form.Label>Enter Phone Number:</Form.Label>
                                                <Form.Control 
                                                type="authPhone" 
                                                placeholder="Enter Phone Number" 
                                                name="accNum"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.authPhone}
                                                disabled={!edit}
                                                required/>
                                            </Form.Group>                                       
                                            </Col>
                                            </Row>
                                            <Form.Group controlId="formBasicEmail">
                                                    <Form.Label>Email ID:</Form.Label>
                                                    <Form.Control 
                                                    type="email" 
                                                    placeholder="Enter Email" 
                                                    name="authEmail"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.authEmail}
                                                    disabled={!edit}
                                                    required/>
                                                </Form.Group> 
                                                <Form.Group controlId="formBasicAddress">
                                                    <Form.Label>Add ID Proof (Aadhar / Driving Licence):</Form.Label>
                                                    <Form.Control 
                                                    type="file" 
                                                    name="idProofImage"
                                                    onChange={(e)=>updateIdProofImg(e.target.files[0])}
                                                    disabled={!edit}
                                                    />
                                                </Form.Group>
                                            
                                            <center>
                                            <Button onClick={()=>updateActive("3")} variant="secondary" style={{margin:'2% 2%'}}>
                                                Back
                                            </Button>
                                            <Button variant="primary" onClick={()=>updateActive("5")} style={{margin:'2% 2%'}}  >
                                                Next
                                            </Button>
                                            </center>
                                        </div>                                       
                                        <div style={{display:active==="5"?"block":"none"}}> 
                                            <Row> 
                                            <Col md={4} xs={12} > 
                                            <Form.Group controlId="formBasicFacebook">
                                                <Form.Label>Facebook:</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Enter Facebook ID" 
                                                name="facebook"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.facebook}
                                                disabled={!edit}
                                                />
                                            </Form.Group>
                                        
                                            </Col>
                                            <Col md={4} xs={12} > 
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label>Instagram:</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Enter Instagram ID" 
                                                name="insta"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.insta}
                                                disabled={!edit}
                                                />
                                            </Form.Group>
                                            </Col>  
                                            <Col md={4} xs={12} > 
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label>Whatsapp:</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Enter Whatsapp Contact" 
                                                name="whatsapp"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.whatsapp}
                                                disabled={!edit}
                                                required/>
                                            </Form.Group>
                                            </Col>  
                                            </Row>

                                            
                                            <Row> 
                                            <Col md={6} xs={12} > 
                                            <Form.Group controlId="formBasicYouTube">
                                                <Form.Label>YouTube:</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Enter YouTube" 
                                                name="youtube"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.youtube}
                                                disabled={!edit}
                                                />
                                            </Form.Group>
                                        
                                            </Col>
                                            <Col md={6} xs={12} > 
                                            <Form.Group controlId="formBasicTwitter">
                                                <Form.Label>Twitter:</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Enter Twitter ID" 
                                                name="twitter"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.twitter}
                                                disabled={!edit}
                                                />
                                            </Form.Group>
                                            </Col>  
                                            
                                            </Row>
                                            <center>
                                            <Button onClick={()=>updateActive("5")} variant="secondary" style={{margin:'2% 2%'}}>
                                                Back
                                            </Button>
                                            {
                                                edit? 
                                                    <Button variant="primary" style={{margin:'2% 2%'}} type="submit" disabled={isSubmitting}>
                                                        Submit
                                                    </Button>:null
                                            }
                                            </center>
                                            </div> 
                                        </Form>
                                    )
                                }
                    </Formik>
                </Container>
                </Tab.Container>
                </Tab>
                <Tab eventKey="financeLog" title="Finance" className={classes.Tabs} >
                        {/* <SupplierFinance/> */}
                         <SupplierFinance  props = {passingToChild} />
                </Tab>
            </Tabs>:<Spinner/>
        }
    </>    
    )
}
export default PartnerView;