import React, { useEffect, useState } from 'react'
import img from './assets/download.png';
import classes from './components/login.module.css';
import {Row,Col} from 'react-bootstrap';
import LoginComponent from './components/login';
import Otp from './opt';
import {checkAuthState} from './entities/account/action/action';
import { useDispatch, useSelector } from 'react-redux';

const AdminLogin = (props)=>{
  const isLoggedIn = useSelector(state=>state?.adminAccount?.isLoggedIn);
  const dispatch = useDispatch();
  const [type,updateType] = useState('1');
  const [key,updateKey] = useState({});
  const loginHandler = (values)=>{
    updateKey(values);
    updateType("4");
  }
  const otpHandler = ()=>{
    updateType("1");
  }
  const handleReset = ()=>{
    updateType("4");
  }
  useEffect(()=>{
    dispatch(checkAuthState());
  },[])
  useEffect(()=>{
    if(isLoggedIn){
      props.history.push("/adminPanel");
    }
  },[isLoggedIn])
  return (
    <div style={{backgroundImage:`url(${img})`}} className={classes.OuterBox}> 
      <div className={classes.Box}>
          {
            type==="4"?null:
            <Row style={{margin:'15px'}}>
                <Col xs={6} className={classes.Col} style={{backgroundColor:`${type==='1'?"pink":"rgb( 69,83,92)"}`,color:`${type==='1'?"#585858":"pink"}`}} onClick={()=>updateType('1')}>Super Admin</Col>
                {/* <Col xs={4} className={classes.Col} style={{backgroundColor:`${type==='2'?"pink":"rgb( 69,83,92)"}`,color:`${type==='2'?"#585858":"pink"}`}} onClick={()=>updateType('2')}>Admin</Col> */}
                <Col xs={6} className={classes.Col} style={{backgroundColor:`${type==='3'?"pink":"rgb( 69,83,92)"}`,color:`${type==='3'?"#585858":"pink"}`}} onClick={()=>updateType('3')}>Staff</Col>
            </Row> 
          }
            <div className={classes.Login}> 
              {
                type==='1'?
                <LoginComponent type="1" label="Enter Key:" placeholder="16 digit key" click={(values,otp)=>loginHandler(values,otp)} len="16"/>:
                // type==="2"?
                // <LoginComponent type="2" label="Enter Key:" placeholder="12 digit key" click={(values,otp)=>loginHandler(values,otp)} len="12"/>:
                type==="3"?
                <LoginComponent type="3" label="Enter Key:" placeholder="12 digit key" click={(values,otp)=>loginHandler(values,otp)} len="12"/>:
                <Otp data={key}  click={()=>otpHandler()} {...props} reset={()=>handleReset()}/>
              }
            </div>
      </div>
    </div>
  );
}

export default AdminLogin;
