import React, { useState,useEffect } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import {GetDeliveryPricingAmount,DeleteDeliveryPricingAmount} from '../../../entities/action/action';

const PricingList = (props)=>{
    const [dataSet,updateDataSet] = useState([]);
    useEffect(()=>{
        (async()=>{
            const response = await GetDeliveryPricingAmount();
            if(response!=null&&response.length>0){
                updateDataSet(response);
            } 
        })()
    },[])
    const handleDelete = async(id)=>{
        const res = await DeleteDeliveryPricingAmount(id);
        if(res.status==200){
            window.location.reload();
        }
    }
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Pricing For</th>
                        <th>Percentage</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataSet.length>0?dataSet.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.category}</td>
                                <td>{data.percentage}</td>
                                <td className={classes.CenterRow}>
                                    <Button 
                                        style={{margin:'auto 1%'}} 
                                        variant="danger"
                                        onClick={()=>handleDelete(data.id)}
                                        >Delete</Button>
                                    <Button 
                                        style={{margin:'auto 1%'}} 
                                        variant="warning"
                                        onClick={()=>handleDelete(data.id)}
                                        >Edit</Button>
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
        </Container>
    )
}
export default PricingList;