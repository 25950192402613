
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useSelector, useDispatch } from "react-redux";
const FinanceModal = (props) => {
  const [show, setShow] = useState(false);
  const [amountInput, updateAmountInput] = useState('');
  const [remarkInput, updateRemarkInput] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  
  console.log("data of user",props);
  console.log(" user detailed",props.props.detaildedData);
  console.log("user normal data",props.props.normaldata);
  console.log("user type",props.props.type);
  console.log(" user data",data);
  console.log(" user key",data.data.key);
  console.log(" user session",data.sessionId);
  console.log("user amount",amountInput);
  console.log("user msg",remarkInput);
  console.log("vendor id",props.props.normaldata.supplierid);
  console.log("vendor email id",props.props.normaldata.companyInformation.emailid);
  
  // const handleSubmit = () => {
  //   // send request to update backend
  //   handleClose();
  // };

  const handleSubmit = async () => {
    const payload = {
      amount: amountInput,
      loginId: "string",
      message: remarkInput,
      sessionKey: data.sessionId,
      vendorEmailId: props.props.normaldata.companyInformation.emailid,
      vendorId: props.props.normaldata.supplierid
    };

    let apiUrl = '';
    if (props.type === 'topup') {
      apiUrl = 'https://api.zupptravel.com/Vendor/finance/topup';
    } else if (props.type === 'credit') {
      apiUrl = 'https://api.zupptravel.com/Vendor/finance/credit';
    } else if (props.type === 'debit') {
      apiUrl = 'https://api.zupptravel.com/Vendor/finance/debittotalamount';
    }

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      // Handle the response as needed
      console.log(response);
    } catch (error) {
      // Handle errors
      console.error('Error submitting data', error);
    }
  };
//  vendor
// https://api.zupptravel.com/Vendor/finance/topup

// {
//   "amount": 0,
//   "loginId": "string",
//   "message": "string",
//   "sessionKey": "string",
//   "vendorEmailId": "string",
//   "vendorId": 0
// }

// credit
// https://api.zupptravel.com/Vendor/finance/credit

// {
//   "amount": 0,
//   "loginId": "string",
//   "message": "string",
//   "sessionKey": "string",
//   "vendorEmailId": "string",
//   "vendorId": 0
// }


// debit

// https://api.zupptravel.com/Vendor/finance/debittotalamount

// {
//   "amount": 0,
//   "loginId": "string",
//   "message": "string",
//   "sessionKey": "string",
//   "vendorEmailId": "string",
//   "vendorId": 0
// }
  return (
    <>
      <Button
        variant="info"
        style={{ width: '100%', marginTop: '0px', marginBottom: '5px' }}
        onClick={handleShow}
      >
        {props.props.type}
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{props.props.type} Money</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formBasicAmount">
              <Form.Label>{props.props.type} Amount</Form.Label>
              <Form.Control
                type="number"
                name="amountInput"
                min={0}
                value={amountInput}
                onChange={(e) => updateAmountInput(e.target.value)}
                placeholder="Enter Amount"
              />
            </Form.Group>
            <Form.Group controlId="formBasicRemarks">
              <Form.Label>Remarks</Form.Label>
              <Form.Control
                type="text"
                name="remarkInput"
                value={remarkInput}
                onChange={(e) => updateRemarkInput(e.target.value)}
                placeholder="Enter Remarks"
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default FinanceModal;


