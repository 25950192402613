import React, { useEffect, useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import {
  AddPackageCategory,
  AddPackageStyle,
} from "../../../entities/action/action";
import PackageCategoryAndStyleList from "./packageCategoryAndStyleList";

const AddPackageCategoryAndStyleForm = (props) => {
  const userData = useSelector((state) => state.adminAccount.apiResponse);
  console.log(userData);
  const [input, SetInput] = useState("");
  const [data, updateData] = useState([]);
  useEffect(() => {
    (async () => {
      if (props.categoryType == "Category") {
        const myresponse = await fetch(
          "https://api.zupptravel.com/getHolidayCategory",
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              emailId: userData.data.key,
              password: "string",
              phoneno: "string",
              sessionKey: userData.sessionId,
            }),
          }
        );
        const myjson = await myresponse.json();
        console.log("MYJSON", myjson);
        if (myjson.successful) {
          updateData(myjson.categoryList);
        }
      } else {
        const myresponse = await fetch(
          "https://api.zupptravel.com/getHolidayStyle",
          {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              emailId: userData.data.key,
              password: "string",
              phoneno: "string",
              sessionKey: userData.sessionId,
            }),
          }
        );
        const myjson = await myresponse.json();
        console.log("MYJSON", myjson);
        if (myjson.successful) {
          updateData(myjson.styleList);
        }
      }
    })();
  }, []);
  const handleSubmit = async () => {
    let response;
    if (props.categoryType == "Category") {
      const newData = {
        categoryId: 0,
        categoryName: input,
        emailId: userData.data.key,
        sessionKey: userData.sessionId,
      };

      response = await AddPackageCategory(newData);
      console.log(response);
      if (response.successful) {
        alert("Successfully added package category!");
        window.location.reload();
      }
    } else {
      const newData = {
        emailId: userData.data.key,
        sessionKey: userData.sessionId,
        styleId: 0,
        styleName: input,
      };

      response = await AddPackageStyle(newData);
      console.log(response);
      if (response.successful) {
        alert("Successfully added package style!");
        window.location.reload();
      }
    }
  };
  return (
    <div>
      <Form.Group controlId="formBasicPromo" as={Row}>
        <Form.Label column sm="3">
          Package {`${props.categoryType}`}:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            placeholder={`Enter ${props.categoryType}`}
            name="category"
            onChange={(e) => SetInput(e.target.value)}
            value={input}
            required
          />
        </Col>
      </Form.Group>
      <center>
        <Button onClick={handleSubmit} style={{ margin: "2%" }}>
          Save Package {`${props.categoryType}`}
        </Button>
      </center>
      <PackageCategoryAndStyleList type={props.categoryType} data={data} />
    </div>
  );
};
export default AddPackageCategoryAndStyleForm;
