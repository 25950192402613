import React, { useState, useEffect } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Field, FieldArray, Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import MultipleSelect from "../../multiSelect";
import DraftEditor from "../../draftEditor";
import { DropzoneArea } from "material-ui-dropzone";
import {
  GetHolidayCategory,
  GetHolidayStyle,
} from "../../../entities/action/action";
import { useSelector } from "react-redux";
import Image from "../../../utilities/imageTestApi/image";

const AddPackage = (props) => {
  const userData = useSelector((state) => state.adminAccount.apiResponse);
  // console.log("arr",myarr)
  const [select, updateSelect] = useState("");
  const [prep, updatePrep] = useState("");
  const [tAndc, updateTAndC] = useState("");
  const [overview, updateOverview] = useState("");
  const [files, updateFiles] = useState([]);
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([1]);
  const [currentPoints1, updateCurrentPoints1] = useState(1);
  const [arrPoints1, updateArrPoints1] = useState([1]);

  const [category, updateCategory] = useState([]);
  const [style, updateStyle] = useState([]);

  useEffect(() => {
    (async () => {
      const newData = {
        password: "string",
        phoneno: "string",

        emailId: userData.data.key,
        sessionKey: userData.sessionId,
      };
      let arr = await GetHolidayStyle(newData);
      //   console.log(arr);
      if (arr.successful) {
        updateStyle(arr.styleList);
      }
    })();
  }, []);

  console.log(style);

  useEffect(() => {
    (async () => {
      const newData = {
        password: "string",
        phoneno: "string",

        emailId: userData.data.key,
        sessionKey: userData.sessionId,
      };
      let arr = await GetHolidayCategory(newData);
      //   console.log(arr);
      if (arr.successful) {
        updateCategory(arr.categoryList);
      }
    })();
  }, []);

  console.log(category);

  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    } else {
      const newCurrentPoint1 = currentPoints1 + 1;
      updateCurrentPoints1(newCurrentPoint1);
      updateArrPoints1([...arrPoints1, newCurrentPoint1]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    } else if (type === 2 && currentPoints1 > 1) {
      const newCurrentPoint1 = currentPoints1 - 1;
      updateCurrentPoints1(newCurrentPoint1);
      let arr = [...arrPoints1];
      arr.pop();
      updateArrPoints1(arr);
    }
  };
  const handleImages = (inpFile) => {
    updateFiles(inpFile);
  };
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{ cancellation: [{ cancellation: "", charges: "" }] }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          setSubmitting(true);
          console.log(values);
          const charges = [];
          const cancellation = [];
          Object.entries(values).map(([key, value]) => {
            console.log(key, value);
            if (key.includes("charges") && typeof value === "string") {
              charges.push(value);
            } else if (
              key.includes("cancellation") &&
              typeof value === "string"
            ) {
              cancellation.push(value);
            }
          });

          const cancellationCharges = [];

          for (let i = 0; i < charges.length; i++) {
            cancellationCharges.push({
              cancellation: cancellation[i],
              charges: charges[i],
            });
          }

          console.log(charges, cancellation, "checkkkkk");
          //   console.log(arrPoints1);
          const response = await Image(files[0]);
          console.log(response);
          const newData = {
            cancellation: cancellationCharges,
            category: values.category,
            code: values.holidayCode,
            emailId: "string",
            endDate: values.endDate,
            endLocation: values.endLocation,
            holidayId: 0,
            images: response,
            metaDescription: values.metaDescription,
            // metaTitle: values.metaTitle,
            name: values.holidayName,
            offer: values.holidayOffer,
            oneLiner: values.holidayOneLiner,
            overview: overview,
            preperation: prep,
            route: values.holidayRoute,
            sessionKey: "string",
            startDate: values.startDate,
            startLocation: values.startLocation,
            style: select,
            termsAndCondition: tAndc,
            theme: values.holidayTheme,
          };
          const res = await fetch("https://api.zupptravel.com/addHoliday", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newData),
          });

          console.log("rrrrrrrrrrrr", newData);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("Added  Package!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicAddress" as={Row}>
              <Form.Label column sm="3">
                Holiday Category:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  placeholder="Holiday Category"
                  name="category"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.category}
                >
                  <option value="">--select--</option>
                  {category.map((item, key) => {
                    return (
                      <>
                        <option value={item.categoryName}>
                          {item.categoryName}
                        </option>
                      </>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicFor" as={Row}>
              <Form.Label column sm="3">
                Holiday For:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  placeholder="Holiday For"
                  name="holidayFor"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.holidayFor}
                >
                  <option value="">--select--</option>
                  <option value="Indian">Indian</option>
                  <option value="Foreign">Foreign</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicHolidayName" as={Row}>
              <Form.Label column sm="3">
                Holiday Style:
              </Form.Label>
              <Col sm="9">
                <MultipleSelect
                  type="14"
                  styleName={style}
                  selectedValue={(selected) => updateSelect(selected)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicHolidayName" as={Row}>
              <Form.Label column sm="3">
                Holiday Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Holiday Name"
                  name="holidayName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.holidayName}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicHolidayTheme" as={Row}>
              <Form.Label column sm="3">
                Holiday Theme:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Holiday Theme"
                  name="holidayTheme"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.holidayTheme}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicHolidayTheme" as={Row}>
              <Form.Label column sm="3">
                Holiday Route:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Holiday Route"
                  name="holidayRoute"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.holidayRoute}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicHolidayId" as={Row}>
              <Form.Label column sm="3">
                Holiday Code:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Holiday Code"
                  name="holidayCode"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.holidayCode}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicHolidayOneliner" as={Row}>
              <Form.Label column sm="3">
                Holiday One-liner:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Holiday One-liner"
                  name="holidayOneLiner"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.holidayOneLiner}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicHolidayOffer" as={Row}>
              <Form.Label column sm="3">
                Offer:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Holiday Offer"
                  name="holidayOffer"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.holidayOffer}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicStartLocation" as={Row}>
              <Form.Label column sm="3">
                Start Location:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Start Location"
                  name="startLocation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.startLocation}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicEndLocation" as={Row}>
              <Form.Label column sm="3">
                End Location:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter End Location"
                  name="endLocation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.endLocation}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicStartDate" as={Row}>
              <Form.Label column sm="3">
                Start Date:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="date"
                  placeholder="Enter Start Date"
                  name="startDate"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.startDate}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicEndLocation" as={Row}>
              <Form.Label column sm="3">
                End Date:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="date"
                  placeholder="Enter End Date"
                  name="endDate"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.endDate}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicMetaTitle" as={Row}>
              <Form.Label column sm="3">
                Meta Title:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Meta Title"
                  name="metaTitle"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.metaTitle}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasic" as={Row}>
              <Form.Label column sm="3">
                Meta Description:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Meta Description"
                  name="metaDescription"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.metaDescription}
                />
              </Col>
            </Form.Group>

            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Cancellation {d} :
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Cancellation `}
                        name={`cancellation${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cancellation[d]?.cancellation}
                        required
                      />
                    </Col>
                    <Form.Label column sm="3">
                      Charge:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Charges `}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`charges${d}`}
                        value={values.cancellation[d]?.charges}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>

              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div>

            <Form.Group controlId="formBasicChild" as={Row}>
              <Form.Label column sm="3">
                Images:
              </Form.Label>
              <Col sm="9">
                <DropzoneArea onChange={handleImages} filesLimit={1} />
              </Col>
            </Form.Group>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Holiday Overview:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateOverview(text)}
                  text={overview}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Tour Preperation:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor change={(text) => updatePrep(text)} text={prep} />
              </Col>
            </Row>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Condition:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateTAndC(text)}
                  text={tAndc}
                />
              </Col>
            </Row>
            <center>
              <Button type="submit" variant="success">
                Save Package
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddPackage;
