import React , {Fragment} from 'react'
import {Col,Button,Form, Row, Modal} from 'react-bootstrap';
import { Formik } from 'formik';
import {useSelector} from 'react-redux';
import {AddDeductEarning} from '../../entities/action/action';

export default function AddAmount(props){
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    return(
       <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Amount
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Formik
            initialValues={{eWallet:0,credits:0,cashback:0,playMoney:0,amountType:''}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    setSubmitting(true);
                    let amount;
                    if(values.amountType==="E-wallet"){
                        amount = values.eWallet;
                    }else if(values.amountType==="Play Money"){
                        amount = values.playMoney;
                    }else{
                        amount = values.cashback;
                    }
                    const newData = {
                        "amount": amount,
                        "credit": true,
                        "custEmailId": props.data.emailId,
                        "debit": false,
                        "emailId": userData.data.key,
                        "message": values.remarks,
                        "sessionKey": userData.sessionId,
                        "type": values.amountType,
                        "userId": props.data.id
                    }
                    const response = await AddDeductEarning(newData,values.amountType);
                    if(response.successful){
                        alert("Successfully Updated Amount");
                        window.location.reload();
                    }
                    setSubmitting(false);
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                    <Form.Group controlId="formBasicAppliesTo" as={Row}>
                                        <Form.Label column sm="2">Amount Type:</Form.Label>
                                        <Col> 
                                            <Form.Control
                                                as="select" 
                                                placeholder="Amount " 
                                                name="amountType"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.amountType}
                                                >
                                                <option value="" label="--select--" />
                                                <option value="E-wallet">E-wallet</option>
                                               
                                                <option value="Play Money">Play Money</option>
                                                <option value="Cashback">Cashback</option>
                                                </Form.Control>
                                        </Col>        
                                    </Form.Group>
                                    {
                                        values.amountType==="E-wallet"?
                                        <Form.Group controlId="formBasicAmount">
                                            <Form.Label>Add EWallet Amount:</Form.Label>
                                            <Form.Control 
                                            type="Number"
                                            
                                            step="any" 
                                            placeholder="Add EWallet Amount" 
                                            name="eWallet"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.eWallet}
                                            />
                                        </Form.Group>:
                                       
                                        values.amountType==="Play Money"?
                                        <Form.Group controlId="formBasicPlayMoney">
                                            <Form.Label>Add Play Money:</Form.Label>
                                            <Form.Control 
                                            type="number"
                                         
                                            step="any" 
                                            placeholder="Add Play Money:" 
                                            name="playMoney"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.playMoney}
                                            />
                                        </Form.Group>:
                                        values.amountType==="Cashback"?
                                        <Form.Group controlId="formBasicPlayMoney">
                                            <Form.Label>Add Cashback:</Form.Label>
                                            <Form.Control 
                                            type="number"
                                           
                                            step="any" 
                                            placeholder="Add Cashback:" 
                                            name="cashback"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.cashback}
                                            />
                                        </Form.Group>:null
                                    } 
                                    {
                                        values.amountType.length>0?
                                        <Fragment>
                                            <Form.Group controlId="formBasicPlayMoney">
                                            <Form.Label>Remarks:</Form.Label>
                                                <Form.Control 
                                                type="text" 
                                                placeholder="Add Remarks" 
                                                name="remarks"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.remarks}
                                                />
                                            </Form.Group>
                                            <center>
                                                <Button variant="success" style={{margin:'2%'}} type="submit" disabled={isSubmitting}>
                                                    Submit
                                                </Button>
                                                <Button onClick={props.onHide} style={{margin:'2%'}}>Close</Button>
                                            </center>
                                        </Fragment>:null
                                    }
                                </Form>
                            )
                            }
            </Formik>
        </Modal.Body>
      </Modal>
    );
}



