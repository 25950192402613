import React, { useState, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import classesX from "../Dashboard/dashboard.module.css";
import PartnerList from "./supplierList";
import PartnerView from "./supplierView";
import AdminNavbar from "../navbar/adminNavbar";
import AddVendor from "./addSupplier";

const PartnerManagement = (props) => {
  const [active, updateActive] = useState("1");
  const [data1, setdata1] = useState({});
  const items = [
    { id: "1", type: "Add Vendor" },
    { id: "2", type: "Vendors List" },
  ];

  return (
    <Fragment>
      <AdminNavbar />
      <h1 className={classesX.Heading}>Vendor Management</h1>
      <Container fluid>
        <Row>
          <Col lg={12} md={12} className={classes.Response2}>
            <div style={{ height: "100%" ,width: '90%'}}>
              <ul className={classes.Ul}style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 ,height:"10vh",}}>
                {items.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => updateActive(item.id)}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                      textAlign: 'center',
                      margin: '0 1px', // Adjust the margin as needed
                      padding:" 2% 0",
                      color: 'white',
                      cursor: 'pointer',
                      width:"50%",
                      height:"100%"
                  }}
                  >
                    {item.type}
                  </li>
                ))}
                {active === "3" ? (
                  <li
                    onClick={() => updateActive("2")}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${
                        active === "3" ? "steelblue" : "#585858"
                      }`,
                      textAlign: "center",
                    }}
                  >
                    Back
                  </li>
                ) : null}
              </ul>
            </div>
          </Col>
          <Col lg={12} md={12} className={classes.Response}>
            <div style={{ height: "100%" ,margin:"2vh 9vw"}}>
              {active === "1" ? (
                <AddVendor />
              ) : active === "2" ? (
                <PartnerList
                  mydata={(e) => setdata1(e)}
                  click={() => updateActive("3")}
                />
              ) : active === "3" ? (
                <PartnerView data={data1} />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PartnerManagement;
