import React, { useEffect, useState } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import Page from '../../pagination/pagination';

const CharterPlaneViewPropertyList = (props)=>{
    const [dataSet,updateDataSet] = useState([1]);
    const [count,updateCount] = useState(0)
    const postPerPage = 20;
    const [currentPage,updateCurrentPage] = useState(1);
    const indexOfLast = currentPage*postPerPage;
    const indexOfFirst = indexOfLast - postPerPage;
    const currentPosts = dataSet.slice(indexOfFirst,indexOfLast);
    const handleChangePage = (event,value)=>{
        updateCurrentPage(value)
    }
    useEffect(()=>{
        updateCount(Math.ceil(dataSet.length/postPerPage))
    },[dataSet])
     
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Charter Plane ID</th>
                        <th>Charter Plane Type</th>
                        <th>Phone Number</th>
                        <th>Charter Plane Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentPosts.length>0?currentPosts.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.charterPlaneId}</td>
                                <td>{data.charterPlaneType}</td>
                                <td>{data.phoneNumber}</td>
                                <td>{data.charterPlaneName}</td>
                                <td className={classes.CenterRow}>
                                    <Button style={{margin:'auto 1%'}} variant="info" onClick={()=>props.click()}>Edit</Button>
                                    {
                                        data.status==="Active"?
                                        <Button style={{margin:'auto 1%'}} variant="success">Active</Button>:
                                        <Button style={{margin:'auto 1%'}} variant="danger">Inactive</Button>
                                    }
                                    <Button variant="warning" onClick={()=>props.setPrice()}>Charter Plane Management</Button>
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
            <div style={{display:'flex',justifyContent:'center',margin:'15px 0'}}>
                <Page count={count} change={handleChangePage} page={currentPage}/> 
            </div>
        </Container>
    )
}
export default CharterPlaneViewPropertyList;