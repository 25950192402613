import React, { useState } from 'react';
import {Col, Container, Row, Form} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import AddFlightMarkup from './flight';
import AddHotelMarkup from './hotel';
import CommonMarkup from './commonMarkup';

const AddMarkup = (props)=>{
    const [active,updateActive] = useState("1");
    const [markupIn,updateMarkupIn] = useState("ONLINE");
 
    return(
        <Container fluid className={classes.Box}>
            <Form.Group controlId="formBasicMarkupIn" as={Row}>
                <Form.Label column sm="2">Markup In:</Form.Label>
                <Col sm="10"> 
                    <Form.Control 
                                as="select" 
                                placeholder="Markup In" 
                                name="markupIn"
                                onChange={(e)=>updateMarkupIn(e.target.value)}
                                value={markupIn}
                                required>
                            
                            <option value="ONLINE">Online</option>
                            <option value="OFFLINE">Offline</option>
                    </Form.Control>
                </Col>
            </Form.Group>
            <Row style={{margin:'0 0 2% 0',textAlign:'center',cursor:'pointer'}}>
                <Col className={classes.Col} 
                style={{backgroundColor:`${active==='1'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("1")}>
                    Flight
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='2'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("2")}>
                    Hotel
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='3'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("3")}>
                    Bus
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='4'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("4")}>
                    Cab
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='5'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("5")}>
                    Insurance
                </Col>
            </Row>
            {
                active==="1"?<AddFlightMarkup markupIn={markupIn}/>:
                active==="2"?<AddHotelMarkup markupIn={markupIn} type="Hotel"/>:
                active==="3"?<CommonMarkup markupIn={markupIn} type="Bus"/>:
                active==="4"?<CommonMarkup markupIn={markupIn} type="Cab"/>:
                active==="5"?<CommonMarkup markupIn={markupIn} type="Insurance"/>:null
            }
        </Container>        
    )
}        
export default AddMarkup;