import React, { Fragment,useState } from 'react';
import {Modal,Form,Button} from 'react-bootstrap';
import {Formik} from 'formik';
import {useSelector} from 'react-redux';

const MetaEditHandler = (props)=>{
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [success,updateSuccess] = useState(false);

    return(
        <Fragment>
            <Button variant="success" onClick={handleShow}>Edit</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Meta Data</Modal.Title>
                </Modal.Header>
                {
                    success?
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>:
                <Formik
                initialValues={{description:props.data.description,title:props.data.metaTitle,keyword:props.data.keyword,language:props.data.language}}
                onSubmit={
                    async(values,{setSubmitting,resetForm})=>{
                        const newData = {
                            "description": values.description,
                            "keyword": values.keyword,
                            "language": values.language,
                            "metaTitle": values.title,
                          }
                        const token = localStorage.getItem('token');
                        const authHeader = `Bearer ${token}`
                        const myresponse = await fetch("https://api.zupptravel.com/api/v1/meta/addUpdateMetaDetails", {
                            method: "post",
                            headers: {
                                "Content-Type": "application/json",
                                'Authorization': authHeader
                            },
                            body: JSON.stringify(newData),
                            });
                            const myjson = await myresponse.json();
                            if(myjson.success){
                                updateSuccess(true);
                                setTimeout(()=>{
                                    updateSuccess(false)
                                    window.location.reload();
                                },5000)
                            }
                    }
                }
                > 
                    {({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                        <Form onSubmit={handleSubmit}>
                            <Modal.Body>
                            <Form.Group>
                                <Form.Label>Meta Title</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Title"
                                name="title" 
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.title}
                                required
                                />
                                <Form.Text className="text-muted">
                                {errors.title  && touched.title }
                                </Form.Text>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Description</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Description"
                                name="description" 
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.description}
                                required
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Meta Keyword</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Keyword"
                                name="keyword" 
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.keyword}
                                required
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Language</Form.Label>
                                <Form.Control 
                                type="text" 
                                placeholder="Language"
                                name="language" 
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.language}
                                required
                                />
                            </Form.Group>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button size="lg" variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button size="lg" type="submit" disabled={isSubmitting}>
                                    Save
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            }
            </Modal>
        </Fragment>
    )
}
export default MetaEditHandler;