import React, { useState, useEffect } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import { Pincode } from "../../entities/action/action";
import { useSelector, useDispatch } from "react-redux";
const AddLocation = (props) => {

  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [pin, setpin] = useState("");
  
  const cityState = useSelector((state) => state.account.cityState);
  const dispatch = useDispatch();

  const [pinError, setPinError] = useState('');

  const handleChange1 = (event) => {
    const { name, value } = event.target;

    // Validate pincode format using a regular expression
    if (name === 'pincode') {
      if (/^[0-9]{6}$/.test(value)) {
        setpin(value);
        setPinError(''); // Clear any previous error
      } else {
        setPinError('Pincode must be a 6-digit number');
      }
    }
  };
  const myfunc = (e) => {
    if (e.target?.value.length === 6) {
      dispatch(Pincode(e.target?.value));
    } else {
      setState("");
      setCity("");
    }
  };
 

  useEffect(() => {
    if (cityState === null) {
        setState('');
        setCity('');
    } else {
        setState(cityState?.state);
        setCity(cityState?.city);
    }
  }, [cityState]);

 
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
         
          const newData = {
            blockTehsilTaluka: values.block,
            city: city,
            country: values.country,
            pincode: pin,
            state: state,
          };
          const token = localStorage.getItem('token');
          const authHeader = `Bearer ${token}`

          const myresponse = await fetch(
            "https://api.zupptravel.com/api/v1/location/addLocation",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                'Authorization': authHeader,
              },
              body: JSON.stringify(newData),
            }
          );

          const json = await myresponse.json();
          console.log(json);
          if (json.success) {
            alert("Location Management added successfully!");
            props.click();
            // window.location.reload();
          } else {
            alert("Error! Please try again later!");
          }
        }}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicTheme" as={Row}>
      <Form.Label column sm="3">
        Pincode:
      </Form.Label>
      <Col sm="9">
        <Form.Control
          type="number"
          placeholder="Enter Pincode"
          name="pincode"
          onChange={handleChange1}
          onBlur={myfunc}
          value={values.pincode}
          isInvalid={!!pinError}
          required
        />
        <Form.Control.Feedback type="invalid">{pinError}</Form.Control.Feedback>
      </Col>
    </Form.Group>

            <Form.Group controlId="formBasicDepositeType" as={Row}>
              <Form.Label column sm="3">
                City :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  readOnly
                  placeholder="Enter pincode to get city"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={city}
                  required
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicDepositeType" as={Row}>
              <Form.Label column sm="3">
                State :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  readOnly
                  placeholder="Enter pincode to get state"
                  name="depositeType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={state}
                  required
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicDepositeType" as={Row}>
              <Form.Label column sm="3">
                Block/Tehsil/Taluka :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Block/Tehsil/Taluka"
                  name="block"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.block}
                  required
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicDepositeType" as={Row}>
              <Form.Label column sm="3">
                Country :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter country"
                  name="country"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.country}
                  required
                ></Form.Control>
              </Col>
            </Form.Group>

            <center>
                <Button type="submit" variant="success">
                  Save Location
                </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddLocation;
