import React, { useState, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import AccommodationList from "./accommodationList";
import AccommodationView from "./accommodationView";
import AddAccommodationType from "./addAccomodationType";
import PropertyDetails from "./propertyDetails";
import MealsAndRoomPrice from "./mealAndRoomPrice";
import RoomData from "./roomData";
import MealsAmenitiesData from "./MealsAmenitiesData"
import AddAmenities from "./addAmenities";
import AddMealType from "./addMealType";
import AddRoomSet from "./addRoomSet";
import ParentMealPrice from "./ParentMealPrice";
import ParentRoomPrice from "./ParentRoomPrice";
import RoomCategory from "./roomCategory";
import RoomInventoryStatus from "./RoomInventoryStatus";
import SetPrice from "./setPrice";
import RoomSetAndList from "./RoomSetAndList";

const AccVendor = (props) => {
  const [active, updateActive] = useState("1");
  const items = [
    { id: "1", type: "Property Details" },
    { id: "7", type: "Add Room Category/Name" },
    { id: "4", type: "Add Room Set" },
    { id: "8", type: "Meal/Room Price" },
    { id: "6", type: "Rooms Availability" },
    { id: "3", type: "Meals & Amenities Data" },
  ];

  // const items = [
  //   { id: "1", type: "Add Accommodation" },
  //   { id: "2", type: "Accommodation List" },
  //   { id: "7", type: "Add Room Category/Name" },
  //   { id: "8", type: "Set Meal Price" },
  //   { id: "9", type: "Set Room Price" },
  //   { id: "6", type: "Room Management" },
  //   { id: "12", type: "Room Inventory Status" },
  //   { id: "3", type: "Add Meal Type" },
  //   { id: "4", type: "Add Room Set" },
  //   { id: "5", type: "Add Amenities & Facilities" },
  // ];
  const items1 = [
    { id: "1", type: "Property Details" },
    { id: "7", type: "Add Room Category/Name" },
    { id: "4", type: "Add Room Set" },
    { id: "8", type: "Meal/Room Price" },
    { id: "6", type: "Room Data" },
    { id: "12", type: "Meal & Amenities Data" },

  ];

  return (
    <Fragment>
      <Container fluid style={{ paddingLeft: "10px" }}>
        <Row>
          <Col lg={2} md={12} className={classes.Response2}>
            <div style={{ height: "100%" }}>
              <ul className={classes.Ul}>
                {items.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => updateActive(item.id)}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${
                        active === item.id ? "steelblue" : "#585858"
                      }`,
                      textAlign: "center",
                    }}
                  >
                    {item.type}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col lg={10} md={12} className={classes.Response}>
            <div style={{ height: "100%",marginBottom:"2vh", }}>
              {active === "1" ? (
                // <AddAccommodationType click={() => updateActive("2")} />
                <PropertyDetails click={() => updateActive("2")} />
              ) : active === "2" ? (
                <AccommodationView />
              ) : active === "6" ? (
                <RoomData />
              ) : active === "3" ? (
                <MealsAmenitiesData />
              ) : active === "4" ? (
                <RoomSetAndList />
              ) : active === "5" ? (
                <AddAmenities />
              ) : active === "7" ? (
                <RoomCategory />
              ) : active === "8" ? (
                <MealsAndRoomPrice />
              ) : active === "9" ? (
                <ParentRoomPrice />
              ) : active === "12" ? (
                <RoomInventoryStatus />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AccVendor;
