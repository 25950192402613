import React, { Fragment,useState,useEffect } from 'react';
import { Checkbox } from '@material-ui/core';



function Filter(props) {
  
  const [check,updateCheck]=useState([]);
  const category = [
      {id:1,type:'Monday'},
      {id:2,type:'Tuesday'},
      {id:3,type:'Wednesday'},
      {id:4,type:'Thursday'},
      {id:5,type:'Friday'},
      {id:6,type:'Saturday'},
      {id:7,type:'Sunday'}
  ]
  const checkHandler = (value)=>{
      const isThere=check.indexOf(value);
      const newChecked=[...check];
      if(isThere===-1){
          newChecked.push(value);
      }else{
          newChecked.splice(isThere,1);
      }
      updateCheck(newChecked);
  }
  useEffect(()=>{
    props.handleFilter(check);
  },[check])
  useEffect(()=>{
    if(props.type=="2"){
      updateCheck(props.data);
    }
  },[])
  return (
    <div>
        {   
            category.map((type,index)=>(
            <Fragment key={index}>
            <Checkbox 
            checked= {check.indexOf(type.type)===-1 ? false : true}
            type="checkbox" 
            disabled={props.disable}
            onChange={()=>checkHandler(type.type)}/>
            <span>{type.type}</span>
            </Fragment>  
            )) 
        }
    </div>
  );
}

export default Filter;