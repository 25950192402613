import React, { useState } from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import AirportMarkupList from './airportMarkupList';
import CommonMarkupList from './commonList';

const MarkupList = (props)=>{
    const [active,updateActive] = useState("1");
 
    return(
        <Container fluid className={classes.Box}>
            <Row style={{margin:'0 0 2% 0',textAlign:'center',cursor:'pointer'}}>
                <Col className={classes.Col} 
                style={{backgroundColor:`${active==='1'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("1")}>
                    Flight
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='2'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("2")}>
                    Hotel
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='3'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("3")}>
                    Bus
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='4'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("4")}>
                    Cab
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='5'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("5")}>
                    Insurance
                </Col>
            </Row>
           
             {
                active==="1"?<AirportMarkupList />:
                active==="2"?<CommonMarkupList type="Hotel"/>:
                active==="3"?<CommonMarkupList type="Bus"/>:
                active==="4"?<CommonMarkupList type="Cab"/>:
                active==="5"?<CommonMarkupList type="Insurance"/>:null
            }
        </Container>        
    )
}        
export default MarkupList;