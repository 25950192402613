import React, { useState,Fragment } from 'react';
import {Form,Button} from 'react-bootstrap';
import classes from './WebsiteManagement/websiteManagement.module.css';
import {useSelector} from 'react-redux';
import { SmsInformation } from '../entities/action/action';

const Sms = (props)=>{
    const [to,updateTo] = useState("Customer");
    const [message,updateMessage] = useState('');
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    const handleCancel = ()=>{
        updateMessage('');
        updateTo('EveryOne');
    }
    const handleSubmit = async()=>{
        const newdata = {
            "emailId":  userData.data.key,
            "message": message,
            "sessionKey":  userData.sessionId,
            "to": to
        }
        const response = await SmsInformation(newdata);
        console.log(response)
        if(response.successful){

            alert("Successfully Sent SMS");
            window.location.reload();
        }else{
            alert("Something went wrong!!");
        }
    }
    return(
        <Fragment>
            <div className={classes.Box}>
               <Form.Group controlId="exampleForm.ControlSelect1">
                    <Form.Label>To : </Form.Label>
                    <Form.Control as="select"
                        onChange={(e)=>updateTo(e.target.value)}
                        value={to}
                    >
                    <option value="">--select--</option>
                    <option>Customer</option>
                    <option>Agents</option>
                    <option>Accommodation Vendor</option>
                    <option>Tour Guide</option>
                    <option>Bus Vendor</option>
                    <option>Charter Plane Vendor</option>
                    <option>Cab Vendor</option>
                    <option>Rental Vehicle Vendor</option>
                    <option>Activities Vendor</option>
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Message : </Form.Label>
                    <Form.Control value={message} placeholder="Enter Message To Send" as="textarea" rows="3" onChange={(e)=>updateMessage(e.target.value)}/>
                </Form.Group>
                <center> 
                    <Button onClick={handleSubmit} style={{margin:'0 2%'}}>Send</Button>
                    <Button onClick={handleCancel} variant="secondary">Cancel</Button>
                </center>
            </div>
        </Fragment>
    )
}
export default Sms;