import React, { useEffect, useState } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import Page from '../../pagination/pagination';

const ActivityList = (props)=>{
    const [dataSet,updateDataSet] = useState([0]);
    const [count,updateCount] = useState(1)
    const postPerPage = 20;
    const [currentPage,updateCurrentPage] = useState(1);
    const indexOfLast = currentPage*postPerPage;
    const indexOfFirst = indexOfLast - postPerPage;
    const currentPosts = dataSet.slice(indexOfFirst,indexOfLast);
    const handleChangePage = (event,value)=>{
        updateCurrentPage(value)
    }
    useEffect(()=>{
        updateCount(Math.ceil(dataSet.length/postPerPage))
    },[dataSet])
    useEffect(()=>{
        (async()=>{
            const newdata = {
                "emailId": "string",
                "password": "string",
                "phoneno": "string",
                "sessionKey": "string"
              }
            const res = await fetch('https://api.zupptravel.com/getVendorActivities',{
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body:JSON.stringify(newdata)
            })
            const json = await res.json();
            if(json.successful){
                updateDataSet(json.vendorActivitiess);
            }else{
                alert("Error! Please try again later");
            }
        })()
    },[])
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Vendor ID</th>
                        <th>Activity ID</th>
                        <th>Activity Type</th>
                        <th>Activity Category</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentPosts.length>0?currentPosts.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.vendorId}</td>
                                <td>{data.id}</td>
                                <td>{data.activityType}</td>
                                <td>{data.category}</td>
                                <td className={classes.CenterRow}>
                                    <Button style={{marginRight:'5px'}} variant="danger">Delete</Button>
                                    <Button onClick={()=>props.click(data)} style={{marginRight:'5px'}}>View</Button>
                                    {
                                        data.status?<Button variant="success">Active</Button>:
                                        <Button variant="warning">Inactive</Button>
                                    }
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
            <div style={{display:'flex',justifyContent:'center',margin:'15px 0'}}>
                <Page count={count} change={handleChangePage} page={currentPage}/> 
            </div>
        </Container>
    )
}
export default ActivityList;