import React, { useState } from "react";
import { Col, Button, Form, Row, Container } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import TourGuideInventoryList from "./tourGuideInventoryList";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import { DatePicker } from "antd";
import moment from "moment";
import { useEffect } from "react";

const { RangePicker } = DatePicker;

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
}

const TourGuideInventory = (props) => {
  const [tourGuideList, UpdateTourGuideList] = useState([]);
  const [selected, updateSelected] = useState("");
  const [dataSet, updateDataSet] = useState([]);
  const [id, setId] = useState("");
  const [tgType, updateTgType] = useState("");
  const [tgName, updateTgName] = useState("");
  const [date, updateDate] = useState([]);

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        `https://api.zupptravel.com/getTourGuide`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await myresponse.json();
      let arr = [];
      console.log(json);
      if (json.successful) {
        json.tourGuides.map((data, id) => {
          console.log(data.tourGuideId);
          arr.push(data.tourGuideId);
        });
        UpdateTourGuideList(arr);

        updateDataSet(json.tourGuides);
      }
    })();
  }, []);

  useEffect(() => {
    dataSet.map((data, key) => {
      if (data.tourGuideId == selected) {
        updateTgName(data.guideName);
        updateTgType(data.guideType);
        setId(data.id);
      }
    });
  }, [selected]);

  return (
    <Container fluid className={classes.Box}>
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const newdata = {
            block: true,
            emailId: "string",
            endDate: date[1]?._d.toISOString().slice(0, 10),
            sessionKey: "string",
            startDate: date[0]?._d.toISOString().slice(0, 10),
            time: values.time,
            tourGuideId: id,
            tourGuideInventoryId: 0,
            tourGuideName: tgName,
          };

          const res = await fetch(
            "https://api.zupptravel.com/addTourGuideInventory",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newdata),
            }
          );

          console.log("rrrrrrrrrrrr", newdata);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("Added TourGide Inventory!");
            // props.click();
            window.location.reload();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicVendorName" as={Row}>
              <Form.Label column sm="2">
                Tour Guide ID:
              </Form.Label>
              <Col sm="10">
                <SingleSelect
                  type="2"
                  list={tourGuideList}
                  change={(select) => updateSelected(select)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTourGuideName" as={Row}>
              <Form.Label column sm="2">
                Tour Guide Name:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  readOnly
                  type="text"
                  placeholder="Tour Guide Name"
                  name="tourGuideName"
                  value={tgName}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasictimePerSlot" as={Row}>
              <Form.Label column sm="2">
                Date:
              </Form.Label>
              <Col>
                <RangePicker
                  onChange={(val) => updateDate(val)}
                  value={date}
                  disabledDate={disabledDate}
                />
              </Col>
              <Form.Label column sm="2">
                Time:
              </Form.Label>
              <Col>
                <Form.Control
                  type="time"
                  name="time"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.time}
                  required
                />
              </Col>
            </Form.Group>
            <center>
              <Button type="submit" variant="success">
                Submit
              </Button>
            </center>
          </Form>
        )}
      </Formik>
      <hr />
      <TourGuideInventoryList />
    </Container>
  );
};
export default TourGuideInventory;
