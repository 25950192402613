import React, { useEffect, useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import { GetVendor, Pincode } from "../../../entities/action/action";
import { useSelector, useDispatch } from "react-redux";
import { DropzoneArea } from "material-ui-dropzone";
import MultipleSelect from "../../multiSelect";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import Image from "../../../utilities/imageTestApi/image";
import * as Yup from 'yup';

const AddAccommodationType = (props) => {
  const [select, updateSelect] = useState("");
  const [state, setState] = useState("");
  const [mycity, setCity] = useState("");
  const [files, updateFiles] = useState([]);
  const [file, updateFile] = useState({});
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([1]);
  const [vendorList, updateVendorList] = useState([]);
  const [block, setBlock] = useState("");
  const [accomodationType, setAccomodationType] = useState("");
  const [accommodationName, setAccomodationName] = useState("");
  const [propertyEmail1, setPropertyEmail1] = useState("");
  const [propertyPhno, setPropertyPhno] = useState("");

  useEffect(() => {
    (async () => {
      const res = await GetVendor();
      // console.log(res);
      if (res != undefined && res.length > 0) {
        const arr = res.filter(
          (item, key) =>
            item.companyInformation.vendortype === "Accommodation Provider"
        );
        updateVendorList(arr);
      }
    })();
  }, []);

  console.log("venddoooooooor", vendorList);

  const [location, updateLocations] = useState([]);

  useEffect(() => {
    (async () => {
      const newData = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch(
        "https://api.zupptravel.com/getLocationManagement",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newData),
        }
      );
      const json = await res.json();
      if (json.successful) {
        updateLocations(json.locationManagements);
      }
    })();
  }, []);
  console.log(location);
  const handleAdd = () => {
    const newCurrentPoint = currentPoints + 1;
    updateCurrentPoints(newCurrentPoint);
    updateArrPoints([...arrPoints, newCurrentPoint]);
  };
  const handleRemove = () => {
    if (currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    }
  };
  const handleImages = (inpFile) => {
    updateFiles(inpFile);
  };

  console.log(files);

  const dispatch = useDispatch();
  const cityState = useSelector((state) => state.account.cityState);
  const myfunc = (e) => {
    if (e.target.value.length === 6) {
      dispatch(Pincode(e.target.value));
    } else {
      setState("");
      setCity("");
    }
  };

  useEffect(() => {
    if (cityState === null) {
        setState("");
        setCity("");
    } else {
        setState(cityState?.state);
        setCity(cityState?.city);
    }
  }, [cityState]);

  const [facility, updatefacility] = useState();
  useEffect(() => {
    (async () => {
      const newdata = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getHotelFacilities", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      console.log(json);
      if (json.successful) {
        updatefacility(json.hotelFacilities);
      }
    })();
  }, []);

  console.log("ttttttttttttttttttttttt", facility);
  const [country, setCountry] = useState("");
  const [pincode, updatePincode] = useState("");
  const handlePincode = (e) => {
    if (e !== "") {
      updatePincode(e);

      const x = location.filter((a, i) => a.pincode === e);
      setCity(x[0].city);
      setState(x[0].state);
      setCountry(x[0].country);
      setBlock(x[0].blockTehsilTaluka);
    }
  };

  const [vendorId, updateVendorId] = useState("");
  console.log(vendorId);
  useEffect(() => {
    vendorList.map((item, key) => {
      if (item.supplierid == vendorId) {
        setAccomodationType(item.companyInformation.accomodationType);
      }
    });
  }, [vendorId]);

  console.log("vvvvvvvvvvvvvvvvvv", accomodationType);

  const phoneSchema = Yup.string().matches(
    /^[0-9]{10}$/,
    'Phone number must be 10 digits'
  );
  
  const validationSchema = Yup.object().shape({
    propertyPhone: phoneSchema,
    propertyPhone1: phoneSchema,
    propertyPhone2: phoneSchema,
    propertyPhone3: phoneSchema,
    propertyPhone4: phoneSchema,
    // Add other fields as needed
  });
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          pincode: "",
          cancellation: [
            {
              cancellation: "",
              charges: "",
            },
          ],
        }}
        validationSchema={validationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log("valuesssssssssssssssssss", values);
          const img = await Image(file);
          console.log(img);
          const img2 = await Image(files[0]);

          const charges = [];
          const cancellation = [];

          Object.entries(values).map(([key, value]) => {
            console.log(key, value);
            if (key.includes("charges") && typeof value === "string") {
              charges.push(value);
            } else if (
              key.includes("cancellation") &&
              typeof value === "string"
            ) {
              cancellation.push(value);
            }
          });

          const cancellationCharges = [];

          for (let i = 0; i < charges.length; i++) {
            cancellationCharges.push({
              cancellation: cancellation[i],
              charges: charges[i],
            });
          }

          console.log(charges, cancellation, "checkkkkk");
          const newdata = {
            accommodationId: 0,
            accommodationName: values.accommodationName,
            accommodationType: accomodationType,

            cancellationCharges: [...cancellationCharges],

            city: mycity,
            country: country,
            emailId: "string",
            images: img2,
            pincode: pincode,
            proof: img,
            propertyEmail1: values.propertyEmail1,
            propertyEmail2: values.propertyEmail2,
            propertyInformation: values.propertyInformation,
            propertyPhone: values.propertyPhone,
            propertyPhone1: values.propertyPhone1,
            propertyPhone2: values.propertyPhone2,
            propertyPhone3: values.propertyPhone3,
            propertyPhone4: values.propertyPhone4,
            propertyStar: values.propertyStar,
            regAddress1: values.addressline1,
            regAddress2: values.addressline2,
            sessionKey: "string",
            state: state,
            status: true,
            vendorId: vendorId,
            village: values.village,
          };

          const res = await fetch(
            "https://api.zupptravel.com/addAccommodation",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newdata),
            }
          );

          console.log("rrrrrrrrrrrr", newdata);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("Added Accommodation!");
            setTimeout(() => {
              window.location.reload();
            }, 0);
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <SingleSelect
                  type="3"
                  list={vendorList}
                  change={(select) => updateVendorId(select)}
                />
              </Col>
            </Form.Group>
            {console.log("valuesssssss", values)}
            <Form.Group controlId="formBasicAccommodationName" as={Row}>
              <Form.Label column sm="3">
                Accommodation Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Accommodation Type"
                  name="accommodationType"
                  value={accomodationType}
                  readOnly
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicAccommodationName" as={Row}>
              <Form.Label column sm="3">
                Accommodation Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Accommodation Name"
                  name="accommodationName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.accommodationName}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyStar" as={Row}>
              <Form.Label column sm="3">
                Property Star:
              </Form.Label>

              <Col sm="9">
                <Form.Control
                  as="select"
                  placeholder="Property Star"
                  name="propertyStar"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.propertyStar}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Star1">Star 1</option>
                  <option value="Star2">Star 2</option>
                  <option value="Star3">Star 3</option>
                  <option value="Star4">Star 4</option>
                  <option value="Star5">Star 5</option>
                  <option value="Star7">Star 7</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyEmail1" as={Row}>
              <Form.Label column sm="3">
                Property Email 1:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Property Email 1"
                  name="propertyEmail1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.propertyEmail1}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyEmail2" as={Row}>
              <Form.Label column sm="3">
                Property Email 2:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Property Email 2"
                  name="propertyEmail2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.propertyEmail2}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyPhone" as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="9">
                <Row>
                  <Form.Label column sm="3">
                    Primary Phone:
                  </Form.Label>
                  <Col sm="3">
                    <Form.Control
                      type="number"
                      placeholder="Enter Property Phone"
                      name="propertyPhone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.propertyPhone}
                      isInvalid={touched.propertyPhone && errors.propertyPhone}

                      required
                    />
                  </Col>
 <Form.Control.Feedback type="invalid">
      {errors.propertyPhone}
    </Form.Control.Feedback>

                  <Form.Label column sm="3">
                    Phone 1:
                  </Form.Label>
                  <Col sm="3">
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone 1"
                      name="propertyPhone1"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.propertyPhone1}
                      isInvalid={touched.propertyPhone && errors.propertyPhone}

                      required
                    />
                    <Form.Control.Feedback type="invalid">
      {errors.propertyPhone}
    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyPhone" as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="9">
                <Row>
                  <Form.Label column sm="3">
                    Phone 2:
                  </Form.Label>
                  <Col sm="3">
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone 2"
                      name="propertyPhone2"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.propertyPhone2}
                      isInvalid={touched.propertyPhone && errors.propertyPhone}

                    />
                    <Form.Control.Feedback type="invalid">
      {errors.propertyPhone}
    </Form.Control.Feedback>
                  </Col>

                  <Form.Label column sm="3">
                    Phone 3:
                  </Form.Label>
                  <Col sm="3">
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone 3"
                      name="propertyPhone3"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.propertyPhone3}
                      isInvalid={touched.propertyPhone && errors.propertyPhone}

                    /><Form.Control.Feedback type="invalid">
                    {errors.propertyPhone}
                  </Form.Control.Feedback>
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyPhone" as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="9">
                <Row>
                  <Form.Label column sm="3">
                    Phone 4:
                  </Form.Label>
                  <Col sm="3">
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone 4"
                      name="propertyPhone4"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.propertyPhone4}
                      isInvalid={touched.propertyPhone && errors.propertyPhone}

                    />
                    <Form.Control.Feedback type="invalid">
      {errors.propertyPhone}
    </Form.Control.Feedback>
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            {/* <Form.Group controlId="formBasicPropertyPhone" as={Row}>
              <Form.Label column sm="3">
                Property Phone:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Property Phone"
                  name="propertyPhone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.propertyPhone}
                  required
                />
              </Col>
            </Form.Group> */}
            <Form.Group controlId="formBasicAddress1" as={Row}>
              <Form.Label column sm="3">
                Registered Address Line 1:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  name="addressline1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.addressline1}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicAddress2" as={Row}>
              <Form.Label column sm="3">
                Registered Address Line 2:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  name="addressline2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.addressline2}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicAddress2" as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="9">
                <Row>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicPin" as={Row}>
                      <Form.Label column sm="3">
                        Pin Code:
                      </Form.Label>
                      <Col sm="9">
                        <SingleSelect
                          type="5"
                          list={location}
                          change={(select) => handlePincode(select)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicCountry" as={Row}>
                      <Form.Label column sm="3">
                        Country:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          readOnly
                          placeholder="Enter Country"
                          name="country"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={country}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicCity" as={Row}>
                      <Form.Label column sm="3">
                        City:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          readOnly
                          placeholder="Enter City"
                          name="city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={mycity}
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicState" as={Row}>
                      <Form.Label column sm="3">
                        State:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          readOnly
                          type="text"
                          placeholder="Enter State"
                          name="state"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={state}
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicVillage" as={Row}>
                      <Form.Label column sm="3">
                        Village:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          placeholder="Enter Village"
                          name="village"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.village}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicBlock" as={Row}>
                      <Form.Label column sm="3">
                        Tehsil/Block :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          placeholder="Enter Tehsil/Block"
                          name="block"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={block}
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPropertyInformation" as={Row}>
              <Form.Label column sm="3">
                Property Information:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Property Information"
                  name="propertyInformation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.propertyInformation}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicAmenities" as={Row}>
              <Form.Label column sm="3">
                Select Facility:
              </Form.Label>
              <Col sm="9">
                <MultipleSelect
                  facility={facility}
                  type="11"
                  selectedValue={(selected) => updateSelect(selected)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicUploadProof" as={Row}>
              <Form.Label column sm="3">
                Upload Proof:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="file"
                  name="file"
                  onChange={(e) => updateFile(e.target.files[0])}
                  required
                />
              </Col>
            </Form.Group>
            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Cancellation :
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Cancellation `}
                        name={`cancellation${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cancellation[d]?.cancellation}
                        required
                      />
                    </Col>
                    <Form.Label column sm="3">
                      Charge:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Charges `}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`charges${d}`}
                        value={values.cancellation[d]?.charges}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>

              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div>

            <br />
            <Form.Group controlId="formBasicChild" as={Row}>
              <Form.Label column sm="3">
                Images:
              </Form.Label>
              <Col sm="9">
                <DropzoneArea onChange={handleImages} filesLimit={1} />
              </Col>
            </Form.Group>
            <center>
              <Button type="submit" variant="success" >
                Save Accommodation
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddAccommodationType;
