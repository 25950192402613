import React,{useEffect,useState} from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { AddMarkup,GetMarkup } from '../../../entities/action/action';

const CommonMarkup = (props)=>{
    console.log(props.type)
    console.log(props.markupIn)
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    
    return(
        <Formik
            initialValues={{amount:'',gst:''}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    console.log(values);
                    const newData = {
                        "amountType": values.amount,
                        "emailId": userData.data.key,
                        "gstCost": Math.round(values.amount*(values.gst/100)).toString(),
                        "highestMarkupRange": values.highest,
                        "lowestMarkupRange": values.lowest,
                        "markUpId": 0,
                        "markupAmount": 0,
                        "markupCalculation": values.markupCalculation,
                        "markupFor": "",
                        "markupGst": values.gst,
                        "markupIn": props.markupIn,
                        "markupType": "",
                        "sessionKey": userData.sessionId,
                        "totalMarkup": 0
                    }
                    console.log(newData)
                    const response = await AddMarkup(props.type,newData)
                    console.log(response);
                    if(response.successful){
                        alert('Successfully added markup')
                        resetForm();
                    }
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicAmountType" as={Row}>
                                            <Form.Label column sm="2">Amount Type:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Amount Type" 
                                                    name="amountType"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.amountType}
                                                    required>
                                                        <option value="">--select--</option>
                                                        <option value="Fix">Fix</option>
                                                        <option value="Percentage">Percentage</option>
                                                        <option value="Both">Both</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicAmount" as={Row}>
                                            <Form.Label column sm="2">Markup Amount:</Form.Label>
                                            <Col> 
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Amount" 
                                                name="amount"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.amount}
                                                required/>
                                            </Col>
                                            <Col> 
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Markup GST" 
                                                name="gst"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.gst}
                                                required/>
                                            </Col>
                                            <Col> 
                                            <Form.Control 
                                                type="text"
                                                readOnly 
                                                placeholder="GST Cost" 
                                                name="totalMarkup"
                                                value={Math.round(values.amount*(values.gst/100)).toString()}
                                                required/>
                                            </Col>
                                            <Col> 
                                            <Form.Control 
                                                type="text"
                                                readOnly 
                                                placeholder="Total Markup" 
                                                name="totalMarkup"
                                                value={Math.round(values.amount*(1+values.gst/100)).toString()}
                                                required/>
                                            </Col>
                                        </Form.Group>   
                                        <Form.Group controlId="formBasicRange" as={Row}>
                                            <Form.Label column sm="2">Markup Range:</Form.Label>
                                            <Col sm="5"> 
                                                <Form.Control
                                                type="number"
                                                min={0} 
                                                placeholder="Lowest" 
                                                name="lowest"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.lowest}
                                                required/>
                                            </Col>
                                            <Col sm="5"> 
                                                <Form.Control
                                                type="number"
                                                min={0} 
                                                placeholder="Highest" 
                                                name="highest"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.highest}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicMarkupCalculation" as={Row}>
                                            <Form.Label column sm="2">Markup Calculation:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Markup Calculation" 
                                                    name="markupCalculation"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.markupCalculation}
                                                    required>
                                                        <option value="">--select--</option>
                                                        {
                                                            props.type!==undefined&&props.type==="Hotel"?
                                                                <option value="Per Night">Per Night</option>:null
                                                        }
                                                        <option value="Fix">Fix</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <center>
                                            <Button type="submit" variant="success">Save {props.type} Markup</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>          
    )
}
export default CommonMarkup;