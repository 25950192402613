import React, { useEffect, useState } from 'react';
import {Form, Button} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import classes from './components/login.module.css';
import { setStorage } from './entities/account/action/action';

const Otp = (props)=>{
    console.log("......",props)
    const [error,updateError] = useState('');
    const[otpNum,updateOtpNum] = useState('');
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    const isLoggedIn = useSelector(state=>state.adminAccount.isLoggedIn);
    const dispatch = useDispatch();
    useEffect(()=>{
        if(isLoggedIn){
            props.history.push('/adminPanel'); 
        }
    },[isLoggedIn])
    const handleResendOtp = async()=>{
        let url = "https://api.zupptravel.com/api/v1/auth/initiateAuthenticationForAdmin";
        await fetch(url, {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone: props.data.phone,
            password: props.data.password,
          }),
        });
        props.reset();
    }
    const handleSubmit = async()=>{
        const res = await fetch('https://api.zupptravel.com/api/v1/auth/authenticate',{
            method:'post',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                phone: props.data.phone,
                password: props.data.password,
                code:otpNum,
            })
        });
        const json = await res.json();
        if(json?.success){
            dispatch(setStorage(json));
        }else{
            updateError('Error');
            alert("Error Wrong OTP!!");
        }
    }
    return (
      <div>
        <Form>
            <Form.Group controlId="formBasicEmail">
                <Form.Label style={{color:'white'}}>Verification Code</Form.Label>
                <Form.Control 
                type="string"
                placeholder="Enter OTP" 
                value={otpNum}
                onChange={(e)=>updateOtpNum(e.target.value)}/>
            </Form.Group>
            <center><Button size="lg" onClick={handleSubmit} style={{margin:'2%'}}>Verify</Button>
            <Button style={{margin:'2%'}} size="lg" variant="danger" onClick={()=>props.history.push('/adminLogin')}>Cancel</Button></center>
            <center>
                <Form.Text onClick={handleResendOtp} style={{fontWeight:'bold',color:'white',cursor:'pointer'}}>
                    Resend OTP?    
                </Form.Text>
            </center>
        </Form>
        {
            error?<p className={classes.Error}>{error}</p>:null
        }
      </div>
    );
}
export default Otp;