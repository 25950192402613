import React, { useState, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import AddAvailabilityAndPrice from "./addAvailabilityAndPrice";
import AddTourGuide from "./addTourGuide";
import AvailabilityAndPriceList from "./availabilityAndPriceList";
import TourGuideInventory from "./tourGuideInventory";
import TourGuideList from "./tourGuideList";
import TourGuideView from "./tourGuideView";

const TourGuide = (props) => {
  const [active, updateActive] = useState("1");
  const [data, setdata] = useState({});
  const items = [
    { id: "1", type: "Add Tour Guide" },
    { id: "2", type: "Tour Guide List" },
    { id: "3", type: "Add Availability & Price" },
    { id: "5", type: "Availability & Price List" },
    { id: "7", type: "Tour Guide Inventory" },
  ];

  return (
    <Fragment>
      <Container fluid style={{ paddingLeft: "10px" }}>
        <Row>
          <Col lg={2} md={12} className={classes.Response2}>
            <div style={{ height: "100%" }}>
              <ul className={classes.Ul}>
                {items.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => updateActive(item.id)}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${
                        active === item.id ? "steelblue" : "#585858"
                      }`,
                      textAlign: "center",
                    }}
                  >
                    {item.type}
                  </li>
                ))}
                {active === "4" ? (
                  <li
                    onClick={() => updateActive("2")}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${
                        active === "4" ? "steelblue" : "#585858"
                      }`,
                      textAlign: "center",
                    }}
                  >
                    Back
                  </li>
                ) : null}
              </ul>
            </div>
          </Col>
          <Col lg={10} md={12} className={classes.Response}>
            <div style={{ height: "100%" }}>
              {active === "1" ? (
                <AddTourGuide click={() => updateActive("2")} />
              ) : active === "2" ? (
                <TourGuideList
                  click={() => updateActive("4")}
                  mydata={(e) => setdata(e)}
                />
              ) : active === "3" ? (
                <AddAvailabilityAndPrice />
              ) : active === "4" ? (
                <TourGuideView data={data} click={() => updateActive("2")} />
              ) : active === "5" ? (
                <AvailabilityAndPriceList />
              ) : active === "7" ? (
                <TourGuideInventory />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TourGuide;
