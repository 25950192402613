import React, { useState,Fragment } from 'react';
import {Form,Button} from 'react-bootstrap';
import classes from './WebsiteManagement/websiteManagement.module.css';
import DraftEditor from './draftEditor';
import {useSelector} from 'react-redux';
import { EmailInformation } from '../entities/action/action';

const Email = (props)=>{
    const [to,updateTo] = useState('');
    const [subject,updateSubject] = useState('');
    const [message,updateMessage] = useState('');
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    const handleCancel = ()=>{
        updateMessage('');
        updateTo('');
        updateSubject('');
    }
    const handleSubmit = async()=>{
        const newdata = {
            "emailId": userData.data.key,
            "mailBody": message,
            "sessionKey": userData.sessionId,
            "subject": subject,
            "to": to,
            "from":userData.data.key
        }
        const response = await EmailInformation(newdata);
        if(response.successful){
            alert("Successfully Sent Email");
            window.location.reload();
        }else{
            alert("Something went wrong!!");
        }
    }
    return(
        <Fragment>
            <div className={classes.Box}>
            <Form.Group controlId="formBasicFrom">
                <Form.Label>To :</Form.Label>
                <Form.Control 
                type="email"
                as="select" 
                placeholder="To *" 
                onChange={(e)=>updateTo(e.target.value)}
                value={to}
                required>
                    <option value="">--select--</option>
                    <option>Customer</option>
                    <option>Agents</option>
                    <option>Accommodation Vendor</option>
                    <option>Tour Guide</option>
                    <option>Bus Vendor</option>
                    <option>Charter Plane Vendor</option>
                    <option>Cab Vendor</option>
                    <option>Rental Vehicle Vendor</option>
                    <option>Activities Vendor</option>
                </Form.Control>
            </Form.Group>
            <Form.Group controlId="formBasicFrom">
                <Form.Label>Subject:</Form.Label>
                <Form.Control 
                type="text" 
                placeholder="Subject" 
                onChange={(e)=>updateSubject(e.target.value)}
                value={subject}
                required/>
            </Form.Group>
            <p>Message:</p>
            <div style={{border:'1px solid black'}}>
                <DraftEditor change={(text)=>updateMessage(text)} text={message}/>
            </div>
                <center> 
                    <Button onClick={handleSubmit} style={{margin:'2% 2%'}}>Send</Button>
                    <Button onClick={handleCancel} style={{margin:'2% 2%'}} variant="secondary">Cancel</Button>
                </center>
            </div>
        </Fragment>
    )
}
export default Email;