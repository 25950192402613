import React, { useState } from "react";
import { Formik } from "formik";
import { Form, Button, Row, Col } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
// import {DropzoneArea} from 'material-ui-dropzone';

const AddRouteGeneralView = (props) => {
  const [message, updateMessage] = useState("");
  // const [files,updateFiles] = useState([]);
  const [edit, updateEdit] = useState(false);
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([
    ...new Array(props.data.location.length).fill(0).map((_, i) => i),
  ]);

  console.log(arrPoints);
  const clickHandler = (id) => {
    const win = window.open(id, "_blank");
    win.focus();
  };

  console.log(props.data);

  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    }
  };

  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          // emailId: "string",
          PerDayRange: props.data.perDayRange,
          // routeId: 0,
          // location: locationss,
          routeType: props.data.routeType,
          // sessionKey: "string",
          stateTax: props.data.stateTax,
          tollCharges: props.data.tollCharges,
          totalDistance: props.data.totalDistance,

          ...Object.assign(
            {},
            ...props.data.location.map((i, index) => ({
              [`location${index}`]: i.location,
            }))
          ),
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);

          const locations = [];
          Object.entries(values).map(([key, value]) => {
            console.log(key, value);
            if (key.includes("location") && typeof value === "string") {
              locations.push(value);
            }
          });

          console.log(locations);

          const locationss = [];

          for (let i = 0; i < locations.length; i++) {
            locationss.push({
              location: locations[i],
            });
          }

          console.log(locationss);
          const newdata = {
            emailId: "string",
            perDayRange: props.data.perDayRange,
            routeId: props.data.id,
            location: locationss,
            routeType: props.data.routeType,
            sessionKey: "string",
            stateTax: props.data.stateTax,
            tollCharges: props.data.tollCharges,
            totalDistance: props.data.totalDistance,
          };
          console.log(newdata);
          const res = await fetch("https://api.zupptravel.com/updateRoute", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newdata),
          });
          const json = await res.json();
          console.log(json);
          if (json.successful) {
            alert("updated Cab Route!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "2%",
              }}
            >
              {!edit ? (
                <Button variant="success" onClick={() => updateEdit(true)}>
                  Edit
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={() => {
                    updateEdit(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="light"
                style={{ marginLeft: "15px" }}
                onClick={() => props.click()}
              >
                {`<--`} Back
              </Button>
            </div>
            <Form.Group controlId="formBasicCabInfoRoute" as={Row}>
              <Form.Label column sm="2">
                Route Type:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  //   placeholder="Enter Total Distance"
                  name="routeType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.routeType}
                  readOnly
                />
                {/* <Form.Control
                  // readOnly
                  type="text"
                  as="select"
                  placeholder="Cab Type"
                  name="routeType"
                  value={values.routeType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                >
                  <option value="">--Select--</option>
                  <option value="local">Local</option>
                  <option value="oneWay">One Way</option>
                  <option value="Return">Return</option>
                  <option value="Airport">Airport</option>
                </Form.Control> */}
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicInfo" as={Row}>
              <Form.Label column sm="2">
                Total Distance:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="number"
                  placeholder="Enter Total Distance"
                  name="totalDistance"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.totalDistance}
                  readOnly
                />
              </Col>
              <Form.Label column sm="2">
                Per Day Range :
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="number"
                  placeholder="Enter PerDay Range"
                  name="PerDayRange"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.PerDayRange}
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicInfo" as={Row}>
              <Form.Label column sm="2">
                Toll Charges:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="number"
                  placeholder="Enter Toll Charges"
                  name="tollCharges"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tollCharges}
                  readOnly
                />
              </Col>
              <Form.Label column sm="2">
                State Tax :
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="number"
                  placeholder="Enter State Tax"
                  name="stateTax"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.stateTax}
                  readOnly
                  disabled={!edit}
                />
              </Col>
            </Form.Group>

            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group
                    controlId={`LocationRoute${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="2">
                      Location Route {d}:
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control
                        type="text"
                        placeholder={`Location ${d}`}
                        name={`location${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`location${d}`]}
                        required
                        disabled={!edit}
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
                // disabled ={!edit}
              >
                Remove
              </Button>
              <Button
                variant="success"
                onClick={() => handleAdd(1)}
                disabled={!edit}
              >
                Add
              </Button>
            </div>

            <center>
              <Button type="submit" variant="success">
                Save Cab Route
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddRouteGeneralView;
