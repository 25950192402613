import React, { useState } from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Formik } from 'formik';
import Image from '../../../utilities/imageTestApi/image';
import MultipleSelect from '../../multiSelect';
import RoomCategoryList from './roomCategoryList';

const RoomCategory = (props)=>{
    const [select,updateSelect] = useState([]);
    const [file1,updateFile1] = useState({});
    const [file2,updateFile2] = useState({});
    const [file3,updateFile3] = useState({});
    const categoryList = [
        {type:'CATEGORY'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'}
    ]
    return(
        <div> 
        <Formik
            initialValues={{title:''}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    setSubmitting(true);
                    const response = await Image(file1);
                    const newData = {
                         image:response
                    }
                    console.log(response);
                    console.log(newData)
                    // const res = await Createcoupon(newData);
                    // if(res){
                    //     alert("Successfully Added Coupon");
                    //     resetForm();
                    //     props.click();
                    // }
                    // setSubmitting(false);
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        {/* <Form.Group controlId="formBasicAccommodationType" as={Row}>
                                            <Form.Label column sm="2">Accommodation Type:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Accommodation Type" 
                                                    name="accommodationType"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.accommodationType}
                                                    required>
                                                    {
                                                        categoryList.map((categories,key)=>
                                                        <option key={key} value={categories.type}>{categories.type}</option>    
                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>    */}
                                        <Form.Group controlId="formBasicRoomName" as={Row}>
                                            <Form.Label column sm="2">Room Name:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Room Name" 
                                                name="roomName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.roomName}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicMetaNoOfRooms" as={Row}>
                                            <Form.Label column sm="2">No. of Rooms:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter No. of Rooms" 
                                                name="noOfRooms"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.noOfRooms}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicCheckInTime" as={Row}>
                                            <Form.Label column sm="2">Check-in Time:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="time" 
                                                name="checkInTime"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.checkInTime}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicCheckOutTime" as={Row}>
                                            <Form.Label column sm="2">Check-out Time:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="time" 
                                                name="checkOutTime"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.checkOutTime}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicAmenities" as={Row}>
                                            <Form.Label column sm="2">Select Amenities:</Form.Label>
                                            <Col sm="10"> 
                                                <MultipleSelect type="1" selectedValue={(selected)=>updateSelect(selected)}/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicPromo" as={Row}>
                                            <Form.Label column sm="2">Image:</Form.Label>
                                            <Col sm="3"> 
                                                <Form.Control
                                                type="file" 
                                                name="file1"
                                                onChange={(e)=>updateFile1(e.target.files[0])}
                                                required/>
                                            </Col>
                                            <Col sm="3"> 
                                                <Form.Control
                                                type="file" 
                                                name="file2"
                                                onChange={(e)=>updateFile2(e.target.files[0])}
                                                required/>
                                            </Col>
                                            <Col sm="3"> 
                                                <Form.Control
                                                type="file" 
                                                name="file3"
                                                onChange={(e)=>updateFile3(e.target.files[0])}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <center>
                                            <Button type="submit" variant="success">Save Room Category</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                        <hr/>
                        <RoomCategoryList/>
                    </div>            
    )
}
export default RoomCategory;