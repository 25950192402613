import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import Page from "../../pagination/pagination";

const AvailabilityAndPriceList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet.slice(indexOfFirst, indexOfLast);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet.length / postPerPage));
  }, [dataSet]);

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        `https://api.zupptravel.com/getTourGuideAvailabilityAndPrice`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await myresponse.json();
      console.log(json);
      if (json.successful) {
        updateDataSet(json.tourGuideAvailabilityAndPrices);
      }
    })();
  }, []);
  const HandleDelete = async (id) => {
    const newd = {
      emailID: "string",
      id: id,
      sessionKey: "string",
    };
    console.log(newd);
    const r = await fetch(
      "https://api.zupptravel.com/deleteTourGuideAvailabilityAndPrice",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newd),
      }
    );
    const j = await r.json();
    if (j.successful) {
      alert("Successfully deleted Availibility and Price Type");
      const newdata = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch(
        "https://api.zupptravel.com/getTourGuideAvailabilityAndPrice",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newdata),
        }
      );
      const json = await res.json();
      if (json.successful) {
        updateDataSet(json.tourGuideAvailabilityAndPrices);
      }
    }
  };
  console.log(dataSet);
  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>TG ID</th>
            <th>Price ID</th>
            <th>Availability Duration</th>
            <th>Cost Type</th>
            <th>Maximum Price</th>
            <th>Customer Price</th>
            <th>ZT Price</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.length > 0
            ? currentPosts.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.tourGuideId}</td>
                  <td>{data.id}</td>
                  <td>{data.availabilityEndDate}</td>
                  <td>{data.costType}</td>
                  <td>{data.offerPriceCXTotalCost}</td>
                  <td>{data.offerPriceCXAmount}</td>
                  <td>{data.purchasePriceZTTotalCost}</td>
                  <td className={classes.CenterRow}>
                    <Button
                      style={{ marginRight: "5px" }}
                      variant="danger"
                      onClick={() => HandleDelete(data.id)}
                    >
                      Delete
                    </Button>
                    {/* <Button style={{ marginRight: "5px" }}>View</Button> */}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default AvailabilityAndPriceList;
