import React, {useState, Fragment, useEffect } from 'react';
import {Col,Row, Container, Button,Form} from 'react-bootstrap';
import classes from './WebsiteManagement/websiteManagement.module.css';
import classesX from './Dashboard/dashboard.module.css'
import classesY from './queryManagement.module.css'
import Query from './query';
import AdminNavbar from './navbar/adminNavbar';
import { Formik } from 'formik';

const QueryManagement = (props)=>{

    const real = [
        {serial:'a',name:'jalaj',contact:'9711325864',email:'test@test.com',subject:'hello',message:'this is a message this is a message this is a message',status:"active"},
        {serial:'b',name:'jalaj',contact:'9711325860',email:'test@test.com',subject:'hello',message:'this is a message this is a message this is a message',status:"active"},
        {serial:'c',name:'jalaj',contact:'9711325861',email:'test@test.com',subject:'hello',message:'this is a message this is a message this is a message this is a message this is a message this is a message this is a message this is a message this is a message',status:"close"},
        {serial:'d',name:'jalaj',contact:'9711325862',email:'test@test.com',subject:'hello',message:'this is a message',status:"close"},
        {serial:'e',name:'jalaj',contact:'9711325863',email:'test@test.com',subject:'hello',message:'this is a message this is a message this is a message this is a message this is a message',status:"close"},
        {serial:'f',name:'jalaj',contact:'9711325865',email:'test@test.com',subject:'hello',message:'this is a message',status:"active"}
    ]
    useEffect(()=>{
        updateRealData(real);
        updateData(real);
    },[])
    const [realData,updateRealData] = useState([]);
    const [data,updateData] = useState([]);
    const [contact,updateContact] = useState('');
    const [serial,updateSerial] = useState('');
    const items = [
        {id:"1",type:"Query"}
    ]
    const handleSearch=()=>{
        if(contact===''&&serial===''){
            updateData(realData); // Again Back to original state
        }
        else if(contact===''&serial!==''){
            const array = realData.filter(arr=>arr.serial===serial);
            updateData(array);
        }
        else if(contact!==''&serial===''){
            const array = realData.filter(arr=>arr.contact===contact);
            updateData(array);
        }else{
            const array = realData.filter(arr=>arr.contact===contact&&arr.serial===serial);
            updateData(array);
        }
    }
    const deleteHandler = (arr)=>{
        updateRealData(arr);
        updateData(arr);
    }
    const updateValue = (val)=>{
        updateData(val);
    }
    return(
        <Fragment> 
           <AdminNavbar/>
            <h1 className={classesX.Heading}>Query Management</h1>  
        <Container fluid>
            <Row style={{margin:'1.5% 0'}}>
                <Col md={4} xs={12}>
                     
                </Col>
                <Col md={4} xs={12}>
                    <div className={classes.CenterRow}>
                        <Form.Control 
                        onChange={(e)=>updateSerial(e.target.value)} 
                        placeholder="Search with serial" 
                        style={{position:'relative'}}/>
                    </div>
                </Col>
                <Col md={4} xs={12}>
                    <div>
                        <Button variant="success" onClick={handleSearch}>Search</Button>
                        <Button style={{margin:'0 1%'}}>Print Excel</Button>
                    </div>
                </Col>
            </Row>
            <br/>
            <Container className={classesY.card}> 
            <Formik
            initialValues={{}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    console.log(values);
                   
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col lg={2} sm={4}>
                        <Form.Group controlId="formBasicAmount">
                        <Form.Label style={{fontWeight:"bold"}}>Ticket:</Form.Label>
                                <Form.Control 
                                    as="text" 
                                    placeholder="Status" 
                                    name="ticket"
                                   readOnly
                                >
                                    
                                </Form.Control>
                        </Form.Group>
                        </Col>
                        <Col lg={2} sm={4}>
                        <Form.Group controlId="formBasicAmount">
                        <Form.Label style={{fontWeight:"bold"}}>Status:</Form.Label>
                                <Form.Control 
                                    as="select" 
                                    placeholder="Status" 
                                    name="status"
                                   
                                >
                                    <option value="">--Select--</option>
                                    <option value="Status">Status</option>
                                </Form.Control>
                        </Form.Group>
                        </Col>
                        <Col lg={2} sm={4}>
                        <Form.Group controlId="formBasicAmount">
                        <Form.Label style={{fontWeight:"bold"}}>Priority:</Form.Label>
                        <Form.Control 
                                    as="select" 
                                    placeholder="Status" 
                                    name="Priority"
                                   
                                >
                                    <option value="">--Select--</option>
                                    <option>Priority</option>
                                </Form.Control>
                        </Form.Group>
                        </Col>
                        <Col lg={2} sm={4}>
                        <Form.Group controlId="formBasicAmount">
                        <Form.Label style={{fontWeight:"bold"}}>Department:</Form.Label>
                        <Form.Control 
                                    as="select" 
                                    placeholder="Department" 
                                    name="department"
                                   
                                >
                                    <option value="">--Select--</option>
                                    <option>Department</option>
                                </Form.Control>
                        </Form.Group>
                        </Col>
                        <Col lg={2} sm={4}>
                        <Form.Group controlId="formBasicAmount">
                        <Form.Label style={{fontWeight:"bold"}}>Ticket From:</Form.Label>
                            <Form.Control 
                                    as="text" 
                                    placeholder="Status" 
                                    name="ticket"
                                   readOnly
                                />
                        </Form.Group>
                        </Col>
                        <Col lg={2} sm={4}>
                        <Form.Group controlId="formBasicAmount">
                        <Form.Label style={{fontWeight:"bold"}}>Ticket Type:</Form.Label>
                        <Form.Control 
                                    as="select" 
                                    placeholder="Ticket Type" 
                                    name="ticketType"
                                   
                                >
                                    <option value="">--Select--</option>
                                    <option>Ticket1</option>
                                </Form.Control>
                        </Form.Group>
                        </Col>
                        
                       
                    </Row>
                    <Row>
                    <Col lg={6} sm={6}>
                        <Form.Group controlId="formBasicAmount">
                        <Form.Label style={{fontWeight:"bold"}}>Message:</Form.Label>
                            <Form.Control 
                                    as="textarea" 
                                    placeholder="Message" 
                                    name="message"
                                    rows={4}
                                   readOnly
                                />
                        
                        </Form.Group>
                        </Col>
                        <Col lg={2} sm={2}>
                        <Form.Group controlId="formBasicAmount">
                        <Form.Label style={{fontWeight:"bold"}}>Assign:</Form.Label>
                        <Form.Control 
                                    as="select" 
                                    placeholder="Assign" 
                                    name="assign"
                                   
                                >
                                    <option value="">--Select--</option>
                                    <option>Assign</option>
                                </Form.Control>
                        </Form.Group>
                        </Col>
                        <Col lg={4} sm={4}>
                        <Form.Group controlId="formBasicAmount">
                        <Form.Label style={{fontWeight:"bold"}}>Creation Date & Time:</Form.Label>
                        <Form.Control 
                                    as="text" 
                                    placeholder="Creation Date & Time" 
                                    name="dateTime"
                                   readOnly
                                >
                                    
                                </Form.Control>
                        </Form.Group>
                        </Col>
                    </Row>
               </Form>
                )}
                </Formik>
                <Row style={{backgroundColor:"whitesmoke",borderRadius:"20px",padding:"10px"}}>
                    <Col>
                    <center><Button style={{marginRight:"20px"}} variant="dark"><i class="fa fa-reply"></i> Reply</Button></center>  
                    </Col>
                    <Col>
                    <center><Button style={{marginRight:"20px"}} variant="dark"><i class='fa fa-forward'></i> Forward</Button></center>  
                    </Col>
                </Row>
                {/* <div style={{backgroundColor:"whitesmoke",borderRadius:"10px",display:"flex",flexDirection:"column",padding:"10px"}}>
                
                    <Button variant="dark">Forward</Button></center>
                </div> */}
            </Container>
        </Container>
        </Fragment>
    )
}

export default QueryManagement;