import React, { useEffect, useState } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import {GetGift,DeleteGift} from '../../../entities/action/action';
import {useSelector} from 'react-redux';

const ActiveGiftCard = (props)=>{
    const [dataSet,updateDataSet] = useState([]);
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    useEffect(()=>{
        (async()=>{
            const newData = {
                "password": "string",
                "phoneno": "string",
                "emailId": userData.data.key,
                "sessionKey": userData.sessionId
            }
            let arr = await GetGift(newData);
            if(arr.successful){
                updateDataSet(arr.giftCards);
            }
        })()
    },[])
    console.log(dataSet)
    const handleDelete = async(id,val)=>{
        const newData = {
            "emailId": userData.data.key,
            "giftCardCategory": val,
            "id": id,
            "sessionKey": userData.sessionId
        }
        const res = await DeleteGift(newData);
        if(res.successful){
            alert("Successfully Deleted");
           window.location.reload();
        }
    }
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Image</th>
                        <th>Title</th>
                        <th>Category</th>
                      
                        <th>Min. Amount</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataSet.length>0?dataSet.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td><img src={data.imageUrl} style={{width:'50px'}} alt="active coupon"/></td>
                                <td>{data.title}</td>
                                <td>{data.giftCardCategory}</td>
                             
                                <td>{data.minimumAmount}</td>
                                <td className={classes.CenterRow}>
                                    <Button style={{margin:'auto 1%'}} variant="danger" onClick={()=>handleDelete(data.id,data.giftCardCategory)}>Delete</Button>
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
        </Container>
    )
}
export default ActiveGiftCard;