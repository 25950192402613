import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import Page from "../../pagination/pagination";

const RoomSetList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet?.slice(indexOfFirst, indexOfLast);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet?.length / postPerPage));
  }, [dataSet]);
  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(`https://api.zupptravel.com/getRoomSet`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const json = await myresponse.json();
      console.log("jsssoooooonnn", json);

      if (json.successful) {
        updateDataSet(json.roomSets);
      }
    })();
  }, []);

  console.log(dataSet);

  const HandleDelete = async (id) => {
    const newd = {
      emailID: "string",
      id: id,
      sessionKey: "string",
    };
    console.log(newd);
    const r = await fetch("https://api.zupptravel.com/deleteRoomSet", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newd),
    });
    const j = await r.json();
    if (j.successful) {
      alert("Successfully deleted Room Set");
      const newdata = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getRoomSet", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      console.log(json);
      if (json.successful) {
        updateDataSet(json.roomSets);
      }
    }
  };

  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Set Room Name</th>
            <th>No. of Adults</th>
            <th>No. of Children</th>
            {/* <th>Vendor Id and Accommodation Id</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts?.length > 0
            ? currentPosts?.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.roomSetName}</td>
                  <td>{data.numberOfAdults}</td>
                  <td>{data.numberOfChildren}</td>
                  {/* <td>{props.vendor}</td> */}
                  <td className={classes.CenterRow}>
                    <Button
                      style={{ margin: "auto 1%" }}
                      variant="danger"
                      onClick={() => HandleDelete(data.id)}
                    >
                      Delete
                    </Button>
                    {/* <Button style={{ margin: "auto 1%" }} variant="success">
                      Edit
                    </Button> */}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default RoomSetList;
