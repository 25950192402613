import React, { useState } from "react";
import { Formik } from "formik";
import { Form, Button, Row, Col } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import { useEffect } from "react";
// import {DropzoneArea} from 'material-ui-dropzone';

const AddBusGeneralView = (props) => {
  const [message, updateMessage] = useState("");
  // const [files,updateFiles] = useState([]);
  const [edit, updateEdit] = useState(false);
  const [currentPoints1, updateCurrentPoints1] = useState(1);
  const [currentPoints, updateCurrentPoints] = useState(1);
  // const [arrPoints, updateArrPoints] = useState([1]);
  // const handleImages = (inpFile)=>{
  //     updateFiles(inpFile)
  // }

  const [arrPoints, updateArrPoints] = useState([
    ...new Array(props.data.cancellation?.length).fill(0).map((_, i) => i),
  ]);

  const [arrPoints1, updateArrPoints1] = useState([
    ...new Array(props.data.seatType?.length).fill(0).map((_, i) => i),
  ]);

  console.log(arrPoints1);

  console.log(props.data);

  useEffect(() => {
    updateMessage(props.data.termsAndConditions);
  }, []);

  console.log(message);
  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    } else {
      const newCurrentPoint1 = currentPoints1 + 1;
      updateCurrentPoints1(newCurrentPoint1);
      updateArrPoints1([...arrPoints1, newCurrentPoint1]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    } else if (type === 2 && currentPoints1 > 1) {
      const newCurrentPoint1 = currentPoints1 - 1;
      updateCurrentPoints1(newCurrentPoint1);
      let arr = [...arrPoints1];
      arr.pop();
      updateArrPoints1(arr);
    }
  };

  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          ...Object.assign(
            {},
            ...props.data.cancellation.map((i, index) => ({
              [`cancellation${index}`]: i.cancellation,
              [`cancellationCharges${index}`]: i.cancellationChargesFor,
            }))
          ),

          ...Object.assign(
            {},
            ...props.data.seatType.map((i, index) => ({
              [`seatType${index}`]: i.seatType,
              [`seatCount${index}`]: i.seatCount,
            }))
          ),

          busManufacturerName: props.data.busManufacturerName,
          busName: props.data.busName,
          busRegistrationNumber: props.data.busRegisterationNo,
          busType: props.data.busType,
          // busVendorId: 0,
          // cancellation: [...cancellationCharges],
          emailId: "string",
          liveTracking: props.data.liveTracking,
          partialCancellation: props.data.partialCancellation,
          // seatType: [...seatTypes1],
          sessionKey: "string",
          termsAndConditions: message,
          vendorId: props.data.vendorId,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
          const charges = [];
          const cancellation = [];
          const seatType = [];
          const seatCount = [];

          Object.entries(values).map(([key, value]) => {
            console.log(key, value);
            if (
              key.includes("cancellationCharges") &&
              typeof value === "string"
            ) {
              charges.push(value);
            } else if (
              key.includes("cancellation") &&
              typeof value === "string"
            ) {
              cancellation.push(value);
            } else if (key.includes("seatCount") && typeof value === "number") {
              seatCount.push(value);
            } else if (key.includes("seatType") && typeof value === "string") {
              seatType.push(value);
            }
          });

          const cancellationCharges = [];
          const seatTypes1 = [];

          for (let i = 0; i < charges.length; i++) {
            cancellationCharges.push({
              cancellation: cancellation[i],
              cancellationCharges: charges[i],
            });
          }

          for (let i = 0; i < seatCount.length; i++) {
            seatTypes1.push({
              seatCount: seatCount[i],
              seatType: seatType[i],
            });
          }

          console.log(seatTypes1);
          console.log(cancellationCharges);

          console.log(charges, cancellation, "checkkkkk");
          console.log(seatCount, seatType, "ccccccccccc");
          const newdata = {
            busManufacturerName: values.busManufacturerName,
            busName: values.busName,
            busRegisterationNo: values.busRegistrationNumber,
            busType: values.busType,
            busVendorId: props.data.id,
            cancellation: [...cancellationCharges],
            emailId: "string",
            liveTracking: values.liveTracking,
            partialCancellation: values.partialCancellation,
            seatType: [...seatTypes1],
            sessionKey: "string",
            termsAndConditions: message,
            vendorId: props.data.vendorId,
          };

          const res = await fetch(
            "https://api.zupptravel.com/updateBusVendor",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newdata),
            }
          );

          console.log("rrrrrrrrrrrr", newdata);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("Bus Vendor Updated!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "2%",
              }}
            >
              {!edit ? (
                <Button variant="success" onClick={() => updateEdit(true)}>
                  Edit
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={() => {
                    updateEdit(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="light"
                style={{ marginLeft: "15px" }}
                onClick={() => props.click()}
              >
                {`<--`} Back
              </Button>
            </div>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Bus Registration Number"
                  name="vendorId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vendorId}
                  required
                  // readOnly
                  disabled={!edit}
                />
                {/* <SingleSelect
                  type="3"
                  list={vendorList}
                  change={(select) => updateVendorId(select)}
                /> */}
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicInfo" as={Row}>
              <Form.Label column sm="3">
                Bus Registration Number:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Bus Registration Number"
                  name="busRegistrationNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.busRegistrationNumber}
                  required
                  disabled={!edit}
                />
              </Col>
              <Form.Label column sm="2">
                Bus Manufacturer Name:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Bus Manufacturer Name"
                  name="busManufacturerName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.busManufacturerName}
                  required
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicBusName" as={Row}>
              <Form.Label column sm="3">
                Bus Amenities:
              </Form.Label>

              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Bus Type"
                  name="busAmenities"
                  value={values.busAmenties}
                  required
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicBusName" as={Row}>
              <Form.Label column sm="3">
                Bus Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Bus Type"
                  name="busType"
                  value={values.busType}
                  required
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicBusName" as={Row}>
              <Form.Label column sm="3">
                Bus Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Bus Name"
                  name="busName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.busName}
                  required
                  readOnly
                />
              </Col>
            </Form.Group>
            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group controlId={`formSeatType${d}`} key={key} as={Row}>
                    <Form.Label column sm="3">
                      Seat Type:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Enter Seat Type ( option ${d} )`}
                        name={`seatType${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`seatType${d}`]}
                        // value={values.seatType[d]?.seatType}
                        required
                        readOnly
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="number"
                        placeholder="Enter Seat Count"
                        name={`seatCount${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        // value={values.seatType[d]?.seatCount}
                        value={values[`seatCount${d}`]}
                        required
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>
              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div> */}

            <Form.Group controlId="formBasicLiveTracking" as={Row}>
              <Form.Label column sm="3">
                Partial Cancellation:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Partial Cancellation"
                  name="partialCancellation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.partialCancellation}
                  required
                  disabled={!edit}
                  // readOnly
                />
              </Col>
              <Form.Label column sm="2">
                Live Tracking:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Live Tracking"
                  name="liveTracking"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.liveTracking}
                  disabled={!edit}
                  required
                  // readOnly
                />
              </Col>
            </Form.Group>
            {arrPoints1.length > 0
              ? arrPoints1.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Cancellation :
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Cancellation ${d}`}
                        name={`cancellation${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`cancellation${d}`]}
                        required
                        disabled={!edit}
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Charges ${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`cancellationCharges${d}`}
                        value={values[`cancellationCharges${d}`]}
                        required
                        disabled={!edit}
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints1 <= 1 ? true : false}
                onClick={() => handleRemove(2)}
              >
                Remove
              </Button>
              <Button
                variant="success"
                onClick={() => handleAdd(2)}
                disabled={!edit}
              >
                Add
              </Button>
            </div>
            {/* <Form.Group controlId="formBasicChild" as={Row}>
                                            <Form.Label column sm="3">Images:</Form.Label>
                                            <Col sm="9"> 
                                                <DropzoneArea onChange={ handleImages }filesLimit={5}/>
                                            </Col>
                                        </Form.Group> */}
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                  // disabled={!edit}
                />
              </Col>
            </Row>

            <center>
              <Button disabled={!edit} type="submit" variant="success">
                Update Bus
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddBusGeneralView;
