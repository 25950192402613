import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import Page from "../../pagination/pagination";

const AccommodationViewPropertyList = (props) => {
  console.log(props);
  const [dataSet, updateDataSet] = useState([]);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet?.slice(indexOfFirst, indexOfLast);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet?.length / postPerPage));
  }, [dataSet]);
  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        `https://api.zupptravel.com/getAccommodation`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await myresponse.json();
      console.log("jsssoooooonnn", json);

      if (json.successful) {
        updateDataSet(json.accommodations);
      }
    })();
  }, []);

  console.log("daytaa", dataSet);
  const handleclick = (data) => {
    console.log(data);
    props.mydata(data);
    props.click();
  };

  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead style={{ fontSize: "12px" }}>
          <tr>
            <th>S.No</th>
            <th>Vendor ID</th>
            <th>Accommodation ID</th>
            <th>Accommodation Type</th>
            <th>Phone Number</th>
            <th>Accommodation Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.length > 0
            ? currentPosts.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.vendorId}</td>
                  <td>{data.accId}</td>
                  <td>{data.accommodationType}</td>
                  <td>{data.propertyPhone}</td>
                  <td>{data.accommodationName}</td>
                  <td className={classes.CenterRow}>
                    <Button
                      style={{ margin: "auto 1%" }}
                      variant="info"
                      onClick={() => handleclick(data)}
                    >
                      Edit
                    </Button>
                    {data.status === "Active" ? (
                      <Button style={{ margin: "auto 1%" }} variant="success">
                        Active
                      </Button>
                    ) : (
                      <Button style={{ margin: "auto 1%" }} variant="danger">
                        Inactive
                      </Button>
                    )}
                    {/* <Button variant="warning" style={{margin:'auto 1%'}}  onClick={()=>props.setPrice()}>Room Management</Button> */}
                    <Button variant="secondary" style={{ margin: "auto 1%" }}>
                      Booking List
                    </Button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default AccommodationViewPropertyList;
