import React,{useEffect, useState} from 'react';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import {Button,Container} from 'react-bootstrap';
import DraftEditor from '../draftEditor';
import { Markup } from 'interweave';

const Pages = (props)=>{
    const [message,updateMessage] = useState('');
    const [downloaded,updateDownloaded] = useState('');

    useEffect(()=>{
        if(props?.text){
            updateDownloaded(props?.text);
        }
    },[props?.text])
    const handleSubmit = async(id,type)=>{
        const newData = {
            "id": id,
            "serviceName": type,
            "text": message,
          }
          const token = localStorage.getItem('token');
          const authHeader = `Bearer ${token}`;
        const myresponse = await fetch("https://api.zupptravel.com/api/v1/page/updatePage", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                'Authorization': authHeader
            },
            body: JSON.stringify(newData),
        });
        const myjson = await myresponse.json();
        if(myjson.success){
            alert("Successfully Updated");
            window.location.reload();
        }
    }
    return(
        <Container fluid className={classes.Box}> 
            <div>
                <p>{props.type}:</p> 
                <DraftEditor change={(text)=>updateMessage(text)} text={message}/>
            </div>
            <center>
                <Button variant="success" onClick={()=>handleSubmit(props?.id,props?.type)}>Update</Button>
            </center>
            <hr/>
            {downloaded.length>0?<Markup content={downloaded} />:null}
        </Container>
    )
}
export default Pages;