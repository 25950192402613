import React, { useEffect, useState } from 'react';
import {Table, Container} from 'react-bootstrap';
import {GetActiveCoupon } from '../../../entities/action/action';
// import {useSelector} from 'react-redux';
import GiftCardModal from './giftCardModal';

const PurchaseGiftCard = (props)=>{
    const [dataSet,updateDataSet] = useState([1]);
    // const userData = useSelector(state=>state.adminAccount.apiResponse);
    // useEffect(()=>{
    //     (async()=>{
    //         let arr = await GetActiveCoupon();
    //         if(arr!=null){
    //             updateDataSet(arr);
    //         }
    //     })()
    // },[])
 
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Gift Card Details</th>
                        <th>Gift Card Code</th>
                        <th>Amount</th>
                        <th>Payment Status</th> 
                        <th>Code Status</th> 

                    </tr>
                </thead>
                <tbody>
                    {
                        dataSet.length>0?dataSet.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td><GiftCardModal/></td>
                                <td>{data.promocode}</td>
                                <td>{data.promoname}</td>
                                <td>{data.promoname}</td> 
                                <td>{data.promoname}</td> 
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
        </Container>
    )
}
export default PurchaseGiftCard;