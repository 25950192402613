import React, { useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import { DatePicker } from "antd";

import moment from "moment";
const { RangePicker } = DatePicker;

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
}

const SetRoomPrice = (props) => {
  const [selected, updateSelected] = useState("");
  const [date, updateDate] = useState([]);
  const categoryList = [
    { type: "CATEGORY" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
    { type: "Agriculture Products" },
  ];
  return (
    <div>
      <Formik
        initialValues={{
          unitSericeVariablemsp: "",
          taxSlabVariablemsp: "",
          unitSericeVariableocc: "",
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {}}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicBusName" as={Row}>
              <Form.Label column sm="2">
                Cab Route:
              </Form.Label>
              <Col sm="10">
                <SingleSelect change={(select) => updateSelected(select)} />
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasictimePerSlot" as={Row}>
              <Form.Label column sm="2">
                Date:
              </Form.Label>
              <Col>
                <RangePicker
                  onChange={(val) => updateDate(val)}
                  value={date}
                  disabledDate={disabledDate}
                />
              </Col>
            </Form.Group>

            <Form.Label style={{ fontWeight: "bold" }}>Room Cost:</Form.Label>
            <div style={{ border: "1px solid grey" }} className="container">
              <Form.Group
                controlId="formBasicMrp"
                as={Row}
                style={{ margin: "1% 0" }}
              >
                <Form.Label>Unit Price:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step={0.01}
                    placeholder="Enter Unit Price"
                    name="unitSericeVariablemsp"
                    min={0}
                    onChange={handleChange}
                    value={values.unitSericeVariablemsp}
                    required
                  />
                </Col>
                <Form.Label>Unit GST Rate:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step={0.01}
                    placeholder="G.S.T Rate"
                    name="taxSlabVariablemsp"
                    min={1}
                    max={100}
                    isInvalid={
                      values.taxSlabVariablemsp < 0 ||
                      values.taxSlabVariablemsp > 100
                    }
                    onChange={handleChange}
                    value={values.taxSlabVariablemsp}
                    required
                  />
                </Col>
                <Form.Label>GST Cost:</Form.Label>
                <Col>
                  <Form.Control
                    readOnly
                    name="taxRateVariablemsp"
                    onChange={handleChange}
                    value={
                      values.unitSericeVariablemsp *
                      0.01 *
                      values.taxSlabVariablemsp
                    }
                    required
                  />
                </Col>
                <Form.Label>M.R.P:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step={0.01}
                    readOnly
                    min={0}
                    placeholder="M.R.P"
                    name="variableCostmsp"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={
                      values.unitSericeVariablemsp +
                      values.unitSericeVariablemsp *
                        0.01 *
                        values.taxSlabVariablemsp
                    }
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group
                controlId="formBasicAppliesTo"
                as={Row}
                style={{ margin: "1% 0" }}
              ></Form.Group>
            </div>
            <Form.Label style={{ fontWeight: "bold" }}>
              Room Cost to CX:
            </Form.Label>
            <div style={{ border: "1px solid grey" }} className="container">
              <Form.Group
                controlId="formBasicMrp"
                as={Row}
                style={{ margin: "1% 0" }}
              >
                <Form.Label>Unit Price:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitSericeVariableocc"
                    min={0}
                    isInvalid={
                      values.unitSericeVariableocc >
                      values.unitSericeVariablemsp
                    }
                    onChange={handleChange}
                    value={values.unitSericeVariableocc}
                    required
                  />
                </Col>
                <Form.Label>Unit GST Rate:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder="G.S.T Rate"
                    name="taxSlabVariableocc"
                    min={1}
                    max={100}
                    isInvalid={
                      values.taxSlabVariablemsp < 0 ||
                      values.taxSlabVariablemsp > 100
                    }
                    onChange={handleChange}
                    value={values.taxSlabVariablemsp}
                    required
                  />
                </Col>
                <Form.Label>GST Cost:</Form.Label>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="taxRateVariableocc"
                    onChange={handleChange}
                    value={
                      values.unitSericeVariableocc *
                      0.01 *
                      values.taxSlabVariablemsp
                    }
                    required
                  />
                </Col>
                <Form.Label>Selling Price:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    readOnly
                    min={0}
                    placeholder="Selling Price"
                    name="variableCostocc"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={
                      values.unitSericeVariableocc +
                      values.unitSericeVariableocc *
                        0.01 *
                        values.taxSlabVariablemsp
                    }
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group
                controlId="formBasicAppliesTo"
                as={Row}
                style={{ margin: "1% 0" }}
              ></Form.Group>
            </div>
            <Form.Label style={{ fontWeight: "bold" }}>
              Room Cost to ZT:
            </Form.Label>
            <div style={{ border: "1px solid grey" }} className="container">
              <Form.Group
                controlId="formBasicMrp"
                as={Row}
                style={{ margin: "1% 0" }}
              >
                <Form.Label>Unit Price</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitSericeVariableww"
                    min={0}
                    value={values.unitSericeVariableocc * 0.8675}
                    required
                  />
                </Col>
                <Form.Label>Unit GST Rate:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder="G.S.T Rate"
                    name="taxSlabVariableww"
                    min={1}
                    max={100}
                    isInvalid={
                      values.taxSlabVariablemsp < 0 ||
                      values.taxSlabVariablemsp > 100
                    }
                    value={values.taxSlabVariablemsp}
                    required
                  />
                </Col>
                <Form.Label>GST Cost:</Form.Label>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="taxRateVariableww"
                    value={
                      values.unitSericeVariableocc *
                      0.8675 *
                      0.01 *
                      values.taxSlabVariablemsp
                    }
                    required
                  />
                </Col>
                <Form.Label>Purchase Price:</Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    readOnly
                    min={0}
                    placeholder="Purchase Price"
                    name="variableCostww"
                    value={
                      values.unitSericeVariableocc *
                        0.8675 *
                        0.01 *
                        values.taxSlabVariablemsp +
                      values.unitSericeVariableocc * 0.8675
                    }
                    required
                  />
                </Col>
              </Form.Group>
            </div>
            <center>
              <Button
                style={{ marginTop: "15px" }}
                type="submit"
                variant="success"
              >
                Save Room Price
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default SetRoomPrice;
