import React, {useState, Fragment } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import classesX from '../../Dashboard/dashboard.module.css'
import AdminNavbar from '../../navbar/adminNavbar';
import BusBookingDetails from './BusBookingDetails';
import BusCustomerDetails from './BusCustomerDetails';
import BusBillingDetails from './BusBillingDetails';
const BusDetails = (props)=>{
    const [active,updateActive] = useState('1');
    const items = [
        {id:"1",type:"Booking Details"},
        {id:"2",type:"Customer Details"},
        {id:"3",type:"Cancellation Details"},
        {id:"4",type:"Billing Details"}
      
    ]
 
    return(
        <Fragment> 
           {/* <AdminNavbar/> */}
            <h1 className={classesX.Heading}>Bus Details</h1>  
       
<Container fluid style={{ paddingLeft: '10px', width: '90%' }}>
    <Row>
        <Col lg={12} md={12} className={classes.Response2}>
            <div style={{ height: '100%' }}>
                <ul className={classes.Ul} style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 }}>
                    {
                        items.map(item => (
                            <li
                                key={item.id}
                                onClick={() => updateActive(item.id)}
                                className={classes.Li}
                                style={{
                                    backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                                    textAlign: 'center',
                                    margin: '0 1px', // Adjust the margin as needed
                                    padding:" 2% 0",
                                    color: 'white',
                                    cursor: 'pointer',
                                    width:"25%",
                                    height:"100%"
                                }}
                            >
                                {item.type}
                            </li>
                        ))
                    }
                </ul>
            </div>
        </Col>
        <Col lg={12} md={12} className={classes.Response}>
            <div style={{ height: '100%' }}>
            {
                active === "1" ? (
                    <BusBookingDetails/>
                ) : active === "2" ? (
                    <BusCustomerDetails/>
                ) : active == "3" ? (
                  null
                ) : active == "4" ? (
                 <BusBillingDetails/>
                ) : null
                // active==="3"?<CouponView data={couponData}/>:null
              }
            </div>
        </Col>
    </Row>
</Container>

        </Fragment>
    )
}

export default BusDetails;