import React,{useState} from 'react';
import {Tab,Tabs} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import VehicleViewPropertyList from './VehicleViewPropertyList';
import AddBusGeneralView from './addVehicleGeneralView';
import SetPrice from './setPrice';
import RoomInventory from './roomInventory';

    const VehicleView = (props)=>{
        const [active,updateactive] = useState("1");
        return(
            <>
            {
                <Tabs defaultActiveKey="vehicleDetails" id="uncontrolled-tab-example"   >
                    <Tab eventKey="vehicleDetails" title={active==="3"?"Vehicle Details":"Vehicle Details"} >
                        <Tab.Container id="left-tabs-example" defaultActiveKey="vehicleDetails" >  
                            {
                                active==="1"?<VehicleViewPropertyList click={()=>updateactive("2")} setPrice={()=>updateactive("3")}/>:
                                active==="2"?<AddBusGeneralView click={()=>updateactive("1")}/>:
                                active==="3"?<SetPrice click={()=>updateactive("1")}/>:null
                            }           
                        </Tab.Container>
                    </Tab>
                    {
                        active==="3"?
                            <Tab eventKey="vehicleInventoryList" title="Vehicle Inventory List" className={classes.Tabs} >
                                <RoomInventory click={()=>updateactive("1")}/>
                            </Tab>:null
                    }
                </Tabs>
            }
        </>    
        )
    }
    export default VehicleView;