import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import Page from "../../pagination/pagination";

const RoomInventoryList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet.slice(indexOfFirst, indexOfLast);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet.length / postPerPage));
  }, [dataSet]);

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        `https://api.zupptravel.com/getTourGuideInventory`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await myresponse.json();
      console.log(json);
      if (json.successful) {
        updateDataSet(json.tourGuideInventorys);
      }
    })();
  }, []);

  console.log(dataSet);

  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>TG ID</th>
            <th>TG Name</th>
            <th>From-To</th>
            <th>Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.length > 0
            ? currentPosts.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.tourGuideId}</td>
                  <td>{data.tourGuideName}</td>
                  <td>
                    {data.startDate} - {data.endDate}
                  </td>
                  <td>{data.time}</td>
                  <td>
                    {data.status ? (
                      <Button variant="danger">Block</Button>
                    ) : (
                      <Button variant="warning">Unblock</Button>
                    )}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default RoomInventoryList;
