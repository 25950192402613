import React, { Fragment, useState, useEffect } from 'react';
import {Col,Table, DropdownButton,Container, Button} from 'react-bootstrap';
// import classes from '../WebsiteManagement/websiteManagement.module.css';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import {useSelector} from 'react-redux';
import AccommodationDetails from './AccommodationDetails';

const BookingDetails = (props)=>{
    const [dataSet,updateDataSet] = useState([]);

    const [active, updateActive] = useState('1');
    const [data, updateData] = useState({});
    

  
        const [showDetails, setShowDetails] = useState(false);

        const handleViewClick = () => {
          setShowDetails(true);
        };
      
        const handleCloseDetails = () => {
          setShowDetails(false);
        };

        const horizontalData = [
            { PNR: "-", Amount: "-", "Number of Rooms": "-", "Accommodation ID": "-", "Guest(s)": "-", "Accommodation Name": "-", "Accommodation Address": "-", "Contact Details": "-", Room: "-", Meal: "-", "Check In Date & Time": "-", "Check Out Date & Time": "-", Amenities: "-" },
            // Add more rows as needed
          ];
        
          // Transpose the data
          const verticalData = Object.keys(horizontalData[0]).map((key) => {
            return { attribute: key, values: horizontalData.map((item) => item[key]) };
          });
    return(
//         <Container fluid>
//   <Col lg={12} md={12} className={classes.Response2}>
//              <Table striped bordered hover>
// <thead>
//     <tr>
//         <th>S.No</th>
//         <th>PNR</th>
//         <th>Amount</th>
//         <th>Number of Rooms</th>
//         <th>Ticket Id</th>
//         <th>Accommodation Id</th>
//         <th>Guests</th>
//         <th>Accommodation Name</th>
//         <th>Accommodation Address</th>
//         <th>Contact Details</th>
//         <th>Room</th>
//         <th>Meal</th>
//         <th>Check In Date & Time</th>
//         <th>Check Out Date & Time</th>
//         <th>Amenities</th>
//     </tr>
// </thead>
// <tbody>
// {
//     // dataSet.length>0?dataSet.map((data,key)=>
//     // <tr key={key}>
//     <tr>
//         {/* <td>{key+1}</td> */}
//         <td>1</td>
//         <td>-</td>
//         <td>-</td>
//         <td>-</td>
//         <td>-</td>
//         <td>-</td>
//         <td>-</td>
//         <td>-</td>
//         <td>-</td>
//         <td>-</td>
//         <td>-</td>
//         <td>-</td>
//         <td>-</td>
//         <td>-</td>
//         {/* <td>-</td> */}
        
//         <td>
//             {/* <DropdownButton id="dropdown-variants-Success" title="Actions"> */}
//                 <div className={classes.Center}>
//                     <Button variant="danger" onClick={() => props.click(data)}>View</Button>
//                 </div>
//             {/* </DropdownButton> */}
//         </td>
//     </tr>       
//     // ):null
// }
// </tbody>
// </Table>
// </Col>
// </Container>
<Container fluid style={{marginTop:"5vh"}}>
<Col lg={12} md={12} className={classes.Response2}>
  <Table striped bordered hover>
 
    <tbody>
      {verticalData.map((data, key) => (
        <tr key={key}>
          <td style={{ width: '35%' }}>{data.attribute}</td>
          <td style={{ width: '65%' }}>{data.values.join(', ')}</td>
        </tr>
      ))}
    </tbody>
  </Table>
  {/* <div className={classes.Center}>
    <Button variant="danger">View</Button>
  </div>  */}
</Col>
</Container>

)
}
export default BookingDetails;
