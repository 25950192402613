import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {Table, Container, Button} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import Page from '../../pagination/pagination';

const DiscountList = (props)=>{
    const [dataSet,updateDataSet] = useState([]);
    // const [data,updateData] = useState([]);
    const data = useSelector((state) => state.adminAccount.apiResponse);
    console.log("data",data);
    useEffect(() => {
        // Function to fetch data from the API
        const fetchData = async () => {
          try {
            // Replace with your actual payload
            const payload = {
              emailId: data.key,
              password: "",
              phoneno: data.phone,
              sessionKey: data.sessionId,
            };
    
            const response = await fetch('https://api.zupptravel.com/getMarkupDiscount', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(payload),
            });
    
            const jsonData = await response.json();
     console.log("jason dta",jsonData)
            if (jsonData.successful) {
              // Update the dataSet state with the fetched data
              updateDataSet(jsonData.markupDiscountList);
            } else {
              console.error('API request unsuccessful:', jsonData.message);
            }
          } catch (error) {
            console.error('Error fetching data:', error);
          }
        };
    
        // Call the fetchData function when the component mounts
        fetchData();
      }, []); // Empty dependency array ensures that the effect runs only once
    

      console.log("data from get api",dataSet);
    const [count,updateCount] = useState(0)
    const postPerPage = 20;
    const [currentPage,updateCurrentPage] = useState(1);
    const indexOfLast = currentPage*postPerPage;
    const indexOfFirst = indexOfLast - postPerPage;
    const currentPosts = dataSet.slice(indexOfFirst,indexOfLast);
    const handleChangePage = (event,value)=>{
        updateCurrentPage(value)
    }
    useEffect(()=>{
        updateCount(Math.ceil(dataSet.length/postPerPage))
    },[dataSet])

    const handleDelete = async (markupDiscountId) => {
        try {
          // Replace with your actual payload
          const payload = {
            emailId: data.data.key,
            markupDiscountId: markupDiscountId, // Pass the specific markupDiscountId to delete
            sessionKey: data.sessionId,
          };
    console.log("payload",payload)
          const response = await fetch('https://api.zupptravel.com/deleteMarkupDiscount', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
          });
    
          const jsonData = await response.json();
    console.log('json data',jsonData)
          if (jsonData.successful) {
            alert("Deleted successfully!");
            window.location.reload();
          }else {
            alert("Somethng Went Wrong");
                console.log('Delete unsuccessful:', jsonData.successful);
              }
        //     // updateDataSet((prevData) => prevData.filter((item) => item.markupDiscountId !== markupDiscountId));
        //     // Optionally, you can redirect to the main page here
        //     window.location.reload();
        //     // Assuming dataSet is an array of objects with 'markupDiscountId' property
        //     // Update the dataSet state by removing the deleted item
        //     updateDataSet((prevData) => prevData.filter((item) => item.markupDiscountId !== markupDiscountId));
            
        //     console.log('Delete successful:', jsonData.message);
        //   } else {
        //     console.error('Delete unsuccessful:', jsonData.message);
        //   }
        } 
          catch (error) {
          console.error('Error deleting data:', error);
        }
      };
     
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Discount Type</th>
                        <th>Airlines</th>
                        <th>Amount Type</th>
                        <th>Fix</th>
                        <th>Basic</th>
                        <th>YQ</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentPosts.length>0?currentPosts.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.discountType}</td>
                                <td>{data.airlines}</td>
                                <td>{data.amountType}</td>
                                <td>{data.fixAmount}</td>
                                <td>{data.enterBasic}</td>
                                <td>{data.yq}</td>
                                <td className={classes.CenterRow}>
                                    <Button style={{margin:'auto 1%'}} variant="danger"
                                      onClick={() => handleDelete(data.id)}>Delete</Button>
                                    {/* <Button style={{margin:'auto 1%'}} variant="success">Edit</Button> */}
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
            <div style={{display:'flex',justifyContent:'center',margin:'15px 0'}}>
                <Page count={count} change={handleChangePage} page={currentPage}/> 
            </div>
        </Container>
    )
}
export default DiscountList;