import React from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { DatePicker } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
// const { RangePicker } = DatePicker;
const { RangePicker } = DatePicker;

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
}

const BlockAndUnblock = (props) => {
  const [roomNames, updateRoomNames] = useState([]);
  const [date, updateDate] = useState([]);
  const [roomName, updateRoomName] = useState("");
  const [roomNames1, updateRoomNames1] = useState([]);
  const [id, updateid] = useState([]);

  const handlePrice = (x) => {
    updateRoomName(x);
  };

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getRoomCategory", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const json = await res.json();
      console.log("firstss ", json);
      if (json.successful) {
        updateRoomNames(json.roomCategorys);
      }
    })();
  }, []);
  useEffect(() => {
    let r = [];
    roomNames.map((data, id) => {
      if (data.accId == props.accid) {
        r.push(data);
      }
    });
    updateRoomNames1(r);
  }, [props.accid]);

  // useEffect(() => {
  //   let arr = [];
  //   roomNames.map((data, key) => {
  //     if ((data.name = roomName)) {
  //       arr.push(data.id);
  //     }
  //   });
  //   updateid([...arr]);
  // }, [roomName]);

  console.log(id);
  console.log(roomName);
  console.log("Room names data", roomNames);

  return (
    <div>
      <Formik
        initialValues={{
          unitSericeVariablemsp: "",
          taxSlabVariablemsp: "",
          unitSericeVariableocc: "",
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const newdata = {
            accommodationId: props.id[0],
            block: true,
            dateFrom: date[0]?._d.toISOString().slice(0, 10),
            dateTo: date[1]?._d.toISOString().slice(0, 10),
            emailId: "string",
            noOfBlockedRooms: values.noOfRooms,
            roomCategoryId: id[0],
            roomCategoryName: values.roomName,
            roomOccupancyId: "string",
            sessionKey: "string",
            vendorId: props.vid[0],
          };

          const res = await fetch("https://api.zupptravel.com/blockRooms", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newdata),
          });

          console.log("rrrrrrrrrrrr", newdata);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("Blocked Room!");
            window.location.reload();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicActionType" as={Row}>
              <Form.Label column sm="2">
                Action Type:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  placeholder="Action Type"
                  name="actionType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.actionType}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Block">Block</option>
                  <option value="Unblock">Unblock</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicRoomName" as={Row}>
              <Form.Label column sm="2">
                Room Name:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  placeholder="Room Name"
                  name="roomName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.roomName}
                  required
                >
                  <option value="">--Select--</option>
                  {roomNames1.map((item, key) => {
                    return (
                      <>
                        <option value={item.name}>{item.name}</option>
                      </>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasictimePerSlot" as={Row}>
              <Form.Label column sm="2">
                Date:
              </Form.Label>
              <Col>
                <RangePicker
                  onChange={(val) => updateDate(val)}
                  value={date}
                  disabledDate={disabledDate}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicMetaNoOfRooms" as={Row}>
              <Form.Label column sm="2">
                No. of Rooms:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  placeholder="Enter No. of Rooms"
                  name="noOfRooms"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.noOfRooms}
                  required
                />
              </Col>
            </Form.Group>
            <center>
              <Button type="submit" variant="success">
                Block/Unblock Room
              </Button>
            </center>

            {handlePrice(values.roomName)}
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default BlockAndUnblock;
