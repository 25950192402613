// import React, { Fragment ,useState} from 'react';
// import { Col, Container, Row, Table, Dropdown } from 'react-bootstrap';
// import classes from '../WebsiteManagement/websiteManagement.module.css';
// import FinanceModal from './financeModal';
// import FinanceList from './financeDetails';

// export default function SupplierFinance(props) {


//     const [normaldata, setNormaldata] = useState("");
//     const [detaildedData, setDetaildedData] = useState("");

//     // console.log("props from parent componenT",props);
//     // console.log("props from parent componenT",props.normaldata);
//     // console.log("props from parent componenT",props.detaildedData);
//     setNormaldata(props.normaldata || 0);
//     setDetaildedData(props.detaildedData || 0);
    
//     return (
//         <Fragment>
//             <Container>
//                 <div className={classes.Box}>
              
//                     <Row>
//                         <Col md={9} xl={9}>
                           
//                             <Table striped bordered hover responsive>

//                                 <tr>
//                                     <td><strong>Total Balance: </strong></td>
//                                     <td>0</td>
//                                     {/* <td>{props.data.totalbalance}</td> */}
//                                 </tr>
//                                 <tr>
//                                     <td><strong>Credit Amount: </strong></td>
//                                     <td>0</td>
//                                     {/* <td>{props.data.creditAmount} </td> */}

//                                 </tr>
//                             </Table>
//                         </Col>
//                         <Col md={3} xl={3}>
//                             <Dropdown>
//                                 <Dropdown.Toggle variant="danger" id="dropdown-basic">
//                                     Action
//                                 </Dropdown.Toggle>

//                                 <Dropdown.Menu right>
//                                     <FinanceModal type="Top Up"/>
//                                     <FinanceModal type="Deduct"/>
//                                     <FinanceModal type="Credit"/>
//                                 </Dropdown.Menu>
//                             </Dropdown>
//                         </Col>
//                     </Row>
//                     <FinanceList/>
//                 </div>
//             </Container>
//         </Fragment>
//     );
// }




import React, { Fragment,useState } from 'react';
import { Col, Container, Row, Table, Dropdown } from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import FinanceModal from './financeModal';
import FinanceList from './financeDetails';

export default function SupplierFinance(props) {
console.log("props from parent",props);
console.log("props from parent",props.props.normaldata);
console.log("props from parent",props.props.detaildedData);
   
const topUp ={
        normaldata : props.props.normaldata,
        detaildedData:props.props.detaildedData,
        type:"Top Up"
    }
    const deduct ={
        normaldata : props.props.normaldata,
        detaildedData:props.props.detaildedData,
        type:"Deduct"
    }
    const credit ={
        normaldata : props.props.normaldata,
        detaildedData:props.props.detaildedData,
        type:"Credit"
    }
    // console.log("topUp",topUp);
    // console.log("deduct",deduct);
    // console.log("credit",credit);

    return (
        <Fragment>
            <Container>
                <div className={classes.Box}>
              
                    <Row>
                        <Col md={9} xl={9}>
                           
                            <Table striped bordered hover responsive>

                                <tr>
                                    <td><strong>Total Balance: </strong></td>
                                    <td>0</td>
                                    {/* <td>{props.data.totalbalance}</td> */}
                                </tr>
                                <tr>
                                    <td><strong>Credit Amount: </strong></td>
                                    <td>0</td>
                                    {/* <td>{props.data.creditAmount} </td> */}

                                </tr>
                            </Table>
                        </Col>
                        <Col md={3} xl={3}>
                            <Dropdown>
                                <Dropdown.Toggle variant="danger" id="dropdown-basic">
                                    Action
                                </Dropdown.Toggle>

                                <Dropdown.Menu right>
                                    <FinanceModal props ={topUp}/>
                                    <FinanceModal props ={deduct}/>
                                    <FinanceModal props ={credit}/>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <FinanceList/>
                </div>
            </Container>
        </Fragment>
    );
}