import React, { useState,useEffect } from 'react';
import {Form,Button,Row,Col} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import Schedular from '../../../utilities/schedular/scheduler.js';
import SingleSelect from '../../../utilities/singleSelect/singleSelect';
import { Formik } from 'formik';


const PriceAndAvailability = (props)=>{
    const [selected,updateSelected] = useState('');
    const[arr,updateArr] = useState([]);
    const[scheduleData,updateScheduleData] = useState([]);
    const [schedular,updateSchedular] = useState(1);
    const [unitSericeVariablemsp,updateUnitSericeVariablemsp] = useState('');
    const [taxSlabVariablemsp,updateTaxSlabVariablemsp] = useState('');
    const [unitSericeVariableocc,updateUnitSericeVariableocc] = useState('');
    useEffect(()=>{
        if(schedular<0){
            alert("Please Enter Valid Number of Schedulars");
        }else{
            let newArr = [];
            for(let i=0;i<schedular;i++){
                newArr.push(i);
            }
            updateArr(newArr);   
            }
    },[schedular])
    const handleChanges = (key,data)=>{
        if(data.date!=undefined && data.slot!=null && data.slot!=undefined){
            console.log(data.date)
        console.log(scheduleData);
        const slots = [];
        let i;
        for(i=0;i<parseInt(data.slot);i++){
            slots[i] = {
                "quantity":data.quantity[i],
                "startTime":data.time[i]
            }
        }
        const newdata = {
            "endingDate": data,
            "numberOfSlot": data.slot,
            "slots": slots,
            "startingDate": data,
            "timePerSlot": data.timePerSlot
          }
        let tempArr = [...scheduleData];
        tempArr[key] = newdata;
        updateScheduleData(tempArr);
        }
    }
    const handleSubmit = ()=>{

    }
    return(
        <div className={classes.Box}>
            <Formik
            initialValues={{
            }}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    console.log(scheduleData);
                    const newdata = {
                        "activityAmount": 0,
                        "activityCXAmount": 0,
                        "activityCXGstCost": 0,
                        "activityCXGstRate": 0,
                        "activityGstCost": 0,
                        "activityGstRate": 0,
                        "activityType": "string",
                        "activityZTAmount": 0,
                        "activityZTGstCost": 0,
                        "activityZTGstRate": 0,
                        "emailId": "string",
                        "mrp": 0,
                        "noOfSchedulars": 0,
                        "purchasePrice": 0,
                        "schedular": scheduleData,
                        "sellingPrice": 0,
                        "sessionKey": "string",
                        "vendorActivitiesPriceAndAvailabilityId": 0,
                        "vendorActivityId": 0
                    }
                    const res = await fetch('https://api.zupptravel.com/addVendorActivitiesPriceAndAvailability',{
                        method: "post",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body:JSON.stringify(newdata)
                    })
                    const json = await res.json();
                    if(json.successful){
                        alert("Added Activity Pricing!");
                        props.click();
                    }else{
                        alert("Error! Please try again later");
                    }
                }
            }
            >
                {
                ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formBasicActivityID" as={Row}>
                    <Form.Label column sm="2">Activity ID:</Form.Label>
                    <Col sm="10"> 
                        <SingleSelect type="1" change={(select)=>updateSelected(select)}/>
                    </Col>
                </Form.Group>
                <Form.Group controlId="formBasicBrand" as={Row}>
                    <Form.Label column sm="2">Number of Schedulars:</Form.Label>
                    <Col sm="10"> 
                        <Form.Control
                            type="text" 
                            placeholder="Enter Number" 
                            name="schedular"
                            onChange={(e)=>updateSchedular(Number(e.target.value))}
                            value={schedular}
                            required/>
                    </Col>
                </Form.Group>
                {
                    arr.length>0?arr.map((_,i)=>
                        <Schedular key={i} change={(data)=>handleChanges(i,data)}/>
                    ):null
                }
                                        <Form.Label style={{fontWeight:"bold"}}>Activity Cost:</Form.Label>
                                        <div style={{border:"1px solid grey"}} className="container">
                                        
                                        <Form.Group controlId="formBasicMrp" as={Row} style={{margin:"1% 0"}}>
                                            <Form.Label>Unit Price:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    type="number" 
                                                    step={0.01}
                                                    placeholder="Enter Unit Price" 
                                                    name="unitSericeVariablemsp"
                                                    min={0}
                                                    onChange={(e)=>updateUnitSericeVariablemsp(Number(e.target.value))}
                                                    value={unitSericeVariablemsp}
                                                    required/>
                                            </Col>
                                            <Form.Label>Unit GST Rate:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    type="number" 
                                                    step={0.01}
                                                    placeholder="G.S.T Rate" 
                                                    name="taxSlabVariablemsp"
                                                    min={1}
                                                    max={100}
                                                    isInvalid={taxSlabVariablemsp<0||taxSlabVariablemsp>100}
                                                    onChange={(e)=>updateTaxSlabVariablemsp(Number(e.target.value))}
                                                    value={taxSlabVariablemsp}
                                                    required/>
                                            </Col>
                                            <Form.Label >GST Cost:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    readOnly 
                                                    name="taxRateVariablemsp"
                                                    value={unitSericeVariablemsp*0.01*taxSlabVariablemsp}
                                                    required/>
                                            </Col>  
                                            <Form.Label >M.R.P:</Form.Label>
                                            <Col > 

                                                <Form.Control
                                                    type="number" 
                                                    step={0.01}
                                                    readOnly
                                                    min={0}
                                                    placeholder="M.R.P" 
                                                    name="variableCostmsp"
                                                    value={unitSericeVariablemsp+unitSericeVariablemsp*0.01*taxSlabVariablemsp}
                                                    required/>
                                            </Col> 
                                        </Form.Group>
                                        <Form.Group controlId="formBasicAppliesTo" as={Row} style={{margin:"1% 0"}}>
                                        
                                        </Form.Group>
                                    
                                        </div>
                                        <Form.Label style={{fontWeight:"bold"}}>Activity Cost to CX:</Form.Label>
                                        <div style={{border:"1px solid grey"}} className="container">
                                    
                                        <Form.Group controlId="formBasicMrp" as={Row} style={{margin:"1% 0"}}>
                                            <Form.Label >Unit Price:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    type="number" 
                                                    step="any"
                                                    placeholder="Enter Unit Price" 
                                                    name="unitSericeVariableocc"
                                                    min={0}
                                                    isInvalid={unitSericeVariableocc>unitSericeVariablemsp}
                                                    onChange={(e)=>updateUnitSericeVariableocc(Number(e.target.value))}
                                                    value={unitSericeVariableocc}
                                                    required/>
                                                
                                            </Col>
                                            <Form.Label>Unit GST Rate:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    type="number" 
                                                    step="any"
                                                    placeholder="G.S.T Rate" 
                                                    name="taxSlabVariableocc"
                                                    min={1}
                                                    max={100}
                                                    isInvalid={taxSlabVariablemsp<0||taxSlabVariablemsp>100}
                                                    onChange={(e)=>updateTaxSlabVariablemsp(Number(e.target.value))}
                                                    value={taxSlabVariablemsp}
                                                    required/>
                                            </Col>
                                            <Form.Label >GST Cost:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    readOnly 
                                                    defaultValue="0" 
                                                    name="taxRateVariableocc"
                                                    value={unitSericeVariableocc*0.01*taxSlabVariablemsp}
                                                    required/>
                                            </Col>  
                                            <Form.Label >Selling Price:</Form.Label>
                                            <Col> 
                                                <Form.Control
                                                    type="number" 
                                                    step="any"
                                                    readOnly
                                                    min={0}
                                                    placeholder="Selling Price" 
                                                    name="variableCostocc"
                                                    value={unitSericeVariableocc+unitSericeVariableocc*0.01*taxSlabVariablemsp}
                                                    required/>
                                            </Col> 
                                        </Form.Group>
                                        <Form.Group controlId="formBasicAppliesTo" as={Row} style={{margin:"1% 0"}}>
                                        
                                        </Form.Group>
                                        </div>
                                        <Form.Label style={{fontWeight:"bold"}}>Activity Cost to ZT:</Form.Label>
                                        <div style={{border:"1px solid grey"}} className="container">
                                        
                                        <Form.Group controlId="formBasicMrp" as={Row} style={{margin:"1% 0"}}>
                                        <Form.Label >Unit Price</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    type="number" 
                                                    step="any"
                                                    placeholder="Enter Unit Price" 
                                                    name="unitSericeVariableww"
                                                    min={0}
                                                    value={unitSericeVariableocc*.8675}
                                                    required/>
                                            </Col>
                                            <Form.Label >Unit GST Rate:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    type="number" 
                                                    step="any"
                                                    placeholder="G.S.T Rate" 
                                                    name="taxSlabVariableww"
                                                    min={1}
                                                    max={100}
                                                    isInvalid={taxSlabVariablemsp<0||taxSlabVariablemsp>100}
                                                    value={taxSlabVariablemsp}
                                                    required/>
                                            </Col>
                                            <Form.Label >GST Cost:</Form.Label>
                                            <Col> 
                                                <Form.Control
                                                    readOnly 
                                                    defaultValue="0" 
                                                    name="taxRateVariableww"
                                                    value={unitSericeVariableocc*0.8675*0.01*taxSlabVariablemsp}
                                                    required/>
                                            </Col>  
                                            <Form.Label >Purchase Price:</Form.Label>
                                            <Col> 
                                                <Form.Control
                                                    type="number" 
                                                    step="any"
                                                    readOnly
                                                    min={0}
                                                    placeholder="Purchase Price" 
                                                    name="variableCostww"
                                                    value={unitSericeVariableocc*0.8675*0.01*taxSlabVariablemsp +unitSericeVariableocc*0.8675 }
                                                    required/>
                                            </Col> 
                                        </Form.Group>
                                    </div>
                                    <center>
                                        <Button style={{marginTop:'10px'}} type="submit">Submit Price And Availability</Button>
                                    </center>
            </Form>
             )}
            </Formik>
        </div>
    )
}
export default PriceAndAvailability;