import React, { useEffect, useState } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import Page from '../../pagination/pagination';

const ActivityInventoryList = (props)=>{
    const [dataSet,updateDataSet] = useState([1]);
    const [count,updateCount] = useState(0)
    const postPerPage = 20;
    const [currentPage,updateCurrentPage] = useState(1);
    const indexOfLast = currentPage*postPerPage;
    const indexOfFirst = indexOfLast - postPerPage;
    const currentPosts = dataSet.slice(indexOfFirst,indexOfLast);
    const handleChangePage = (event,value)=>{
        updateCurrentPage(value)
    }
    useEffect(()=>{
        updateCount(Math.ceil(dataSet.length/postPerPage))
    },[dataSet])
     
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Activity ID</th>
                        <th>Activity Name</th>
                        <th>From-To</th>
                        <th>Slot</th>
                        <th>Time</th>
                        <th>Quantity</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentPosts.length>0?currentPosts.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.activityId}</td>
                                <td>{data.activityName}</td>
                                <td>{data.from}-{data.to}</td>
                                <td>{data.slot}</td>
                                <td>{data.time}</td>
                                <td>{data.quantity}</td>
                                <td>
                                    {
                                        data.status?
                                        <Button variant="danger">Block</Button>:
                                        <Button variant="warning">Unblock</Button>
                                    }
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
            <div style={{display:'flex',justifyContent:'center',margin:'15px 0'}}>
                <Page count={count} change={handleChangePage} page={currentPage}/> 
            </div>
        </Container>
    )
}
export default ActivityInventoryList;