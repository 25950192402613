import * as actionType from '../../actionType';
import {GetDepartment,GetDesignation} from '../../action/action';


export const Logout = ()=>{
    return  dispatch=>{
        dispatch({
            type:actionType.LOGOUT_START_INIT
        })
        try{
            localStorage.clear();
            dispatch({
                type:actionType.LOGOUT_SUCCESS,
            })
        }catch(err){
            dispatch({
                type:actionType.AUTH_FAIL,
                payload:err
            })
        }
    }
}


export const setStorage = (data)=>{
    return async dispatch=>{
        const expirationDate = data?.expirationDate;
        const expirationTime = new Date(expirationDate).getTime() - new Date().getTime();
        localStorage.setItem('token',data?.token);
        localStorage.setItem('expirationDate',expirationDate);
        dispatch(checkAuthTimeout(expirationTime));
        dispatch(loginSuccessVerified())
    }
}

export const loginSuccess = (data)=>{
    return {
        type:actionType.AUTH_SUCCESS,
        payload:data
    }
}

export const loginSuccessVerified = ()=>{
    return{
        type:actionType.LOGIN_VERIFIED
    }
}

export const authRefreshStart = ()=>{
    return{
        type:actionType.AUTH_REFRESH
    }
}

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(Logout());
        }, expirationTime);
    };
};


export const checkAuthState = ()=>{
    return async dispatch=>{
        const token = localStorage.getItem('token');
        if(!token){
            dispatch(Logout())
        }else{
            const expirationDate = localStorage.getItem('expirationDate');
            if(expirationDate<=new Date()){
                dispatch(Logout());
            }else{
                dispatch(authRefreshStart());
                const authHeader = `Bearer ${token}`
                const res = await fetch('https://api.zupptravel.com/api/v1/refresh/authenticate',{
                    method:'post',
                    headers:{
                        'Content-Type':'application/json',
                        'Authorization': authHeader
                    }
                });
                const json = await res.json();
                if(json?.success){
                    dispatch(setStorage(json));
                }else{
                    dispatch(Logout());
                }
            }
        }
    }
}

export const SetDepartment = ()=>{
    return async dispatch=>{
        const res1 = await GetDepartment();
        dispatch({
            type:actionType.GET_DEPARTMENT,
            payload:res1
        })
    }
}

export const SetDesignation = ()=>{
    return async dispatch=>{
        const res2 = await GetDesignation();
        dispatch({
            type:actionType.GET_DESIGNATION,
            payload:res2
        })
    }
}