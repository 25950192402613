import React, { useState, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import classesX from "../Dashboard/dashboard.module.css";
import AdminNavbar from "../navbar/adminNavbar";
import ActiveGiftCard from "./view/giftCardlist";
import PurchaseGiftCard from "./view/giftPurchaseList";
import CreateGiftCard from "./view/addGiftCard";
import AddGiftCategoryAndSubcategory from "./view/AddGiftCategorySubCategory";

const GiftCardManagement = (props) => {
  const [active, updateActive] = useState("1");

  const items = [
    { id: "1", type: "Add Gift Card" },
    { id: "2", type: "Add Gift Category" },
    { id: "3", type: "Gift Card List" },
    { id: "4", type: "Gift Card Purchases List" },
  ];
  return (
    <Fragment>
      <AdminNavbar />
      <h1 className={classesX.Heading}>Gift Card Management</h1>
      <Container fluid>
        <Row>
          <Col lg={12} md={12} className={classes.Response2}>
            <div style={{ height: "100%", width: '95%' }}>
              <ul className={classes.Ul} style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 ,height:"10vh",}}>
                {items.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => updateActive(item.id)}
                    className={classes.Li}
                    style={{
                      backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                      textAlign: 'center',
                      margin: '0 1px', // Adjust the margin as needed
                      padding:" 2% 0",
                      color: 'white',
                      cursor: 'pointer',
                      width:"25%",
                      height:"100%"
                  }}
                  >
                    {item.type}
                  </li>
                ))}
              </ul>
            </div>
          </Col>
          <Col lg={12} md={12} className={classes.Response}>
            <div style={{ height: "100%" ,margin:"2vh 9vw"}}>
              {active === "1" ? (
                <CreateGiftCard click={() => updateActive("3")} />
              ) : active === "2" ? (
                <AddGiftCategoryAndSubcategory />
              ) : active === "3" ? (
                <ActiveGiftCard />
              ) : active === "4" ? (
                <PurchaseGiftCard />
              ) : null}
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default GiftCardManagement;
