import React, {useState, Fragment } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import classesX from '../Dashboard/dashboard.module.css'
import AdminNavbar from '../navbar/adminNavbar';
import AddPackage from './view/addPackage';
import AddItinerary from './view/addItinerary';
import AddPackageCategoryAndStyle from './view/addPackageCategoryAndStyle';
import PackageList from './view/packageList';
import ItineraryList from './view/itineraryList';

const PackageManagement = (props)=>{
    const [active,updateActive] = useState('1');
    const items = [
        {id:"1",type:"Add Package"},
        {id:"2",type:"Add Itinerary"},
        {id:"3",type:"Itinerary List"},
        {id:"4",type:"Package List"},
        {id:"5",type:"Add Package Category And Style"}
    ]
 
    return(
        <Fragment> 
           <AdminNavbar/>
            <h1 className={classesX.Heading}>Holiday Management</h1>  
        <Container fluid style={{paddingLeft:'10px'}}>
            <Row> 
                <Col lg={12} md={12} className={classes.Response2}>
                    <div style={{height:'100%'}}> 
                        <ul className={classes.Ul} style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 ,height:"10vh",}}>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                    style={{
                                        backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                                        textAlign: 'center',
                                        margin: '0 1px', // Adjust the margin as needed
                                        padding:" 2% 0",
                                        color: 'white',
                                        cursor: 'pointer',
                                        width:"20%",
                                        height:"100%"
                                    }}>
                                    {item.type}
                                    </li>
                                )
                            }
                            {
                                active==="6"?
                                <li 
                                    onClick={()=>updateActive("4")}
                                    className={classes.Li}
                                    style={{backgroundColor:`${active==="6"?'steelblue':'#585858'}`,textAlign:'center'}}>
                                    Back
                                </li>:null
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={12} md={12} className={classes.Response}>
                    <div style={{height:'100%',margin:"2vh 9vw"}}> 
                        {
                            active==="1"?<AddPackage/>:
                            active==="2"?<AddItinerary/>:
                            active==="3"?<ItineraryList/>:
                            active==="4"?<PackageList/>:
                            active==="5"?<AddPackageCategoryAndStyle/>:null 
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default PackageManagement;