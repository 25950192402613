import React, { Fragment, useState, useEffect } from 'react';
import {Col,Table, DropdownButton,Container, Button} from 'react-bootstrap';
// import classes from '../WebsiteManagement/websiteManagement.module.css';
import classes from '../../WebsiteManagement/websiteManagement.module.css';

import {useSelector} from 'react-redux';
// import AccommodationDetails from './AccommodationDetails';

const CabData = (props)=>{
    const [dataSet,updateDataSet] = useState([]);

    const [active, updateActive] = useState('1');
    const [data, updateData] = useState({});
    

  
        const [showDetails, setShowDetails] = useState(false);

        const handleViewClick = () => {
          setShowDetails(true);
        };
      
        const handleCloseDetails = () => {
          setShowDetails(false);
        };
    return(
        <Container fluid style={{marginTop:"5vh"}}>
  <Col lg={12} md={12} className={classes.Response2}>
             <Table striped bordered hover>
<thead>
    <tr>
        <th>S.No</th>
        <th>Booking Date and Time</th>
        <th>Booking ID</th>
        <th>Phone Number</th>
        <th>Booking Status</th>
        <th>Action</th>
    </tr>
</thead>
<tbody>
{
    // dataSet.length>0?dataSet.map((data,key)=>
    // <tr key={key}>
    <tr>
        {/* <td>{key+1}</td> */}
        <td>1</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>-</td>
        <td>
            {/* <DropdownButton id="dropdown-variants-Success" title="Actions"> */}
                <div className={classes.Center}>
                    <Button variant="danger" onClick={() => props.click(data)}>View</Button>
                </div>
            {/* </DropdownButton> */}
        </td>
    </tr>       
    // ):null
}
</tbody>
</Table>
</Col>
</Container>

)
}
export default CabData;
