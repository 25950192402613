import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import Page from "../../pagination/pagination";

const AccommodationList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet.slice(indexOfFirst, indexOfLast);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet.length / postPerPage));
  }, [dataSet]);
  const handleclick = (data) => {
    console.log(data);
    props.mydata(data);
    props.click();
  };

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        `https://api.zupptravel.com/getBusVendor`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await myresponse.json();
      console.log("jsssoooooonnn", json);

      if (json.successful) {
        updateDataSet(json.busVendors);
      }
    })();
  }, []);
  console.log(dataSet);

  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Bus ID</th>
            <th>Bus Number</th>
            <th>Email ID</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.length > 0
            ? currentPosts.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.vendorId}</td>
                  <td>{data.busRegisterationNo}</td>
                  <td>{data.email}</td>
                  <td className={classes.CenterRow}>
                    <Button
                      style={{ margin: "auto 1%" }}
                      variant="info"
                      onClick={() => handleclick(data)}
                    >
                      View
                    </Button>
                    {data.status === "Active" ? (
                      <Button style={{ margin: "auto 1%" }} variant="success">
                        Active
                      </Button>
                    ) : (
                      <Button style={{ margin: "auto 1%" }} variant="danger">
                        Inactive
                      </Button>
                    )}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default AccommodationList;
