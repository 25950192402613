import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import Page from "../../pagination/pagination";
import { useSelector } from "react-redux";

const DriverList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet.slice(indexOfFirst, indexOfLast);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet.length / postPerPage));
  }, [dataSet]);

  const handleclick = (data) => {
    console.log(data);
    props.mydata(data);
    props.click();
  };

  useEffect(() => {
    (async () => {
      const newdata = {
        emailId: data.data.key,
        password: "string",
        phoneno: "string",
        sessionKey: data.sessionId,
      };
      console.log(newdata);
      const res = await fetch("https://api.zupptravel.com/getCabDriver", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      console.log(json);
      if (json.successful) {
        updateDataSet(json.cabDriverDetailss);
      }
    })();
  }, []);

  const HandleDelete = async (id) => {
    // console.log(id);

    const newd = {
      emailID: "string",
      id: id,
      sessionKey: "string",
      // CabAmenitiesId: id,
    };
    console.log(newd);
    const r = await fetch("https://api.zupptravel.com/deleteCabDriver", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newd),
    });
    const j = await r.json();
    if (j.successful) {
      alert("Successfully deleted Driver");
      const newdata = {
        emailId: data.data.key,
        password: "string",
        phoneno: "string",
        sessionKey: data.sessionId,
      };
      const res = await fetch("https://api.zupptravel.com/getCabDriver", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      if (json.successful) {
        updateDataSet(json.cabDriverDetailss);
      }
    }
  };

  console.log(dataSet);
  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Driver Name</th>
            <th>Driver Type</th>
            <th>Age</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.length > 0
            ? currentPosts.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.driverName}</td>
                  <td>{data.driverType}</td>
                  <td>{data.age}</td>
                  <td className={classes.CenterRow}>
                    <Button
                      style={{ margin: "auto 1%" }}
                      variant="info"
                      onClick={() => handleclick(data)}
                    >
                      View
                    </Button>
                    <Button
                      style={{ marginRight: "5px" }}
                      variant="danger"
                      onClick={() => HandleDelete(data.id)}
                    >
                      Delete
                    </Button>
                    {/* {data.status === "Active" ? (
                      <Button style={{ margin: "auto 1%" }} variant="success">
                        Active
                      </Button>
                    ) : (
                      <Button style={{ margin: "auto 1%" }} variant="danger">
                        Inactive
                      </Button>
                    )} */}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default DriverList;
