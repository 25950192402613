import React, { useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import MultipleSelect from "../../multiSelect";
import { DropzoneArea } from "material-ui-dropzone";

const TourGuideView = (props) => {
  const [message, updateMessage] = useState(props.data.termsAndCondition);
  const [message2, updateMessage2] = useState("");
  const [select, updateSelect] = useState([]);
  const [files, updateFiles] = useState([]);
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([
    ...new Array(props.data.cancellation.length).fill(0).map((_, i) => i),
  ]);
  const [currentPoints1, updateCurrentPoints1] = useState(1);
  const [arrPoints1, updateArrPoints1] = useState([
    ...new Array(props.data.certification.length).fill(0).map((_, i) => i),
  ]);
  const [edit, updateEdit] = useState(false);

  const handleImages = (inpFile) => {
    updateFiles(inpFile);
  };

  const clickHandler = (id) => {
    const win = window.open(id, "_blank");
    win.focus();
  };
  console.log(props.data.termsAndCondition);
  console.log(message.toString());

  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    } else {
      const newCurrentPoint1 = currentPoints1 + 1;
      updateCurrentPoints1(newCurrentPoint1);
      updateArrPoints1([...arrPoints1, newCurrentPoint1]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    } else if (type === 2 && currentPoints1 > 1) {
      const newCurrentPoint1 = currentPoints1 - 1;
      updateCurrentPoints1(newCurrentPoint1);
      let arr = [...arrPoints1];
      arr.pop();
      updateArrPoints1(arr);
    }
  };

  console.log(arrPoints);
  console.log(arrPoints1);
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          ...Object.assign(
            {},
            ...props.data.cancellation.map((i, index) => ({
              [`cancellation${index}`]: i.cancellation,
              [`charges${index}`]: i.cancellationChargesFor,
            }))
          ),

          ...Object.assign(
            {},
            ...props.data.certification.map((i, index) => ({
              [`certificationType${index}`]: i.certificationType,
              [`imageUrl${index}`]: i.imageUrl,
            }))
          ),
          // about: message2,
          // active: true,
          // cancellation: [...cancellationCharges],
          citiesServed: props.data.citiesServed,
          // certification: [...certificationType1],
          // emailId: values.tourGuideEmail,
          tourGuideName: props.data.guideName,
          tourGuideType: props.data.guideType,
          language: props.data.language,
          sessionKey: "string",
          // termsAndCondition: message,
          // tourGuideId: 0,
          vendorId: props.data.vendorId,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
          const charges = [];
          const cancellation = [];
          const certificationType = [];
          const imageUrl = [];

          Object.entries(values).map(([key, value]) => {
            console.log(key, value);
            if (key.includes("charges") && typeof value === "string") {
              charges.push(value);
            } else if (
              key.includes("cancellation") &&
              typeof value === "string"
            ) {
              cancellation.push(value);
            } else if (key.includes("imageUrl") && typeof value === "string") {
              imageUrl.push(value);
            } else if (
              key.includes("certificationType") &&
              typeof value === "string"
            ) {
              certificationType.push(value);
            }
          });

          const cancellationCharges = [];
          const certificationType1 = [];

          for (let i = 0; i < charges.length; i++) {
            cancellationCharges.push({
              cancellation: cancellation[i],
              charges: charges[i],
            });
          }

          for (let i = 0; i < certificationType.length; i++) {
            certificationType1.push({
              certificationType: certificationType[i],
              imageUrl: imageUrl[i],
            });
          }
          console.log(charges, cancellation, "checkkkkk");
          console.log(certificationType, imageUrl, "kkkkkkkkkkkkkk");

          const newdata = {
            about: message2,
            active: true,
            cancellation: [...cancellationCharges],
            citiesServed: select,
            certification: [...certificationType1],
            emailId: "string",
            guideName: values.tourGuideName,
            guideType: values.tourGuideType,
            language: values.language,
            sessionKey: "string",
            termsAndCondition: message,
            tourGuideId: props.data.id,
            vendorId: props.data.vendorId,
          };
          console.log(newdata);
          const res = await fetch(
            "https://api.zupptravel.com/updateTourGuide",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newdata),
            }
          );
          const json = await res.json();
          console.log(json);
          if (json.successful) {
            alert(" Tour guide Updated!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "2%",
              }}
            >
              {!edit ? (
                <Button variant="success" onClick={() => updateEdit(true)}>
                  Edit
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={() => {
                    updateEdit(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
              )}
            </div>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Tour Guide Name"
                  name="vendorId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vendorId}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTourGuide" as={Row}>
              <Form.Label column sm="3">
                Tour Guide Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Tour Guide Name"
                  name="tourGuideName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tourGuideName}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTourGuide" as={Row}>
              <Form.Label column sm="3">
                Tour Guide Email:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Tour Guide Email"
                  name="tourGuideEmail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tourGuideEmail}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTourGuideType" as={Row}>
              <Form.Label column sm="3">
                Tour Guide Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Tour Guide Type"
                  name="tourGuideType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tourGuideType}
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVehicleAmenities" as={Row}>
              <Form.Label column sm="3">
                Cities served:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder={`Enter Language`}
                  name={`citiesServed`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // value={values[`language`]}
                  value={values.citiesServed}
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group controlId={`formBasicLanguage`} as={Row}>
              <Form.Label column sm="3">
                Language
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder={`Enter Language`}
                  name={`language`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // value={values[`language`]}
                  value={values.language}
                  readOnly
                />
              </Col>
            </Form.Group>
            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Cancellation :
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Cancellation `}
                        name={`cancellation${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`cancellation${d}`]}
                        required
                        readOnly
                      />
                    </Col>
                    <Form.Label column sm="3">
                      Charge:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Charges `}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`charges${d}`}
                        value={values[`charges${d}`]}
                        require
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>

              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div> */}

            <br />

            {arrPoints1.length > 0
              ? arrPoints1.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Certification :
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Certification `}
                        name={`certificationType${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        readOnly
                        value={values[`certificationType${d}`]}
                        required
                      />
                    </Col>

                    <Form.Label column sm="3">
                      Image
                    </Form.Label>
                    <Col sm="3">
                      <Form.Control
                        type="file"
                        name={`imageUrl${d}`}
                        // onChange={(e) => updateCertificateImage(e, key)}
                        onBlur={handleBlur}
                        readOnly
                      />
                    </Col>
                    <Col>
                      <Button
                        onClick={() => clickHandler(values[`imageUrl${d}`])}
                      >
                        Open
                      </Button>
                    </Col>
                  </Form.Group>
                ))
              : null}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints1 <= 1 ? true : false}
                onClick={() => handleRemove(2)}
              >
                Remove
              </Button>

              <Button variant="success" onClick={() => handleAdd(2)}>
                Add
              </Button>
            </div> */}

            {/* <Field name={`cancellation.${index}.cancellation`}/> */}

            {/* <Form.Label column sm="3">
                            Image
                          </Form.Label>
                          <Col sm="3">
                            <Form.Control
                              type="file"
                              name={`certification.${index}.imageUrl`}
                              onChange={(e) => updateCertificateImage(e, index)}
                              onBlur={handleBlur}
                            />
                          </Col> */}

            {/* <Field name={`cancellation.${index}.charges`}/> */}

            {/* <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'15px'}}>
                                            <Button 
                                                variant="danger" 
                                                style={{marginRight:'15px'}} 
                                                disabled={currentPoints<=1?true:false}
                                                onClick={()=>handleRemove(1)}
                                            >Remove</Button>
                                            <Button variant="success" onClick={()=>handleAdd(1)}>Add</Button>
                                        </div> */}

            <Row style={{ margin: "2% 0", float: "none" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                />
              </Col>
            </Row>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                About:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage2(text)}
                  text={message2}
                />
              </Col>
            </Row>
            <center>
              <Button type="submit" variant="success">
                Save Tour Guide
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default TourGuideView;
