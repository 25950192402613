import React, { useEffect, useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../draftEditor";
import Filter from "../filter";

import { UpdateSightSeeing, CityState } from "../../entities/action/action";
import { useSelector } from "react-redux";
import { Markup } from "interweave";

const SightseeingView = (props) => {
  const [data, setdata] = useState(props.data);
  const [message, updateMessage] = useState("");
  const [tAndC, updateTAndC] = useState("");
  const [openDays, updateOpenDays] = useState(data.openDays.split(","));
  const [edit, updateEdit] = useState(false);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  console.log(openDays);

  useEffect(() => {
    setdata(props.data);
    setState(props.data.state);
    setCity(props.data.city);
  }, [props.data]);

  console.log(props.data);

  const userData = useSelector((state) => state.adminAccount.apiResponse);

  const myfunc = async (e) => {
    if (e.target.value.length === 6) {
      const response = await CityState(e.target.value);
      console.log(Object.values(response.data));
      if (response.status == "success") {
        setState(Object.values(response.data)[0].state_name);
        setCity(Object.values(response.data)[0].city_name);
      }
    } else {
      setState("");
      setCity("");
    }
  };
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          sightSeeingType: data.sightSeeingType,
          pincode: data.pincode,
          sightseeingName: data.sightSeeingName,
          ticketRequired: data.ticketRequired ? "Yes" : "No",
          bestTimeToVisit: data.bestTimeToVisit,
          travelType: data.travelType,
          visitDuration: data.visitDuration,
          timeUnit: data.timeUnit,
          state: data.state,
          city: data.city,
          indianCost: data.ticketCostIndians,
          foreignersCost: data.ticketCostForeigners,
          openAt: data.openTime,
          closeTime: data.closeTime,
          openDays: openDays,
          summerCloseTime: data.summerCloseTime,
          summerOpenTime: data.summerOpenTime,
          worldLocation: data.worldLocation,
          winterCloseTime: data.winterCloseTime,
          winterOpenTime: data.winterOpenTime,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const newData = {
            bestTimeToVisit: values.bestTimeToVisit,
            city: values.city,
            closeTime: values.closeTime,
            description: message,
            pincode: values.pincode,
            emailId: userData.data.key,
            openDays: openDays.toString(),
            openTime: values.openAt,
            sessionKey: userData.sessionId,
            winterCloseTime: values.winterCloseTime,
            winterOpenTime: values.winterOpenTime,
            summerCloseTime: values.summerCloseTime,
            summerOpenTime: values.summerOpenTime,
            sightSeeingId: 0,
            sightSeeingName: values.sightseeingName,
            sightSeeingType: values.sightSeeingType,
            state: values.state,
            termsAndCondition: tAndC,
            ticketCostForeigners: values.foreignersCost,
            ticketCostIndians: values.indianCost,
            ticketRequired: values.ticketRequired === "Yes" ? true : false,
            timeUnit: values.timeUnit,
            travelType: values.travelType,
            visitDuration: values.visitDuration,
            worldLocation: values.worldLocation,
          };
          const response = await UpdateSightSeeing(newData);
          console.log(response);
          if (response.successful) {
            alert("Successfully updated Sightseeing");
            resetForm();
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "2%",
              }}
            >
              {!edit ? (
                <Button variant="success" onClick={() => updateEdit(true)}>
                  Edit
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={() => {
                    updateEdit(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
              )}
            </div>
            <Form.Group controlId="formBasicCName" as={Row}>
              <Form.Label column sm="3">
                Sightseeing Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Sightseeing Type"
                  name="sightSeeingType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.sightSeeingType}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCityState" as={Row}>
              <Form.Label column sm="3">
                World Location:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="select"
                  name="worldLocation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.worldLocation}
                  disabled={!edit}
                  required
                >
                  <option value="">--select--</option>
                  <option value="India">India</option>
                  <option value="Foreign">Foreign</option>
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                Zip Code:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter Zip Code"
                  name="pincode"
                  onChange={handleChange}
                  onBlur={myfunc}
                  value={values.pincode}
                  disabled={!edit}
                ></Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasic" as={Row}>
              <Form.Label column sm="3">
                State:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter State"
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={state}
                  readOnly
                  disabled={!edit}
                  required
                >
                  {/* <option value=""></option> */}
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                City:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter City"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={city}
                  readOnly
                  disabled={!edit}
                  required
                >
                  {/* <option value=""></option> */}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCName" as={Row}>
              <Form.Label column sm="3">
                Sightseeing Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Sightseeing Name"
                  name="sightseeingName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.sightseeingName}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTicketRequired" as={Row}>
              <Form.Label column sm="3">
                Ticket Required:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  as="select"
                  name="ticketRequired"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!edit}
                  value={values.ticketRequired}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicBestTimeToVisit" as={Row}>
              <Form.Label column sm="3">
                Best time to visit:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Best time to visit"
                  name="bestTimeToVisit"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={!edit}
                  value={values.bestTimeToVisit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTravel" as={Row}>
              <Form.Label column sm="3">
                Travel Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Travel Type"
                  name="travelType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.travelType}
                  disabled={!edit}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicVisitDuration" as={Row}>
              <Form.Label column sm="3">
                Visit Duration:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter Visit Duration"
                  name="visitDuration"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.visitDuration}
                  disabled={!edit}
                  required
                />
              </Col>
              <Form.Label column sm="2">
                Time Unit:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter Time Unit"
                  name="timeUnit"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.timeUnit}
                  required
                  disabled={!edit}
                />
              </Col>
            </Form.Group>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Description:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                />
                <hr />
                {data.description !== null || data.description !== undefined ? (
                  <Markup content={data.description} />
                ) : null}
              </Col>
            </Row>
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateTAndC(text)}
                  text={tAndC}
                />
                <hr />
                {data.termsAndCondition !== null ||
                data.termsAndCondition !== undefined ? (
                  <Markup content={data.termsAndCondition} />
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col sm={3}>Open Days:</Col>
              <Col xs={9}>
                <Filter
                  disable={!edit}
                  type="2"
                  data={openDays}
                  handleFilter={(filter) => updateOpenDays(filter)}
                />
              </Col>
            </Row>
            <Row style={{ margin: "1% 0" }}>
              <Col sm={3} style={{ padding: "0" }}>
                Timing:
              </Col>
              <Col xs={9}>
                <Form.Group controlId="formBasicProductId" as={Row}>
                  <Form.Label column sm="2">
                    SUMMER OPEN:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="time"
                      name="summerOpenTime"
                      disabled={!edit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.summerOpenTime}
                      required
                    />
                  </Col>
                  <Form.Label column sm="2">
                    SUMMER CLOSE:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="time"
                      name="summerCloseTime"
                      disabled={!edit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.summerCloseTime}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ margin: "1% 0" }}>
              <Col sm={3} style={{ padding: "0" }}>
                Timing:
              </Col>
              <Col xs={9}>
                <Form.Group controlId="formBasicProductId" as={Row}>
                  <Form.Label column sm="2">
                    WINTER OPEN:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="time"
                      name="summerOpenTime"
                      disabled={!edit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.winterOpenTime}
                      required
                    />
                  </Col>
                  <Form.Label column sm="2">
                    WINTER CLOSE:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="time"
                      name="summerCloseTime"
                      disabled={!edit}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.winterCloseTime}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <Row style={{ margin: "1% 0" }}>
              <Col sm={3} style={{ padding: "0" }}>
                Ticket Cost:
              </Col>
              <Col xs={9}>
                <Form.Group controlId="formBasicProductId" as={Row}>
                  <Form.Label column sm="2">
                    Indian:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="number"
                      step="any"
                      min={0}
                      placeholder="Indian"
                      name="indianCost"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!edit}
                      value={values.indianCost}
                      required
                    />
                  </Col>
                  <Form.Label column sm="2">
                    Foreigners:
                  </Form.Label>
                  <Col sm="4">
                    <Form.Control
                      type="number"
                      step="any"
                      min={0}
                      placeholder="Foreigners"
                      name="foreignersCost"
                      onChange={handleChange}
                      disabled={!edit}
                      onBlur={handleBlur}
                      value={values.foreignersCost}
                      required
                    />
                  </Col>
                </Form.Group>
              </Col>
            </Row>
            <center>
              <Button disabled={!edit} type="submit" variant="success">
                Save Sightseeing
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default SightseeingView;
