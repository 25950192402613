import React,{useState} from 'react';
import {Tab,Tabs} from 'react-bootstrap';
import SupplierFinance from '../../supplierManagement/supplierFinance';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import CharterPlaneViewPropertyList from './CharterPlaneViewPropertyList';
import AddCharterPlaneGeneralView from './addCharterPlaneGeneralView';
import SetPrice from './setPrice';
import RoomInventory from './roomInventory';

    const CharterPlaneView = (props)=>{
        const [active,updateactive] = useState("1");
        return(
            <>
            {
                <Tabs defaultActiveKey="CharterPlaneDetails" id="uncontrolled-tab-example"   >
                    <Tab eventKey="CharterPlaneDetails" title={active==="3"?"CharterPlane Details":"CharterPlane Details"} >
                        <Tab.Container id="left-tabs-example" defaultActiveKey="CharterPlaneDetails" >  
                            {
                                active==="1"?<CharterPlaneViewPropertyList click={()=>updateactive("2")} setPrice={()=>updateactive("3")}/>:
                                active==="2"?<AddCharterPlaneGeneralView click={()=>updateactive("1")}/>:
                                active==="3"?<SetPrice click={()=>updateactive("1")}/>:null
                            }           
                        </Tab.Container>
                    </Tab>
                    {
                        active==="1"?
                            <Tab eventKey="bookingList" title="Booking List" className={classes.Tabs} >
                    
                            </Tab>:null
                    } 
                    {
                        active==="1"?
                            <Tab eventKey="financeAndTransaction" title="Finance & Transaction" className={classes.Tabs} >
                                <SupplierFinance/>
                            </Tab>:null
                    } 
                    {
                        active==="3"?
                            <Tab eventKey="CharterPlaneInventoryList" title="CharterPlane Inventory List" className={classes.Tabs} >
                                <RoomInventory click={()=>updateactive("1")}/>
                            </Tab>:null
                    }
                </Tabs>
            }
        </>    
        )
    }
    export default CharterPlaneView;