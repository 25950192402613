import React, { useState, useEffect } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import { GetVendor, HandleSupplierStatus } from "../../entities/action/action";
import Page from "../pagination/pagination";

const PartnerList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet;
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet.length / postPerPage));
  }, [dataSet]);
  useEffect(() => {
    (async () => {
      const response = await GetVendor();
      console.log(response);
      updateDataSet(response);
    })();
  }, []);
  const handleStatus = async (localData, newStatus) => {
    //hit the url
    const response = await HandleSupplierStatus(
      newStatus,
      localData.supplierid
    );
    if (response.status === 200) {
      let newArray = [...dataSet];
      newArray.map((data) => {
        if (data.supplierid === localData.supplierid) {
          if (data.status === "ACTIVE") {
            data.status = "INACTIVE";
          } else {
            data.status = "ACTIVE";
          }
        }
      });
      updateDataSet(newArray);
    }
  };

  const handleclick = (data) => {
    console.log(data);
    props.mydata(data);
    props.click();
  };

  console.log(dataSet);

  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Vendor ID</th>
            <th>Vendor Name</th>
            <th>Vendor Type</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.length > 0
            ? currentPosts.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.supplierid}</td>
                  <td>{data.suppliername}</td>
                  <td>{data.companyInformation.vendortype}</td>
                  <td>
                    {data.status === "ACTIVE" ? (
                      <Button
                        variant="success"
                        onClick={() => handleStatus(data, "INACTIVE")}
                      >
                        ACTIVE
                      </Button>
                    ) : (
                      <Button
                        variant="danger"
                        onClick={() => handleStatus(data, "ACTIVE")}
                      >
                        INACTIVE
                      </Button>
                    )}
                  </td>
                  <td className={classes.CenterRow}>
                    <Button
                      style={{ margin: "auto 1%" }}
                      onClick={() => handleclick(data)}
                    >
                      View
                    </Button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default PartnerList;
