import React, { useState } from 'react';
import { Button,Form,Row,Col } from 'react-bootstrap';
import CategorySubcategoryList from './listForGiftCategoryAndSubCategory';
import { AddGiftCategory, AddSubCategory } from '../../../entities/action/action';
import { useSelector } from 'react-redux';

const GiftCategorySubCategory = (props)=>{
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    const categoryList = [
        {type:'CATEGORY'},
        {type:'Agriculture Products',categoryId:'1'},
        {type:'Agriculture Products',categoryId:'2'},
        {type:'Agriculture Products',categoryId:'3'},
        {type:'Agriculture Products',categoryId:'4'},
        {type:'Agriculture Products',categoryId:'5'},
        {type:'Agriculture Products',categoryId:'6'},
        {type:'Agriculture Products',categoryId:'7'},
        {type:'Agriculture Products',categoryId:'8'},
        {type:'Agriculture Products',categoryId:'9'}
    ]
    const [category,SetCategory] = useState('');
    const [extra,updateExtra] = useState('');
    const handleSubmit = async()=>{
        const newData = {
            "giftCategoryId": 0,
            "categoryName": category,
            "emailId": userData.data.key,
            "sessionKey": userData.sessionId
          }
        let response;
        if(props.categoryType=="CATEGORY"){
            response = await AddGiftCategory(newData);
            console.log(response)
            if(response.successful){
                alert("Successfully Added Category");
                window.location.reload();
            }
        } 
         
    }
    return(
        <div> 
        {
            props.categoryType==="SUB-CATEGORY"?
            <Form.Group controlId="formBasicCategory" as={Row}>
                <Form.Label column sm="3">Gift CATEGORY:</Form.Label>
                <Col sm={9}> 
                    <Form.Control 
                        as="select" 
                        placeholder="CATEGORY" 
                        name="category"
                        onChange={(e)=>updateExtra(e.target.value)}
                        value={extra}
                        required>
                            {
                                categoryList.map((categories,i)=>
                                    <option key={i} value={categories.categoryId}>{categories.type}</option>    
                                )
                            }
                    </Form.Control>
                </Col>
            </Form.Group>:null
        }
            <Form.Group controlId="formBasicPromo" as={Row}>
                <Form.Label column sm="3">Gift {`${props.categoryType}`}:</Form.Label>
                <Col sm="9"> 
                    <Form.Control
                        type="text" 
                        placeholder={`Enter ${props.categoryType}`} 
                        name="category"
                        onChange={(e)=>SetCategory(e.target.value)}
                        value={category}
                        required/>
                </Col>
            </Form.Group>
            <center>
                <Button onClick={handleSubmit} style={{margin:'2%'}}>Save Gift {`${props.categoryType}`}</Button>
            </center>
            <CategorySubcategoryList list={`${props.categoryType}`}/>
        </div>
    )
}
export default GiftCategorySubCategory;