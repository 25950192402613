import React,{useState} from 'react';
import {Col,Button,Form, Row, Container} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import ActivityInventoryList from './activityInventoryList';
import SingleSelect from '../../../utilities/singleSelect/singleSelect';
import { DatePicker} from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
}

const ActivityInventory = (props)=>{
    const [selected,updateSelected] = useState('');
    const[date,updateDate] = useState([]);
    return(
        <Container fluid className={classes.Box}> 
        <Formik
            initialValues={{}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicVendorName" as={Row}>
                                            <Form.Label column sm="2">Activity ID:</Form.Label>
                                            <Col sm="10"> 
                                                <SingleSelect type="2" change={(select)=>updateSelected(select)}/>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicActivity" as={Row}>
                                            <Form.Label column sm="2">Activity Name:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    readOnly
                                                    type="text"
                                                    placeholder="Activity Name"
                                                    name="activityName"
                                                    value={values.activityName}
                                                    required/>
                                            </Col>
                                        </Form.Group>   
                                        <Form.Group controlId="formBasictimePerSlot" as={Row}>
                                            <Form.Label column sm="2">Date:</Form.Label>
                                            <Col> 
                                                <RangePicker
                                                    onChange={val=>updateDate(val)} 
                                                    value={date}
                                                    disabledDate={disabledDate} />
                                            </Col>
                                            <Form.Label column sm="2">Time:</Form.Label>
                                            <Col> 
                                                <Form.Control
                                                type="time" 
                                                name="time"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.time}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <center>
                                            <Button type="submit" variant="success">Submit</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                        <hr/>
                        <ActivityInventoryList/>
                    </Container>            
    )
}
export default ActivityInventory;