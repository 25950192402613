import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";

const data = ["JK", "Ram", "Rahul", "Sharma ji ka ladka"];
const data2 = ["JK", "Ram", "Rahul", "Sharma ji ka ladka"];
const data9 = ["JK", "Ram", "Rahul", "Sharma ji ka ladka"];
const data10 = ["JK", "Ram", "Rahul", "Sharma ji ka ladka"];
const data11 = ["JK", "Ram", "Rahul", "Sharma ji ka ladka"];
const data12 = ["JK", "Ram", "Rahul", "Sharma ji ka ladka"];

const SingleSelect = (props) => {
  const [selected, updateSelected] = useState("");
  const [names, updateName] = useState([]);
  useEffect(() => {
    if (props.type == "1") {
      updateName(data);
    } else if (props.type == "2") {
      updateName(data2);
    } else if (props.type == "9") {
      updateName(data9);
    } else if (props.type == "10") {
      updateName(data10);
    } else if (props.type === "3") {
    } else if (props.type === "12") {
    }
  }, [props.type]);
  useEffect(() => {
    if (props.list != undefined && props.list.length >= 0) {
      updateName(props.list);
      if (props.value != undefined && props.value.length >= 0) {
        updateSelected(props.value);
      }
    }
  }, [props.list, props.value]);
  const stateOptions = _.map(names, (state, index) => ({
    key: index,
    text:
      props.type === "1"
        ? state
        : props.type === "2"
        ? state
        : props.type === "10"
        ? `${state.amenities}`
        : props.type === "9"
        ? state
        : props.type === "3"
        ? `${state.supplierid} - ${state.suppliername}`
        : props.type === "4"
        ? state
        : props.type === "7"
        ? state
        : props.type === "6"
        ? `${state.mealType}`
        : props.type === "12"
        ? state
        : props.type === "5"
        ? `${state.pincode}`
        : props.type === "11"
        ? `${state.pincode}`
        : null,
    value:
      props.type === "1"
        ? state
        : props.type === "2"
        ? state
        : props.type === "9"
        ? state
        : props.type === "12"
        ? state
        : props.type === "3"
        ? `${state.supplierid}`
        : props.type === "4"
        ? state
        : props.type === "6"
        ? state
        : props.type === "7"
        ? state
        : props.type === "5"
        ? `${state.pincode}`
        : props.type === "11"
        ? `${state.pincode}`
        : props.type === "10"
        ? `${state.amenities}`
        : null,
  }));
  useEffect(() => {
    props.change(selected);
  }, [selected]);
  // console.log("selected value ", selected);
  return (
    <Dropdown
      placeholder={
        props.type === "1"
          ? "Select Vendor Name"
          : props.type === "2"
          ? "Tour Guide ID"
          : props.type === "3"
          ? "Vendor ID"
          : props.type === "4"
          ? "Accomodation ID"
          : props.type === "12"
          ? "Gift Card Category"
          : props.type === "6"
          ? "Meal Type"
          : props.type === "5"
          ? "Select Pincode"
          : props.type === "9"
          ? "Cities"
          : props.type === "10"
          ? "Amenities"
          : props.type === "7"
          ? "Meal Type"
          : props.type === "11"
          ? "Pincodes"
          : null
      }
      fluid
      search
      selection
      options={stateOptions}
      onChange={(e, optionsObj) => updateSelected(optionsObj.value)}
    />
  );
};
export default SingleSelect;
