import React, {useState, Fragment } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import classesX from '../Dashboard/dashboard.module.css'
import AdminNavbar from '../navbar/adminNavbar';
import CreateDeposit from './createDeposit';
import TravelDepositList from './travelDepositList';

const TravelDeposit = (props)=>{
    const [active,updateActive] = useState('1');
    const items = [
        {id:"1",type:"Create Deposit"},
        {id:"2",type:"Travel Deposit List"}
    ]
 
    return(
        <Fragment> 
           <AdminNavbar/>
            <h1 className={classesX.Heading}>Travel Deposit</h1>  
        <Container fluid style={{paddingLeft:'10px', width: '95%'}}>
            <Row> 
                <Col lg={12} md={12} className={classes.Response2}>
                    <div style={{height:'100%'}}> 
                        <ul className={classes.Ul} style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 ,height:"10vh",}}>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                    style={{
                                        backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                                        textAlign: 'center',
                                        margin: '0 1px', // Adjust the margin as needed
                                        padding:" 2% 0",
                                        color: 'white',
                                        cursor: 'pointer',
                                        width:"25%",
                                        height:"100%"
                                    }}>
                                    {item.type}
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={12} md={12} className={classes.Response}>
                    <div style={{height:'100%',marginTop:"2vh"}}> 
                        {
                            active==="1"?<CreateDeposit/>:
                            active==="2"?<TravelDepositList/>:null
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default TravelDeposit;