// import React, {useState, Fragment } from 'react';
// import {Col,Row, Container,Button} from 'react-bootstrap';
// import classes from '../../WebsiteManagement/websiteManagement.module.css';
// import BlockAndUnblock from './blockAndUnblock';
// import SetSeatLayout from './BusSeatLayout';
// import BusRoute from './BusRoute';
// import SetSeatPriceAndAvailability from './SetSeatPriceAndAvailability';

// const SetPrice = (props)=>{
//     const [active,updateActive] = useState('1');

//     return(
//         <Fragment>
//             <Container fluid className={classes.Box}>
//             <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'2%'}}>
//                 <Button variant="light" style={{marginLeft:'15px'}} onClick={()=>props.click()}>{`<--`} Back</Button>
//             </div>
//             <Row style={{margin:'0 0 2% 0',textAlign:'center',cursor:'pointer',fontSize:'0.8rem'}}>
//                 <Col className={classes.Col}
//                 style={{backgroundColor:`${active==='1'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
//                 onClick={()=>updateActive("1")}>
//                     Add Bus Route
//                 </Col>
//                 <Col className={classes.Col}
//                 style={{backgroundColor:`${active==='2'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
//                 onClick={()=>updateActive("2")}>
//                     Set Seat Layout
//                 </Col>
//                 <Col className={classes.Col}
//                 style={{backgroundColor:`${active==='3'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
//                 onClick={()=>updateActive("3")}>
//                     Set Seat Price & Availability
//                 </Col>
//                 <Col className={classes.Col}
//                 style={{backgroundColor:`${active==='4'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
//                 onClick={()=>updateActive("4")}>
//                     Block & Unblock Seats
//                 </Col>
//             </Row>
//             {
//                 active==="1"?<BusRoute/>:
//                 active==="2"?<SetSeatLayout/>:
//                 active==="3"?<SetSeatPriceAndAvailability/>:
//                 active==="4"?<BlockAndUnblock/>:null
//             }
//         </Container>
//         </Fragment>
//     )
// }

// export default SetPrice;
import React, { useState, Fragment, useEffect } from "react";
import { Col, Row, Container, Button, Form } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import BlockAndUnblock from "./blockAndUnblock";
import SetSeatLayout from "./BusSeatLayout";
import BusRoute from "./BusRoute";
import SetSeatPriceAndAvailability from "./SetSeatPriceAndAvailability";
import { Formik } from "formik";
import { GetVendor } from "../../../entities/action/action";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";

const SetPrice = (props) => {
  const [active, updateActive] = useState("1");
  const [vendorId, updateVendorId] = useState("");
  const [vendorList, updateVendorList] = useState([]);
  const [selected, updateSelected] = useState("");
  useEffect(() => {
    (async () => {
      const res = await GetVendor();
      if (res != undefined && res.length > 0) {
        console.log("resssssssssssssssssssssssss", res);
        const arr = res.filter(
          (item, key) => item.companyInformation.vendortype === "Bus Operator"
        );
        console.log(res);
        updateVendorList(arr);
      }
    })();
  }, []);
  const [busSelection, updateBusSelection] = useState("");

  return (
    <Fragment>
      <Container fluid className={classes.Box}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "2%",
          }}
        >
          <Button
            variant="light"
            style={{ marginLeft: "15px" }}
            onClick={() => props.click()}
          >
            {`<--`} Back
          </Button>
        </div>
        <Formik
          initialValues={{}}
          onSubmit={async (values, { setSubmitting, resetForm }) => {
            console.log(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group controlId="formBasicVendor" as={Row}>
                <Form.Label column sm="3">
                  Vendor ID:
                </Form.Label>
                <Col sm="9">
                  <SingleSelect
                    type="3"
                    list={vendorList}
                    change={(select) => updateVendorId(select)}
                  />
                </Col>
              </Form.Group>
              <Form.Group controlId="formBasicVendor" as={Row}>
                <Form.Label column sm="2">
                  Bus Registration Number:
                </Form.Label>
                <Col sm="4">
                  <Col sm="10">
                    <SingleSelect change={(select) => updateSelected(select)} />
                  </Col>
                </Col>
              </Form.Group>
            </Form>
          )}
        </Formik>
        <Row
          style={{
            margin: "0 0 2% 0",
            textAlign: "center",
            cursor: "pointer",
            fontSize: "0.8rem",
          }}
        >
          <Col
            className={classes.Col}
            style={{
              backgroundColor: `${active === "1" ? "steelblue" : "#585858"}`,
              color: "white",
              padding: "1%",
            }}
            onClick={() => updateActive("1")}
          >
            Add Bus Route
          </Col>
          {/* <Col
            className={classes.Col}
            style={{
              backgroundColor: `${active === "2" ? "steelblue" : "#585858"}`,
              color: "white",
              padding: "1%",
            }}
            onClick={() => updateActive("2")}
          >
            Set Seat Layout
          </Col> */}
          <Col
            className={classes.Col}
            style={{
              backgroundColor: `${active === "3" ? "steelblue" : "#585858"}`,
              color: "white",
              padding: "1%",
            }}
            onClick={() => updateActive("3")}
          >
            Set Seat Price & Availability
          </Col>
          <Col
            className={classes.Col}
            style={{
              backgroundColor: `${active === "4" ? "steelblue" : "#585858"}`,
              color: "white",
              padding: "1%",
            }}
            onClick={() => updateActive("4")}
          >
            Block & Unblock Seats
          </Col>
        </Row>
        {active === "1" ? (
          <BusRoute />
        ) : active === "2" ? (
          <SetSeatLayout />
        ) : active === "3" ? (
          <SetSeatPriceAndAvailability />
        ) : active === "4" ? (
          <BlockAndUnblock />
        ) : null}
      </Container>
    </Fragment>
  );
};

export default SetPrice;
