import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../WebsiteManagement/websiteManagement.module.css";
// import {GetActiveCoupon,DeleteCoupon} from '../../entities/action/action';
import { useSelector } from "react-redux";
import Page from "../pagination/pagination";
import { DeletePincodeManagement } from "../../entities/action/action";

const PincodeList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const [update, setUpdate] = useState(false);
  console.log(data);
  const userData = useSelector((state) => state.adminAccount.apiResponse);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);

  useEffect(() => {
    (async () => {
      const myresponse = await fetch(
        `https://api.zupptravel.com/api/v1/pincode/getPincode`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const json = await myresponse.json();
      console.log(json);
      if (json?.success) {
        updateDataSet(json);
      }
    })();
  }, [update]);
  console.log("data from  pincode",dataSet);

  console.log("data from  pincode",dataSet.pincodes);


  const handleDelete = async (id) => {
    try {
      // Create the request data with the 'id'
      const requestData = {
        id: id,
      };
  
      console.log("Pincode id", id);
  
      // Make the DELETE request directly
      const myresponse = await fetch(
        "https://api.zupptravel.com/deletePincodeManagement",
        {
          method: "DELETE", // Use the DELETE HTTP method
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
  
      console.log("Server Response:", myresponse.status, myresponse.statusText);
  
      if (myresponse.ok) {
        // If the response status is in the 200 range, show a success message
        alert("Successfully Deleted");
  
        // Trigger an update (if needed)
        const callback = (prev) => {
          return !prev;
        };
        setUpdate(callback);
      } else {
        // Handle the case where the server responded with an error status
        console.error("Server error");
        alert("Error deleting pincode. Please check your network connection.");
      }
    } catch (error) {
      // Handle any unexpected errors that may occur during the request
      console.error("Error:", error);
      alert("An unexpected error occurred.");
    }
  };
  


  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Id</th>
            <th>Pincode</th>
            <th>City</th>
            <th>State</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
    
            {dataSet && dataSet.pincodes ? (
  dataSet.pincodes.map((data, key) => (
    <tr key={key}>
      <td>{key + 1}</td>
      <td>{data.id}</td>
      <td>{data.pincode}</td>
      <td>{data.city}</td>
      <td>{data.state}</td>
      <td className={classes.CenterRow}>
        <Button
          onClick={(e) => {
            handleDelete(data.id);
          }}
          style={{ margin: "auto 1%" }}
          variant="danger"
        >
          Delete
        </Button>
      </td>
    </tr>
  ))
) : (
  <tr>
    <td colSpan="6">No data available</td>
  </tr>
)}

        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        {/* <Page count={count} change={handleChangePage} page={currentPage} /> */}
      </div>
    </Container>
  );
};
export default PincodeList;
