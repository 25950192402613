import React, {useState, Fragment, useEffect } from 'react';
import {Col,Row, Container } from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import classesX from '../Dashboard/dashboard.module.css';
import Pages from './pages';
import AdminNavbar from '../navbar/adminNavbar';
import About from './about';

const PageManagement = (props)=>{
    const [active,updateActive] = useState();
    const [subActive2,updateSubActive2] = useState("19");
    const [items, setItems] = useState([]);

    useEffect(()=>{
        (async()=>{
            const token = localStorage.getItem('token');
            const authHeader = `Bearer ${token}`;
            const myresponse = await fetch(`https://api.zupptravel.com/api/v1/page/getAllPages`, {
                method: "get",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': authHeader
                },
            });
            const myjson = await myresponse.json();
            if(myjson.success){
                updateActive(myjson?.pages?.[0]?.id)
                setItems(myjson.pages);
            }
        })()
    },[])

    return(
        <Fragment> 
            <AdminNavbar/>
            <h1 className={classesX.Heading}>Page Management</h1>  
        <Container fluid>
            <Row> 
                <Col lg={3} md={12} className={classes.Response2}>
                    <div style={{height:'100%'}}> 
                        <ul className={classes.Ul}>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                    style={{backgroundColor:`${active===item.id?'steelblue':'#585858'}`,textAlign:'center'}}>
                                    {item.serviceName}
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={9} md={12} className={classes.Response}>
                    <div style={{height:'100%'}}> 
                        {
                            items.map((item,key)=>
                                active===item.id ?<Pages id={item?.id} type={item?.serviceName} text={item?.text} key={key}/>:null
                            )
                        }
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default PageManagement;