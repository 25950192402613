
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import BusData from './BusData';
import BusDetails from './BusDetails';
import {Col,Row} from 'react-bootstrap';
// import classes from '../WebsiteManagement/websiteManagement.module.css';
import classes from '../../WebsiteManagement/websiteManagement.module.css'

const BusD = (props) => {
  const [active, updateActive] = useState('1');
  const [data, updateData] = useState({});

  const handleClick = (select) => {
    console.log(select);
    updateData(select);
    updateActive('2');
  };

  const handleDetailsClose = () => {
    updateActive('1');
  };

  return (
    <Container fluid>
          <Col lg={12} md={12} className={classes.Response2}>
      {active === '1' ? (
        <BusData click={(select) => handleClick(select)} />
      ) : null}

      {active === '2' ? (
        <BusDetails data={data} clicked={handleDetailsClose} />
      ) : null}

</Col>
    </Container>
  );
};

export default BusD;
