import React, { useEffect, useState } from 'react';
import {Table, Container} from 'react-bootstrap';

const PartnerList = (props)=>{
    
    const [eWallet,updateEwallet] = useState([]);
    const [playMoney,updateplayMoney] = useState([]);
    const [cashbackMoney,updateCasebackMoney] = useState([]);
    useEffect(()=>{
        updateEwallet(props.data.eWalletTransactions);
        updateplayMoney(props.data.playMoneyTransactions);
        updateCasebackMoney(props.data.cashBackTransactions);
    },[props.data])
    console.log("Ewalllet details",props.data);
    console.log("Ewalllet details",eWallet);
    console.log("playMoney details",playMoney);
    console.log("cashbackMoney details",cashbackMoney);

    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Date & Time</th>
                        <th>Reward Type</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Remarks</th>
                    </tr>
                </thead>
                {/* <tbody>
                    {
                        eWallet.length>0?eWallet.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.tableEntryDate!=null?data.tableEntryDate.split('.')[0].split('T')[0]:"-"} & {data.tableEntryDate!=null?data.tableEntryDate.split('.')[0].split('T')[1]:"-"}</td>
                                <td>{data.type}</td>
                                <td>{data.debit===true?data.amount:"-"}</td>
                                <td>{data.credit===true?data.amount:"-"}</td>
                                <td>{data.message}</td>
                            </tr>
                        ):null
                    }
                      {
                        cashbackMoney.length>0?cashbackMoney.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.tableEntryDate!=null?data.tableEntryDate.split('.')[0].split('T')[0]:"-"} & {data.tableEntryDate!=null?data.tableEntryDate.split('.')[0].split('T')[1]:"-"}</td>
                                <td>{data.type}</td>
                                <td>{data.debit===true?data.amount:"-"}</td>
                                <td>{data.credit===true?data.amount:"-"}</td>
                                <td>{data.message}</td>
                            </tr>
                        ):null
                    }
                    {
                        playMoney.length>0?playMoney.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.tableEntryDate!=null?data.tableEntryDate.split('.')[0].split('T')[0]:"-"} & {data.tableEntryDate!=null?data.tableEntryDate.split('.')[0].split('T')[1]:"-"}</td>
                                <td>{data.type}</td>
                                <td>{data.debit===true?data.amount:"-"}</td>
                                <td>{data.credit===true?data.amount:"-"}</td>
                                <td>{data.message}</td>
                            </tr>
                        ):null
                    }
                </tbody> */}
                  <tbody>
        {eWallet.concat(cashbackMoney, playMoney).map((data, index) => (
          <tr key={index + 1}>
            <td>{index + 1}</td>
            <td>{data.tableEntryDate != null ? data.tableEntryDate.split('.')[0].split('T')[0] : "-"} & {data.tableEntryDate != null ? data.tableEntryDate.split('.')[0].split('T')[1] : "-"}</td>
            <td>{data.type}</td>
            <td>{data.debit === true ? data.amount : "-"}</td>
            <td>{data.credit === true ? data.amount : "-"}</td>
            <td>{data.message}</td>
          </tr>
        ))}
      </tbody>
            </Table>
        </Container>
    )
}
export default PartnerList;

