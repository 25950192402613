import React from 'react';
import {Col,Button,Form, Row, Container} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import RoomInventoryList from './roomInventoryList';


const RoomInventory = (props)=>{

    const categoryList = [
        {type:'CATEGORY'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'}
    ]
    return(
        <Container fluid className={classes.Box}> 
        <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'2%'}}>
                <Button variant="light" style={{marginLeft:'15px'}} onClick={()=>props.click()}>{`<--`} Back</Button>
        </div>
        <Formik
            initialValues={{unitSericeVariablemsp:'',taxSlabVariablemsp:'',unitSericeVariableocc:''}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicRoomName" as={Row}>
                                            <Form.Label column sm="2">Room Name:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Room Name" 
                                                    name="roomName"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.roomName}
                                                    required>
                                                    {
                                                        categoryList.map((categories,key)=>
                                                        <option key={key} value={categories.type}>{categories.type}</option>    
                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>   
                                        <Form.Group controlId="formBasicdateFrom" as={Row}>
                                            <Form.Label column sm="2">Date From:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="date" 
                                                name="dateFrom"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.dateFrom}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicdateTo" as={Row}>
                                            <Form.Label column sm="2">Date To:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="date" 
                                                name="dateTo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.dateTo}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <center>
                                            <Button type="submit" variant="success">Show Details</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                        <hr/>
                        <RoomInventoryList/>
                    </Container>            
    )
}
export default RoomInventory;