import React, {useState, Fragment } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import classesX from '../Dashboard/dashboard.module.css';
import AdminNavbar from '../navbar/adminNavbar';
import AccVendor from './accVendor/accVendor';
import TourGuide from './tourGuide/tourGuide';
import BusVendor from './busVendor/busVendor';
import RentalVehicleVendor from "./rentalVehicleVendor/rentalVehicleVendor";
import CharterPlaneVendor from './charterPlaneVendor/charterPlaneVendor';
import Activity from './activity/activity';
import CabVendor from './cabVendor/cabVendor';

const OfflineServiceManagement = (props)=>{
    const [active,updateActive] = useState('1');
 
    return(
        <Fragment>
           <AdminNavbar/>
            <h1 className={classesX.Heading}>Offline Service Management</h1>  
            <Container fluid>
            <Row style={{margin:'0 0 2% 0',textAlign:'center',cursor:'pointer',fontSize:'0.9rem'}}>
                <Col className={classes.Col} 
                style={{backgroundColor:`${active==='1'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("1")}>
                    Accommodation Vendor
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='2'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("2")}>
                    Tour Guide
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='3'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("3")}>
                    Cab Vendor
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='4'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("4")}>
                    Bus Vendor
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='5'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("5")}>
                    Rental Vehicle Vendor
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='6'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("6")}>
                    Charter Plane Vendor
                </Col>
                <Col className={classes.Col}
                style={{backgroundColor:`${active==='7'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("7")}>
                    Activities Vendor
                </Col>
            </Row>
            {
                active==="1"?<AccVendor/>:
                active==="2"?<TourGuide />:
                active==="3"?<CabVendor/>:
                active==="4"?<BusVendor/>:
                active==="5"?<RentalVehicleVendor/>:
                active==="6"?<CharterPlaneVendor/>:
                active==="7"?<Activity/>:null
            }
        </Container>     
        </Fragment>
    )
}

export default OfflineServiceManagement;