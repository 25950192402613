import React, { useState } from 'react';
import { Button,Form,Row,Col } from 'react-bootstrap';
import { AddBLOGCategory } from '../../../entities/action/action';
import BlogCategorySubcategoryList from './listForBlogCategory';
import Image from '../../../utilities/imageTestApi/image';

const AddBlogCategoryForm = (props)=>{
    const [category,SetCategory] = useState('');
    const [file,updateFile] = useState({});
    const handleSubmit = async()=>{
        const response1 = await Image(file);
        const newData = {
            "categoryName": category,
            "imageUrl": response1,
          }
        let response;
        if(props?.categoryType === "CATEGORY"){
            response = await AddBLOGCategory(newData);
            console.log(response)
            if(response==="success"){
                alert("Successfully Added Category");
                window.location.reload();
            }
        } 
         
    }
    return(
        <div> 
            <Form.Group controlId="formBasicPromo" as={Row}>
                <Form.Label column sm="3">Blog {`${props.categoryType}`}:</Form.Label>
                <Col sm="9"> 
                    <Form.Control
                        type="text" 
                        placeholder={`Enter Blog ${props.categoryType}`} 
                        name="category"
                        onChange={(e)=>SetCategory(e.target.value)}
                        value={category}
                        required/>
                </Col>
            </Form.Group>
            <Form.Group controlId="formBasicPromo" as={Row}>
                <Form.Label column sm="3">Images:</Form.Label>
                <Col sm="9"> 
                    <Form.Control
                    type="file" 
                    name="file"
                    onChange={(e)=>updateFile(e.target.files[0])}
                    required/>
                </Col>
            </Form.Group>
            <center>
                <Button onClick={handleSubmit} style={{margin:'2%'}}>Save Blog {`${props.categoryType}`}</Button>
            </center>
            <BlogCategorySubcategoryList list={`${props.categoryType}`}/>
        </div>
    )
}
export default AddBlogCategoryForm;