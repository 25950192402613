import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import {
  DeleteDepartment,
  DeleteDesignation,
} from "../../../entities/action/action";
import { useSelector } from "react-redux";

const AddAmenitiesAndHotelFacilitiesList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const [update, setUpdate] = useState(false);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  useEffect(() => {
    (async () => {
      if (props.type == "Amenities") {
        const newdata = {
          emailId: data.data.key,
          password: "string",
          phoneno: "string",
          sessionKey: data.sessionId,
        };
        console.log(newdata);
        const res = await fetch("https://api.zupptravel.com/getBusAmenities", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newdata),
        });
        const json = await res.json();
        console.log(json);
        if (json.successful) {
          updateDataSet(json.busAmenities);
        }
      } else {
        const newdata = {
          emailId: data.data.key,
          password: "string",
          phoneno: "string",
          sessionKey: data.sessionId,
        };
        console.log(newdata);
        const res = await fetch("https://api.zupptravel.com/getBusFacilities", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newdata),
        });

        const json = await res.json();
        console.log(json);
        if (json.successful) {
          updateDataSet(json.busFacilities);
        }
      }
    })();
  }, [props]);
  console.log(dataSet);

  // useEffect(() => {
  //   if (props.data != undefined) {
  //     updateDataSet(props.data);
  //   }
  // }, [props.data]);
  const HandleDelete = async (id) => {
    if (props.type == "Amenities") {
      const newd = {
        emailID: "string",
        id: id,
        sessionKey: "string",
      };
      console.log(newd);
      const r = await fetch("https://api.zupptravel.com/deletebusAmenities", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newd),
      });
      const j = await r.json();
      if (j.successful) {
        alert("Successfully deleted amenity");
        const newdata = {
          emailId: data.data.key,
          password: "string",
          phoneno: "string",
          sessionKey: data.sessionId,
        };
        const res = await fetch("https://api.zupptravel.com/getbusAmenities", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newdata),
        });
        const json = await res.json();
        if (json.successful) {
          updateDataSet(json.busAmenitiess);
        }
      }
    } else {
      const newd = {
        emailID: "string",
        id: id,
        sessionKey: "string",
      };
      const r = await fetch("https://api.zupptravel.com/deletebusFacilities", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newd),
      });
      const j = await r.json();
      if (j.successful) {
        alert("Successfully deleted facility");
        const newdata = {
          emailId: "string",
          password: "string",
          phoneno: "string",
          sessionKey: "string",
        };
        const res = await fetch("https://api.zupptravel.com/getbusFacilities", {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newdata),
        });
        const json = await res.json();
        if (json.successful) {
          updateDataSet(json.hotelFacilities);
        }
      }
    }
  };

  console.log(props);

  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>{props.type}</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {dataSet.length > 0
            ? dataSet.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>
                    {data.amenities} {data.facilities}
                  </td>
                  <td className={classes.CenterRow}>
                    <Button
                      style={{ margin: "auto 1%" }}
                      variant="danger"
                      onClick={() => HandleDelete(data.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
    </Container>
  );

  // const [dataSet,updateDataSet] = useState([]);
  // useEffect(()=>{
  //     if(props.data!=undefined){
  //         updateDataSet(props.data);
  //     }
  // },[props.data])
  // const HandleDelete = async(id)=>{
  //     let response;
  //     if(props.type=="Amenities"){
  //         response = await DeleteDepartment(id);
  //     }else{
  //         response = await DeleteDesignation(id);
  //     }
  //     if(response.status==200){
  //         alert("Successfully Removed");
  //         window.location.reload();
  //     }else{
  //         alert("Not Present or already Removed Please Refresh Your Screen");
  //     }
  // }
  // return(
  //     <Container fluid style={{padding:'0'}}>
  //         <Table striped bordered hover responsive>
  //             <thead>
  //                 <tr>
  //                     <th>S.No</th>
  //                     <th>{props.type}</th>
  //                     <th>Action</th>
  //                 </tr>
  //             </thead>
  //             <tbody>
  //                 {
  //                     dataSet.length>0?dataSet.map((data,key)=>
  //                         <tr key={key}>
  //                             <td>{key+1}</td>
  //                             <td>{data[props.type.toLowerCase()]}</td>
  //                             <td className={classes.CenterRow}>
  //                                 <Button
  //                                     style={{margin:'auto 1%'}}
  //                                     variant="danger"
  //                                     onClick={()=>HandleDelete(data.id)}
  //                                 >Delete</Button>
  //                             </td>
  //                         </tr>
  //                     ):null
  //                 }
  //             </tbody>
  //         </Table>
  //     </Container>
  // )
};
export default AddAmenitiesAndHotelFacilitiesList;
