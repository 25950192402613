import React, {useState, Fragment } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import classesX from '../Dashboard/dashboard.module.css';
import AdminNavbar from '../navbar/adminNavbar';
import AddMarkup from './markup/addMarkup';
import AddDiscount from './discount/addDiscount';
import MarkupList from './markupList/markupList';
import DiscountList from './discountList/discountList';
import Pricing from './pricing/pricing';

const OnlineMarkupManagement = (props)=>{
    const [active,updateActive] = useState('1');
 
    const items = [
        {id:"1",type:"Add Markup"},
        {id:"2",type:"Markup List"},
        {id:"3",type:"Add Discount"},
        {id:"4",type:"Discount List"},
        {id:"5",type:"Pricing"}
    ]
    return(
        <Fragment>
           <AdminNavbar/>
            <h1 className={classesX.Heading}>Online Markup Management</h1>  
        <Container fluid>
            <Row> 
                <Col lg={3} md={12} className={classes.Response2}>
                    <div style={{height:'100%'}}> 
                        <ul className={classes.Ul}>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                    style={{backgroundColor:`${active===item.id?'steelblue':'#585858'}`,textAlign:'center'}}>
                                    {item.type}
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={9} md={12} className={classes.Response}>
                    <div style={{height:'100%'}}> 
                        {
                            active==="1"?<AddMarkup/>:
                            active==="2"?<MarkupList/>:
                            active==="3"?<AddDiscount/>:
                            active==="4"?<DiscountList/>:
                            active==="5"?<Pricing/>:null
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default OnlineMarkupManagement;