import React, { useEffect, useState } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import Page from '../../pagination/pagination';
import { GetMarkup } from '../../../entities/action/action';
import { useSelector } from 'react-redux';

const CommonMarkupList = (props)=>{
    const [dataSet,updateDataSet] = useState([]);
    const [count,updateCount] = useState(0)
    const postPerPage = 20;
    const [currentPage,updateCurrentPage] = useState(1);
    const indexOfLast = currentPage*postPerPage;
    const indexOfFirst = indexOfLast - postPerPage;
    const currentPosts = dataSet.slice(indexOfFirst,indexOfLast);
    const handleChangePage = (event,value)=>{
        updateCurrentPage(value)
    }
    const userData = useSelector(state=>state.adminAccount.apiResponse);

    useEffect(()=>{
        
        (async()=>{
            const newData = {
                "phoneno": "",
                "password": "",
                "emailId": userData.data.key,
                "sessionKey": userData.sessionId
              }
            let arr = await GetMarkup(props.type,newData);
            console.log(arr)
            if(arr.successful){
                if(props.type==="Bus"){
                    updateDataSet(arr.busMarkups);
                  }else if(props.type==="Hotel"){
                    updateDataSet(arr.hotelMarkups);
                  }else if(props.type==="Cab"){
                    updateDataSet(arr.cabMarkups);
                  }else{
                    updateDataSet(arr.insuranceMarkups);
                  }
                
            }
        })()
    },[props.type])
    // const [dataSet,updateDataSet] = useState([]);
    useEffect(()=>{
        updateCount(Math.ceil(dataSet.length/postPerPage))
    },[dataSet])
     
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Amount Type</th>
                        <th>Markup Amount</th>
                        <th>Lowest</th>
                        <th>Highest</th>
                        <th>Markup Calculation</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentPosts.length>0?currentPosts.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.amountType}</td>
                                <td>{data.markupAmount}</td>
                                <td>{data.lowestMarkupRange}</td>
                                <td>{data.highestMarkupRange}</td>
                                <td>{data.markupCalculation}</td>
                                <td className={classes.CenterRow}>
                                    <Button style={{margin:'auto 1%'}} variant="danger">Delete</Button>
                                    <Button style={{margin:'auto 1%'}} variant="success">Edit</Button>
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
            <div style={{display:'flex',justifyContent:'center',margin:'15px 0'}}>
                <Page count={count} change={handleChangePage} page={currentPage}/> 
            </div>
        </Container>
    )
}
export default CommonMarkupList;