import React, { useState } from "react";
import { Formik } from "formik";
import { Form, Button, Row, Col } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
// import {DropzoneArea} from 'material-ui-dropzone';

const AddCabGeneralView = (props) => {
  const [message, updateMessage] = useState("");
  // const [files,updateFiles] = useState([]);
  const [edit, updateEdit] = useState(false);
  // const handleImages = (inpFile)=>{
  //     updateFiles(inpFile)
  // }
  const [arrPoints1, updateArrPoints1] = useState([
    ...new Array(props.data.cabCancellation.length).fill(0).map((_, i) => i),
  ]);
  console.log(props.data);

  const clickHandler = (id) => {
    const win = window.open(id, "_blank");
    win.focus();
  };
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          // cabCancellation: cancellationCharges,
          // cabDetailsId: 0,
          ...Object.assign(
            {},
            ...props.data.cabCancellation.map((i, index) => ({
              [`cancellation${index}`]: i.cancellation1,
              [`charges${index}`]: i.cancellationCharges1,
            }))
          ),
          cabAmenities: props.data.cabAmenities,
          CabManufacturerName: props.data.cabManufacturerName,
          CabName: props.data.cabName,
          CabRegistrationNumber: props.data.cabRegistrationNumber,
          CabType: props.data.cabType,
          // emailId: "string",
          fuelType: props.data.fuelType,
          liveTracking: props.data.liveTracking,
          partialCancellation: props.data.partialCancellation,
          // rcImage: img,
          seatCount1: props.data.seatCount,
          // seatType: values.seatType,
          // sessionKey: "string",
          // termsAndCondition: message,
          vendorId: props.data.vendorId,
          waitingPeriod: props.data.waitingPeriod,
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
          const charges = [];
          const cancellation = [];

          Object.entries(values).map(([key, value]) => {
            console.log(key, value);
            if (key.includes("charges") && typeof value === "string") {
              charges.push(value);
            } else if (
              key.includes("cancellation") &&
              typeof value === "string"
            ) {
              cancellation.push(value);
            }
          });

          const cancellationCharges = [];

          for (let i = 0; i < charges.length; i++) {
            cancellationCharges.push({
              cancellation1: cancellation[i],
              cancellationCharges1: charges[i],
            });
          }

          console.log(charges, cancellation, "checkkkkk");

          const newdata = {
            cabCancellation: cancellationCharges,
            cabDetailsId: props.data.id,
            cabAmenities: props.data.cabAmenities,
            cabManufacturerName: values.CabManufacturerName,
            cabName: values.CabName,
            cabRegistrationNumber: values.CabRegistrationNumber,
            cabType: values.CabType,
            // emailId: "string",
            fuelType: values.fuelType,
            liveTracking: values.liveTracking,
            partialCancellation: values.partialCancellation,
            rcImage: props.data.rcImage,
            seatCount: values.seatCount1,
            // seatType: values.seatType,
            sessionKey: "string",
            termsAndCondition: props.data.termsAndCondition,
            vendorId: props.data.vendorId,
            waitingPeriod: values.waitingPeriod,
          };

          const res = await fetch(
            "https://api.zupptravel.com/updateCabDetails",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newdata),
            }
          );

          console.log("rrrrrrrrrrrr", newdata);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("Cab Detail updated!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "2%",
              }}
            >
              {!edit ? (
                <Button variant="success" onClick={() => updateEdit(true)}>
                  Edit
                </Button>
              ) : (
                <Button
                  variant="danger"
                  onClick={() => {
                    updateEdit(false);
                    resetForm();
                  }}
                >
                  Cancel
                </Button>
              )}
              <Button
                variant="light"
                style={{ marginLeft: "15px" }}
                onClick={() => props.click()}
              >
                {`<--`} Back
              </Button>
            </div>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Vendor ID"
                  name="vendorId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vendorId}
                  required
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicInfo" as={Row}>
              <Form.Label column sm="3">
                Cab Registration Number:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Cab Registration Number"
                  name="CabRegistrationNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.CabRegistrationNumber}
                  required
                  readOnly
                />
              </Col>
              <Form.Label column sm="2">
                Cab Manufacturer Name:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Cab Manufacturer Name"
                  name="CabManufacturerName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.CabManufacturerName}
                  required
                  readOnly
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCabType" as={Row}>
              <Form.Label column sm="3">
                Cab Type:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter Cab Type"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="CabType"
                  value={values.CabType}
                  required
                  readOnly
                />
              </Col>
              <Form.Label column sm="2">
                Cab Name(Model):
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter Cab Name"
                  name="CabName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.CabName}
                  required
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicBusName" as={Row}>
              <Form.Label column sm="3">
                Cab Amenities:
              </Form.Label>

              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Cab Name"
                  name="cabAmenities"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cabAmenities}
                  required
                  readOnly
                />
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicCabName" as={Row}>
              <Form.Label column sm="3">
                Fuel Type:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  //   as="select"
                  type="text"
                  placeholder="Enter Fuel Type"
                  name="fuelType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.fuelType}
                  required
                  readOnly
                />
              </Col>

              <Form.Label column sm="2">
                Waiting Period:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter Waiting Period"
                  name="waitingPeriod"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.waitingPeriod}
                  required
                  readOnly
                />
              </Col>
            </Form.Group>
            {/* {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group controlId={`formSeatType${d}`} key={key} as={Row}>
                    <Form.Label column sm="3">
                      Seat Type {d}:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Enter Seat Type ( option ${d} )`}
                        name={`seatType${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`seatType${d}`]}
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder="Enter Seat Count"
                        name={`seatCount${1}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`seatCount${1}`]}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null} */}
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>
              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div> */}
            <Form.Group controlId="formBasicSeatType1" as={Row}>
              <Form.Label column sm="2">
                Seat Count:
              </Form.Label>
              {/* <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter Seat Type ( option 1 )"
                  name="seatType1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatType1}
                  required
                />
              </Col> */}
              <Form.Label column sm="1">
                {/* Seat Count: */}
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter Seat Count"
                  name="seatCount1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatCount1}
                  required
                  readOnly
                />
              </Col>

              <Form.Label column sm="2">
                RC Image:
              </Form.Label>
              <Col sm="2">
                <Form.Control
                  type="file"
                  name="file"
                  readOnly
                  //   onChange={(e) => updateFile(e.target.files[0])}
                  required
                  disabled
                />
              </Col>
              <Col>
                <Button onClick={() => clickHandler(props.data.rcImage)}>
                  Open
                </Button>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicLiveTracking" as={Row}>
              <Form.Label column sm="3">
                Partial Cancellation:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  //   as="select"
                  type="text"
                  placeholder="Partial Cancellation"
                  name="partialCancellation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.partialCancellation}
                  required
                  readOnly
                />
              </Col>
              <Form.Label column sm="2">
                Live Tracking:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Live Tracking"
                  name="liveTracking"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.liveTracking}
                  required
                  readOnly
                />
              </Col>
            </Form.Group>
            {arrPoints1.length > 0
              ? arrPoints1.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Cancellation {d}:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Cancellation ${d}`}
                        name={`cancellation${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`cancellation${d}`]}
                        required
                        readOnly
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Charges ${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`charges${d}`}
                        value={values[`charges${d}`]}
                        required
                        readOnly
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}

            {/* <Form.Group controlId="formBasicChild" as={Row}>
                                            <Form.Label column sm="3">Images:</Form.Label>
                                            <Col sm="9"> 
                                                <DropzoneArea onChange={ handleImages }filesLimit={5}/>
                                            </Col>
                                        </Form.Group> */}
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                />
              </Col>
            </Row>
            <center>
              <Button type="submit" variant="success">
                update Cab
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddCabGeneralView;
