import React, { useState, useEffect } from "react";
import { Col, Row, Container, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import Image from "../../../utilities/imageTestApi/image";
import MultipleSelect from "../../multiSelect";
import RoomCategoryList from "./roomCategoryList";
import ParentMealPrice from "./ParentMealPrice";
import ParentRoomPrice from "./ParentRoomPrice";
import { GetVendor } from "../../../entities/action/action";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import RoomName from "./RoomName";

const MealsAndRoomPrice = (props) => {

    const [active, updateActive] = useState("2");

  return (
    <Container fluid className={classes.Box}>
      <Row
        style={{
          margin: "0 0 2% 0",
          textAlign: "center",
          cursor: "pointer",
          fontSize: "0.8rem",
        }}
      >
        <Col
          className={classes.Col}
          style={{
            backgroundColor: `${active === "2" ? "steelblue" : "#585858"}`,
            color: "white",
            padding: "1%",
          }}
          onClick={() => updateActive("2")}
        >
 Set Meal Price
        </Col>
        <Col
          className={classes.Col}
          style={{
            backgroundColor: `${active === "3" ? "steelblue" : "#585858"}`,
            color: "white",
            padding: "1%",
          }}
          onClick={() => updateActive("3")}
        >
      Set Room Price
        </Col>
      </Row>
      {
        // active==="1"?<RoomCategory/>:
        active === "2" ? (
          <ParentMealPrice />
        ) : active === "3" ? (
          <ParentRoomPrice />
        ) : null
      }
    </Container>
  );
};
export default MealsAndRoomPrice;
