import React, { useEffect, useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Field, FieldArray, Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import MultipleSelect from "../../multiSelect";
import { DropzoneArea } from "material-ui-dropzone";
import { GetVendor } from "../../../entities/action/action";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import { useDispatch, useSelector } from "react-redux";
import { Pincode } from "../../../entities/action/action";
import Image from "../../../utilities/imageTestApi/image";

const AddDriver = (props) => {
  const [message, updateMessage] = useState("");
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const [city, updateCity] = useState([]);
  const [dataSet, updateDataSet] = useState([]);
  const [location, updateLocations] = useState([]);
  const [state, setState] = useState("");
  const [mycity, setCity] = useState("");
  const [block, setBlock] = useState("");
  const [message2, updateMessage2] = useState("");
  const [file, updateFile] = useState({});
  const [file1, updateFile1] = useState({});
  const [select, updateSelect] = useState("");
  const [files, updateFiles] = useState([]);
  const [file2, updateFile2] = useState([]);
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([1]);
  const [currentPoints1, updateCurrentPoints1] = useState(1);
  const [arrPoints1, updateArrPoints1] = useState([1]);
  const [currentPoints2, updateCurrentPoints2] = useState(1);
  const [arrPoints2, updateArrPoints2] = useState([1]);
  const [vendorList, updateVendorList] = useState([]);
  const [vendorId, updateVendorId] = useState("");

  useEffect(() => {
    (async () => {
      const newData = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch(
        "https://api.zupptravel.com/getLocationManagement",
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(newData),
        }
      );
      const json = await res.json();
      if (json.successful) {
        updateLocations(json.locationManagements);
      }
    })();
  }, []);

  const [country, setCountry] = useState("");
  const [pincode, updatePincode] = useState("");
  const handlePincode = (e) => {
    if (e !== "") {
      updatePincode(e);
      const x = location.filter((a, i) => a.pincode === e);
      setCity(x[0].city);
      setState(x[0].state);
      setCountry(x[0].country);
      setBlock(x[0].blockTehsilTaluka);
    }
  };

  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log("values", values);
          const img = await Image(file);
          const img1 = await Image(file1);
          const img2 = await Image(file2);
          console.log(img);
          const newdata = {
            addressLine1: values.driverAddress,
            addressLine2: values.driverAddress1,
            age: values.driverage,
            cabDriverId: 0,
            city: mycity,
            country: country,
            driverName: values.driverName,
            driverType: values.drivingType,
            emailId: "string",
            experience: values.drivingExperience,
            licenceCopy: img1,
            photo: img2,
            pinCode: pincode,
            proof: img,
            sessionKey: "string",
            state: state,
            tehsil_block: block,
            village: values.village,
          };
          console.log(newdata);
          const res = await fetch("https://api.zupptravel.com/addCabDriver", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newdata),
          });
          const json = await res.json();
          console.log(json);
          if (json.successful) {
            alert("Added Cab Driver!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicInfo" as={Row}>
              <Form.Label column sm="2">
                Driver Name:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter Driver Name"
                  name="driverName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.driverName}
                />
              </Col>
              <Form.Label column sm="2">
                Driver Age:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="string"
                  placeholder="Enter Driver Age"
                  name="driverage"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.driverage}
                />
              </Col>
            </Form.Group>
            <br />

            <Form.Group controlId="formBasicTourGuideType" as={Row}>
              <Form.Label column sm="2">
                Driving Type:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  // as="select"
                  type="text"
                  placeholder="Enter Driving Type"
                  name="drivingType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.drivingType}
                />
                {/* <option value="">--select--</option> */}
                {/* </Form.Control> */}
              </Col>
              <Form.Label column sm="2">
                Driving Licence Copy:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="file"
                  name="file"
                  onChange={(e) => updateFile1(e.target.files[0])}
                  required
                />
              </Col>
            </Form.Group>
            <br />
            <Form.Group controlId={`DrivingExperienceandImage`} as={Row}>
              <Form.Label column sm="2">
                Driving Experience :
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder={`Enter Driving Experience `}
                  name={`drivingExperience`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.drivingExperience}
                  required
                />
              </Col>

              <Form.Label column sm="2">
                Upload ID Proof:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="file"
                  name="file"
                  onChange={(e) => updateFile(e.target.files[0])}
                  required
                />
              </Col>
            </Form.Group>

            <Form.Group controlId={"drivinguploadphoto"} as={Row}>
              <Form.Label column sm="2">
                Upload Photo:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="file"
                  name="file"
                  onChange={(e) => updateFile2(e.target.files[0])}
                  required
                />
              </Col>
            </Form.Group>

            <br />

            <Form.Group controlId={"DrivingLicenceCopy"} as={Row}>
              <Form.Label column sm="2">
                Address Line 1:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder={`Enter Address`}
                  name={`driverAddress`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // value={values[`language`]}
                  value={values.driverAddress}
                />
              </Col>
              <Form.Label column sm="2">
                Address Line 2:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder={`Enter Address`}
                  name={`driverAddress1`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  // value={values[`language`]}
                  value={values.driverAddress1}
                />
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicAddress2" as={Row}>
              <Form.Label column sm="2"></Form.Label>
              <Col sm="9">
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="formBasicPin" as={Row}>
                      <Form.Label column sm="2">
                        Pin Code:
                      </Form.Label>
                      <Col sm="9">
                        <SingleSelect
                          type="5"
                          list={location}
                          change={(select) => handlePincode(select)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="formBasicCountry" as={Row}>
                      <Form.Label column sm="2">
                        Country:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          readOnly
                          placeholder="Enter Country"
                          name="country"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={country}
                        />
                      </Col>
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="formBasicCity" as={Row}>
                      <Form.Label column sm="2">
                        City:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          readOnly
                          placeholder="Enter City"
                          name="city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={mycity}
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicState" as={Row}>
                      <Form.Label column sm="2">
                        State:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          readOnly
                          type="text"
                          placeholder="Enter State"
                          name="state"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={state}
                          required
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicVillage" as={Row}>
                      <Form.Label column sm="2">
                        Village:
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          placeholder="Enter Village"
                          name="village"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.village}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col md={6} xs={12}>
                    <Form.Group controlId="formBasicBlock" as={Row}>
                      <Form.Label column sm="2">
                        Tehsil/Block :
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="text"
                          placeholder="Enter Tehsil/Block"
                          name="block"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={block}
                          readOnly
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <center>
              <Button type="submit" variant="success">
                Save Driver
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddDriver;
