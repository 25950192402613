import React, { useEffect, useState } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
// import {GetActiveCoupon,DeleteCoupon} from '../../entities/action/action';
import {useSelector} from 'react-redux';
import Page from '../../pagination/pagination';
import classes from '../../WebsiteManagement/websiteManagement.module.css';

const IpBlockList = (props)=>{
    const [dataSet,updateDataSet] = useState([]);
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    const [count,updateCount] = useState(0)
    const postPerPage = 20;
    const [currentPage,updateCurrentPage] = useState(1);
    const indexOfLast = currentPage*postPerPage;
    const indexOfFirst = indexOfLast - postPerPage;
    const currentPosts = dataSet.slice(indexOfFirst,indexOfLast);
    const handleChangePage = (event,value)=>{
        updateCurrentPage(value)
    }
    useEffect(()=>{
        updateCount(Math.ceil(dataSet.length/postPerPage))
    },[dataSet])
    // useEffect(()=>{
    //     (async()=>{
    //         let arr = await GetCurrencyList();
    //         if(arr!=null){
    //             updateDataSet(arr);
    //         }
    //     })()
    // },[])
    // const handleDelete = async(id)=>{
    //     const newData = {
    //         "id": id,
    //         "loginId": userData.data.key,
    //         "sessionKey": userData.sessionId
    //     }
    //     const res = await DeleteCurrency(newData);
    //     if(res.successful){
    //         alert("Successfully Deleted");
    //         let arr = await GetActiveCoupon();
    //         if(arr!=null){
    //             updateDataSet(arr);
    //         }
    //     }
    // }
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>IP Address</th>
                        <th>Module</th>
                        <th>Remarks</th>
                        <th>Time</th>
                        <th>Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        currentPosts.length>0?currentPosts.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td>{data.ipAddress}</td>
                                <td>{data.module}</td>
                                <td>{data.remark}</td>
                                <td>{data.time}</td>
                                <td>{data.time}</td>
                                <td>{data.date}</td>
                                <td className={classes.CenterRow}>
                                    <Button style={{margin:'auto 1%'}} variant="danger">Delete</Button>
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
            <div style={{display:'flex',justifyContent:'center',margin:'15px 0'}}>
                <Page count={count} change={handleChangePage} page={currentPage}/> 
            </div>
        </Container>
    )
}
export default IpBlockList;