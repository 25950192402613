import React, { useState, useEffect } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import { DatePicker } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const AddAvailabilityAndPrice = (props) => {
  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf("day");
  }

  const [tourGuideList, UpdateTourGuideList] = useState([]);
  const [tgType, updateTgType] = useState("");
  const [tgName, updateTgName] = useState("");
  const [dataSet, updateDataSet] = useState([]);
  const [id, setId] = useState("");
  const [date, updateDate] = useState([]);
  const [selected, updateSelected] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [unitPriceGst, setUnitPriceGst] = useState("");
  const [unitPrice1, setUnitPrice1] = useState("");
  const [unitPriceGst1, setUnitPriceGst1] = useState("");
  const [unitPrice2, setUnitPrice2] = useState("");
  const [unitPriceGst2, setUnitPriceGst2] = useState("");
  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        `https://api.zupptravel.com/getTourGuide`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await myresponse.json();
      let arr = [];
      console.log(json);
      if (json.successful) {
        json.tourGuides.map((data, id) => {
          console.log(data.tourGuideId);
          arr.push(data.tourGuideId);
        });
        UpdateTourGuideList(arr);

        updateDataSet(json.tourGuides);
      }
    })();
  }, []);

  console.log(selected);

  useEffect(() => {
    dataSet.map((data, key) => {
      if (data.tourGuideId == selected) {
        updateTgName(data.guideName);
        updateTgType(data.guideType);
        setId(data.id);
      }
    });
  }, [selected]);

  const handlePrice = (x, y, z, b) => {
    setUnitPrice(x);
    setUnitPriceGst(y);
    setUnitPrice1(z);

    setUnitPrice2(b);
  };

  console.log(date);
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          unitSericeVariablemsp: "",
          taxSlabVariablemsp: "",
          unitSericeVariableocc: "",
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const newdata = {
            availabilityEndDate: date[1]?._d.toISOString().slice(0, 10),
            availabilityStartDate: date[0]?._d.toISOString().slice(0, 10),
            costType: values.costType,
            emailId: "string",
            offerPriceCXAmount: unitPrice1,
            offerPriceCXGstCost: unitPrice1 * 0.01 * unitPriceGst,
            offerPriceCXGstRate: unitPriceGst,
            offerPriceCXTotalCost:
              unitPrice1 + unitPrice1 * 0.01 * unitPriceGst,
            pubishTotalCost: unitPrice + unitPrice * 0.01 * unitPriceGst,
            publishAmount: unitPrice,
            publishGstCost: unitPrice * 0.01 * unitPriceGst,
            publishGstRate: unitPriceGst,
            purchasePriceZTAmount: unitPrice2,
            purchasePriceZTGstCost: unitPrice2 * 0.01 * unitPriceGst,
            purchasePriceZTGstRate: unitPriceGst,
            purchasePriceZTTotalCost:
              unitPrice2 + unitPrice2 * 0.01 * unitPriceGst,
            sessionKey: "string",
            tgName: tgName,
            tgType: tgType,
            tourGuideAvailabilityAndPriceId: 0,
            tourGuideId: id,
          };

          const res = await fetch(
            "https://api.zupptravel.com/addTourGuideAvailabilityAndPrice",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newdata),
            }
          );

          console.log("rrrrrrrrrrrr", newdata);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("Added TourGide Avalibility!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicVendorName" as={Row}>
              <Form.Label column sm="2">
                Tour Guide ID:
              </Form.Label>
              <Col sm="10">
                <SingleSelect
                  type="2"
                  list={tourGuideList}
                  change={(select) => updateSelected(select)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTGType" as={Row}>
              <Form.Label column sm="2">
                TG Type:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  readOnly
                  type="text"
                  name="tgType"
                  placeholder="Tour Guide Type"
                  value={tgType}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTGName" as={Row}>
              <Form.Label column sm="2">
                TG Name:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  type="text"
                  readOnly
                  placeholder="TG Name"
                  name="tgName"
                  value={tgName}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicdateFrom" as={Row}>
              <Form.Label column sm="2">
                Availability:
              </Form.Label>
              {/* <Col sm="5">
                <Form.Control
                  type="date"
                  name="dateFrom"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateFrom}
                  required
                />
                <RangePicker
                  onChange={(val) => updateDate(val)}
                  value={date}
                  disabledDate={disabledDate}
                />
              </Col> */}
              <Col sm="5">
                <RangePicker
                  onChange={(val) => updateDate(val)}
                  value={date}
                  disabledDate={disabledDate}
                />

                {/* <Form.Control
                  type="date"
                  name="dateTo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.dateTo}
                  required
                /> */}
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTGType" as={Row}>
              <Form.Label column sm="2">
                Cost Type:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  name="costType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.costType}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Per Day">Per Day</option>
                  <option value="Per Day">Per Hour</option>
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Label style={{ fontWeight: "bold" }}>
              Publish Price:
            </Form.Label>
            <div style={{ border: "1px solid grey" }} className="container">
              <Form.Group
                controlId="formBasicMrp1"
                style={{ marginTop: "15px" }}
                as={Row}
              >
                <Form.Label column sm="3">
                  Publish Fare:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitPrice1"
                    min={0}
                    onChange={handleChange}
                    value={values.unitPrice1}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder=" Unit GST "
                    name="unitGst1"
                    min={1}
                    max={100}
                    isInvalid={values.unitGst1 < 0 || values.unitGst1 > 100}
                    onChange={handleChange}
                    value={values.unitGst1}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="totalGst1"
                    value={values.unitPrice1 * 0.01 * values.unitGst1}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    min={0}
                    placeholder="MSP"
                    name="publishMsp1"
                    onBlur={handleBlur}
                    value={
                      values.unitPrice1 +
                      values.unitPrice1 * 0.01 * values.unitGst1
                    }
                    required
                  />
                </Col>
              </Form.Group>
            </div>
            <Form.Label style={{ fontWeight: "bold" }}>
              Offer Price to CX:
            </Form.Label>
            <div style={{ border: "1px solid grey" }} className="container">
              <Form.Group
                controlId="formBasicMrp2"
                style={{ marginTop: "15px" }}
                as={Row}
              >
                <Form.Label column sm="3">
                  Offer Price to Customer:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitPrice2"
                    min={0}
                    onChange={handleChange}
                    isInvalid={values.unitPrice2 > values.unitPrice1}
                    value={values.unitPrice2}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder=" Unit GST "
                    name="unitGst1"
                    min={1}
                    readOnly
                    max={100}
                    isInvalid={values.unitGst1 < 0 || values.unitGst1 > 100}
                    value={values.unitGst1}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="totalGst2"
                    value={values.unitPrice2 * 0.01 * values.unitGst1}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    min={0}
                    placeholder="MSP"
                    name="publishMsp2"
                    onBlur={handleBlur}
                    value={
                      values.unitPrice2 +
                      values.unitPrice2 * 0.01 * values.unitGst1
                    }
                    required
                  />
                </Col>
              </Form.Group>
            </div>
            <Form.Label style={{ fontWeight: "bold" }}>
              Purchase Price to ZT:
            </Form.Label>
            <div style={{ border: "1px solid grey" }} className="container">
              <Form.Group
                controlId="formBasicMrp3"
                style={{ marginTop: "15px" }}
                as={Row}
              >
                <Form.Label column sm="3">
                  Purchase Price to ZT:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitPrice3"
                    min={0}
                    readOnly
                    onChange={handleChange}
                    value={Math.round(values.unitPrice2 * 0.8675)}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    step="any"
                    placeholder=" Unit GST "
                    name="unitGst1"
                    min={1}
                    // max={100}
                    readOnly
                    // isInvalid={values.unitGst1 < 0 || values.unitGst1 > 100}
                    value={Math.round(values.unitGst1)}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="totalGst3"
                    value={Math.round(
                      values.unitPrice2 * 0.8675 * 0.01 * values.unitGst1
                    )}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    min={0}
                    placeholder="MSP"
                    name="publishMsp3"
                    onBlur={handleBlur}
                    value={Math.round(
                      values.unitPrice2 * 0.8675 * 0.01 * values.unitGst1 +
                        values.unitPrice2 * 0.8675
                    )}
                    required
                  />
                </Col>
              </Form.Group>
            </div>
            <center>
              <Button
                style={{ marginTop: "15px" }}
                type="submit"
                variant="success"
              >
                Add Availability And Price
              </Button>
            </center>
            {handlePrice(
              values.unitPrice1,
              values.unitGst1,
              values.unitPrice2,

              values.unitPrice2 * 0.8675
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddAvailabilityAndPrice;
