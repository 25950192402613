import React, { useEffect, useState } from 'react';
import {Table, Container, Button} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import {DeletePackage,DeleteStyle} from '../../../entities/action/action';
import { useSelector } from 'react-redux';

const PackageCategoryAndStyleList = (props)=>{
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    const [dataSet,updateData] = useState([]);
    useEffect(()=>{
        
        (async()=>{
            if(props.type=="Category"){
                const myresponse = await fetch("https://api.zupptravel.com/getHolidayCategory", {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body:JSON.stringify({
                    "emailId": userData.data.key,
                    "password": "string",
                    "phoneno": "string",
                    "sessionKey": userData.sessionId
                })
            });
            const myjson = await myresponse.json();
            console.log("MYJSON",myjson)
            if(myjson.successful){
                updateData(myjson.categoryList)
            }
            }else{
                const myresponse = await fetch("https://api.zupptravel.com/getHolidayStyle", {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body:JSON.stringify({
                    "emailId": userData.data.key,
                    "password": "string",
                    "phoneno": "string",
                    "sessionKey": userData.sessionId
                })
            });
            const myjson = await myresponse.json();
            console.log("MYJSON",myjson)
            if(myjson.successful){
                updateData(myjson.styleList)
            }
            }
            
        })()
    
},[props.type])
    // useEffect(()=>{
    //     if(props.data!=undefined){
    //         updateDataSet(props.data);
    //     }
    // },[props.data])
    const HandleDelete = async(id)=>{
        let response;
        if(props.type=="Category"){
            const newData={
                "emailID": userData.data.key,
                "id": id,
                "sessionKey": userData.sessionId
            }
            response = await DeletePackage(newData);
        }else{
            const newData={
                "emailID": userData.data.key,
                "id": id,
                "sessionKey": userData.sessionId
            }
            response = await DeleteStyle(id);
        }
        if(response.successful){
            alert("Successfully Removed");
            window.location.reload();
        }else{
            alert("Not Present or already Removed Please Refresh Your Screen");
        }
    }
    console.log(dataSet)
    return(
        <Container fluid style={{padding:'0'}}>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Package {props.type}</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataSet.length>0?dataSet.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td> {props.type==="Category"?data.categoryName:data.styleName} </td>
                                <td className={classes.CenterRow}>
                                    <Button 
                                        style={{margin:'auto 1%'}} 
                                        variant="danger"
                                        onClick={()=>HandleDelete(data.id)}
                                    >Delete</Button>
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
        </Container>
    )
}
export default PackageCategoryAndStyleList;