import React, { useEffect, useState }  from 'react';
import { Route,Switch,withRouter} from 'react-router-dom';
import AdminPanel from './components/adminPanel';
import AdminLogin from './adminLogin';
import {Helmet} from "react-helmet";
import { GetMetaDetails } from './entities/action/action';

const App = ()=>{
    const [data,updateData] = useState({});
    useEffect(()=>{
        (async()=>{
            const response = await GetMetaDetails();
            if(response?.successful&&response?.meta!==null){
                updateData(response.meta);
            }
        })()
    },[])
    return(
        <> 
            <Helmet>
                <meta charSet="utf-8" />
                <title>{data.metaTitle}</title>
                <meta
                    name="description"
                    content={data.description}
                />
                <meta name="keywords" content={data.keyword}/> 
                <link rel="canonical" href="https://zupptravel.com/" />
                <link rel="icon" href={data?.imageUrl} sizes="32x32"/>
                <link rel="apple-touch-icon" href={data?.imageUrl}/>
            </Helmet>
            <Switch> 
                <Route path="/adminLogin" component={AdminLogin}/>
                <Route path="/adminPanel" component={AdminPanel}/>
          
            </Switch>
        </>
    )
}
export default withRouter(App);