import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import Page from "../../pagination/pagination";
import { useSelector } from "react-redux";

const RoomCategoryList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet.slice(indexOfFirst, indexOfLast);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet.length / postPerPage));
  }, [dataSet]);

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        `https://api.zupptravel.com/getRoomCategory`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await myresponse.json();
      console.log("jsssoooooonnn", json);

      if (json.successful) {
        updateDataSet(json.roomCategorys);
      }
    })();
  }, []);

  const HandleDelete = async (id) => {
    const newd = {
      emailID: data.data.key,
      id: id,
      sessionKey: data.sessionId,
    };
    console.log(newd);
    const r = await fetch("https://api.zupptravel.com/deleteRoomCategory", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newd),
    });
    const j = await r.json();
    if (j.successful) {
      alert("Successfully deleted Room Category");
      const newdata = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getRoomCategory", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      if (json.successful) {
        updateDataSet(json.roomCategorys);
      }
    }
  };

  console.log(dataSet);

  const HandleCreate = async () => {
    const res = await fetch(
      "https://api.zupptravel.com/createHotelAvailabilityInventory",
      {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        // body: JSON.stringify(newdata),
      }
    );
    const json = await res.json();
    console.log("first ", json);

    if (json.successful) {
      alert("Room Inventory Created");
      window.location.reload();
    } else {
      alert("Error! Please try again later");
    }
  };

  return (
    <Container fluid style={{ padding: "0" }}>
      <Button
        style={{ display: "flex", margin: "10px", float: "right" }}
        variant="success"
        onClick={() => HandleCreate()}
      >
        Create Room Inventory
      </Button>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Room Name</th>
            <th>Accomodation Id</th>
            <th>No. of Rooms</th>
            <th>Check-in Time</th>
            <th>Check-out Time</th>
            <th>Amenities</th>
            <th>Image 1</th>
            <th>Image 2</th>
            <th>Image 3</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.length > 0
            ? currentPosts.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  <td>{data.name}</td>
                  <td>{data.accId}</td>
                  <td>{data.noOfRooms}</td>
                  <td>{data.checkInTime}</td>
                  <td>{data.checkOutTime}</td>
                  <td>
                    {data.amenities[0]?.name} {data.amenities[1]?.name}
                  </td>
                  <td>
                    <img
                      src={data.imageUrl1}
                      style={{ width: "50px" }}
                      alt="active coupon"
                    />
                  </td>
                  <td>
                    <img
                      src={data.imageUrl2}
                      style={{ width: "50px" }}
                      alt="active coupon"
                    />
                  </td>
                  <td>
                    <img
                      src={data.imageUrl3}
                      style={{ width: "50px" }}
                      alt="active coupon"
                    />
                  </td>
                  <td>
                    {data.active ? (
                      <Button variant="success">Active</Button>
                    ) : (
                      <Button variant="warning">Inactive</Button>
                    )}
                    {/* <Button style={{ margin: "auto 1%" }} variant="success">
                      Edit
                    </Button> */}
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default RoomCategoryList;
