import React, { useEffect, useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import { GetVendor } from "../../../entities/action/action";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import { useSelector } from "react-redux";
// import {DropzoneArea} from 'material-ui-dropzone';

const AddBusType = (props) => {
  const [message, updateMessage] = useState("");
  const [vendorList, updateVendorList] = useState([]);
  // const [files,updateFiles] = useState([]);

  // const handleImages = (inpFile)=>{
  //     updateFiles(inpFile)
  // }
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([1]);
  const [currentPoints1, updateCurrentPoints1] = useState(1);
  const [arrPoints1, updateArrPoints1] = useState([1]);
  const [vendorId, updateVendorId] = useState("");
  const [dataSet, updateDataSet] = useState([]);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const [amenities, updateAmenities] = useState("");

  useEffect(() => {
    (async () => {
      const res = await GetVendor();
      console.log(res);

      if (res != undefined && res.length > 0) {
        const arr = res.filter(
          (item, key) => item.companyInformation.vendortype === "Bus Operator"
        );
        // console.log(res);
        updateVendorList(arr);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const newdata = {
        emailId: data.data.key,
        password: "string",
        phoneno: "string",
        sessionKey: data.sessionId,
      };
      console.log(newdata);
      const res = await fetch("https://api.zupptravel.com/getBusAmenities", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      console.log(json);
      if (json.successful) {
        updateDataSet(json.busAmenities);
      }
    })();
  }, []);

  console.log("dataaa", dataSet);

  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    } else {
      const newCurrentPoint1 = currentPoints1 + 1;
      updateCurrentPoints1(newCurrentPoint1);
      updateArrPoints1([...arrPoints1, newCurrentPoint1]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    } else if (type === 2 && currentPoints1 > 1) {
      const newCurrentPoint1 = currentPoints1 - 1;
      updateCurrentPoints1(newCurrentPoint1);
      let arr = [...arrPoints1];
      arr.pop();
      updateArrPoints1(arr);
    }
  };

  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{
          cancellation: [
            {
              cancellation: "",
              cancellationCharges: "",
            },
          ],
          seatType: [
            {
              seatCount: "",
              seatType: "",
            },
          ],
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
          const charges = [];
          const cancellation = [];
          const seatType = [];
          const seatCount = [];

          Object.entries(values).map(([key, value]) => {
            console.log(key, value);
            if (
              key.includes("cancellationCharges") &&
              typeof value === "string"
            ) {
              charges.push(value);
            } else if (
              key.includes("cancellation") &&
              typeof value === "string"
            ) {
              cancellation.push(value);
            } else if (key.includes("seatCount") && typeof value === "number") {
              seatCount.push(value);
            } else if (key.includes("seatType") && typeof value === "string") {
              seatType.push(value);
            }
          });

          const cancellationCharges = [];
          const seatTypes1 = [];

          for (let i = 0; i < charges.length; i++) {
            cancellationCharges.push({
              cancellation: cancellation[i],
              cancellationCharges: charges[i],
            });
          }

          for (let i = 0; i < seatCount.length; i++) {
            seatTypes1.push({
              seatCount: seatCount[i],
              seatType: seatType[i],
            });
          }

          console.log(seatTypes1);
          console.log(cancellationCharges);

          console.log(charges, cancellation, "checkkkkk");
          console.log(seatCount, seatType, "ccccccccccc");
          const newdata = {
            busManufacturerName: values.busManufacturerName,
            busName: values.busName,
            busRegisterationNo: values.busRegistrationNumber,
            busType: values.busType,
            busVendorId: 0,
            cancellation: [...cancellationCharges],
            emailId: "string",
            liveTracking: values.liveTracking,
            partialCancellation: values.partialCancellation,
            seatType: [...seatTypes1],
            sessionKey: "string",
            termsAndConditions: message,
            vendorId: vendorId,
          };

          const res = await fetch("https://api.zupptravel.com/addBusVendor", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newdata),
          });

          console.log("rrrrrrrrrrrr", newdata);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("Added Bus Vendor!");
            props.click();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <SingleSelect
                  type="3"
                  list={vendorList}
                  change={(select) => updateVendorId(select)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicInfo" as={Row}>
              <Form.Label column sm="3">
                Bus Registration Number:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Bus Registration Number"
                  name="busRegistrationNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.busRegistrationNumber}
                  required
                />
              </Col>
              <Form.Label column sm="2">
                Bus Manufacturer Name:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Bus Manufacturer Name"
                  name="busManufacturerName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.busManufacturerName}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicBusName" as={Row}>
              <Form.Label column sm="3">
                Bus Amenities:
              </Form.Label>

              <Col sm="9">
                <SingleSelect
                  type="10"
                  list={dataSet}
                  change={(select) => updateAmenities(select)}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicBusName" as={Row}>
              <Form.Label column sm="3">
                Bus Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Bus Type"
                  name="busType"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.busType}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicBusName" as={Row}>
              <Form.Label column sm="3">
                Bus Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Bus Name"
                  name="busName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.busName}
                  required
                />
              </Col>
            </Form.Group>
            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group controlId={`formSeatType${d}`} key={key} as={Row}>
                    <Form.Label column sm="3">
                      Seat Type {d}:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Enter Seat Type ( option ${d} )`}
                        name={`seatType${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.seatType[d]?.seatType}
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="number"
                        placeholder="Enter Seat Count"
                        name={`seatCount${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.seatType[d]?.seatCount}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>
              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div>

            <Form.Group controlId="formBasicLiveTracking" as={Row}>
              <Form.Label column sm="3">
                Partial Cancellation:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="select"
                  placeholder="Partial Cancellation"
                  name="partialCancellation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.partialCancellation}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                Live Tracking:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  as="select"
                  placeholder="Live Tracking"
                  name="liveTracking"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.liveTracking}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
            </Form.Group>
            {arrPoints1.length > 0
              ? arrPoints1.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Cancellation {d}:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Cancellation ${d}`}
                        name={`cancellation${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cancellation[d]?.cancellation}
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Charges ${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`cancellationCharges${d}`}
                        value={values.cancellation[d]?.cancellationCharges}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints1 <= 1 ? true : false}
                onClick={() => handleRemove(2)}
              >
                Remove
              </Button>
              <Button variant="success" onClick={() => handleAdd(2)}>
                Add
              </Button>
            </div>
            {/* <Form.Group controlId="formBasicChild" as={Row}>
                                            <Form.Label column sm="3">Images:</Form.Label>
                                            <Col sm="9"> 
                                                <DropzoneArea onChange={ handleImages }filesLimit={5}/>
                                            </Col>
                                        </Form.Group> */}
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                />
              </Col>
            </Row>
            <center>
              <Button type="submit" variant="success">
                Save Bus
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddBusType;
