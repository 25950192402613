import * as actionTypes from '../actionType';

const INIT_STATE = {
    
    isLoading:false,
    userData:{},
    logout:{},
    cityState:{},
    rate:{},
    pinloading:false,
    rateloading:false,
    walletAmount:'',
    finalAmount:{},
    tracking:{},
    shipment:{},
    shipmentLoading:false,
    allorders:[],
    details:{},
    wlist:{},
    olist:{},
    superResponse:{},
    otpresponse:{},
    apiData:{},
    paymentManagement:{
      allPaymentGateways:[],
      allBankDetails:[]}

}

const reducer = (state=INIT_STATE,action)=>{
    switch(action.type){
        case actionTypes.LOGIN_INIT:
            return { ...state,isLoading:true };
          case actionTypes.LOGIN:
            // console.log(action.payload)
            // console.log(state)
            return {
              ...state,
              userData:action.payload,
              
            };
            case actionTypes.LOGOUT_INIT:
            return { ...state,isLoading:true };
          case actionTypes.LOGOUT:
            return {
              ...state,
              logout:action.payload
              
            };
            case actionTypes.PINCODE_INIT:
              return { ...state,pinloading:true };
            case actionTypes.PINCODE:
              return {
                ...state,
                cityState:action.payload,
                pinloading:false,
               
                
              };
              case actionTypes.RATE_INIT:
              return { ...state,rateloading:true };
            case actionTypes.RATE:
              return {
                ...state,
                rateloading:false,
                rate:action.payload
                
              };
              case actionTypes.REFRESH:
                return {
                  
                  logout:{},
                };
                case actionTypes.WALLET_INIT:
                  return { ...state };
                case actionTypes.WALLET:
                  return {
                    ...state,
                    walletAmount:action.payload
                    
                  };
                  case actionTypes.FINALAMOUNT_INIT:
                  return { ...state };
                case actionTypes.FINALAMOUNT:
                  return {
                    ...state,
                    finalAmount:action.payload
                    
                  };
                  case actionTypes.TRACKING_INIT:
                    return { ...state,isLoading:true };
                  case actionTypes.TRACKING:
                    return {
                      ...state,
                      tracking:action.payload,
                      isLoading:false
                      
                    };
                    case actionTypes.PRINTSHIPMENT_INIT:
                    return { ...state,shipmentLoading:true };
                  case actionTypes.PRINTSHIPMENT:
                    return {
                      ...state,
                      shipment:action.payload,
                      shipmentLoading:false
                      
                    };
                    case actionTypes.ALLORDERS_INIT:
                        return { ...state,isloading:true };
                      case actionTypes.ALLORDERS:
                        return {
                          ...state,
                          allorders:action.payload,
                          isloading:false
                          
                        };
                    case actionTypes.DETAILS_INIT:
                      return { ...state,isloading:true };
                    case actionTypes.DETAILS:
                      return {
                        ...state,
                        details:action.payload,
                        isloading:false
                        
                      };
                      case actionTypes.WAREHOUSELIST_INIT:
                      return { ...state,isloading:true };
                    case actionTypes.WAREHOUSELIST:
                      return {
                        ...state,
                        wlist:action.payload,
                        isloading:false
                        
                      };
                      case actionTypes.TOTALORDERS_INIT:
                        return { ...state,isloading:true };
                      case actionTypes.TOTALORDERS:
                        return {
                          ...state,
                          olist:action.payload,
                          isloading:false
                          
                        };
                      case actionTypes.SUPERLOGIN_INIT:
                        return { ...state,isloading:true };
                      case actionTypes.SUPERLOGIN:
                        return {
                          ...state,
                          superResponse:action.payload,
                          isloading:false
                          
                        };
                        case actionTypes.GETAPIDATA:
                        return {
                          ...state,
                          apiData:action.payload
                        }
                      case actionTypes.PAYMENTMANAGEMENT:
                        return{
                          ...state,
                          paymentGateway:action.payload
                        }
                      //   case actionTypes.OTP_INIT:
                      //   return { ...state,isloading:true };
                      // case actionTypes.OTP:
                      //   return {
                      //     ...state,
                      //     otpresponse:action.payload,
                      //     isloading:false
                          
                      //   };
  
        default:
            return state            
    }
}
export default reducer;