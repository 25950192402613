import React, {useState, Fragment,useEffect } from 'react';
import {Col,Row, Container,Table} from 'react-bootstrap';
import Sms from './smsInformation';
import classes from './WebsiteManagement/websiteManagement.module.css';
import classesX from './Dashboard/dashboard.module.css';
import Email from './emailInformation';
import AdminNavbar from './navbar/adminNavbar';
import { GetNewsletter } from '../entities/action/action';
import CreateNewsletterUser from './addNewsletterUsers';

const InformationManagement = (props)=>{
    const [active,updateActive] = useState('1');
    const [data,updateData] = useState([]);
    const items = [
        {id:"1",type:"Newsletter Email List"},
        {id:"2",type:"SMS"},
        {id:"3",type:"Email"},
        {id:"4",type:"Add User"}
    ]
    useEffect(()=>{
        (async()=>{
            const response = await GetNewsletter();
            if(response?.success){
                updateData(response?.newsLetters);
            }
        })()
    },[])
    return(
        <Fragment> 
            <AdminNavbar/>
            <h1 className={classesX.Heading}>Information Management</h1>  
        <Container fluid>
            <Row> 
                <Col lg={12} md={12} className={classes.Response2}>
                    <div style={{height:'100%',width: '90%'}}> 
                        <ul className={classes.Ul}style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 ,height:"10vh",}}>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                    style={{
                                        backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                                        textAlign: 'center',
                                        margin: '0 1px', // Adjust the margin as needed
                                        padding:" 2% 0",
                                        color: 'white',
                                        cursor: 'pointer',
                                        width:"33%",
                                        height:"100%"
                                    }}>
                                    {item.type}
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={12} md={12} className={classes.Response}>
                    <div style={{height:'100%',margin:"2vh 9vw"}}> 
                        {
                            active==="1"?
                            <Container fluid style={{padding:'0'}}>
                                <Table striped bordered hover responsive>
                                    <thead>
                                        <tr>
                                            <th>S.No</th>
                                            <th>NewsLetter ID</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            data.length>0?data.map((data,key)=>
                                                <tr key={key}>
                                                    <td>{key+1}</td>
                                                    <td>{data?.id}</td>
                                                    <td>{data.customerEmail}</td>
                                                    <td>{data.customerPhone}</td>
                                                    <td>{data?.created.split('T')[0]}</td>
                                                </tr>
                                            ):null
                                        }
                                    </tbody>
                                </Table>
                            </Container>:
                        active==="2"?<Sms/>:
                        active==="3"?<Email/>:
                        active==="4"?<CreateNewsletterUser/>:null
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default InformationManagement;