import React, { useState } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { AddDepartment, AddDesignation } from "../../../entities/action/action";
import AddAmenitiesAndHotelFacilitiesList from "./addAmenitiesAndFacilitiesList";

const AddAmenitiesAndHotelFacilitiesForm = (props) => {
  const [input, SetInput] = useState("");
  const [icon, SetIcon] = useState("");
  const handleSubmit = async () => {
    if (props.categoryType == "Amenities") {
      const newdata = {
        amenities: input,
        amenitiesId: 0,
        emailId: "string",
        icon: icon,
        sessionKey: "string",
      };
      console.log(newdata);
      const res = await fetch("https://api.zupptravel.com/addBusAmenities", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      console.log(json);
      if (json.successful) {
        alert("Added Bus Amenities");
        window.location.reload();
      }
    } else {
      const newdata = {
        facilities: input,
        facilitiesId: 0,
        emailId: "string",
        icon: icon,
        sessionKey: "string",
      };
      console.log(newdata);
      const res = await fetch("https://api.zupptravel.com/addBusFacilities", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      console.log(json);
      if (json.successful) {
        alert("Added Bus Facility");
        window.location.reload();
      }
    }
    // let newData;
    // let response;
    // if (props.categoryType == "Amenities") {
    //   newData = {
    //     department: input,
    //   };
    //   response = await AddDepartment(newData);
    // } else {
    //   newData = {
    //     designation: input,
    //   };
    //   response = await AddDesignation(newData);
    // }
    // if (response == 200) {
    //   alert("Successfully Added");
    //   SetInput("");
    //   window.location.reload();
    // }
    // console.log(response, "rrrrrrrr");
  };

  return (
    <form onSubmit={handleSubmit}>
      <Form.Group controlId="formBasicPromo" as={Row}>
        <Form.Label column sm="3">
          {props.categoryType}:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            placeholder={`Enter ${props.categoryType}`}
            name="category"
            onChange={(e) => SetInput(e.target.value)}
            value={input}
            required
          />
        </Col>
      </Form.Group>
      <Form.Group controlId="formBasicIcon" as={Row}>
        <Form.Label column sm="3">
          Icon:
        </Form.Label>
        <Col sm="9">
          <Form.Control
            type="text"
            placeholder="Enter Icon"
            name="icon"
            onChange={(e) => SetIcon(e.target.value)}
            value={icon}
            required
          />
        </Col>
      </Form.Group>
      <center>
        <Button type="submit" style={{ margin: "2%" }}>
          Save {`${props.categoryType}`}
        </Button>
      </center>
      <AddAmenitiesAndHotelFacilitiesList
        type={props.categoryType}
        data={props.data}
      />
    </form>
  );
};
export default AddAmenitiesAndHotelFacilitiesForm;
