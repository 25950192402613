import React, { useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { DatePicker } from "antd";
import { Formik } from "formik";
import moment from "moment";
import { useEffect } from "react";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
const { RangePicker } = DatePicker;

function disabledDate(current) {
  // Can not select days before today and today
  return current && current < moment().endOf("day");
}

const DisplayRoom = (props) => {
  const [date, updateDate] = useState([]);
  const [roomNames1, updateRoomNames1] = useState([]);
  const [id, updateid] = useState([]);
  const [mealType, SetMealType] = useState("");
  const [rs, SetRs] = useState("");
  const [roomsetData, updateRoomSetData] = useState({});
  const [mealTypes, updateMealTypes] = useState([]);
  const [mt, SetMt] = useState([]);
  const [mt2, SetMt2] = useState([]);
  const [roomNames, updateRoomNames] = useState([]);
  const [roomName, updateRoomName] = useState("");
  const [roomSetNames, updateRoomSetNames] = useState([]);
  const [roomSetName, updateRoomSetName] = useState([]);
  const [roomAmount, setRoomAmount] = useState("");
  const [roomGstCost, setRoomGstCost] = useState("");
  const [roomGstRate, setRoomGstRate] = useState("");
  const [mrp1, setMrp1] = useState("");
  const [roomCxAmount, setRoomCxAmount] = useState("");
  const [roomCxGstCost, setRoomCxGstCost] = useState("");
  const [roomCxGstRate, setRoomCxGstRate] = useState("");
  const [sellingPrice1, setSellingPrice1] = useState("");
  const [roomZtAmount, setRoomZtAmount] = useState("");
  const [RoomZtGstCost, setRoomZtGstCost] = useState("");
  const [RoomZtGstRate, setRoomZtGstRate] = useState("");
  const [purchasePrice1, setPurchasePrice1] = useState("");
  const [mealAmount, setMealAmount] = useState("");
  const [mealGstCost, setMealGstCost] = useState("");
  const [mealGstRate, setMealGstRate] = useState("");
  const [mrp, setMrp] = useState("");
  const [mealCxAmount, setMealCxAmount] = useState("");
  const [mealCxGstCost, setMealCxGstCost] = useState("");
  const [mealCxGstRate, setMealCxGstRate] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [mealZtAmount, setMealZtAmount] = useState("");
  const [mealZtGstCost, setMealZtGstCost] = useState("");
  const [mealZtGstRate, setMealZtGstRate] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [RoomPricesData, UpdateRoomPricesData] = useState([]);

  const [mealPricesData, UpdateMealPricesData] = useState([]);
  const handlePrice = (x, v) => {
    updateRoomName(x);
    // setUnitGstRate(c);
    // setUnitPrice(y);
    // setCxUnitPrice(z);
    // setZtUnitPrice(a);
    SetRs(v);
  };

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getRoomCategory", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const json = await res.json();
      // console.log("firstss ", json);
      if (json.successful) {
        updateRoomNames(json.roomCategorys);
      }
    })();
  }, []);

  // console.log("pricessss", roomNames);

  useEffect(() => {
    let arr1 = [];
    roomNames.map((data, key) => {
      if ((data.name = roomName)) {
        arr1.push(data.id);
      }
    });
    updateid([...arr1]);
  }, []);

  useEffect(() => {
    let r = [];
    roomNames.map((data, id) => {
      if (data.accId == props.accid) {
        r.push(data);
      }
    });
    updateRoomNames1(r);
  }, [props.accid]);

  // console.log(roomNames1);

  useEffect(() => {
    (async () => {
      const newdata = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getMealType", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();

      let arr = [];
      if (json.successful) {
        json.mealTypes.map((data, key) => {
          // console.log(data.mealType);
          arr.push(data.mealType);
        });
        SetMt([...arr]);
        updateMealTypes(json.mealTypes);
      }
    })();
  }, []);

  // console.log(mealTypes);

  useEffect(() => {
    (async () => {
      const newdata = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getMealPrice", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      //   console.log("ffffffffffff", json);

      if (json.successful) {
        UpdateMealPricesData(json.mealPrices);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const newdata = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getRoomPrice", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      // console.log("ffffffffffff", json);

      if (json.successful) {
        UpdateRoomPricesData(json.roomPrices);
      }
    })();
  }, []);

  // console.log("aaaaaaaaaaaaaa", RoomPricesData);

  console.log("ffffffffff", mealPricesData);

  // useEffect(() => {
  //   mealPricesData.map((data, key) => {
  //     if (
  //       data.mealType == mealType &&
  //       data.startDate <= date[0]?._d.toISOString().slice(0, 10) &&
  //       data.endDate >= date[1]?._d.toISOString().slice(0, 10)
  //     ) {
  //       setMealAmount(data.mealAmount);
  //       setMealGstCost(data.mealGstCost);
  //       setMealGstRate(data.mealGstRate);
  //       setMrp(data.mrp);
  //       setMealCxAmount(data.mealCXAmount);
  //       setMealCxGstCost(data.mealCXGstCost);
  //       setMealCxGstRate(data.mealCXGstRate);
  //       setSellingPrice(data.sellingPrice);
  //       setMealZtAmount(data.mealZTAmount);
  //       setMealZtGstCost(data.mealZTGstCost);
  //       setMealZtGstRate(data.mealZTGstRate);
  //       setPurchasePrice(data.purchasePrice);
  //     }
  //   });
  // }, [mealType]);

  useEffect(() => {
    const foundMeal = mealPricesData.find((data) => {
      return (
        data.mealType === mealType &&
        data.startDate <= date[0]?._d.toISOString().slice(0, 10) &&
        data.endDate >= date[1]?._d.toISOString().slice(0, 10)
      );
    });
  
    if (foundMeal) {
      setMealAmount(foundMeal.mealAmount);
      setMealGstCost(foundMeal.mealGstCost);
      setMealGstRate(foundMeal.mealGstRate);
      setMrp(foundMeal.mrp);
      setMealCxAmount(foundMeal.mealCXAmount);
      setMealCxGstCost(foundMeal.mealCXGstCost);
      setMealCxGstRate(foundMeal.mealCXGstRate);
      setSellingPrice(foundMeal.sellingPrice);
      setMealZtAmount(foundMeal.mealZTAmount);
      setMealZtGstCost(foundMeal.mealZTGstCost);
      setMealZtGstRate(foundMeal.mealZTGstRate);
      setPurchasePrice(foundMeal.purchasePrice);
    } else {
      // Set default values or handle the case where no matching mealType is found
      setMealAmount(0);
      setMealGstCost(0);
      setMealGstRate(0);
      setMrp(0);
      setMealCxAmount(0);
      setMealCxGstCost(0);
      setMealCxGstRate(0);
      setSellingPrice(0);
      setMealZtAmount(0);
      setMealZtGstCost(0);
      setMealZtGstRate(0);
      setPurchasePrice(0);
      // ... (set default values for other state variables)
    }
  }, [mealType, mealPricesData, date]);
  

  console.log("RoomPricesData",RoomPricesData);

  useEffect(() => {
    const foundRoom = RoomPricesData.find((data) => {
      return (
        data.roomSet === rs &&
        data.dateFrom <= date[0]?._d.toISOString().slice(0, 10) &&
        data.dateTo >= date[1]?._d.toISOString().slice(0, 10)
      );
    });
  
    if (foundRoom) {
      setRoomAmount(foundRoom.roomAmount);
      setRoomGstCost(foundRoom.roomGstCost);
      setRoomGstRate(foundRoom.roomGstRate);
      setMrp1(foundRoom.mrp);
      setRoomCxAmount(foundRoom.roomCXAmount);
      setRoomCxGstCost(foundRoom.roomCXGstCost);
      setRoomCxGstRate(foundRoom.roomCXGstRate);
      setSellingPrice1(foundRoom.sellingPrice);
      setRoomZtAmount(foundRoom.roomZTAmount);
      setRoomZtGstCost(foundRoom.roomZTGstCost);
      setRoomZtGstRate(foundRoom.roomZTGstRate);
      setPurchasePrice1(foundRoom.purchasePrice);
    } else {
      // Set default values or handle the case where no matching roomSet is found
      setRoomAmount(0);
      setRoomGstCost(0);
      setMealGstRate(0);
      setMrp1(0);
      setMealCxAmount(0);
      setMealCxGstCost(0);
      setMealCxGstRate(0);
      setSellingPrice(0);
      setMealZtAmount(0);
      setMealZtGstCost(0);
      setMealZtGstRate(0);
      setPurchasePrice1(0);
      // ... (set default values for other state variables)
    }
  }, [rs, RoomPricesData, date]);
  
  // useEffect(() => {
  //   RoomPricesData.map((data, key) => {
  //     if (
  //       data.roomSet == rs &&
  //       data.dateFrom <= date[0]?._d.toISOString().slice(0, 10) &&
  //       data.dateTo >= date[1]?._d.toISOString().slice(0, 10)
  //     ) {
  //       setRoomAmount(data.roomAmount);
  //       setRoomGstCost(data.roomGstCost);
  //       setRoomGstRate(data.roomGstRate);
  //       setMrp1(data.mrp);
  //       setRoomCxAmount(data.roomCXAmount);
  //       setRoomCxGstCost(data.roomCXGstCost);
  //       setRoomCxGstRate(data.roomCXGstRate);
  //       setSellingPrice1(data.sellingPrice);
  //       setRoomZtAmount(data.roomZTAmount);
  //       setRoomZtGstCost(data.roomZTGstCost);
  //       setRoomZtGstRate(data.roomZTGstRate);
  //       setPurchasePrice1(data.purchasePrice);
  //     }
  //   });
  // }, [rs]);
  // console.log(roomAmount);

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getRoomSet", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      const json = await res.json();
      // console.log("firstss ", json);
      if (json.successful) {
        updateRoomSetNames(json.roomSets);
      }
    })();
  }, []);

  useEffect(() => {
    let arr = [];
    mealTypes.map((data, key) => {
      // console.log(data.numberOfAdults);

      if (
        data.numberOfAdults == roomsetData[0].numberOfAdults &&
        data.numberOfChildren == roomsetData[0].numberOfChildren
      ) {
        arr.push(data.mealType);
      }
    });
    SetMt2([...arr]);
  }, [rs]);

  // console.log(mt2);

  // console.log(roomSetNames);
  // console.log(roomName);

  useEffect(() => {
    let arr = [];
    let arr2 = [];
    roomSetNames.map((data, id) => {
      if (data.roomName == roomName) {
        arr2.push(data);

        arr.push(data.roomSetName);
      }
    });

    updateRoomSetData(arr2);

    updateRoomSetName(arr);
  }, [roomName]);

  // console.log(roomsetData);
  // console.log(roomSetName);
  // console.log(rs);

  return (
    <div>
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          const newdata = {
            emailId: "string",
            endDate: date[1]?._d.toISOString().slice(0, 10),
            mealAmount: mealAmount,
            mealCXAmount: mealCxAmount,
            mealCXGstCost: mealCxGstCost,
            mealCXGstRate: mealCxGstRate,
            mealGstCost: mealGstCost,
            mealGstRate: mealGstRate,
            mealMrp: mrp1,
            mealPurchasePrice: purchasePrice,
            mealSellingPrice: sellingPrice,
            mealType: mealType,
            mealTypeId: 0,
            mealZTAmount: mealZtAmount,
            mealZTGstCost: mealZtGstCost,
            mealZTGstRate: mealZtGstRate,
            roomAmount: roomAmount,
            roomAvailabilityId: "string",
            roomCXAmount: roomCxAmount,
            roomCXGstCost: roomCxGstCost,
            roomCXGstRate: roomCxGstRate,
            roomCategory: values.roomCategory,
            roomCategoryId: 0,
            roomGstCost: roomGstCost,
            roomGstRate: roomGstRate,
            roomMrp: mrp,
            roomPurchasePrice: purchasePrice1,
            roomSellingPrice: sellingPrice1,
            roomSet: values.roomSet,
            roomSetId: 0,
            roomZTAmount: roomZtAmount,
            roomZTGstCost: RoomZtGstCost,
            roomZTGstRate: RoomZtGstRate,
            sessionKey: "string",
            startDate: date[0]?._d.toISOString().slice(0, 10),
            totalAmount: roomAmount + mealAmount,
            totalCXAmount: roomCxAmount + mealCxAmount,
            totalCXGstCost:
              mealCxAmount * 0.01 * mealCxGstRate +
              roomCxAmount * 0.01 * roomCxGstRate,
            totalCXGstRate: roomCxGstRate + mealCxGstRate,
            totalGstCost:
              mealAmount * 0.01 * mealGstRate + roomAmount * 0.01 * roomGstRate,
            totalGstRate: roomGstRate + mealGstRate,
            totalMrp:
              mealAmount * 0.01 * mealGstRate +
              roomAmount * 0.01 * roomGstRate +
              roomAmount +
              mealAmount,
            totalPurchasePrice:
              mealZtAmount * 0.01 * mealZtGstRate +
              roomZtAmount * 0.01 * RoomZtGstRate +
              roomZtAmount +
              mealZtAmount,
            totalSellingPrice:
              mealCxAmount * 0.01 * mealCxGstRate +
              roomCxAmount * 0.01 * roomCxGstRate +
              roomCxAmount +
              mealCxAmount,
            totalZTAmount: roomZtAmount + mealZtAmount,
            totalZTGstCost:
              mealZtAmount * 0.01 * mealZtGstRate +
              roomZtAmount * 0.01 * RoomZtGstRate,
            totalZTGstRate: RoomZtGstRate + mealZtGstRate,
          };

          const res = await fetch(
            "https://api.zupptravel.com/addRoomAvailability",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(newdata),
            }
          );

          console.log("rrrrrrrrrrrr", newdata);
          const json = await res.json();
          console.log("jsonnnnnnnnnn", json);
          if (json.successful) {
            alert("Added Room Avalibility!");
            window.location.reload();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            {/* <Form.Group controlId="formBasicAccommodationName" as={Row}>
                                            <Form.Label column sm="2">Accommodation Name:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Accommodation Name" 
                                                    name="accommodationName"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.accommodationName}
                                                    required>
                                                    {
                                                        categoryList.map((categories,key)=>
                                                        <option key={key} value={categories.type}>{categories.type}</option>    
                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>    */}

            <Form.Group controlId="formBasictimePerSlot" as={Row}>
              <Form.Label column sm="2">
                Date:
              </Form.Label>
              <Col>
                <RangePicker
                  onChange={(val) => updateDate(val)}
                  value={date}
                  disabledDate={disabledDate}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicRoomCategory" as={Row}>
              <Form.Label column sm="2">
                Room Name:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  placeholder="Room Category"
                  name="roomCategory"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.roomCategory}
                  required
                >
                  <option value="">--Select--</option>
                  {roomNames1.map((item, key) => {
                    return (
                      <>
                        <option value={item.name}>{item.name}</option>
                      </>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicRoomSet" as={Row}>
              <Form.Label column sm="2">
                Room Set:
              </Form.Label>
              <Col sm="10">
                <Form.Control
                  as="select"
                  placeholder="Room Set"
                  name="roomSet"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.roomSet}
                  required
                >
                  <option value="">--Select--</option>
                  {roomSetName.map((item, key) => {
                    return (
                      <>
                        <option value={item}>{item}</option>
                      </>
                    );
                  })}
                </Form.Control>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicMealType" as={Row}>
              <Form.Label column sm="2">
                Meal Type:
              </Form.Label>
              <Col sm="10">
                <SingleSelect
                  type="7"
                  list={mt2}
                  change={(select) => SetMealType(select)}
                />
              </Col>
            </Form.Group>

            <Form.Label style={{ fontWeight: "bold" }}>
              Publish Price:
            </Form.Label>
            <div style={{ border: "1px solid grey" }} className="container">
              <Form.Group
                controlId="formBasicMrp1"
                style={{ marginTop: "15px" }}
                as={Row}
              >
                <Form.Label column sm="3">
                  Selected Room Cost:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitPrice1"
                    min={0}
                    onChange={handleChange}
                    value={roomAmount}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    placeholder=" Unit GST "
                    name="unitGst1"
                    min={1}
                    max={100}
                    isInvalid={values.unitGst1 < 0 || values.unitGst1 > 100}
                    onChange={handleChange}
                    value={roomGstRate}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="totalGst1"
                    value={roomGstCost}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    min={0}
                    placeholder="MSP"
                    name="publishMsp1"
                    onBlur={handleBlur}
                    value={mrp}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group controlId="formBasicMrp1" as={Row}>
                <Form.Label column sm="3">
                  Selected Meal Cost:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitMealMsp1"
                    min={0}
                    onChange={handleChange}
                    value={mealAmount}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    placeholder="Unit GST"
                    name="unitMealGst1"
                    min={1}
                    max={100}
                    isInvalid={
                      values.unitMealGst1 < 0 || values.unitMealGst1 > 100
                    }
                    onChange={handleChange}
                    value={mealGstRate}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="totalMealGst1"
                    value={mealGstCost}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    min={0}
                    placeholder="Total MSP"
                    name="totalMealMsp1"
                    value={mrp1}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group controlId="formBasicOPrice1" as={Row}>
                <Form.Label column sm="3">
                  Total Publish Price:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`Rs ${roomAmount + mealAmount}`}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`Rs ${roomGstRate + mealGstRate}`}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`Rs ${
                      mealAmount * 0.01 * mealGstRate +
                      roomAmount * 0.01 * roomGstRate
                    }`}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`Rs ${
                      mealAmount * 0.01 * mealGstRate +
                      roomAmount * 0.01 * roomGstRate +
                      roomAmount +
                      mealAmount
                    }`}
                  />
                </Col>
              </Form.Group>
            </div>
            <Form.Label style={{ fontWeight: "bold" }}>
              Offer Price to CX:
            </Form.Label>
            <div style={{ border: "1px solid grey" }} className="container">
              <Form.Group
                controlId="formBasicMrp2"
                style={{ marginTop: "15px" }}
                as={Row}
              >
                <Form.Label column sm="3">
                  Selected Room Cost:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitPrice2"
                    min={0}
                    onChange={handleChange}
                    value={roomCxAmount}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    placeholder=" Unit GST "
                    name="unitGst2"
                    min={1}
                    max={100}
                    isInvalid={values.unitGst2 < 0 || values.unitGst2 > 100}
                    onChange={handleChange}
                    value={roomCxGstRate}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="totalGst2"
                    value={roomCxGstCost}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    min={0}
                    placeholder="MSP"
                    name="publishMsp2"
                    onBlur={handleBlur}
                    value={sellingPrice1}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group controlId="formBasicMrp2" as={Row}>
                <Form.Label column sm="3">
                  Selected Meal Cost:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitMealMsp2"
                    min={0}
                    onChange={handleChange}
                    value={mealCxAmount}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    placeholder="Unit GST"
                    name="unitMealGst2"
                    min={1}
                    max={100}
                    isInvalid={
                      values.unitMealGst2 < 0 || values.unitMealGst2 > 100
                    }
                    onChange={handleChange}
                    value={mealCxGstRate}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="totalMealGst2"
                    value={mealCxGstCost}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    min={0}
                    placeholder="Total MSP"
                    name="totalMealMsp2"
                    value={sellingPrice}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group controlId="formBasicOPrice2" as={Row}>
                <Form.Label column sm="3">
                  Total Publish Price:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`Rs ${roomCxAmount + mealCxAmount}`}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`Rs ${roomCxGstRate + mealCxGstRate}`}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`Rs ${
                      mealCxAmount * 0.01 * mealCxGstRate +
                      roomCxAmount * 0.01 * roomCxGstRate
                    }`}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`Rs ${
                      mealCxAmount * 0.01 * mealCxGstRate +
                      roomCxAmount * 0.01 * roomCxGstRate +
                      roomCxAmount +
                      mealCxAmount
                    }`}
                  />
                </Col>
              </Form.Group>
            </div>
            <Form.Label style={{ fontWeight: "bold" }}>
              Purchase Price to ZT:
            </Form.Label>
            <div style={{ border: "1px solid grey" }} className="container">
              <Form.Group
                controlId="formBasicMrp3"
                style={{ marginTop: "15px" }}
                as={Row}
              >
                <Form.Label column sm="3">
                  Selected Room Cost:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitPrice3"
                    min={0}
                    onChange={handleChange}
                    value={roomZtAmount}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    placeholder=" Unit GST "
                    name="unitGst3"
                    min={1}
                    max={100}
                    isInvalid={values.unitGst3 < 0 || values.unitGst3 > 100}
                    onChange={handleChange}
                    value={RoomZtGstRate}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="totalGst3"
                    value={RoomZtGstCost}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    min={0}
                    placeholder="MSP"
                    name="publishMsp3"
                    onBlur={handleBlur}
                    value={purchasePrice1}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group controlId="formBasicMrp3" as={Row}>
                <Form.Label column sm="3">
                  Selected Meal Cost:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    placeholder="Enter Unit Price"
                    name="unitMealMsp3"
                    min={0}
                    onChange={handleChange}
                    value={mealZtAmount}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    placeholder="Unit GST"
                    name="unitMealGst3"
                    min={1}
                    max={100}
                    isInvalid={
                      values.unitMealGst3 < 0 || values.unitMealGst3 > 100
                    }
                    onChange={handleChange}
                    value={mealZtGstRate}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    readOnly
                    defaultValue="0"
                    name="totalMealGst3"
                    value={mealZtGstCost}
                    required
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="number"
                    readOnly
                    step="any"
                    min={0}
                    placeholder="Total MSP"
                    name="totalMealMsp3"
                    value={purchasePrice}
                    required
                  />
                </Col>
              </Form.Group>
              <Form.Group controlId="formBasicOPrice3" as={Row}>
                <Form.Label column sm="3">
                  Total Publish Price:
                </Form.Label>
                <Col>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`Rs ${roomZtAmount + mealZtAmount}`}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`Rs ${RoomZtGstRate + mealZtGstRate}`}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`Rs ${
                      mealZtAmount * 0.01 * mealZtGstRate +
                      roomZtAmount * 0.01 * RoomZtGstRate
                    }`}
                  />
                </Col>
                <Col>
                  <Form.Control
                    type="text"
                    readOnly
                    value={`Rs ${
                      mealZtAmount * 0.01 * mealZtGstRate +
                      roomZtAmount * 0.01 * RoomZtGstRate +
                      roomZtAmount +
                      mealZtAmount
                    }`}
                  />
                </Col>
              </Form.Group>
            </div>
            <center>
              <Button
                type="submit"
                variant="success"
                style={{ marginTop: "15px" }}
              >
                Save Room Price
              </Button>
            </center>
            {handlePrice(
              values.roomCategory,
              //   values.taxSlabVariablemsp,
              //   values.unitSericeVariablemsp,
              //   values.unitSericeVariableocc,
              //   values.unitSericeVariableocc * 0.8675,
              values.roomSet
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default DisplayRoom;
