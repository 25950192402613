import React, { useEffect, useState } from "react";
import { Table, Container, Button } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import Page from "../../pagination/pagination";
import { useSelector } from "react-redux";

const RoomPriceList = (props) => {
  const [dataSet, updateDataSet] = useState([]);
  const [count, updateCount] = useState(0);
  const postPerPage = 20;
  const [currentPage, updateCurrentPage] = useState(1);
  const indexOfLast = currentPage * postPerPage;
  const indexOfFirst = indexOfLast - postPerPage;
  const currentPosts = dataSet.slice(indexOfFirst, indexOfLast);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const handleChangePage = (event, value) => {
    updateCurrentPage(value);
  };
  useEffect(() => {
    updateCount(Math.ceil(dataSet.length / postPerPage));
  }, [dataSet]);

  useEffect(() => {
    (async () => {
      const data = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const myresponse = await fetch(
        `https://api.zupptravel.com/getRoomPrice`,
        {
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const json = await myresponse.json();
      console.log("jsssoooooonnn", json);

      if (json.successful) {
        updateDataSet(json.roomPrices);
      }
    })();
  }, []);

  console.log("object ", dataSet);

  const HandleDelete = async (id) => {
    const newd = {
      emailID: data.data.key,
      id: id,
      sessionKey: data.sessionId,
    };
    console.log(newd);
    const r = await fetch("https://api.zupptravel.com/deleteRoomPrice", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newd),
    });
    const j = await r.json();
    if (j.successful) {
      alert("Successfully deleted Room Price");
      const newdata = {
        emailId: "string",
        password: "string",
        phoneno: "string",
        sessionKey: "string",
      };
      const res = await fetch("https://api.zupptravel.com/getRoomPrice", {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(newdata),
      });
      const json = await res.json();
      if (json.successful) {
        updateDataSet(json.roomPrices);
      }
    }
  };

  console.log(dataSet);

  return (
    <Container fluid style={{ padding: "0" }}>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>S.No</th>
            {/* <th>Meal Type</th> */}
            <th>Accomodation Id</th>
            <th>Room Name</th>
            <th>Room Set</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Price</th>

            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentPosts.length > 0
            ? currentPosts.map((data, key) => (
                <tr key={key}>
                  <td>{key + 1}</td>
                  {/* <td>{data.mealType}</td> */}
                  <td>{data.accId}</td>
                  <td>{data.roomCategory}</td>
                  <td>{data.roomSet}</td>
                  <td>{data.dateFrom}</td>
                  <td>{data.dateTo}</td>
                  <td>
                    {data.mrp} {data.purchasePrice} {data.sellingPrice}
                  </td>

                  <td>
                    <Button
                      style={{ margin: "auto 1%" }}
                      variant="danger"
                      onClick={() => HandleDelete(data.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              ))
            : null}
        </tbody>
      </Table>
      <div
        style={{ display: "flex", justifyContent: "center", margin: "15px 0" }}
      >
        <Page count={count} change={handleChangePage} page={currentPage} />
      </div>
    </Container>
  );
};
export default RoomPriceList;
