import React, { useState, useEffect } from "react";
import { Col, Button, Form, Container, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import {
  Pincode,
  SendOTP,
  VerifyOTP,
  CreateVendor,
  getPincodeById
} from "../../entities/action/action";
import { useSelector, useDispatch } from "react-redux";
import Image from "../../utilities/imageTestApi/image";
import * as Yup from "yup";

const AddPartner = (props) => {
  const [active, updateActive] = useState("1");
  const [state, setState] = useState("");
  const [mycity, setCity] = useState("");
  const [pin, setpin] = useState("");
  const [billingstate, setbillingState] = useState("");
  const [billingcity, setbillingCity] = useState("");
  const cityState = useSelector((state) => state.account.cityState);
  const [disable, updateDisable] = useState(false);
  const [idProodImg, updateIdProofImg] = useState({});
  const [idProodImgIndividual, updateIdProofImgIndividual] = useState({});
  const [cancelCheque, updateCancelCheque] = useState({});
  const [panUpload, updatePanUpload] = useState({});
  const [cinUpload, updateCinUpload] = useState({});
  const [aadharUpload, updateAadharUpload] = useState({});

  const dispatch = useDispatch();
  const data = useSelector((state) => state.adminAccount.apiResponse);

  const [pinError, setPinError] = useState('');

  const handleChange1 = (event) => {
    const { name, value } = event.target;

    // Validate pincode format using a regular expression
    if (name === 'pincode') {
      if (/^[0-9]{6}$/.test(value)) {
        setpin(value);
        setPinError('')
      } else {
        setPinError('Pincode must be a 6-digit number');
      }
    }
  };

  const myfunc = (e) => {
    if (e.target?.value.length === 6) {
      dispatch(Pincode(data));
    } else {
      setState("");
      setCity("");
    }
  };
  const myfunc2 = async(e) => {
    if (e.target.value.length === 6) {
        const pin = await getPincodeById(e.target.value);
        setbillingState(pin?.state);
        setbillingCity(pin?.city);
    } else {
        setbillingState("");
        setbillingCity("");
    }
  };

  useEffect(() => {
    if (!cityState) {
        setState('');
        setCity('');
    } else {
        setState(cityState?.state);
        setCity(cityState?.city);
    }
  }, [cityState]);

  const handleSendOTP = async (number) => {
    if (`${number}`.length == 10 && !disable) {
      const response = await SendOTP({ phone: number });
      if (response.status == 200) {
        alert("OTP Successfully Send");
      }
    } else {
      alert("Something went wrong!!");
    }
  };
  const handleVerifyOTP = async (otp, number) => {
    if (`${number}`.length == 10 && `${otp}`.length == 6) {
      const response = await VerifyOTP({ phone: number, otp: otp });
      if (response.status == 200) {
        alert("valid otp");
        updateDisable(true);
      }
    } else {
      alert("Please Enter Valid OTP");
    }
  };
  return (
    <Container fluid className={classes.Box} style={{ height: "auto" }}>
      <Row
        style={{ margin: "0 0 2% 0", textAlign: "center", cursor: "pointer" }}
      >
        <Col
          md={3}
          xs={12}
          className={classes.Col}
          style={{
            backgroundColor: `${active === "1" ? "steelblue" : "#585858"}`,
            color: "white",
            padding: "0.5%",
          }}
          onClick={() => updateActive("1")}
        >
          Basic Information
        </Col>
        <Col
          md={2}
          xs={12}
          className={classes.Col}
          style={{
            backgroundColor: `${active === "2" ? "steelblue" : "#585858"}`,
            color: "white",
            padding: "0.5%",
          }}
          onClick={() => updateActive("2")}
        >
          Billing Information
        </Col>
        <Col
          md={2}
          xs={12}
          className={classes.Col}
          style={{
            backgroundColor: `${active === "3" ? "steelblue" : "#585858"}`,
            color: "white",
            padding: "0.5%",
          }}
          onClick={() => updateActive("3")}
        >
          Banking Information
        </Col>
        <Col
          md={2}
          xs={12}
          className={classes.Col}
          style={{
            backgroundColor: `${active === "4" ? "steelblue" : "#585858"}`,
            color: "white",
            padding: "0.5%",
          }}
          onClick={() => updateActive("4")}
        >
          Authorized Person's Information 
        </Col>
        <Col
          md={3}
          xs={12}
          className={classes.Col}
          style={{
            backgroundColor: `${active === "5" ? "steelblue" : "#585858"}`,
            color: "white",
            padding: "0.5%",
          }}
          onClick={() => updateActive("5")}
        >
          Digital Identity
        </Col>
      </Row>
      <Formik
        initialValues={{
          companyType: "",
          companyName: "",
          addressline1: "",
          addressline2: "",
          village: "",
          email: "",
          gender: "",
          city: "",
          state: "",
          pin: "",
          phone: "",
          certificationType: "",
          billingName: "",
          billingAddress: "",
          gstInfo: "",
          gstNumber: "",
          gstAddress: "",
          pan: "",
          certificate: "",
          cName: "",
          accName: "",
          ifs: "",
          accNum: "",
          accType: "",
          authEmail: "",
          authIdProof: "",
          authName: "",
          authPhone: "",
          insta: "",
          facebook: "",
          youtube: "",
          whatsapp: "",
          twitter: "",
          phonefixed: "",
        }}
        // validationSchema={SignUpSchema}
        onSubmit={async (
          values,
          { setSubmitting, resetForm, isValidating }
        ) => {
          setSubmitting(true);

          const img1 = await Image(idProodImg);
          const img2 = await Image(cancelCheque);
          const img3 = await Image(idProodImgIndividual);
          const img5 = await Image(panUpload);
          const aadhar = await Image(aadharUpload);
          const cinimg = await Image(cinUpload);
          const newData = {
            authorizedPersonInformation: {
              emailid: values.authEmail,
              idproofimg: img1,
              name: values.authName,
              otp: values.otp,
              phonenumber: values.authPhone,
            },
            bankingInformation: {
              accountholdername: values.accName,
              accountnumber: values.accNum,
              accounttype: values.accountType,
              cancelledchequeimg: img2,
              ifsc: values.ifs,
            },
            billingInformation: {
              billingAddr1: values.billingaddressline1,
              billingAddr2: values.billingaddressline2,
              billingname: values.billingName,
              gst: values.gstInfo,
              gstAddress: values.gstAddress,
              gstNo: values.gstNumber,
              pincode: values.billingpincode,
              village: values.billingvillage,
              state: billingstate,
              billingCity: billingcity,
            },
            category: "Company",
            companyInformation: {
              aadharimg: aadhar,
              aadharnum: values.aadharNumber,
              accomodationType: values.accType,
              certimg: img3,
              certtype: values.certificationType,
              cinimg: cinimg,
              cinnum: values.cinNumber,
              city: mycity,
              companyname: values.cName,
              emailid: values.email,
              fixedphno: values.phonefixed,
              identitytype: values.iType,
              panimg: img5,
              pannum: values.panNumber,
              password: values.password,
              phoneno: values.phone,
              pincode: pin,
              regaddrline1: values.addressline1,
              regaddrline2: values.addressline2,
              state: state,
              vendortype: values.sType,
              vilage: values.billingvillage,
            },
            digitalIdentityInformation: {
              facebook: values.facebook,
              instagram: values.insta,
              twitter: values.twitter,
              whatsapp: values.whatsapp,
              youtube: values.youtube,
            },
          };

          console.log("newdata", newData);
          const response = await CreateVendor(newData);
          if (response.supplierid != undefined) {
            alert("Supplier Added Successfully");
            // window.location.reload();
          } else {
            alert("Already exist Phone Number or Email");
          }
          // }else{
          //     alert("Please Verify Your Phone first");
          // }
          setSubmitting(false);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <div style={{ display: active === "1" ? "block" : "none" }}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Identity Type:</Form.Label>
                <Form.Control
                  as="select"
                  name="iType"
                  onChange={handleChange}
                  value={values.iType}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Sole Proprietorship">
                    Sole Proprietorship
                  </option>
                  <option value="Private Limited">Private Limited</option>
                  <option value="Limited Liability Partnership">
                    Limited Liability Partnership
                  </option>
                  <option value="Registered Partnership">
                    Registered Partnership
                  </option>
                  <option value="Public Limited Company">
                    Public Limited Company
                  </option>
                  <option value="One Person Company">One Person Company</option>
                  <option value="Individual">Individual</option>
                </Form.Control>
              </Form.Group>
              <Form.Row>
                <Form.Group controlId="exampleForm.ControlSType" as={Col}>
                  <Form.Label>Vendor Type:</Form.Label>
                  <Form.Control
                    as="select"
                    name="sType"
                    onChange={handleChange}
                    value={values.sType}
                    required
                  >
                    <option value="">--select--</option>
                    <option value="Bus Operator">Bus Operator</option>
                    <option value="Cab Operator">Cab Operator</option>
                    <option value="Vehical Operator">Vehical Operator</option>
                    <option value="Tour Guide">Tour Guide</option>
                    <option value="Accommodation Provider">
                      Accommodation Provider
                    </option>
                    <option value="Charter Plane Operator">
                      Charter Plane Operator
                    </option>
                    <option value="Activities Provider">
                      Activities Provider
                    </option>
                  </Form.Control>
                </Form.Group>
                {values.sType === "Accommodation Provider" ? (
                  <Form.Group controlId="exampleForm.ControlSType" as={Col}>
                    <Form.Label>Accommodation Type:</Form.Label>
                    <Form.Control
                      as="select"
                      name="accType"
                      onChange={handleChange}
                      value={values.accType}
                      required
                    >
                      <option value="">--select--</option>
                      <option value="Hotel & Resorts">Hotel & Resorts</option>
                      <option value="Camps">Camps</option>
                      <option value="Homestays">Homestays</option>
                      <option value="Hostel">Hostel</option>
                      <option value="Vilas & Appartments">
                        Vilas & Appartments
                      </option>
                      <option value="Guest House">Guest House</option>
                      <option value="Dharmshaala">Dharmshaala</option>
                    </Form.Control>
                  </Form.Group>
                ) : null}
              </Form.Row>
              <Form.Group controlId="formBasicName1">
                <Form.Label>Company Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="cName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.cName}
                  required
                />
              </Form.Group>
              <Row style={{ margin: 0 }}>
                <Col xl={4} lg={12} className={classes.Col}>
                  <Form.Group controlId="formBasicAdhaarType">
                    <Form.Label>Upload Aadhar:</Form.Label>
                    <Form.Control
                      type="file"
                      name="aadharUpload"
                      onChange={(e) => updateAadharUpload(e.target.files[0])}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col xl={4} lg={12} className={classes.Col}>
                  <Form.Group controlId="formBasicPin">
                    <Form.Label>Upload Individual/Company PAN Card:</Form.Label>
                    <Form.Control
                      type="file"
                      name="panUpload"
                      onChange={(e) => updatePanUpload(e.target.files[0])}
                      required
                    />
                  </Form.Group>
                </Col>
                {values.iType !== "Individual" ? (
                  <Col xl={4} lg={12} className={classes.Col}>
                    <Form.Group controlId="formBasicCheque">
                      <Form.Label>Upload CIN Certificate:</Form.Label>
                      <Form.Control
                        type="file"
                        name="cinUpload"
                        onChange={(e) => updateCinUpload(e.target.files[0])}
                        required={
                          values.warehouseTypeDocument ==
                          "Individiual Warehouse"
                            ? false
                            : true
                        }
                      />
                    </Form.Group>
                  </Col>
                ) : null}
              </Row>
              <Row>
                <Col md={4} xs={12}>
                  <Form.Group controlId="formBasicAadhar">
                    <Form.Label> Aadhar Number:</Form.Label>
                    <Form.Control
                      required
                      type="number"
                      placeholder="Enter Aadhar Number"
                      name="aadharNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.aadharNumber}
                      // validate={validAdhar}

                      className={
                        touched.aadharNumber && errors.aadharNumber
                          ? "has-error"
                          : null
                      }
                    />

                    {touched.aadharNumber && errors.aadharNumber ? (
                      <Form.Text className="text-muted">
                        {errors.aadharNumber}
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Group controlId="formBasicPan">
                    <Form.Label>PAN Number:</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Enter PAN Number"
                      name="panNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.panNumber}
                      className={
                        touched.panNumber && errors.panNumber
                          ? "has-error"
                          : null
                      }
                    />
                    {touched.panNumber && errors.panNumber ? (
                      <Form.Text className="text-muted">
                        {errors.panNumber}
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                </Col>
                {values.iType !== "Individual" ? (
                  <Col md={4} xs={12}>
                    <Form.Group controlId="formBasicCIN">
                      <Form.Label>CIN Number:</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter CIN Number"
                        name="cinNumber"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cinNumber}
                        required={
                          values.warehouseTypeDocument ==
                          "Individiual Warehouse"
                            ? false
                            : true
                        }
                        className={
                          touched.cinNumber && errors.cinNumber
                            ? "has-error"
                            : null
                        }
                      />
                      <Form.Text className="text-muted">
                        {errors.cinNumber && touched.cinNumber}
                      </Form.Text>
                    </Form.Group>
                  </Col>
                ) : null}
              </Row>
              <Form.Group controlId="formBasicAddress1">
                <Form.Label>Registered Address Line 1:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  name="addressline1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.addressline1}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicAddress2">
                <Form.Label>Registered Address Line 2:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  name="addressline2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.addressline2}
                  required
                />
              </Form.Group>
              <Row>
                <Col md={3} xs={12}>
                  <Form.Group controlId="formBasicPin">
                    <Form.Label>Pin Code:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Pin Code"
                      name="pincode"
                      onChange={handleChange1}
                      // onBlur={handleBlur}
                      value={values.pincode}
                      onBlur={myfunc}
                      isInvalid={!!pinError}
                      className={touched.pincode ? "has-error" : null}
                    />
                    {errors.pincode && touched.pincode && errors.pincode}
                  </Form.Group>
                </Col>
                <Col md={3} xs={12}>
                  <Form.Group controlId="formBasicCity">
                    <Form.Label>City:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter City"
                      name="city"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={mycity}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={3} xs={12}>
                  <Form.Group controlId="formBasicState">
                    <Form.Label>State:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter State"
                      name="state"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={state}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={3} xs={12}>
                  <Form.Group controlId="formBasicVillage">
                    <Form.Label>Village:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Village"
                      name="village"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.village}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={12}>
                  <Form.Group controlId="exampleForm.ControlSelect2">
                    <Form.Label>Address Proof:</Form.Label>
                    <Form.Control
                      as="select"
                      name="certificationType"
                      onChange={handleChange}
                      value={values.certificationType}
                      required
                    >
                      <option value="">--select--</option>
                      <option value="Registration Certification">
                        Registration Certification
                      </option>
                      <option value="MSME Registgration Certificate">
                        MSME Registgration Certificate
                      </option>
                      <option value="Shop Establishment Certificate">
                        Shop Establishment Certificate
                      </option>
                      <option value="Others">Others</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Group controlId="formBasicDoc1">
                    <Form.Label>Upload Address Proof Documentation:</Form.Label>
                    <Form.Control
                      type="file"
                      name="idProofImgIndividual"
                      onChange={(e) =>
                        updateIdProofImgIndividual(e.target.files[0])
                      }
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6} xs={12}>
                  <Form.Group controlId="formBasicPhone2">
                    <Form.Label>Phone Number:</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Phone Number"
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      required
                    />
                    {touched.phone && errors.phone ? (
                      <Form.Text className="text-muted">
                        {errors.phone}
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Group controlId="formBasicPhone1">
                    <Form.Label>Fixed Phone:</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Fixed Phone Number"
                      name="phonefixed"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phonefixed}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Row>
                <Form.Group controlId="formBasicEmail" as={Col}>
                  <Form.Label>Email ID:</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter Email"
                    name="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formBasicPassword" as={Col}>
                  <Form.Label>Password:</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    required
                  />
                  {touched.password && errors.password ? (
                    <Form.Text className="text-muted">
                      {errors.password}
                    </Form.Text>
                  ) : null}
                </Form.Group>
              </Form.Row>
              <center>
                <Button
                  style={{ margin: "2%" }}
                  onClick={() => updateActive("2")}
                >
                  Next
                </Button>
              </center>
            </div>
            <div style={{ display: active === "2" ? "block" : "none" }}>
              {/* billing add */}
              <Form.Group controlId="formBasicAddress1">
                <Form.Label>Billing Address Line 1:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  name="billingaddressline1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.billingaddressline1}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicAddress2">
                <Form.Label>Billing Address Line 2:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Address"
                  name="billingaddressline2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.billingaddressline2}
                  required
                />
              </Form.Group>
              <Row>
                <Col md={3} xs={12}>
                  <Form.Group controlId="formBasicPin">
                    <Form.Label>Billing Pin Code:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Pin Code"
                      name="billingpincode"
                      onChange={handleChange}
                      // onBlur={handleBlur}
                      value={values.billingpincode}
                      onBlur={myfunc2}
                      className={touched.pincode ? "has-error" : null}
                    />
                    {errors.pincode && touched.pincode && errors.pincode}
                  </Form.Group>
                </Col>
                <Col md={3} xs={12}>
                  <Form.Group controlId="formBasicCity">
                    <Form.Label>Billing City:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Billing City"
                      name="billingcity"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={billingcity}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={3} xs={12}>
                  <Form.Group controlId="formBasicState">
                    <Form.Label>Billing State:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Billing State"
                      name="billingstate"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={billingstate}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={3} xs={12}>
                  <Form.Group controlId="formBasicVillage">
                    <Form.Label>Billing Village:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Village"
                      name="billingvillage"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.billingvillage}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {/* //////////billing address as others */}
              <Form.Group controlId="formBasicName3">
                <Form.Label>Billing Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Billing Name"
                  name="billingName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.billingName}
                  required
                />
              </Form.Group>
              {/* <Row>
                                        <Col md={6} xs={12} >
                                        <Form.Group controlId="formBasicAddress3">
                                            <Form.Label>Billing Address:</Form.Label>
                                            <Form.Control 
                                            type="text" 
                                            placeholder="Billing Address" 
                                            name="billingAddress"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.billingAddress}
                                            required/>
                                        </Form.Group>
                                        </Col>
                                        <Col md={6} xs={12} > 
                                        <Form.Group controlId="formBasicPincode">
                                            <Form.Label>Pincode:</Form.Label>
                                            <Form.Control 
                                            type="number" 
                                            placeholder="Pincode" 
                                            name="pin"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.pin}
                                            required/>
                                        </Form.Group>
                                        </Col>
                                        </Row> */}

              <Form.Group controlId="exampleForm.ControlSelect3">
                <Form.Label>GST:</Form.Label>
                <Form.Control
                  as="select"
                  name="gstInfo"
                  onChange={handleChange}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Form.Group>

              {values.gstInfo == "Yes" ? (
                <>
                  <Form.Group controlId="formBasicGstNumber" id="1">
                    <Form.Label>GST Number:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="GST Number"
                      name="gstNumber"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.gstNumber}
                      required
                    />
                  </Form.Group>
                  <Form.Group controlId="formBasicGstAddress" id="2">
                    <Form.Label>GST Address:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="GST Address"
                      name="gstAddress"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.gstAddress}
                    />
                  </Form.Group>
                </>
              ) : null}

              <center>
                <Button
                  onClick={() => updateActive("1")}
                  variant="secondary"
                  style={{ margin: "2%" }}
                >
                  Back
                </Button>
                <Button
                  onClick={() => updateActive("3")}
                  style={{ margin: "2%" }}
                >
                  Next
                </Button>
              </center>
            </div>
            <div style={{ display: active === "3" ? "block" : "none" }}>
              <Form.Group controlId="exampleForm.ControlSelect4">
                <Form.Label>Account Type:</Form.Label>
                <Form.Control
                  readOnly
                  name="accountType"
                  value={
                    values.accountType === "Individual" ? "Savings" : "Current"
                  }
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicName4">
                <Form.Label>Account Holder's Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Account Holder's Name"
                  name="accName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.accName}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicAccNumber">
                <Form.Label>Account Number:</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Account Number"
                  name="accNum"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.accNum}
                  required
                />
              </Form.Group>
              <Row>
                <Col md={6} xs={12}>
                  <Form.Group controlId="formBasicPin2">
                    <Form.Label>IFS Code:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter IFS Code"
                      name="ifs"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.ifs}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Group controlId="formBasicDoc2">
                    <Form.Label>Upload Cancelled Cheque:</Form.Label>
                    <Form.Control
                      type="file"
                      name="cancelCheque"
                      onChange={(e) => updateCancelCheque(e.target.files[0])}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <center>
                <Button
                  onClick={() => updateActive("2")}
                  variant="secondary"
                  style={{ margin: "2%" }}
                >
                  Back
                </Button>
                <Button
                  onClick={() => updateActive("4")}
                  style={{ margin: "2%" }}
                >
                  Next
                </Button>
              </center>
            </div>
            <div style={{ display: active === "4" ? "block" : "none" }}>
              <Form.Group controlId="formBasicName5">
                <Form.Label>Name:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Name"
                  name="authName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.authName}
                  required
                />
              </Form.Group>
              <Row>
                <Col md={6} xs={12}>
                  <Form.Group controlId="formBasicpNumber">
                    <Form.Label>Enter Phone Number:</Form.Label>
                    <Form.Control
                      readOnly={disable}
                      type="number"
                      placeholder="Enter Phone Number"
                      name="authPhone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.authPhone}
                      style={{
                        border: `${disable ? "2px solid green" : null}`,
                      }}
                      required
                    />
                  </Form.Group>
                  <Row style={{ marginBottom: "10px" }}>
                    <Col>
                      <Button
                        disabled={disable}
                        onClick={() => handleSendOTP(values.authPhone)}
                      >
                        Send OTP
                      </Button>
                    </Col>
                    <Col>
                      <p
                        style={{
                          color: "steelblue",
                          textDecoration: "underline",
                          cursor: "pointer",
                        }}
                        onClick={() => handleSendOTP(values.authPhone)}
                      >
                        Resend OTP?
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Group controlId="formBasicotp">
                    <Form.Label>Enter OTP:</Form.Label>
                    <Form.Control
                      readOnly={disable}
                      type="number"
                      placeholder="Enter OTP"
                      name="otp"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.otp}
                      required
                    />
                  </Form.Group>
                  <Row style={{ marginBottom: "10px" }}>
                    <Col>
                      <Button
                        disabled={disable}
                        variant="success"
                        onClick={() =>
                          handleVerifyOTP(values.otp, values.authPhone)
                        }
                      >
                        Verify OTP
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Form.Group controlId="formBasicEmail4">
                <Form.Label>Email ID:</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter Email"
                  name="authEmail"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.authEmail}
                  required
                />
              </Form.Group>
              <Form.Group controlId="formBasicAddress4">
                <Form.Label>
                  Add ID Proof (Aadhar / Driving Licence):
                </Form.Label>
                <Form.Control
                  type="file"
                  name="idProofImage"
                  onChange={(e) => updateIdProofImg(e.target.files[0])}
                  required
                />
              </Form.Group>

              <center>
                <Button
                  onClick={() => updateActive("3")}
                  variant="secondary"
                  style={{ margin: "2% 2%" }}
                >
                  Back
                </Button>
                <Button
                  variant="primary"
                  onClick={() => updateActive("5")}
                  style={{ margin: "2% 2%" }}
                >
                  Next
                </Button>
              </center>
            </div>
            <div style={{ display: active === "5" ? "block" : "none" }}>
              <Row>
                <Col md={4} xs={12}>
                  <Form.Group controlId="formBasicFacebook">
                    <Form.Label>Facebook:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Facebook ID"
                      name="facebook"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.facebook}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Group controlId="formBasicName7">
                    <Form.Label>Instagram:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Instagram ID"
                      name="insta"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.insta}
                    />
                  </Form.Group>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Group controlId="formBasicName6">
                    <Form.Label>Whatsapp:</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter Whatsapp Contact"
                      name="whatsapp"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.whatsapp}
                    />
                    {touched.whatsapp && errors.whatsapp ? (
                      <Form.Text className="text-muted">
                        {errors.whatsapp}
                      </Form.Text>
                    ) : null}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col md={6} xs={12}>
                  <Form.Group controlId="formBasicYouTube">
                    <Form.Label>YouTube:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter YouTube"
                      name="youtube"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.youtube}
                    />
                  </Form.Group>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Group controlId="formBasicTwitter">
                    <Form.Label>Twitter:</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Twitter ID"
                      name="twitter"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.twitter}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <center>
                <Button
                  onClick={() => updateActive("5")}
                  variant="secondary"
                  style={{ margin: "2% 2%" }}
                >
                  Back
                </Button>
                <Button
                  // onClick={() => props.updateActive("2")}
                  variant="primary"
                  style={{ margin: "2% 2%" }}
                  type="submit"
                >
                  Submit
                </Button>
              </center>
            </div>
          </Form>
        )}
      </Formik>
    </Container>
  );
};
export default AddPartner;
