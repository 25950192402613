import React, { useState, useEffect } from 'react';
import {Table,Container} from 'react-bootstrap';
import AddAddress from './addAddressModal';
import { GetContactInfo } from '../../../entities/action/action';


const ContactInfo = (props)=>{
    const [dataSet,updateDataSet] = useState([]);
    useEffect(()=>{
        (async()=>{
            const response = await GetContactInfo();
            if(response.success&&response?.websiteManagementContactInfo!==null){
                updateDataSet([response.websiteManagementContactInfo]);
            }
        })()
    },[])
    return(
        <Container fluid>
                <div style={{display: 'flex', justifyContent: 'end', margin: '10px 0'}}>
                    <AddAddress/>
                </div>
                <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Address Line 1</th>
                        <th>Address Line 2</th>
                        <th>Village</th>
                        <th>City</th>
                        <th>State</th>
                        <th>Pincode</th>
                        <th>Phone Number</th>
                        <th>Email ID</th>
                        <th>CIN</th>
                    </tr>
                </thead>
                <tbody>
                {
                    dataSet?.length>0?dataSet?.map((data,key)=>
                    <tr key={key}>
                        <td>{key+1}</td>
                        <td>{data.addressLine1}</td>
                        <td>{data.addressLine2}</td>
                        <td>{data.village}</td>
                        <td>{data.city}</td>
                        <td>{data.state}</td>
                        <td>{data.pincode}</td>
                        <td>{data.phoneNumber}</td>
                        <td>{data.webEmailId}</td>
                        <td>{data.cin}</td>
                    </tr>       
                    ):null
                }
             </tbody>
            </Table>
        </Container>
    )
}
export default ContactInfo;