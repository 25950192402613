import React, { useEffect, useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftEditor from "../../draftEditor";
import { GetVendor } from "../../../entities/action/action";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
// import {DropzoneArea} from 'material-ui-dropzone';

const AddCharterPlaneType = (props) => {
  const [message, updateMessage] = useState("");
  // const [files,updateFiles] = useState([]);

  // const handleImages = (inpFile)=>{
  //     updateFiles(inpFile)
  // }
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([1]);
  const [vendorList, updateVendorList] = useState([]);
  const [vendorId, updateVendorId] = useState("");
  useEffect(() => {
    (async () => {
      const res = await GetVendor();
      if (res != undefined && res.length > 0) {
        const arr = res.filter(
          (item, key) =>
            item.companyInformation.vendortype === "Charter Plane Operator"
        );
        console.log(res);
        console.log(arr);
        updateVendorList(arr);
      }
    })();
  }, []);

  const handleAdd = () => {
    const newCurrentPoint = currentPoints + 1;
    updateCurrentPoints(newCurrentPoint);
    updateArrPoints([...arrPoints, newCurrentPoint]);
  };
  const handleRemove = () => {
    if (currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    }
  };

  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicVendor" as={Row}>
              <Form.Label column sm="3">
                Vendor ID:
              </Form.Label>
              <Col sm="9">
                <SingleSelect
                  type="3"
                  list={vendorList}
                  change={(select) => updateVendorId(select)}
                />
                {/* <Form.Control
                  as="select"
                  placeholder="Vendor ID"
                  name="vendorId"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.vendorId}
                  required
                >
                  <option value="">--select--</option>
                </Form.Control> */}
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCharterPlaneName" as={Row}>
              <Form.Label column sm="3">
                Charter Plane Type:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Charter Plane Type"
                  name="charterPlaneName"
                  value={values.charterPlaneType}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicCharterPlaneName" as={Row}>
              <Form.Label column sm="3">
                Charter Plane Name:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Charter Plane Name"
                  name="charterPlaneName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.charterPlaneName}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicInfo" as={Row}>
              <Form.Label column sm="3">
                Charter Plane Registration Number:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Charter Plane Registration Number"
                  name="charterPlaneRegistrationNumber"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.charterPlaneRegistrationNumber}
                  required
                />
              </Col>
              <Form.Label column sm="2">
                Charter Plane Depot Name:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Charter Plane Depot Name"
                  name="charterPlaneDepotName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.charterPlaneDepotName}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTravelFrom" as={Row}>
              <Form.Label column sm="3">
                Travel From:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Travel From"
                  name="travelFrom"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.travelFrom}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicTravelTo" as={Row}>
              <Form.Label column sm="3">
                Travel To:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                  placeholder="Enter Travel To"
                  name="travelTo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.travelTo}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicSeatType1" as={Row}>
              <Form.Label column sm="3">
                Seat Type:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter Seat Type ( option 1 )"
                  name="seatType1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatType1}
                  required
                />
              </Col>
              <Form.Label column sm="2">
                Seat Count:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter Seat Count"
                  name="seatCount1"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatCount1}
                  required
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicSeatType" as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter Seat Type ( option 2 )"
                  name="seatType2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatType2}
                />
              </Col>
              <Form.Label column sm="2"></Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter Seat Count"
                  name="seatCount2"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatCount2}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicSeatType" as={Row}>
              <Form.Label column sm="3"></Form.Label>
              <Col sm="4">
                <Form.Control
                  type="text"
                  placeholder="Enter Seat Type ( option 3 )"
                  name="seatType3"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatType3}
                />
              </Col>
              <Form.Label column sm="2"></Form.Label>
              <Col sm="3">
                <Form.Control
                  type="text"
                  placeholder="Enter Seat Count"
                  name="seatCount3"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.seatCount3}
                />
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicLiveTracking" as={Row}>
              <Form.Label column sm="3">
                Partial Cancellation:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  as="select"
                  placeholder="Partial Cancellation"
                  name="partialCancellation"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.partialCancellation}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
              <Form.Label column sm="2">
                Live Tracking:
              </Form.Label>
              <Col sm="3">
                <Form.Control
                  as="select"
                  placeholder="Live Tracking"
                  name="liveTracking"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.liveTracking}
                  required
                >
                  <option value="">--select--</option>
                  <option value="Yes">Yes</option>
                  <option value="No">No</option>
                </Form.Control>
              </Col>
            </Form.Group>
            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group
                    controlId={`formCancellation${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="3">
                      Cancellation {d}:
                    </Form.Label>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Cancellation ${d}`}
                        name={`Cancellation${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`Cancellation${d}`]}
                        required
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="text"
                        placeholder={`Charges ${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        name={`cancellationCharges${d}`}
                        value={values[`cancellationCharges${d}`]}
                        required
                      />
                    </Col>
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove()}
              >
                Remove
              </Button>
              <Button variant="success" onClick={() => handleAdd()}>
                Add
              </Button>
            </div>
            {/* <Form.Group controlId="formBasicChild" as={Row}>
                                            <Form.Label column sm="3">Images:</Form.Label>
                                            <Col sm="9"> 
                                                <DropzoneArea onChange={ handleImages }filesLimit={5}/>
                                            </Col>
                                        </Form.Group> */}
            <Row style={{ margin: "2% 0" }}>
              <Col sm={3} style={{ margin: "2% 0" }} className={classes.Col}>
                Terms & Conditions:
              </Col>
              <Col sm={9} style={{ border: "1px solid black" }}>
                <DraftEditor
                  change={(text) => updateMessage(text)}
                  text={message}
                />
              </Col>
            </Row>
            <center>
              <Button type="submit" variant="success">
                Save CharterPlane
              </Button>
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddCharterPlaneType;
