import React from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Formik } from 'formik';
import { AddMarkup } from '../../../entities/action/action';

const AddFlightMarkup = (props)=>{

    return(
        <Formik
            initialValues={{amount:'',gst:''}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    console.log(values)
                    const newData = {
                        "amountType": values.amountType,
                        "gstCost": Math.round(values.amount*(values.gst/100)).toString(),
                        "highestMarkupRange": values.highest,
                        "lowestMarkupRange": values.lowest,
                        "markUpId": 0,
                        "markupAmount": values.amount,//10
                        "markupCalculation": values.markupCalculation,
                        "markupFor": values.markupFor,
                        "markupGst": values.gst,//20
                        "markupIn": props.markupIn,
                        "markupType": values.markupType,
                        "totalMarkup": Math.round(values.amount*(1+values.gst/100)).toString()
                    }
                    const response = await AddMarkup("flight",newData)
                    if(response?.success){
                        alert('Successfully added markup')
                        window.location.reload();
                        resetForm();
                    }
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicMarkupFor" as={Row}>
                                            <Form.Label column sm="2">Markup For:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Markup For" 
                                                    name="markupFor"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.markupFor}
                                                    required>
                                                        <option value="">--select--</option>
                                                        <option value="INTERNATIONAL">International Flight</option>
                                                        <option value="DOMESTIC">Domestic Flight</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicAmountType" as={Row}>
                                            <Form.Label column sm="2">Amount Type:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Amount Type" 
                                                    name="amountType"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.amountType}
                                                    required>
                                                        <option value="">--select--</option>
                                                        <option value="FIX">Fix</option>
                                                        <option value="PERCENTAGE">Percentage</option>
                                                        {/* <option value="Both">Both</option> */}
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicAmount" as={Row}>
                                            <Form.Label column sm="2">Markup Amount:</Form.Label>
                                            <Col> 
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Amount" 
                                                name="amount"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.amount}
                                                required/>
                                            </Col>
                                            <Col> 
                                            <Form.Control 
                                                type="text" 
                                                placeholder="Markup GST" 
                                                name="gst"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.gst}
                                                required/>
                                            </Col>
                                            <Col> 
                                            <Form.Control 
                                                type="text" 
                                                placeholder="GST Cost" 
                                                name="gstcost"
                                                value={Math.round(values.amount*(values.gst/100)).toString()}
                                                required/>
                                            </Col>
                                            <Col> 
                                            <Form.Control 
                                                type="text"
                                                readOnly 
                                                placeholder="Total Markup" 
                                                name="totalMarkup"
                                                value={Math.round(values.amount*(1+values.gst/100)).toString()}
                                                required/>
                                            </Col>
                                        </Form.Group>   
                                        <Form.Group controlId="formBasicMarkupType" as={Row}>
                                            <Form.Label column sm="2">Markup Type:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Markup Type" 
                                                    name="markupType"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.markupType}
                                                    required>
                                                        <option value="">--select--</option>
                                                        <option value="All">ALL</option>
                                                        <option value="AIRLINEWISE">Airlinewise</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicRange" as={Row}>
                                            <Form.Label column sm="2">Markup Range:</Form.Label>
                                            <Col sm="5"> 
                                                <Form.Control
                                                type="number"
                                                min={0} 
                                                placeholder="Lowest" 
                                                name="lowest"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.lowest}
                                                required/>
                                            </Col>
                                            <Col sm="5"> 
                                                <Form.Control
                                                type="number"
                                                min={0} 
                                                placeholder="Highest" 
                                                name="highest"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.highest}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicMarkupCalculation" as={Row}>
                                            <Form.Label column sm="2">Markup Calculation:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Markup Calculation" 
                                                    name="markupCalculation"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.markupCalculation}
                                                    required>
                                                        <option value="">--select--</option>
                                                        <option value="PERPAX">Per Pax</option>
                                                        <option value="FIX">Fix</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <center>
                                            <Button type="submit" variant="success">Save Flight Markup</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>          
    )
}
export default AddFlightMarkup;