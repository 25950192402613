import React from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Formik } from 'formik';


const BlockAndUnblock = (props)=>{

    const categoryList = [
        {type:'CATEGORY'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'}
    ]
    return(
        <div> 
        <Formik
            initialValues={{unitSericeVariablemsp:'',taxSlabVariablemsp:'',unitSericeVariableocc:''}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        <Form.Group controlId="formBasicActionType" as={Row}>
                                            <Form.Label column sm="2">Action Type:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Action Type" 
                                                    name="actionType"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.actionType}
                                                    required>
                                                    <option value="">--select--</option>
                                                    <option value="Block">Block</option>
                                                    <option value="Unblock">Unblock</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>   
                                        <Form.Group controlId="formBasicRoomName" as={Row}>
                                            <Form.Label column sm="2">Room Name:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Room Name" 
                                                    name="roomName"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.roomName}
                                                    required>
                                                    {
                                                        categoryList.map((categories,key)=>
                                                        <option key={key} value={categories.type}>{categories.type}</option>    
                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>   
                                        <Form.Group controlId="formBasicdateFrom" as={Row}>
                                            <Form.Label column sm="2">Date From:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="date" 
                                                name="dateFrom"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.dateFrom}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicdateTo" as={Row}>
                                            <Form.Label column sm="2">Date To:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="date" 
                                                name="dateTo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.dateTo}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicMetaNoOfRooms" as={Row}>
                                            <Form.Label column sm="2">No. of Rooms:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter No. of Rooms" 
                                                name="noOfRooms"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.noOfRooms}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <center>
                                            <Button type="submit" variant="success">Block/Unblock Room</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                    </div>            
    )
}
export default BlockAndUnblock;