import React, { useState,useEffect } from 'react';
import {Col,Row,Button,Container,DropdownButton,Table} from 'react-bootstrap';
import classes from '../websiteManagement.module.css';
import AddSlider from './addSliderImageCustomer';
import {useSelector} from 'react-redux';
import { GetHomepageSliderCustomer,SliderCustomerDelete } from '../../../entities/action/action';

const ImageSlider = (props)=>{
    const [dataItems,updateData] = useState([]);
    console.log(dataItems)
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    useEffect(()=>{
        (async()=>{
            const newData = {
                "emailId": userData.data.key,
                "password": "string",
                "sessionKey": userData.sessionId
            }
            const response = await GetHomepageSliderCustomer(newData);
            if(response.successful){
                updateData(response.homepageSliderCustomerList);
            } 
        })()
    },[])
    const handleDelete = async(id)=>{
        const data = {
            "id": id,
            "loginid": userData.data.key,
            "sessionKey": userData.sessionId
        }
        const res = await SliderCustomerDelete(data);
        if(res.successful){
            alert("Successfully Deleted");
            window.location.reload();
        }else{
            alert("Something went wrong!!");
        }
    }
    return(
        <Container fluid>
            <Row>
                <Col xs={10}></Col>
                <Col xs={2}>
                    <AddSlider />
                </Col>
            </Row>
            <Table striped bordered hover>
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Image</th>
                        <th>Timer</th>
                        <th>Link</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        dataItems.length>0?dataItems.map((data,key)=>
                            <tr key={key}>
                                <td>{key+1}</td>
                                <td><img src={data.imageUrl} alt="sliderImage" style={{width:'80px'}}/></td>
                                <td>{data.timer}</td>
                                <td>{data.link}</td>
                                <td>
                                    <DropdownButton id="dropdown-variants-Success" title="Actions">
                                        <div className={classes.Center}>
                                            <Button variant="danger" onClick={()=>handleDelete(data.id)}>Delete</Button>
                                        </div>
                                    </DropdownButton>
                                </td>
                            </tr>
                        ):null
                    }
                </tbody>
            </Table>
        </Container>
    )
}
export default ImageSlider;