import React, { Fragment,useState,useEffect } from 'react';
import {Modal,Button, Form ,Col} from 'react-bootstrap';
import {Formik} from 'formik';
import {useSelector} from 'react-redux';

const AddAddress = (props)=>{
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // const data = useSelector(state=>state.adminAccount.apiResponse);
    const [success,updateSuccess] = useState(false);
    useEffect(()=>{
        if(success){
            setTimeout(()=>{
                updateSuccess(false);
            },5000)
        }
    },[success])

    return(
        <Fragment>
            <Button variant="success" onClick={handleShow}>Add</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                <Modal.Title>Add Contact Info</Modal.Title>
                </Modal.Header>
                {
                    success?
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                        </svg>:
                    <Formik
                    initialValues={{addressline1:'',addressline2:'',city:'',email:'',phone:'',CIN:'',pin:'',village:'',state:''}}
                    onSubmit={
                        async(values,{setSubmitting,resetForm})=>{
                            setSubmitting(true)
                            const newData = {
                                "addressLine1": values.addressline1,
                                "addressLine2": values.addressline2,
                                "city": values.city,
                                "cin": values.CIN,
                                "phoneNumber": values.phone,
                                "pincode": values.pin,
                                "state": values.state,
                                "village": values.village,
                                "webEmailId": values.email
                              }
                            const token = localStorage.getItem('token');
                            const authHeader = `Bearer ${token}`
                            const myresponse = await fetch("https://api.zupptravel.com/api/v1/contact/addContactInfo", {
                                method: "post",
                                headers: {
                                    "Content-Type": "application/json",
                                    'Authorization': authHeader
                                },
                                body: JSON.stringify(newData),
                                });
                                const myjson = await myresponse.json();
                                if(myjson.success){
                                    updateSuccess(true);
                                }
                            setSubmitting(false);
                            resetForm();
                        }
                    }>
                        {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                    <Modal.Body> 
                                    <Form.Group controlId="formBasicAddress">
                                        <Form.Label>Address Line 1:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter Address" 
                                        name="addressline1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.addressline1}
                                        required/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicAddress">
                                        <Form.Label>Address Line 2:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter Address" 
                                        name="addressline2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.addressline2}
                                        required/>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>Email ID:</Form.Label>
                                        <Form.Control 
                                        type="email" 
                                        placeholder="Enter Email" 
                                        name="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        required/>
                                    </Form.Group>
                                    <Form.Row> 
                                        <Form.Group as={Col} controlId="formBasicVillage">
                                            <Form.Label>Village:</Form.Label>
                                            <Form.Control 
                                            type="text" 
                                            placeholder="Enter Village" 
                                            name="village"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.village}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formBasicCity">
                                            <Form.Label>City:</Form.Label>
                                            <Form.Control 
                                            type="text" 
                                            placeholder="Enter City" 
                                            name="city"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.city}
                                            required/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row> 
                                        <Form.Group as={Col} controlId="formBasicState">
                                            <Form.Label>State:</Form.Label>
                                            <Form.Control 
                                            type="text" 
                                            placeholder="Enter State" 
                                            name="state"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.state}
                                            required/>
                                        </Form.Group>
                                        <Form.Group as={Col} controlId="formBasicPin">
                                            <Form.Label>Pin Code:</Form.Label>
                                            <Form.Control 
                                            type="text" 
                                            placeholder="Enter Pin Code" 
                                            name="pin"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.pin}
                                            required/>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row> 
                                    <Form.Group as={Col} controlId="formBasicPhone">
                                        <Form.Label>Phone Number:</Form.Label>
                                        <Form.Control 
                                        type="number" 
                                        placeholder="Enter Phone Number" 
                                        name="phone"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.phone}
                                        required/>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formBasicCIN">
                                        <Form.Label>CIN:</Form.Label>
                                        <Form.Control 
                                        type="text" 
                                        placeholder="Enter CIN" 
                                        name="CIN"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.CIN}
                                        required/>
                                    </Form.Group>
                                    </Form.Row>
                                    </Modal.Body>
                                    <Modal.Footer>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        Submit
                                    </Button>
                                    </Modal.Footer>
                                </Form>
                            )
                        }
                    </Formik>
                }    
            </Modal>
        </Fragment>
    )
}
export default AddAddress;