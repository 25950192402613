import React, {useState, Fragment } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import classesX from '../Dashboard/dashboard.module.css'
import Coupons from './coupons';
import CouponView from './updateViewCoupon';
import Offerings from './offerings';
import AdminNavbar from '../navbar/adminNavbar';

const CouponManagement = (props)=>{
    const [active,updateActive] = useState('1');
    const [couponData,updateCouponData] = useState({});
    const items = [
        {id:"1",type:"Coupons"},
        {id:"2",type:"Offerings"}
    ]
    const handleCouponView = (data)=>{
        updateActive("4");
        updateCouponData(data);
    }
    return(
        <Fragment> 
           <AdminNavbar/>
            <h1 className={classesX.Heading}>Money Management</h1>  
        <Container fluid style={{paddingLeft:'10px',width: '90%'}}>
            <Row> 
                <Col lg={12} md={12} className={classes.Response2}>
                    <div style={{height:'100%'}}> 
                        <ul className={classes.Ul}style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 ,height:"10vh",}}>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                    style={{
                                        backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                                        textAlign: 'center',
                                        margin: '0 1px', // Adjust the margin as needed
                                        padding:" 2% 0",
                                        color: 'white',
                                        cursor: 'pointer',
                                        width:"50%",
                                        height:"100%"
                                    }}>
                                    {item.type}
                                    </li>
                                )
                            }
                            {
                                active==="4"?
                                <li 
                                    onClick={()=>updateActive("2")}
                                    className={classes.Li}
                                    style={{backgroundColor:`${active==="3"?'steelblue':'#585858'}`,textAlign:'center'}}>
                                    Back
                                </li>:null
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={12} md={12} className={classes.Response}>
                    <div style={{height:'100%',marginTop:"2vh",marginLeft:"2vw"}}> 
                        {
                            active==="1"?<Coupons/>:
                            active==="2"?<Offerings/>:
                            active==="4"?<CouponView data={couponData}/>:null
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default CouponManagement;