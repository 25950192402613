import React, { useState, useEffect } from "react";
import { Col, Button, Form, Container, Row } from "react-bootstrap";
import { Formik } from "formik";
import classes from "../WebsiteManagement/websiteManagement.module.css";
import { Pincode } from "../../entities/action/action";
import { useSelector, useDispatch } from "react-redux";
import Loder from "../Loder/Loder";


const AddPincode = (props) => {
  // const months = [1,2,3,4,5,6,7,8,9,10,11,12];
  const [loading, setLoading] = useState(false);
  const data = useSelector((state) => state.adminAccount.apiResponse);
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const cityState = useSelector((state) => state.account.cityState);
  const dispatch = useDispatch();

  // const refreshPage = () => {
  //   window.location.reload();
  // };

  const myfunc = (e) => {
    if (e.target.value.length === 6) {
      dispatch(Pincode(data));
    } else {
      setState("");
      setCity("");
    }
  };
  useEffect(() => {
    if (!cityState) {
        setState("");
        setCity("");
    } else {
        setState(cityState.state);
        setCity(cityState.city);
    }
  }, [cityState]);

  const validate = (values) => {
    const errors = {};
  
    if (!values.pincode || values.pincode.toString().length !== 6) {
      errors.pincode = "Pincode must be exactly 6 digits";
    }
  
    if (!/^[a-zA-Z ]*$/.test(values.city)) {
      errors.city = "Invalid city name";
    }
  
    if (!/^[a-zA-Z ]*$/.test(values.state)) {
      errors.state = "Invalid state name";
    }
  
    return errors;
  };
  
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{}}
        validate={validate}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
          const newData = {
            city: values.city,
            pincode: values.pincode,
            state: values.state,
          };
          console.log(newData);

          const token = localStorage.getItem('token');
          const authHeader = `Bearer ${token}`

          const myresponse = await fetch(
            "https://api.zupptravel.com/api/v1/pincode/addPincode",
            {
              method: "post",
              headers: {
                "Content-Type": "application/json",
                'Authorization': authHeader
              },
              body: JSON.stringify(newData),
            }
          );

          const json = await myresponse.json();
          console.log(json);
          if (json?.success) {
            alert("Pincode Management added successfully!");
            window.location.reload();
          } else {
            alert("Error! Please try again later!");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicTheme" as={Row}>
              <Form.Label column sm="3">
                Pincode:
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="number"
                  placeholder="Enter Pincode"
                  name="pincode"
                  onChange={handleChange}
                  onBlur={myfunc}
                  value={values.pincode}
                  isInvalid={touched.pincode && !!errors.pincode}

                  required
                />
                
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicDepositeType" as={Row}>
              <Form.Label column sm="3">
                City :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
                 
                  placeholder="Enter city name"
                  name="city"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  isInvalid={touched.city && !!errors.city}
                  required
                /><Form.Control.Feedback type="invalid">{errors.pincode}</Form.Control.Feedback>
              </Col>
            </Form.Group>
            <Form.Group controlId="formBasicDepositeType" as={Row}>
              <Form.Label column sm="3">
                State :
              </Form.Label>
              <Col sm="9">
                <Form.Control
                  type="text"
             
                  placeholder="Enter state name"
                  name="state"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.state}
                  isInvalid={touched.state && !!errors.state}
                  required
                />
                <Form.Control.Feedback type="invalid">{errors.pincode}</Form.Control.Feedback>
              </Col>
            </Form.Group>

            <center>
              {loading ? (
                <Loder />
              ) : (
                <Button type="submit" variant="success">
                  Add Pincode
                </Button>
              )}
            </center>
          </Form>
        )}
      </Formik>
    </div>
  );
};
export default AddPincode;
