import React, { useState } from 'react';
import {Col,Button,Form,Row,Container} from 'react-bootstrap';
import { Formik } from 'formik';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DraftEditor from '../draftEditor';
import { Checkbox } from 'antd';
import {useSelector} from 'react-redux';
import { CDMPromotionalOffer } from '../../entities/action/action';

const CDMPromotionalOffers = (props)=>{
    const options = [
        { label: 'Accommodation Vendor', value: 'Accommodation Vendor' },
        { label: 'Bus Vendor', value: 'Bus Vendor' },
        { label: 'Tour Guide', value: 'Tour Guide' },
        { label: 'Cab Vendor', value: 'Cab Vendor' },
        { label: 'Charter Plane Vendor', value: 'Charter Plane Vendor' },
        { label: 'Actvities Vendor', value: 'Actvities Vendor' },
        { label: 'Rental Vehicle Vendor', value: 'Rental Vehicle Vendor' },
    ];
    const [message,updateMessage] = useState('');
    const [benifits, setBenifits] = useState([]);
    const onChange = (checkedValues)=> {
        setBenifits(checkedValues);
    }
    const userData = useSelector(state=>state.adminAccount.apiResponse);
    return(
        <Container fluid> 
        <Formik
            initialValues={{otherBenefits:''}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    if(values.incentives>0||values.walletMoney>0||values.otherBenefits.length>0){
                        setSubmitting(true)
                        const newData = {
                            "benefitsFor": benifits.toString(),
                            "emailId": userData.data.key,
                            "endValidity": values.vTo,
                            "endValidityTime": values.vTime,
                            "incentives": values.incentives,
                            "minimumSellingPrice": values.priceMin,
                            "otherBenefits": values.otherBenefits,
                            "sessionKey": userData.sessionId,
                            "startValidity": values.vFrom,
                            "termsAndConditions": message,
                            "walletMoney": values.walletMoney
                        }
                            const response = await CDMPromotionalOffer(newData); 
                            if(response){
                                alert("Successfully added wallet offer");
                                window.location.reload();
                                resetForm();
                            }else{
                                alert("Something went wrong!!!!");
                            }
                        setSubmitting(false);
                    }else{
                        alert("Minimum one entry of Benefit Types is required !!");
                    }
                }
            }
            >
                {
                            ({values,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                   <Row>
                                       <Col md={9}>
                                       <Form.Group controlId="formBasicAddress" as={Row}>
                                            <Form.Label column sm="2">Benefits for:</Form.Label>
                                            <Col>
                                                <Checkbox.Group 
                                                    style={{marginRight:"30px"}} 
                                                    options={options} 
                                                    value={benifits}
                                                    onChange={onChange}                   
                                                />
                                            </Col>
                                        </Form.Group>
                                       </Col>
                                       <Col  md={3}>
                                           <Button style={{float:"right"}}>View Offers List</Button>
                                       </Col>
                                   </Row>

                                        
                                       
                                        <Form.Group controlId="formBasicAddress" as={Row}>
                                            <Form.Label column sm="2">VALIDITY:</Form.Label>
                                            <Col sm={4}> 
                                                <Form.Control 
                                                type="date" 
                                                placeholder="From" 
                                                name="vFrom"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.vFrom}
                                                required/>
                                            </Col>
                                            <Col sm={4}> 
                                                <Form.Control 
                                                type="date" 
                                                placeholder="To" 
                                                name="vTo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.vTo}
                                                required/>
                                            </Col>
                                            <Col sm={2}>
                                                <Form.Control 
                                                type="time" 
                                                placeholder="Time" 
                                                name="vTime"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.vTime}
                                                required/>
                                            </Col>
                                        </Form.Group>    
                                        
                                       
                                        <Form.Group controlId="formBasicAddress" as={Row}>
                                            <Form.Label column sm="2">Minimum Selling Price:</Form.Label> 
                                            {/* //this price is basic or minimum price which would be act as minimum cost to avail the offer */}
                                            <Col sm={10}> 
                                                <Form.Control 
                                                type="number"
                                                min={0} 
                                                placeholder="Minimum" 
                                                name="priceMin"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.priceMin}
                                                required
                                                />
                                            </Col>
                                            
                                        </Form.Group>
                                       
                                        <Form.Group controlId="formBasicPromo" as={Row} style={{marginTop:'2%'}}>
                                            <Form.Label column sm="2">Benifit Type:</Form.Label>
                                            <Col sm="10"> 
                                                <Row>
                                                         
                                                    <Col xs={2}>Incentives</Col>
                                                    <Col xs={4}>
                                                    <Form.Control
                                                        type="number" 
                                                        min={0}
                                                        placeholder="Incentives" 
                                                        name="incentives"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.incentives}
                                                        />
                                                    </Col>
                                                    <Col xs={2}>Wallet Money</Col>
                                                    <Col xs={4}>
                                                    <Form.Control
                                                        type="number" 
                                                        min={0}
                                                        placeholder="Wallet Money" 
                                                        name="walletMoney"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.walletMoney}
                                                        />
                                                    </Col>
                                                    <Col xs={2}>Other Benefits</Col>
                                                    <Col xs={10}>
                                                    <Form.Control
                                                        type="text" 
                                                        min={0}
                                                        placeholder="Other Benefits" 
                                                        name="otherBenefits"
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.otherBenefits}
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Form.Group>
                                        <Row style={{margin:'2% 0'}}>
                                            <Col sm={2} style={{margin:'2% 0'}} className={classes.Col}>
                                                Terms & Conditions:
                                            </Col>
                                            <Col sm={10} style={{border:'1px solid black'}}>
                                                <DraftEditor change={(text)=>updateMessage(text)} text={message}/>
                                            </Col>
                                        </Row>
                                        <center>
                                            <Button type="submit" variant="success">Submit</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                    </Container>            
    )
}
export default CDMPromotionalOffers;