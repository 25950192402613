import React,{useState} from 'react';
import {Button,Modal,Table} from 'react-bootstrap';

const GiftCardModal =  (props)=> {
    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    return (
      <>
        <Button variant="warning" onClick={handleShow}>
          Open
        </Button>
  
        <Modal show={show} onHide={handleClose} centered size="xl">
          <Modal.Header closeButton>
            <Modal.Title>Gift Card Details:</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Table striped bordered hover>
            <thead>
                <tr>
                <th>Card Type</th>
                <th>Sending From</th>
                <th>Sending To</th>
                <th>Receiver Phone Number</th>
                <th>Delivery Date</th>
                <th>Delivery Time</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                {/* <td>{props.data.zoneatotal}</td>
                <td>{props.data.zonebtotal}</td>
                <td>{props.data.zonectotal}</td>
                <td>{props.data.zonedtotal}</td>
                <td>{props.data.zoneetotal}</td> */}
                </tr>
            </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  export default GiftCardModal;