import React, { useState } from "react";
import { Col, Button, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import Image from "../../../utilities/imageTestApi/image";
import MultipleSelect from "../../multiSelect";
import RoomCategoryList from "./roomCategoryList";
import SingleSelect from "../../../utilities/singleSelect/singleSelect";
import classes from "../../WebsiteManagement/websiteManagement.module.css";

const CabRoute = (props) => {
  const [selected, updateSelected] = useState("");
  const [select, updateSelect] = useState([]);
  const [file1, updateFile1] = useState({});
  const [file2, updateFile2] = useState({});
  const [file3, updateFile3] = useState({});
  const [CabType, updateCabType] = useState("");
  const [CabName, updateCabName] = useState("");
  const [currentPoints, updateCurrentPoints] = useState(1);
  const [arrPoints, updateArrPoints] = useState([1]);
  const [currentPoints1, updateCurrentPoints1] = useState(1);
  const [arrPoints1, updateArrPoints1] = useState([1]);
  const handleAdd = (type) => {
    if (type === 1) {
      const newCurrentPoint = currentPoints + 1;
      updateCurrentPoints(newCurrentPoint);
      updateArrPoints([...arrPoints, newCurrentPoint]);
    } else {
      const newCurrentPoint1 = currentPoints1 + 1;
      updateCurrentPoints1(newCurrentPoint1);
      updateArrPoints1([...arrPoints1, newCurrentPoint1]);
    }
  };
  const handleRemove = (type) => {
    if (type === 1 && currentPoints > 1) {
      const newCurrentPoint = currentPoints - 1;
      updateCurrentPoints(newCurrentPoint);
      let arr = [...arrPoints];
      arr.pop();
      updateArrPoints(arr);
    } else if (type === 2 && currentPoints1 > 1) {
      const newCurrentPoint1 = currentPoints1 - 1;
      updateCurrentPoints1(newCurrentPoint1);
      let arr = [...arrPoints1];
      arr.pop();
      updateArrPoints1(arr);
    }
  };
  return (
    <div className={classes.Box}>
      <Formik
        initialValues={{}}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          console.log(values);
          const locations = [];
          Object.entries(values).map(([key, value]) => {
            console.log(key, value);
            if (key.includes("location") && typeof value === "string") {
              locations.push(value);
            }
          });

          console.log(locations);

          const locationss = [];

          for (let i = 0; i < locations.length; i++) {
            locationss.push({
              location: locations[i],
            });
          }

          console.log(locationss);
          const newdata = {
            emailId: "string",
            perDayRange: values.PerDayRange,
            routeId: 0,
            location: locationss,
            routeType: values.routeType,
            sessionKey: "string",
            stateTax: values.stateTax,
            tollCharges: values.tollCharges,
            totalDistance: values.totalDistance,
          };
          console.log(newdata);
          const res = await fetch("https://api.zupptravel.com/addRoute", {
            method: "post",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(newdata),
          });
          const json = await res.json();
          console.log(json);
          if (json.successful) {
            alert("Added Cab Route!");
            window.location.reload();
          } else {
            alert("Error! Please try again later");
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form onSubmit={handleSubmit}>
            {/* <Form.Group controlId="formBasicCabName" as={Row}>
              <Form.Label column sm="2">
                Cab Registration:
              </Form.Label>
              <Col sm="10">
                <SingleSelect change={(select) => updateSelected(select)} />
              </Col>
            </Form.Group> */}
            <Form.Group controlId="formBasicCabInfoRoute" as={Row}>
              <Form.Label column sm="2">
                Route Type:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  // readOnly
                  type="text"
                  as="select"
                  placeholder="Cab Type"
                  name="routeType"
                  value={values.routeType}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                >
                  <option value="">--Select--</option>
                  <option value="local">Local</option>
                  <option value="oneWay">One Way</option>
                  <option value="Return">Return</option>
                  <option value="Airport">Airport</option>
                </Form.Control>
              </Col>
            </Form.Group>
            {/* <Form.Group controlId="formBasicCabInfo" as={Row}>
              <Form.Label column sm="2">
                Location:
              </Form.Label>
              <Col sm="5">
                <SingleSelect change={(select) => updateSelected(select)} />
              </Col>
              <Col sm="5">
                <SingleSelect change={(select) => updateSelected(select)} />
              </Col>
            </Form.Group> */}

            <Form.Group controlId="formBasicInfo" as={Row}>
              <Form.Label column sm="2">
                Total Distance:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="number"
                  placeholder="Enter Total Distance"
                  name="totalDistance"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.totalDistance}
                />
              </Col>
              <Form.Label column sm="2">
                Per Day Range :
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="number"
                  placeholder="Enter PerDay Range"
                  name="PerDayRange"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.PerDayRange}
                />
              </Col>
            </Form.Group>

            <Form.Group controlId="formBasicInfo" as={Row}>
              <Form.Label column sm="2">
                Toll Charges:
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="number"
                  placeholder="Enter Toll Charges"
                  name="tollCharges"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.tollCharges}
                />
              </Col>
              <Form.Label column sm="2">
                State Tax :
              </Form.Label>
              <Col sm="4">
                <Form.Control
                  type="number"
                  placeholder="Enter State Tax"
                  name="stateTax"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.stateTax}
                />
              </Col>
            </Form.Group>

            {arrPoints.length > 0
              ? arrPoints.map((d, key) => (
                  <Form.Group
                    controlId={`LocationRoute${d}`}
                    key={key}
                    as={Row}
                  >
                    <Form.Label column sm="2">
                      Location Route {d}:
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control
                        type="text"
                        placeholder={`Location ${d}`}
                        name={`location${d}`}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values[`location${d}`]}
                        required
                      />
                    </Col>
                    {/* <Col> 
                                                        <Form.Control
                                                            type="time" 
                                                            name="boardingTime"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.boardingTime}
                                                            required/>
                                                    </Col> */}
                  </Form.Group>
                ))
              : null}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "15px",
              }}
            >
              <Button
                variant="danger"
                style={{ marginRight: "15px" }}
                disabled={currentPoints <= 1 ? true : false}
                onClick={() => handleRemove(1)}
              >
                Remove
              </Button>
              <Button variant="success" onClick={() => handleAdd(1)}>
                Add
              </Button>
            </div>
            {/* {
                                               arrPoints1.length>0?arrPoints1.map((d,key)=>
                                                <Form.Group controlId={`formBoarding${d}`} key={key} as={Row}>
                                                    <Form.Label column sm="2">Dropoff Point {d}:</Form.Label>
                                                    <Col> 
                                                        <Form.Control 
                                                            type="text"
                                                            placeholder={`Location ${d}`}
                                                            name={`dropoffLocation${d}`}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values[`dropoffLocation${d}`]}
                                                            required/>
                                                    </Col>
                                                    <Col> 
                                                        <Form.Control
                                                            type="time" 
                                                            name="dropoffBoardingTime"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.dropoffBoardingTime}
                                                            required/>
                                                    </Col>
                                                </Form.Group>
                                               ):null 
                                            }
                                        <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'15px'}}>
                                            <Button 
                                                variant="danger" 
                                                style={{marginRight:'15px'}} 
                                                disabled={currentPoints1<=1?true:false}
                                                onClick={()=>handleRemove(2)}
                                            >Remove</Button>
                                            <Button variant="success" onClick={()=>handleAdd(2)}>Add</Button>
                                        </div> */}
            <center>
              <Button type="submit" variant="success">
                Save Cab Route
              </Button>
            </center>
          </Form>
        )}
      </Formik>
      <hr />
      {/* <RoomCategoryList /> */}
    </div>
  );
};
export default CabRoute;
