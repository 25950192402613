import React, {useState, Fragment } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import AddActivity from './addActivity';
import PriceAndActivity from './priceAndAvailability';
import ActivityList from './activityList';
import ActivityAvailabilityAndPriceList from './availabilityAndPriceList';
import ActivityInventory from './activityInventory';
import ActivityView from './activityView';

const Activity = (props)=>{
    const [active,updateActive] = useState('1');
    const items = [
        {id:"1",type:"Add Activity"},
        {id:"2",type:"Activity List"},
        {id:"3",type:"Price & Availability"},
        {id:"5",type:"Price & Availability List"},
        {id:"6",type:"Activity Invetory"}
    ]
    const [data,updateData] = useState([]);
 const handleClick=(data)=>{
     console.log(data)
    updateData(data);
    updateActive("4")
 }
    return(
        <Fragment> 
        <Container fluid style={{paddingLeft:'10px'}}>
            <Row> 
                <Col lg={2} md={12} className={classes.Response2}>
                    <div style={{height:'100%'}}> 
                        <ul className={classes.Ul}>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                    style={{backgroundColor:`${active===item.id?'steelblue':'#585858'}`,textAlign:'center'}}>
                                    {item.type}
                                    </li>
                                )
                            }
                            {
                                active==="4"?<li 
                                onClick={()=>updateActive("2")}
                                className={classes.Li}
                                style={{backgroundColor:`${active==="4"?'steelblue':'#585858'}`,textAlign:'center'}}>
                                Back
                                </li>:null
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={10} md={12} className={classes.Response}>
                    <div style={{height:'100%'}}> 
                        {
                            active==="1"?<AddActivity/>:
                            active==="2"?<ActivityList click={(data)=>handleClick(data)}/>:
                            active==="4"?<ActivityView data={data}/>:
                            active==="3"?<PriceAndActivity/>:
                            active==="5"?<ActivityAvailabilityAndPriceList/>:
                            active==="6"?<ActivityInventory/>:null
                        }   
                    </div>
                </Col>
            </Row>
        </Container>
        </Fragment>
    )
}

export default Activity;