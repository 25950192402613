import React from 'react';
import {Col,Button,Form, Row} from 'react-bootstrap';
import { Formik } from 'formik';


const SetRoomPrice = (props)=>{

    const categoryList = [
        {type:'CATEGORY'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'},
        {type:'Agriculture Products'}
    ]
    return(
        <div> 
        <Formik
            initialValues={{unitSericeVariablemsp:'',taxSlabVariablemsp:'',unitSericeVariableocc:''}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting})=>(
                                <Form onSubmit={handleSubmit}>
                                        {/* <Form.Group controlId="formBasicAccommodationName" as={Row}>
                                            <Form.Label column sm="2">Accommodation Name:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Accommodation Name" 
                                                    name="accommodationName"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.accommodationName}
                                                    required>
                                                    {
                                                        categoryList.map((categories,key)=>
                                                        <option key={key} value={categories.type}>{categories.type}</option>    
                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>    */}
                                        <Form.Group controlId="formBasicRoomName" as={Row}>
                                            <Form.Label column sm="2">Room Name:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Room Name" 
                                                    name="roomName"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.roomName}
                                                    required>
                                                    {
                                                        categoryList.map((categories,key)=>
                                                        <option key={key} value={categories.type}>{categories.type}</option>    
                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>   
                                        <Form.Group controlId="formBasicRoomSet" as={Row}>
                                            <Form.Label column sm="2">Room Set:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Room Set" 
                                                    name="roomSet"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.roomSet}
                                                    required>
                                                    {
                                                        categoryList.map((categories,key)=>
                                                        <option key={key} value={categories.type}>{categories.type}</option>    
                                                        )
                                                    }
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>   
                                        <Form.Group controlId="formBasicdateFrom" as={Row}>
                                            <Form.Label column sm="2">Date From:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="date" 
                                                name="dateFrom"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.dateFrom}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicdateTo" as={Row}>
                                            <Form.Label column sm="2">Date To:</Form.Label>
                                            <Col sm="10"> 
                                                <Form.Control
                                                type="date" 
                                                name="dateTo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.dateTo}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Label style={{fontWeight:"bold"}}>Room Cost:</Form.Label>
                                        <div style={{border:"1px solid grey"}} className="container">
                                        
                                        <Form.Group controlId="formBasicMrp" as={Row} style={{margin:"1% 0"}}>
                                            <Form.Label>Unit Price:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    type="number" 
                                                    step={0.01}
                                                    placeholder="Enter Unit Price" 
                                                    name="unitSericeVariablemsp"
                                                    min={0}
                                                    onChange={handleChange}
                                                    value={values.unitSericeVariablemsp}
                                                    required/>
                                            </Col>
                                            <Form.Label>Unit GST Rate:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    type="number" 
                                                    step={0.01}
                                                    placeholder="G.S.T Rate" 
                                                    name="taxSlabVariablemsp"
                                                    min={1}
                                                    max={100}
                                                    isInvalid={values.taxSlabVariablemsp<0||values.taxSlabVariablemsp>100}
                                                    onChange={handleChange}
                                                    value={values.taxSlabVariablemsp}
                                                    required/>
                                            </Col>
                                            <Form.Label >GST Cost:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    readOnly 
                                                    name="taxRateVariablemsp"
                                                    onChange={handleChange}
                                                    value={values.unitSericeVariablemsp*0.01*values.taxSlabVariablemsp}
                                                    required/>
                                            </Col>  
                                            <Form.Label >M.R.P:</Form.Label>
                                            <Col > 

                                                <Form.Control
                                                    type="number" 
                                                    step={0.01}
                                                    readOnly
                                                    min={0}
                                                    placeholder="M.R.P" 
                                                    name="variableCostmsp"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.unitSericeVariablemsp+values.unitSericeVariablemsp*0.01*values.taxSlabVariablemsp}
                                                    required/>
                                            </Col> 
                                        </Form.Group>
                                        <Form.Group controlId="formBasicAppliesTo" as={Row} style={{margin:"1% 0"}}>
                                        
                                        </Form.Group>
                                    
                                        </div>
                                        <Form.Label style={{fontWeight:"bold"}}>Room Cost to CX:</Form.Label>
                                        <div style={{border:"1px solid grey"}} className="container">
                                    
                                        <Form.Group controlId="formBasicMrp" as={Row} style={{margin:"1% 0"}}>
                                            <Form.Label >Unit Price:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    type="number" 
                                                    step="any"
                                                    placeholder="Enter Unit Price" 
                                                    name="unitSericeVariableocc"
                                                    min={0}
                                                    isInvalid={values.unitSericeVariableocc>values.unitSericeVariablemsp}
                                                    onChange={handleChange}
                                                    value={values.unitSericeVariableocc}
                                                    required/>
                                                
                                            </Col>
                                            <Form.Label>Unit GST Rate:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    type="number" 
                                                    step="any"
                                                    placeholder="G.S.T Rate" 
                                                    name="taxSlabVariableocc"
                                                    min={1}
                                                    max={100}
                                                    isInvalid={values.taxSlabVariablemsp<0||values.taxSlabVariablemsp>100}
                                                    onChange={handleChange}
                                                    value={values.taxSlabVariablemsp}
                                                    required/>
                                            </Col>
                                            <Form.Label >GST Cost:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    readOnly 
                                                    defaultValue="0" 
                                                    name="taxRateVariableocc"
                                                    onChange={handleChange}
                                                    value={values.unitSericeVariableocc*0.01*values.taxSlabVariablemsp}
                                                    required/>
                                            </Col>  
                                            <Form.Label >Selling Price:</Form.Label>
                                            <Col> 
                                                <Form.Control
                                                    type="number" 
                                                    step="any"
                                                    readOnly
                                                    min={0}
                                                    placeholder="Selling Price" 
                                                    name="variableCostocc"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.unitSericeVariableocc+values.unitSericeVariableocc*0.01*values.taxSlabVariablemsp}
                                                    required/>
                                            </Col> 
                                        </Form.Group>
                                        <Form.Group controlId="formBasicAppliesTo" as={Row} style={{margin:"1% 0"}}>
                                        
                                        </Form.Group>
                                        </div>
                                        <Form.Label style={{fontWeight:"bold"}}>Room Cost to ZT:</Form.Label>
                                        <div style={{border:"1px solid grey"}} className="container">
                                        
                                        <Form.Group controlId="formBasicMrp" as={Row} style={{margin:"1% 0"}}>
                                        <Form.Label >Unit Price</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    type="number" 
                                                    step="any"
                                                    placeholder="Enter Unit Price" 
                                                    name="unitSericeVariableww"
                                                    min={0}
                                                    value={values.unitSericeVariableocc*.8675}
                                                    required/>
                                            </Col>
                                            <Form.Label >Unit GST Rate:</Form.Label>
                                            <Col > 
                                                <Form.Control
                                                    type="number" 
                                                    step="any"
                                                    placeholder="G.S.T Rate" 
                                                    name="taxSlabVariableww"
                                                    min={1}
                                                    max={100}
                                                    isInvalid={values.taxSlabVariablemsp<0||values.taxSlabVariablemsp>100}
                                                    value={values.taxSlabVariablemsp}
                                                    required/>
                                            </Col>
                                            <Form.Label >GST Cost:</Form.Label>
                                            <Col> 
                                                <Form.Control
                                                    readOnly 
                                                    defaultValue="0" 
                                                    name="taxRateVariableww"
                                                    value={values.unitSericeVariableocc*0.8675*0.01*values.taxSlabVariablemsp}
                                                    required/>
                                            </Col>  
                                            <Form.Label >Purchase Price:</Form.Label>
                                            <Col> 
                                                <Form.Control
                                                    type="number" 
                                                    step="any"
                                                    readOnly
                                                    min={0}
                                                    placeholder="Purchase Price" 
                                                    name="variableCostww"
                                                    value={values.unitSericeVariableocc*0.8675*0.01*values.taxSlabVariablemsp +values.unitSericeVariableocc*0.8675 }
                                                    required/>
                                            </Col> 
                                        </Form.Group>
                                        </div>
                                        <center>
                                            <Button style={{marginTop:'15px'}} type="submit" variant="success">Save Room Price</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                    </div>            
    )
}
export default SetRoomPrice;