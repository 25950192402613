import _ from "lodash";
import React, { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import {
  GetCategory,
  GetStoreById,
  GetStoreList,
  GetSupplier,
} from "../entities/action/action";

// const AddStaff = [
//     {category:"Dashboard",id:"1"},
//     {category:"API Management",id:"2"},
//     {category:"Customer Delivery Management",id:"0"},
//     {category:"Purchase Management",id:"21"},
//     {category:"Order Management",id:"3"},
//     // {category:"Store Management",id:"4"},
//     {category:"Supplier Management",id:"7"},
//     {category:"Catalogue Management",id:"14"},
//     {category:"Money Management",id:"9"},
//     {category:"Customer Management",id:"13"},
//     {category:"Staff Management",id:"15"},
//     {category:"Query Management",id:"16"},
//     {category:"Information Management",id:"17"},
//     // {category:"Payment Management",id:"18"},
//     {category:"Website Management",id:"19"},
//     {category:"Abandon Cart",id:"25"},
//     {category:"Abandon Wishlist",id:"26"},
//     {category:"Page Management",id:"20"},

//     {category:"Gift Card Management",id:"8"},
//     {category:"Blog Management",id:"22"},
//     {category:"Request Management",id:"23"},
//     {category:"Customer Review Management",id:"24"},

//     {category:"Loyalty Management",id:"10"},
//     {category:"Membership Management",id:"11"},
//     {category:"Subscription Management",id:"12"},
//     {category:"Inventory Management",id:"5"},
//     {category:"POS Management",id:"6"},
// ]
const AddStaff = [
  "Dashboard",
  "API Management",
  "Customer Delivery Management",
  "Purchase Management",
  "Order Management",
  // {category:"Store Management",id:"4"},
  "Supplier Management",
  "Catalogue Management",
  "Money Management",
  "Customer Management",
  "Staff Management",
  "Query Management",
  "Information Management",
  // {category:"Payment Management",id:"18"},
  "Website Management",
  "Abandon Cart",
  "Abandon Wishlist",
  "Page Management",
  "Gift Card Management",
  "Blog Management",
  "Request Management",
  "Customer Review Management",
  "Loyalty Management",
  "Membership Management",
  "Subscription Management",
  "Inventory Management",
  "POS Management",
];
// const AddStaff = [
//      "Dashboard" ,
//      "API Management",
//      "Order Management",
//      "Store Management",
//      "Inventory Management",
//      "POS Management",
//      "Partner Management",
//      "Gift Card Management",
//      "Coupon Management",
//      "Loyalty Program Management",
//      "Membership Management",
//      "Subscription Management",
//      "Customer Management",
//      "Staff Management",
//      "Query Management",
//      "Information Management",
//      "Payment Management",
//      "Website Management",
//      "Page Management"
// ];

const MultipleSelect = (props) => {
  // const categoryData = useSelector(state=>state.adminAccount.category);
  const [selected, updateSelected] = useState([]);
  const [names, updateName] = useState([]);
  useEffect(
    () =>
      props.type === "1"
        ? updateName(AddStaff)
        : props.type === "2"
        ? updateName([])
        : props.type === "3"
        ? updateName([])
        : props.type === "4"
        ? updateName([])
        : props.type === "5"
        ? updateName([])
        : props.type === "6"
        ? updateName([])
        : props.type === "7"
        ? updateName([])
        : props.type === "8"
        ? updateName([])
        : props.type === "10"
        ? updateName([])
        : props.type === "11"
        ? updateName([])
        : props.type === "14"
        ? updateName([])
        : props.type === "12"
        ? updateName([])
        : props.type === "13"
        ? updateName([])
        : null,
    []
  );

  useEffect(() => {
    (async () => {
      if (
        props.type == "4" &&
        props.supplierId != undefined &&
        props.supplierId != ""
      ) {
        const res = await GetStoreById(props.supplierId);
        console.log("storesssssssssssssss", res);
        if (res.successful && res.storeResponseBySupplierId.length > 0) {
          updateName(res.storeResponseBySupplierId);
        }
      }
      // if(props.type=="5"&&props.supplierId!=undefined&&props.supplierId!=""){
      //   const res = await GetWarehouseById(props.supplierId);
      //   if(res.status&&res.suppWarehouseListDTO.length>0){
      //     updateName(res.suppWarehouseListDTO);
      //   }
      // }

      if (
        props.type == "9" &&
        props.supplierId != undefined &&
        props.supplierId != ""
      ) {
        const res = await GetCategory();
        if (res.status) {
          updateName(res);
        }
      }
      if (props.type == "2") {
        const res = await GetStoreList({
          loginEmaild: "string",
          sessionKey: "string",
        });
        if (res != null) {
          updateName(res);
        }
      }
    })();
  }, [props.supplierId]);
  // console.log("............",props.category)
  // console.log(names)
  useEffect(() => {
    if (
      props.type === "3" &&
      props.subCategory !== undefined &&
      props.subCategory.length >= 0
    ) {
      updateName(props.subCategory);
    }
  }, [props.subCategory, props.type]);
  useEffect(() => {
    if (
      props.type === "7" &&
      props.category !== undefined &&
      props.category.length >= 0
    ) {
      updateName(props.category);
    }
  }, [props.category, props.type]);
  useEffect(() => {
    if (
      props.type === "10" &&
      props.amenities !== undefined &&
      props.amenities.length >= 0
    ) {
      updateName(props.amenities);
    }
  }, [props.amenities, props.type]);
  useEffect(() => {
    if (
      props.type === "13" &&
      props.features !== undefined &&
      props.features.length >= 0
    ) {
      updateName(props.features);
    }
  }, [props.features, props.type]);

  useEffect(() => {
    if (
      props.type === "11" &&
      props.facility !== undefined &&
      props.facility.length >= 0
    ) {
      updateName(props.facility);
    }
  }, [props.facility, props.type]);
  useEffect(() => {
    if (
      props.type === "14" &&
      props.styleName !== undefined &&
      props.styleName.length >= 0
    ) {
      updateName(props.styleName);
    }
  }, [props.styleName, props.type]);
  useEffect(() => {
    if (
      props.type === "8" &&
      props.productlist !== undefined &&
      props.productlist.length >= 0
    ) {
      updateName(props.productlist);
    }
  }, [props.productlist, props.type]);
  useEffect(() => {
    if (
      props.type === "6" &&
      props.variant !== undefined &&
      props.variant.length >= 0
    ) {
      // console.log(props.variant)
      updateName(props.variant);
    }
  }, [props.variant, props.type]);

  useEffect(() => {
    // console.log("1");
    if (
      props.type === "1" &&
      props.list !== undefined &&
      props.list.length >= 0
    ) {
      updateSelected(props.list);
    }
    if (
      props.type === "3" &&
      props.list !== undefined &&
      props.list.length >= 0
    ) {
      updateSelected(props.list);
    }
    if (
      props.type === "8" &&
      props.list !== undefined &&
      props.list.length >= 0
    ) {
      updateSelected(props.list);
    }
    if (
      props.type === "7" &&
      props.list !== undefined &&
      props.list.length >= 0
    ) {
      // console.log("////",props.list)
      updateSelected(props.list);
    }
    if (
      props.type === "10" &&
      props.list !== undefined &&
      props.list.length >= 0
    ) {
      updateSelected(props.list);
    }
    if (
      props.type === "11" &&
      props.list !== undefined &&
      props.list.length >= 0
    ) {
      updateSelected(props.list);
    }
    if (
      props.type === "14" &&
      props.list !== undefined &&
      props.list.length >= 0
    ) {
      updateSelected(props.list);
    }
    if (
      props.type === "13" &&
      props.list !== undefined &&
      props.list.length >= 0
    ) {
      updateSelected(props.list);
    }
    if (
      props.type === "4" &&
      props.list !== undefined &&
      props.list.length >= 0
    ) {
      updateSelected(props.list);
    }
    if (
      props.type === "5" &&
      props.list !== undefined &&
      props.list.length >= 0
    ) {
      updateSelected(props.list);
    }
    if (
      props.type === "6" &&
      props.list !== undefined &&
      props.list.length >= 0
    ) {
      updateSelected(props.list);
    }
    if (
      props.type === "9" &&
      props.list !== undefined &&
      props.list.length >= 0
    ) {
      updateSelected(props.list);
    }
  }, [props.list, props.type]);
  // console.log(";;;;;;;;",names)
  useEffect(() => {
    // console.log("selected",selected)
    // console.log("iiiiiiiiiiiiiiiiiiiii",props)
    props.selectedValue(selected);
  }, [selected]);

  const stateOptions = _.map(names, (state, index) => ({
    key: index,
    text:
      props.type === "11"
        ? `${state.facilities}`
        : props.type === "14"
        ? `${state.styleName}`
        : props.type === "10"
        ? `${state.amenities}`
        : props.type === "13"
        ? `${state.features}`
        : props.type === "4"
        ? `${state.storeName}-${state.storeWarehouseId[0]}`
        : props.type === "5"
        ? `${state.warehouseName}-${state.warehouseId}`
        : props.type === "2"
        ? `${state.name} - ${state.id}`
        : props.type == "3"
        ? `${state.subcategoryName}`
        : props.type == "7"
        ? `${state.category}`
        : props.type == "8"
        ? `${state.productCategoryName}`
        : state,
    value:
      props.type === "11"
        ? `${state.facilities}`
        : props.type === "14"
        ? `${state.styleName}`
        : props.type === "10"
        ? `${state.amenities}`
        : props.type === "13"
        ? `${state.features}`
        : props.type === "4"
        ? `${state.storeWarehouseId[0]}`
        : props.type === "5"
        ? `${state.warehouseId}`
        : props.type === "2"
        ? state.id
        : props.type == "3"
        ? `${state.subcategoryName}`
        : props.type == "7"
        ? `${state.category}`
        : props.type == "8"
        ? `${state.productCategoryName}`
        : state,
  }));
  return (
    <Dropdown
      placeholder={
        props.type === "11"
          ? "Select Facility"
          : props.type === "14"
          ? "Select Style"
          : props.type === "10"
          ? "Select Amenities"
          : props.type === "1"
          ? "Module Access"
          : props.type === "2"
          ? "Store Selection"
          : props.type === "3"
          ? "SUB-CATEGORY"
          : props.type === "7"
          ? "CATEGORY"
          : props.type === "8"
          ? "Product List"
          : props.type === "4"
          ? "Store Selection"
          : props.type === "5"
          ? "Applicable Warehouse"
          : props.type === "12"
          ? "Facilities"
          : props.type === "13"
          ? "Features"
          : props.type === "6"
          ? "Variant"
          : ""
      }
      fluid
      multiple
      search
      selection
      options={stateOptions}
      value={selected}
      disabled={props.disabled !== undefined ? props.disabled : false}
      onChange={(e, optionsObj) => updateSelected(optionsObj.value)}
    />
  );
};

export default MultipleSelect;
