import React,{useState} from 'react';
import {Formik} from 'formik';
import {Form,Button,Row,Col} from 'react-bootstrap';
import classes from '../../WebsiteManagement/websiteManagement.module.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import DraftEditor from '../../draftEditor';
// import {DropzoneArea} from 'material-ui-dropzone';

const AddBusGeneralView = (props)=>{
    const [message,updateMessage] = useState('');
    // const [files,updateFiles] = useState([]);
    const [edit,updateEdit] = useState(false);
    // const handleImages = (inpFile)=>{
    //     updateFiles(inpFile)
    // }
 
    return(
        <div className={classes.Box}> 
        <Formik
            initialValues={{}}
            onSubmit={
                async(values,{setSubmitting,resetForm})=>{
                    console.log(values);
                }
            }
            >
                {
                            ({values,errors,touched,handleChange,handleBlur,handleSubmit,isSubmitting,resetForm})=>(
                                <Form onSubmit={handleSubmit}>
                                    <div style={{display:'flex',justifyContent:'flex-end',marginBottom:'2%'}}>
                                        {
                                            !edit?
                                                <Button variant="success" onClick={()=>updateEdit(true)}>Edit</Button>:
                                                <Button variant="danger" onClick={()=>{updateEdit(false); resetForm()}}>Cancel</Button>
                                        }
                                        <Button variant="light" style={{marginLeft:'15px'}} onClick={()=>props.click()}>{`<--`} Back</Button>
                                    </div>
                                    <Form.Group controlId="formBasicVendor" as={Row}>
                                            <Form.Label column sm="3">Vendor ID:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                    readOnly 
                                                    as="select" 
                                                    placeholder="Vendor ID" 
                                                    name="vendorId"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.vendorId}
                                                    disabled={!edit}
                                                    required>
                                                        <option value="">--select--</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicCharterPlaneName" as={Row}>
                                            <Form.Label column sm="3">Charter Plane Type:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Charter Plane Type" 
                                                name="charterPlaneName"
                                                value={values.charterPlaneType}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicCharterPlaneName" as={Row}>
                                            <Form.Label column sm="3">Charter Plane Name:</Form.Label>
                                            <Col sm="9"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Charter Plane Name" 
                                                name="charterPlaneName"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.charterPlaneName}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicInfo" as={Row}>
                                        <Form.Label column sm="3">Charter Plane Registration Number:</Form.Label>
                                            <Col sm="4"> 
                                                <Form.Control 
                                                    type="text"
                                                    placeholder="Charter Plane Registration Number" 
                                                    name="charterPlaneRegistrationNumber"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.charterPlaneRegistrationNumber}
                                                    disabled={!edit}
                                                    required/>
                                            </Col>
                                        <Form.Label column sm="2">Charter Plane Depot Name:</Form.Label>
                                            <Col sm="3"> 
                                                <Form.Control 
                                                    type="text" 
                                                    placeholder="Charter Plane Depot Name" 
                                                    name="charterPlaneDepotName"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.charterPlaneDepotName}
                                                    disabled={!edit}
                                                    required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicTravelFrom" as={Row}>
                                            <Form.Label column sm="3">Travel From:</Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Travel From" 
                                                name="travelFrom"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.travelFrom}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicTravelTo" as={Row}>
                                            <Form.Label column sm="3">Travel To:</Form.Label>
                                            <Col sm="9">
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Travel To" 
                                                name="travelTo"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.travelTo}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicSeatType1" as={Row}>
                                            <Form.Label column sm="3">Seat Type:</Form.Label>
                                            <Col sm="4"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Seat Type ( option 1 )" 
                                                name="seatType1"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.seatType1}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                            <Form.Label column sm="2">Seat Count:</Form.Label>
                                            <Col sm="3"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Seat Count" 
                                                name="seatCount1"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.seatCount1}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicSeatType" as={Row}>
                                            <Form.Label column sm="3"></Form.Label>
                                            <Col sm="4"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Seat Type ( option 2 )" 
                                                name="seatType2"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.seatType2}
                                                disabled={!edit}
                                                />
                                            </Col>
                                            <Form.Label column sm="2"></Form.Label>
                                            <Col sm="3"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Seat Count" 
                                                name="seatCount2"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.seatCount2}
                                                disabled={!edit}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicSeatType" as={Row}>
                                            <Form.Label column sm="3"></Form.Label>
                                            <Col sm="4"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Seat Type ( option 3 )" 
                                                name="seatType3"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.seatType3}
                                                disabled={!edit}
                                                />
                                            </Col>
                                            <Form.Label column sm="2"></Form.Label>
                                            <Col sm="3"> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Seat Count" 
                                                name="seatCount3"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.seatCount3}
                                                disabled={!edit}
                                                />
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicLiveTracking" as={Row}>
                                        <Form.Label column sm="3">Partial Cancellation:</Form.Label>
                                            <Col sm="4"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Partial Cancellation" 
                                                    name="partialCancellation"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.partialCancellation}
                                                    disabled={!edit}
                                                    required>
                                                        <option value="">--select--</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                </Form.Control>
                                            </Col>
                                            <Form.Label column sm="2">Live Tracking:</Form.Label>
                                            <Col sm="3"> 
                                                <Form.Control 
                                                    as="select" 
                                                    placeholder="Live Tracking" 
                                                    name="liveTracking"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.liveTracking}
                                                    disabled={!edit}
                                                    required>
                                                        <option value="">--select--</option>
                                                        <option value="Yes">Yes</option>
                                                        <option value="No">No</option>
                                                </Form.Control>
                                            </Col>
                                        </Form.Group>
                                        <Form.Group controlId="formBasicCancellationPolicy1" as={Row}>
                                            <Form.Label column sm="3">Cancellation Policy & Charges:</Form.Label>
                                            <Col> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Cancellation Policy 1" 
                                                name="cancellationPolicy1"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.cancellationPolicy1}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                            <Col> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Charges 1" 
                                                name="charges1"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.charges1}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicCancellationPolicy2" as={Row}>
                                            <Form.Label column sm="3"></Form.Label>
                                            <Col> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Cancellation Policy 2" 
                                                name="cancellationPolicy2"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.cancellationPolicy2}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                            <Col> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Charges 2" 
                                                name="charges2"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.charges2}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group> 
                                        <Form.Group controlId="formBasicCancellationPolicy3" as={Row}>
                                            <Form.Label column sm="3"></Form.Label>
                                            <Col> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Cancellation Policy 3" 
                                                name="cancellationPolicy3"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.cancellationPolicy3}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                            <Col> 
                                                <Form.Control
                                                type="text" 
                                                placeholder="Enter Charges 3" 
                                                name="charges3"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.charges3}
                                                disabled={!edit}
                                                required/>
                                            </Col>
                                        </Form.Group> 
                                        {/* <Form.Group controlId="formBasicChild" as={Row}>
                                            <Form.Label column sm="3">Images:</Form.Label>
                                            <Col sm="9"> 
                                                <DropzoneArea onChange={ handleImages }filesLimit={5}/>
                                            </Col>
                                        </Form.Group> */}
                                        <Row style={{margin:'2% 0'}}>
                                            <Col sm={3} style={{margin:'2% 0'}} className={classes.Col}>
                                                Terms & Conditions:
                                            </Col>
                                            <Col sm={9} style={{border:'1px solid black'}}>
                                                <DraftEditor change={(text)=>updateMessage(text)} text={message} />
                                            </Col>
                                        </Row>
                                        <center>
                                            <Button disabled={!edit} type="submit" variant="success">Update Bus</Button>
                                        </center>
                                    </Form>
                            )}
                        </Formik>
                    </div>            
    )
}
export default AddBusGeneralView;