import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import classes from "../../WebsiteManagement/websiteManagement.module.css";
import AddAmenitiesAndHotelFacilitiesForm from "./addAmenitiesHotelForm";

const AddAmenities = (props) => {
  const [active, updateActive] = useState("1");
  return (
    <Container fluid className={classes.Box}>
      <Row
        style={{ margin: "0 0 2% 0", textAlign: "center", cursor: "pointer" }}
      >
        {/* <Col
          xs={6}
          className={classes.Col}
          style={{
            backgroundColor: `${active === "1" ? "steelblue" : "#585858"}`,
            color: "white",
            padding: "1%",
          }}
          onClick={() => updateActive("1")}
        >
          Add Amenities
        </Col> */}
        {/* <Col xs={6} className={classes.Col}
                style={{backgroundColor:`${active==='2'?'steelblue':'#585858'}`,color:'white',padding:'1%'}}
                onClick={()=>updateActive("2")}>
                    Add Cab Facilities
                </Col> */}
      </Row>
      {active === "1" ? (
        <AddAmenitiesAndHotelFacilitiesForm
          categoryType="Amenities"
          data={[]}
        />
      ) : active === "2" ? (
        <AddAmenitiesAndHotelFacilitiesForm
          categoryType="Cab Facilities"
          data={[]}
        />
      ) : null}
    </Container>
  );
};
export default AddAmenities;
