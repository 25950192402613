import React, {useState, Fragment } from 'react';
import {Col,Row, Container} from 'react-bootstrap';
import classes from '../WebsiteManagement/websiteManagement.module.css';
import classesX from '../Dashboard/dashboard.module.css'
import AdminNavbar from '../navbar/adminNavbar';
import Accommodation from './Accommodation/Accommodation';
import FlightD from './Flight/FlightD';
import BusD from './Bus/BusD';
import CabD from './Cab/CabD';
import HolidayD from './Holiday/HolidayD';
import TourD from './Tour/TourD';
const BookingManagement = (props)=>{
    const [active,updateActive] = useState('1');
    const items = [
        {id:"1",type:"Flight"},
        {id:"2",type:"Accommodation"},
        {id:"3",type:"Cab"},
        {id:"4",type:"Bus"},
        {id:"5",type:"Holiday"},
        {id:"6",type:"Charter Plane"},
        {id:"7",type:"Insurance"},
        {id:"8",type:"Tour Guide"}
    ]
 
    return(
        <Fragment> 
           <AdminNavbar/>
            <h1 className={classesX.Heading}>Booking Managements</h1>  
        {/* <Container fluid style={{paddingLeft:'10px'}}>
            <Row> 
                <Col lg={12} md={12} className={classes.Response2}>
                    <div style={{height:'100%',width:"90vw"}}> 
                        <ul  style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 }}
>
                            {
                                items.map(item=><li 
                                    key={item.id} 
                                    onClick={()=>updateActive(item.id)}
                                    className={classes.Li}
                                    style={{backgroundColor:`${active===item.id?'steelblue':'#585858'}`,textAlign:'center'}}>
                                    {item.type}
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </Col>
                <Col lg={9} md={12} className={classes.Response}>
                    <div style={{height:'100%'}}> 
                        {
                            active==="1"?null:null
                        }   
                    </div>
                </Col>
            </Row>
        </Container> */}

<Container fluid style={{ paddingLeft: '10px', width: '90%' }}>
    <Row>
        <Col lg={12} md={12} className={classes.Response2}>
            <div style={{ height: '100%' }}>
                <ul className={classes.Ul} style={{ display: 'flex', flexDirection: 'row', listStyle: 'none', padding: 0 }}>
                    {
                        items.map(item => (
                            <li
                                key={item.id}
                                onClick={() => updateActive(item.id)}
                                className={classes.Li}
                                style={{
                                    backgroundColor: `${active === item.id ? 'steelblue' : '#585858'}`,
                                    textAlign: 'center',
                                    margin: '0 1px', // Adjust the margin as needed
                                    padding:" 2% 0",
                                    color: 'white',
                                    cursor: 'pointer',
                                    width:"25%",
                                    height:"100%"
                                }}
                            >
                                {item.type}
                            </li>
                        ))
                    }
                </ul>
            </div>
        </Col>
        <Col lg={12} md={12} className={classes.Response}>
            <div style={{ height: '100%' }}>
            {
                active === "1" ? (
                    <FlightD/>
                ) : active === "2" ? (
                  <Accommodation />
                ) : active == "3" ? (
                  <CabD/>
                ) : active == "4" ? (
                    <BusD/>

                ) : active == "5" ? (
                    <HolidayD/>
                ) : active == "6" ? (
                    null
                )  : active == "7" ? (
                    null
                )  : active == "8" ? (
                    <TourD/>
                ) : null
                // active==="3"?<CouponView data={couponData}/>:null
              }
            </div>
        </Col>
    </Row>
</Container>

        </Fragment>
    )
}

export default BookingManagement;